import React, {useEffect, useState} from 'react';
import {Dialog, DialogContent, DialogTitle, Grid, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Close, Delete} from '@material-ui/icons';
import {useInsightMaps} from "../../../context/InsightMindMapsContext";
import PopConfirmCustom from "../../Custom/PopConfirmCustom";

const list = ['gray', 'yellow', 'red', 'blue', 'green'];

function KeyDialog({open, setOpen, keys, setKeys}) {
    const classes = useStyles();

    const [select, setSelect] = useState('');
    const {updateField} = useInsightMaps()
    useEffect(() => {
        if (open) {
            setSelect(open.item);
        }
    }, [open]);
    const handleClose = () => {
        setOpen(null);
    };
    const onSave = (item) => {
        if (!open) return;

        keys[open.id] = item;
        setKeys({...keys});
        updateField("key", keys)
        handleClose();
    };

    const onDelete = async () => {
        try {
            delete keys[open.id];
            setKeys({...keys});
            updateField("key", keys)
            handleClose()
        } catch (e) {
            console.log(e);
        }
    }

    const onCancel = () => {

    }

    return (
        <Dialog open={Boolean(open)} onClose={handleClose} className={classes.root}>
            <DialogTitle>
                １０ 変革の鍵
                <IconButton className={classes.close} onClick={handleClose}>
                    <Close />
                </IconButton>
                {
                    Boolean(keys[open?.id]) &&
                    <PopConfirmCustom
                        title={"Do you want to delete?"}
                        onConfirm={onDelete}
                        onCancel={onCancel}
                        okText={'Yes'}
                        cancelText={'No'}
                    >
                        <IconButton className={classes.delete}>
                            <Delete />
                        </IconButton>
                    </PopConfirmCustom>
                }
            </DialogTitle>
            <DialogContent>
                <Grid container justify={'center'}>
                    {list.map((item) => {
                        return (
                            <Grid item xs={2} onClick={() => onSave(item)} key={item}
                                  style={{minwidth: 60, margin: "auto"}}>
                                <img
                                    alt={''}
                                    src={`img/key/${item}.png`}
                                    className={classes.img}
                                    style={
                                        select === item
                                            ? {
                                                borderBottom: `3px solid ${item}`,
                                                paddingBottom: 10,
                                                // maxWidth: "100%",
                                            }
                                            : {
                                                paddingBottom: 10,
                                                // maxWidth: "100%",
                                            }
                                    }
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialogTitle-root': {
            minWidth: 600,
            background:
                '-webkit-linear-gradient(45deg, rgba(174, 211, 97, 1) 0%, rgba(174, 211, 97, 0.5) 49%, rgba(174, 211, 97, 0.25) 50%, rgba(174, 211, 97, 0.75) 100%)',
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bold',
        },
        '& .MuiDialogActions-root': {
            justifyContent: 'center',
        },
        '& .MuiDialogContent-root': {
            overflowY: "hidden",
            textAlign: 'center'
        },
        [theme.breakpoints.down('xs')]: {
            '& .MuiDialogTitle-root': {
                minWidth: 'auto',
            },
        },
    },
    img: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    close: {
        float: 'right',
        padding: 0,
    },
    delete: {
        float: "right",
        padding: 0,
        marginRight: 10
    },
}));

export default KeyDialog;
