export let LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
export let REGISTER_URL = process.env.REACT_APP_REGISTER_URL;
export let SWITCH_ACCOUNT = process.env.REACT_APP_LOGIN_URL + '/select-account';
export let API_GENIAM = process.env.REACT_APP_API_GENIAM;
export let IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';
export let GET_USER_INFO = API_GENIAM + '/getuserdata';
export let LOGOUT_URL = API_GENIAM + '/logout';
export let ACCESS_TOKEN_KEY = 'accessToken';
export let REFRESH_TOKEN_KEY = 'refreshToken';
export let USER_INFO_KEY = 'userInfo';
export let insightMapColl = IS_PRODUCTION ? 'insightmind' : 'insightmind-stg';
export let insightMapShareColl = IS_PRODUCTION ? 'insightMindShare' : 'insightMindShare-stg';
export let CART_URL = IS_PRODUCTION ? "https://cart2.geniam.com" : "https://cart2-stg.geniam.com"
export let GENIAM_REFRESH_TOKEN = `ge_${process.env.REACT_APP_ENV}_refreshToken`;
export const URL_INSIGHTMIND = IS_PRODUCTION ? "https://ism.geniam.com" : "https://ism-stg.geniam.com"
export let RECOMMEND_COLLECTION = IS_PRODUCTION ? "recommend" : "recommend-stg"

export const HX_CLOUD_DOMAIN = IS_PRODUCTION ? 'https://hx-cloud.geniam.com' : 'https://hx-cloud-stg.geniam.com'
export const APPOINTMENT_DOMAIN = IS_PRODUCTION ? 'https://appy.geniam.com' : 'https://appy-stg.geniam.com'
export const MILESTONE_DOMAIN = IS_PRODUCTION ? "https://milestone.geniam.com/" : "https://milestone-stg.geniam.com/"
export const ZOOM_REDIRECT_OAUTH = IS_PRODUCTION ? "https://calendar.geniam.com" : "https://calendar-stg.geniam.com"

export const GLOBAL_NAVI_APPS_COLL = IS_PRODUCTION ? "GlobalNavigation" : "GlobalNavigation-stg"
export const APP_GLOBAL_ID = "calenview"
export const SUBSCRIPTION_SERVICES = IS_PRODUCTION ? "https://myaccount.geniam.com/account/subscription" : "https://myaccount-stg.geniam.com/account/subscription"
export let ZOOM_COLLECTION = IS_PRODUCTION ? "metamors" : "metamors-stg"

export let MEETING_COLLECTION = IS_PRODUCTION ? "meetings" : "meetings-stg"
export let IDENTITY_COLLECTION = IS_PRODUCTION ? "identities" : "identities-stg"
export let SERVICE_COLLECTION = IS_PRODUCTION ? "serviceguide" : "serviceguide-stg"
export const USER_COLL = IS_PRODUCTION ? "users" : "users-stg"
export const SUBSCRIPTION_COLL = 'subscriptions'

export let CALENDAR_COLLECTION = IS_PRODUCTION ? "calendar" : "calendar-stg"
