import {calenviewProd, oldPlan} from './calenviewPlan';


export const plans = [
    {
        numOfCal: 3,
        numOfView: 1,
        info: calenviewProd[0],
        type: null,
        price: null
    },
    {
        numOfCal: 8,
        numOfView: 4,
        info: calenviewProd[1]
    },
    {
        numOfCal: 16,
        numOfView: 8,
        info: calenviewProd[2]
    },
    {
        numOfCal: 50,
        numOfView: 8,
        info: calenviewProd[3]
    },
]
const getServiceId = (prices = []) => {
    let serviceId = 0
    let type = null
    let price = null
    calenviewProd.forEach(item => {
        if (prices.includes(item.price.month) || prices.includes(item.price.year)) {
            serviceId = item.serviceId
            type = prices.includes(item.price.year) ? "year" : "month"
            price = prices.includes(item.price.year) ? item.price.year : item.price.month
        }
    })
    if (!serviceId) {
        oldPlan.forEach((plan) => {
            if (prices.includes(plan)) {
                serviceId = 1
                type = 'month'
                price = plan
            }
        })
    }
    return {serviceId, type, price}
}
export default (prices = []) => {
    if (!prices.length) {
        return plans[0]
    }
    const {serviceId, type, price} = getServiceId(prices)
    return {...plans[serviceId], type, price}
}
