export const updatedName = {
    "map": "葛藤",
    "line": "境界線",
    "quantizing": "エネルギー",
    "space": "バイアス",
    "identity": "主体",
    "clear": "クリア",
    "barrier": "ブロック",
    "key": "変革の鍵",
    "process": "プロセス",
    "task": "タスク",
    "memo": "メモ",
    "restore": "データを復元する"
}
