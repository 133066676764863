import React, {createContext, useContext, useEffect, useState} from 'react';
import Fire from '../firebaseConfig';
import Cookies from 'js-cookie';
import {COOKIE_GENIAM_REFRESH_TOKEN_KEY} from '../config/cookies';
import qs from 'query-string';
import {getUserData, signInFirebase} from '../actions/user';
import {useHistory} from 'react-router-dom';
import {setGlobal, useGlobal} from 'reactn';
import {insightMapColl} from "../config/constants";
import moment from "moment";
import {v4 as uuidv4} from 'uuid'
import _ from "lodash";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {
    getFolderCollRef,
    getIsmUserDocRef,
    getMapCollRef,
    getShareMapCollRef,
    getTagCollRef
} from "../common/firebaseRef";

// import TinySegmenter from "tiny-segmenter"

// const {useGlobal, setGlobal} = globalValue.default;
export const InsightMindUserContext = createContext({});

export const useInsightMindUserContext = () => useContext(InsightMindUserContext)

export const InsightMindUserProvider = ({children}) => {
    const insightMindUser = useInsightMindUser();
    const query = qs.parse(window.location.search);
    const path = window.location.pathname;
    const history = useHistory();

    useEffect(() => {
        const {token, refreshToken} = query;

        if (token || refreshToken) {
            const opts = {expires: 1826};
            if (process.env.NODE_ENV === 'production') opts.domain = '.geniam.com';
            localStorage.setItem('accessToken', token);
            localStorage.setItem('refreshToken', refreshToken);
            Cookies.set(COOKIE_GENIAM_REFRESH_TOKEN_KEY, refreshToken, opts);
            delete query.token;
            delete query.refreshToken;
            history.push(`${path}?${qs.stringify(query)}`);
        }

        getUserData()
            .then((data) => {
                if (!data) setGlobal({langDefault: 'ja-jp', anonymous: true});
            })
            .catch((e) => {
                console.log(e);
            });

        Fire.auth().onAuthStateChanged((user) => {
            if (!user) {
                signInFirebase();
            }
        });
        // eslint-disable-next-line
    }, []);

    // if (!user?.user_id) return <LinearProgress/>;
    return <InsightMindUserContext.Provider value={insightMindUser}>
        {children}
    </InsightMindUserContext.Provider>;
};

export const useInsightMindUser = () => {
    const [insightMindUser = {}, setInsightMindUser] = useGlobal('insightMindUser');
    const [user] = useGlobal('user');
    const [tags, setTags] = useState([]);
    const [tagsFavorite, setTagsFavorite] = useState([]);
    const [tagsNormal, setTagsNormal] = useState([]);
    const [mapsData, setMapsData] = useState([]);
    const [mapShare, setMapShare] = useState([])
    const [sort, setSort] = useState("dateDown")
    const [map, loadMap, errorMap] = useCollection(user?.user_id && getMapCollRef())
    const [shareMap, loadShareMap, errorShare] = useCollection(user?.user_id && getShareMapCollRef())
    const [folder, loadFolder, errorFolder] = useCollection(user?.user_id && getFolderCollRef())
    const [tag, loadTag, errorTag] = useCollection(user?.user_id && getTagCollRef())
    const [ismUser, loadIsmUser, errorUser] = useDocument(user?.user_id && getIsmUserDocRef())

    useEffect(() => {
        if (!user?.user_id || loadIsmUser || errorUser)
            return;
        if (!ismUser && !loadIsmUser)
            return initCalendarUserSetting();

        const data = {id: user.user_id, ...ismUser.data()};
        setInsightMindUser(data)

    }, [user?.user_id, ismUser, loadIsmUser, errorUser])

    useEffect(() => {
        if (!user?.user_id || loadTag || errorTag)
            return;

        if (!tag && !loadTag)
            return initTagsDefault();

        let tagArray = [];
        let tagFavoritesData = [];
        let tagNormalsData = [];
        tag.docs.forEach(snapshot => {
            if (!snapshot.data().is_delete)
                tagArray.push({...snapshot.data(), id: snapshot.id});
            if (!snapshot.data().is_delete && snapshot.data().color && snapshot.data() && !snapshot.data().is_favorite)
                tagNormalsData.push({...snapshot.data(), id: snapshot.id});
            if (!snapshot.data().is_delete && snapshot.data().is_favorite)
                tagFavoritesData.push({...snapshot.data(), id: snapshot.id})
        });

        setTags([...tagArray]);
        setTagsFavorite([...tagFavoritesData]);
        sortTags(sort, tagNormalsData)
    }, [user?.user_id, tag, loadTag, errorTag])

    useEffect(() => {
        if (!user?.user_id || loadShareMap || errorShare || (!shareMap && !loadShareMap))
            return;

        const data = shareMap.docs.map(doc => ({...doc.data(), id: doc.id}));
        setMapShare(data)

    }, [user?.user_id, shareMap, loadShareMap, errorShare])

    useEffect(() => {
        if (!user?.user_id || loadMap || errorMap || (!map && !loadMap))
            return;
        const data = map.docs.map(doc => ({...doc.data(), id: doc.id, owner: user.user_id}));
        setMapsData(_.orderBy(data, "updatedAt", "desc"))
        setGlobal({
            maps: _.orderBy(data, "updatedAt", "desc")
        })

    }, [user?.user_id, map, loadMap, errorMap])

    useEffect(() => {
        if (!user?.user_id || loadFolder || errorFolder || (!folder && !loadFolder))
            return;

        const data = folder.docs.map(doc => ({...doc.data(), id: doc.id}))
        setGlobal({
            folders: data
        })

    }, [user?.user_id, folder, loadFolder, errorFolder])

    useEffect(() => {
        sortTags(sort, tagsNormal)
    }, [sort])

    const sortTags = (sort, tagsNormal) => {
        if (!tagsNormal.length)
            return setTagsNormal([])
        let nextValue = [...tagsNormal]
        if (sort === "dateDown") {
            nextValue.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        }
        if (sort === "dateUp") {
            nextValue.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        }
        if (sort === "nameDown") {

            nextValue.sort((a, b) => {
                const x = a.content.toLowerCase();
                const y = b.content.toLowerCase();
                if (x > y) return 1
                if (x < y) return -1
                return 0
            })

        }
        if (sort === "nameUp") {
            nextValue.sort((a, b) => {
                const x = a.content.toLowerCase();
                const y = b.content.toLowerCase();
                if (x > y) return -1
                if (x < y) return 1
                return 0
            })
        }
        setTagsNormal([...nextValue])
    }
    const initCalendarUserSetting = async () => {
        try {
            const batch = Fire.firestore().batch();
            const userRef = Fire.firestore().doc(insightMapColl +
                `/${user.user_id}`
            );
            batch.set(userRef, {
                email: user.email,
                nickname: user.nickname,
                uuid: user.user_id,
                menu_position: 0,
                is_deleted: 0,
                isDeleted: false,
                is_auto_capture: 1,
                is_auto_update: 0,
                show_guide: 1,
                reg_date: moment().format(),
                createdAt: moment().format(),
                updatedAt: moment().format(),
            }, {merge: true});
            // handle some continues init
            const folderId = uuidv4();
            const folderRef = Fire.firestore().doc(
                `${insightMapColl}/${user.user_id}/folder/${folderId}`
            );
            batch.set(folderRef, {
                folder_id: folderId,
                id: folderId,
                is_delete: false,
                name: "Myインサイトマインド",
                createdAt: moment().format(),
                updatedAt: moment().format(),
            });
            await batch.commit();
        } catch (e) {
            console.log(e.toString())
        }
    };

    const initTagsDefault = async () => {
        try {
            const batch = Fire.firestore().batch();
            tagsDefault.forEach(tag => {
                const tagDocRef = Fire.firestore().doc(insightMapColl +
                    `/${user.user_id}/tags/${tag.tag_id}`
                );
                batch.set(tagDocRef, tag, {merge: true})
            });
            await batch.commit()
        } catch (e) {
            console.log(e.toString())
        }
    };


    return {
        insightMindUser,
        tags,
        tagsNormal,
        tagsFavorite,
        sort,
        setSort,
        mapsShare: mapShare,
        maps: mapsData,
        loading: loadMap || loadShareMap
    };
};

const tagsDefault = [
    {
        color: "gray",
        content: "what is this?",
        is_delete: false,
        is_favorite: false,
        reg_date: moment().format(),
        sort: 0,
        tag_id: Date.now(),
        upd_date: null,
        createdAt: moment().format(),
        updatedAt: moment().format()
    },
    {
        color: "red",
        content: "what is tag?",
        is_delete: false,
        is_favorite: false,
        reg_date: moment().format(),
        sort: 0,
        tag_id: Date.now() + 1,
        upd_date: null,
        createdAt: moment().format(),
        updatedAt: moment().format()
    }
];
