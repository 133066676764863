import React, {useEffect, useState} from 'react';
import rtn from "reactn";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Close, Delete} from '@material-ui/icons';
import {useInsightMaps} from "../../../context/InsightMindMapsContext";
import {insightMapColl} from "../../../config/constants";
import moment from "moment";
import {useInsightMindUserContext} from "../../../context/InsightMindUserProvider";
import _ from "lodash";
import ListIdentities from "./DialogContent/ListIdentities";
import InputTag from "./DialogContent/InputTag";
import CheckedShowHistoryIdentity from "./DialogContent/CheckedShowHistoryIdentity";
import ListRecentIdentities from "./DialogContent/ListRecentIdentities";
import ButtonCustom from "../../ButtonCustom";
import useKeyboardJs from "react-use/lib/useKeyboardJs";
import Fire from "../../../firebaseConfig"
import PopConfirmCustom from "../../Custom/PopConfirmCustom";

function IdentityDialog({open, setOpen, identity, setIdentity}) {
    const classes = useStyles();
    const {map, updateField, tagsShare, updateMultiFiled} = useInsightMaps();
    const {tags} = useInsightMindUserContext();
    const listIdentities = ['gray', 'yellow', 'red', 'blue', 'green'];
    const [text, setText] = useState('');
    const [color, setColor] = useState('gray');
    const [tag, setTag] = useState('');
    const [subject, setSubject] = useState('none');
    const [user] = rtn.useGlobal('user');
    const [showHistoryIdentity, setShowHistoryIdentity] = useState(false);
    const [showRecent, setShowRecent] = useState(false);
    const [tagSelected, setTagSelected] = useState(null);
    const [isPressed] = useKeyboardJs('enter')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (isPressed) {
            onSave()
        }
    }, [isPressed])


    useEffect(() => {
        if (open) {
            setText(open.text);
            setColor(open.color);
            setSubject(open.subject);
            getTagInfo();
        }
    }, [open, tags, map]);

    const getTagInfo = () => {
        let value = map?.tag_id && map.tag_id[open.id] ? map.tag_id[open.id] : null;
        if (!value) return null;
        let data = map?.owner === user.user_id ? tags : tagsShare
        const idx = _.findIndex(data, tg => `${tg.id}` === `${value}`);
        if (idx !== -1) {
            setTag(data[idx].content);
            return data[idx]
        }
        setTag("")
        return null;
    };

    const onSave = async () => {
        try {
            setLoading(true)
            if (!open) return null;
            let tagIds = map?.tag_id || {};
            let identityData = identity;
            let colorIdentity = showHistoryIdentity && tagSelected ? tagSelected.color : color;
            identityData[open.id] = `${subject || "none"},${colorIdentity},${text}`;
            setIdentity({...identityData});

            if (showHistoryIdentity && tagSelected) {
                tagIds[open.id] = tagSelected.id;
                if (map.owner !== user.user_id) {
                    const tagDocRef = Fire.firestore().doc(insightMapColl + `/${map.owner}/tags/${tagSelected.id}`);
                    await tagDocRef.set(tagSelected, {merge: true})
                }
                // updateField("tag_id", tagIds, null, false);
            } else if (tag) {
                let tagId = Date.now();
                let tagInfo = getTagInfo();
                let reg_date = moment().format();
                let is_favorite = false;
                let is_delete = false;

                let upd_date = moment().format();
                if (!tagInfo) {
                    tagIds[open.id] = tagId;
                    // updateField("tag_id", tagIds, null, false);
                } else {
                    tagId = tagInfo.tag_id;
                    tagIds[open.id] = tagId;
                    reg_date = tagInfo?.reg_date || null;
                    upd_date = moment().format();
                    is_favorite = tagInfo?.is_favorite || false;
                    is_delete = tagInfo?.is_delete || false;
                    // updateField("tag_id", tagIds, null, false);
                }
                const tagDocRef = Fire.firestore().doc(insightMapColl + `/${map.owner}/tags/${tagId}`);
                await tagDocRef.set({
                    color: color,
                    content: tag,
                    is_delete,
                    is_favorite,
                    reg_date,
                    sort: 0,
                    tag_id: tagId,
                    upd_date: upd_date,
                }, {merge: true})
            }
            updateMultiFiled(["identity", "tag_id"], [identityData, tagIds], null, false);
            onClose();
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
        }
    };

    const onClose = () => {
        setTag("");
        setColor(null);
        setText(null);
        setTagSelected(null);
        setShowRecent(false);
        setShowHistoryIdentity(false)
        setOpen(null);
    };

    const onDelete = async () => {
        try {
            delete identity[open.id];
            setIdentity({...identity});
            updateField("identity", identity)
            let tagIds = map?.tag_id || {};
            if (tagIds[open.id]) {
                delete tagIds[open.id]
                updateField("tag_id", tagIds);
            }
            onClose()
        } catch (e) {
            console.log(e);
        }
    }

    const onCancel = () => {

    }

    return (
        <Dialog open={Boolean(open)} onClose={onClose} className={classes.root}>
            <DialogTitle>
                ６ 主体
                <IconButton
                    className={classes.close}
                    onClick={() => {
                        setOpen(null);
                    }}
                >
                    <Close />
                </IconButton>
                {
                    Boolean(identity[open?.id]) &&
                    <PopConfirmCustom
                        title={"Do you want to delete?"}
                        onConfirm={onDelete}
                        onCancel={onCancel}
                        okText={'Yes'}
                        cancelText={'No'}
                    >
                        <IconButton className={classes.delete}>
                            <Delete />
                        </IconButton>
                    </PopConfirmCustom>
                }
            </DialogTitle>

            <DialogContent>
                <div className={classes.formContent}>
                    <Typography>吹き出しの言葉</Typography>
                    <TextField
                        autoFocus={true}
                        fullWidth
                        variant={'outlined'}
                        className={classes.textField}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <img alt={'penIco'} src={'img/icon/pen.png'} />
                                </InputAdornment>
                            )
                        }}
                        value={text ? text : ""}
                        onChange={(e) => {
                            if (e.target.value?.length > 30) return null;
                            setText(e.target.value);
                        }}
                    />
                </div>
                {
                    showHistoryIdentity ?
                        <ListRecentIdentities
                            classes={classes}
                            showHistoryIdentity={showHistoryIdentity}
                            setShowHistoryIdentity={setShowHistoryIdentity}
                            showRecent={showRecent}
                            setShowRecent={setShowRecent}
                            tagSelected={tagSelected}
                            setTagSelected={setTagSelected}
                        />
                        :
                        <>
                            <ListIdentities
                                classes={classes}
                                color={color}
                                setColor={setColor}
                                listIdentities={listIdentities}
                            />
                            <InputTag
                                classes={classes}
                                tag={tag}
                                setTag={setTag}
                            />
                        </>
                }
                <CheckedShowHistoryIdentity
                    classes={classes}
                    showHistoryIdentity={showHistoryIdentity}
                    setShowHistoryIdentity={setShowHistoryIdentity}
                />
            </DialogContent>
            <Divider light className={classes.divider} />
            <DialogActions className={classes.actionsContainer}>
                <ButtonCustom onClick={onSave}
                              className={classes.button}
                              backgroundColor={"rgb(241, 95, 144, 1)"}
                              backgroundBlur={"rgba(241, 95, 144, 0.5)"}
                              disabled={loading}
                >Ok</ButtonCustom>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDialog-paperWidthSm": {
            minWidth: 600,
        },
        '& .MuiDialogTitle-root': {
            background:
                '-webkit-linear-gradient(45deg, rgba(241, 95, 144, 1) 0%, rgba(241, 95, 144, 0.5) 49%, rgba(241, 95, 144, 0.25) 50%, rgba(241, 95, 144, 0.75) 100%)',
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bold',
        },
        '& .MuiDialogActions-root': {
            justifyContent: 'center',
        },
        '& .MuiDialogContent-root': {
            overflowX: "hidden"
        },
        [theme.breakpoints.down('xs')]: {
            '& .MuiDialog-paper': {
                minWidth: 'auto',
            },
        },

    },
    button: {
        margin: '10px auto',
    },
    actionsContainer: {
        backgroundColor: '#f0f0f0',
    },
    divider: {
        backgroundColor: 'black',
    },
    formContent: {
        marginTop: 30,
        marginBottom: 10,
        padding: '0px 10px',
        minWidth: 400,
        [theme.breakpoints.down('xs')]: {
            minWidth: 'auto',
        },
    },
    listIdentities: {
        // display:"flex",
        // justifyContent: "center"
        marginBottom: 10,
    },
    identityImg: {
        position: "relative",
        textAlign: "center",
        '&:hover': {
            cursor: 'pointer',
        },
        paddingBottom: 10,
    },
    identityImgBottom: {
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
        height: "6px",
        transition: "all 200ms ease-in-out 0s"
    },
    textField: {
        '& input': {
            textAlign: 'center',
            padding: 10,
            marginRight: 40
        },
    },
    formCheckbox: {
        display: 'flex',
        alignItems: 'center',
    },
    close: {
        float: 'right',
        padding: 0,
    },
    delete: {
        float: "right",
        padding: 0,
        marginRight: 10
    },
    divHistoryIdentities: {},
    startTitle: {
        margin: "0 10px",
        padding: "15px 0"
    },
    buttonRecent: {
        borderBottom: "1px solid #a3b3b3",
        padding: "2.5em 0 !important",
        marginTop: "-1rem",
        textAlign: "center",
        marginBottom: "20px"
    },
    buttonRecentDiv: {
        border: "1px solid #d671ad",
        color: "#d671ad",
        width: "80%",
        padding: "10px 16px",
        fontSize: "18px",
        lineHeight: 1.3333333,
        borderRadius: "6px",
        backgroundColor: "#fff",
        userSelect: "none",
        backgroundImage: "none",
        touchAction: "manipulation",
        cursor: "pointer",
        marginBottom: 0,
        fontWeight: 400,
        whiteSpace: "nowrap",
    },
    buttonLoadMore: {},
    divRecentGrid: {
        position: "relative", width: "100%", textAlign: "center", minHeight: "120px"
    },
    divRecent: {
        position: "absolute", top: 0, left: 0, right: 0
    },
    divImg: {
        position: "relative",
        width: "100%",
        cursor: "pointer",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "70px",
        color: "#fff",
        paddingTop: "30%"
    },
    divShowRecent: {},
    IdentitySelected: {
        position: "absolute",
        top: "78px",
        left: "50%",
        transform: "translate(-50%)",
        height: "6px",
        transition: "all 200ms ease-in-out 0s"
    },
    glyphiconCSS: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%)"
    },
    iconIden: {
        width: "100%",
        maxWidth: 40,
        margin: "auto"
    }
}));
export default IdentityDialog;
