export const linesMap = [
    {
        id: '01',
        type: 'circle',
        d: 'M0,15 A15,15 0 0,0 0,-15',
        transform: '',
    },
    {
        id: '02',
        type: 'circle',
        d: 'M0,15 A15,15 0 0,0 0,-15',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '03',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-11.5',
        y2: '-113.5',
        transform: '',
    },
    {
        id: '04',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-11.5',
        y2: '-113.5',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '05',
        type: 'circle',
        d: 'M110,3.5 A110,110 0 0,0 -3.5,-110',
        transform: '',
    },
    {
        id: '06',
        type: 'circle',
        d: 'M110,3.5 A110,110 0 0,0 -3.5,-110',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '07',
        type: 'circle',
        d: 'M110,3.5 A110,110 0 0,0 -3.5,-110',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '08',
        type: 'circle',
        d: 'M110,3.5 A110,110 0 0,0 -3.5,-110',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '09',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-106.5',
        y2: '-223.5',
        transform: '',
    },
    {
        id: '10',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-106.5',
        y2: '-223.5',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '11',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-106.5',
        y2: '-223.5',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '12',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-106.5',
        y2: '-223.5',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '13',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: '',
    },
    {
        id: '14',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: '15',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '16',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: '17',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '18',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: '19',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '20',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: '21',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: '',
    },
    {
        id: '22',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: '23',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '24',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: '25',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '26',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: '27',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '28',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: '29',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: '',
    },
    {
        id: '30',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(0.9239,0.3827,-0.3827,0.9239,0,0)',
    },
    {
        id: '31',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: '32',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(0.3827,0.9239,-0.9239,0.3827,0,0)',
    },
    {
        id: '33',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '34',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)',
    },
    {
        id: '35',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: '36',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)',
    },
    {
        id: '37',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '38',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)',
    },
    {
        id: '39',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: '40',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)',
    },
    {
        id: '41',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '42',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(0.3827,-0.9239,0.9239,0.3827,0,0)',
    },
    {
        id: '43',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: '44',
        type: 'circle',
        d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(0.9239,-0.3827,0.3827,0.9239,0,0)',
    },
    {
        id: '45',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: '',
    },
    {
        id: '46',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(0.9239,0.3827,-0.3827,0.9239,0,0)',
    },
    {
        id: '47',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: '48',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(0.3827,0.9239,-0.9239,0.3827,0,0)',
    },
    {
        id: '49',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '50',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)',
    },
    {
        id: '51',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: '52',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)',
    },
    {
        id: '53',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '54',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)',
    },
    {
        id: '55',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: '56',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)',
    },
    {
        id: '57',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '58',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(0.3827,-0.9239,0.9239,0.3827,0,0)',
    },
    {
        id: '59',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: '60',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-326.5',
        y2: '-441.75',
        transform: 'matrix(0.9239,-0.3827,0.3827,0.9239,0,0)',
    },
    {
        id: '61',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: '',
    },
    {
        id: '62',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(0.9239,0.3827,-0.3827,0.9239,0,0)',
    },
    {
        id: '63',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: '64',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(0.3827,0.9239,-0.9239,0.3827,0,0)',
    },
    {
        id: '65',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '66',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)',
    },
    {
        id: '67',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: '68',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)',
    },
    {
        id: '69',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '70',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)',
    },
    {
        id: '71',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: '72',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)',
    },
    {
        id: '73',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '74',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(0.3827,-0.9239,0.9239,0.3827,0,0)',
    },
    {
        id: '75',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: '76',
        type: 'circle',
        d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
        transform: 'matrix(0.9239,-0.3827,0.3827,0.9239,0,0)',
    },
    {
        id: '77',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-436.5',
        y2: '-553.5',
        transform: '',
    },
    {
        id: '78',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-436.5',
        y2: '-553.5',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: '79',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-436.5',
        y2: '-553.5',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '80',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-436.5',
        y2: '-553.5',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: '81',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-436.5',
        y2: '-553.5',
        transform: '',
    },
    {
        id: '82',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-436.5',
        y2: '-553.5',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: '83',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-436.5',
        y2: '-553.5',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '84',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-436.5',
        y2: '-553.5',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: '85',
        type: 'circle',
        d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
        transform: '',
    },
    {
        id: '86',
        type: 'circle',
        d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: '87',
        type: 'circle',
        d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '88',
        type: 'circle',
        d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: '89',
        type: 'circle',
        d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '90',
        type: 'circle',
        d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: '91',
        type: 'circle',
        d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '92',
        type: 'circle',
        d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: '93',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-546.5',
        y2: '-663.5',
        transform: '',
    },
    {
        id: '94',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-546.5',
        y2: '-663.5',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '95',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-546.5',
        y2: '-663.5',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '96',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-546.5',
        y2: '-663.5',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '97',
        type: 'circle',
        d: 'M660,3.5 A660,660 0 0,0 -3.5,-660',
        transform: '',
    },
    {
        id: '98',
        type: 'circle',
        d: 'M660,3.5 A660,660 0 0,0 -3.5,-660',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '99',
        type: 'circle',
        d: 'M660,3.5 A660,660 0 0,0 -3.5,-660',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '100',
        type: 'circle',
        d: 'M660,3.5 A660,660 0 0,0 -3.5,-660',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '101',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-656.5',
        y2: '-773.5',
        transform: '',
    },
    {
        id: '102',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-656.5',
        y2: '-773.5',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '103',
        type: 'circle',
        d: 'M0,770 A770,770 0 0,0 0,-770',
        transform: '',
    },
    {
        id: '104',
        type: 'circle',
        d: 'M0,770 A770,770 0 0,0 0,-770',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
];

export const linesMiniMap = [
    {
        id: '01',
        type: 'circle',
        d: 'M0,15 A15,15 0 0,0 0,-15',
        transform: '',
    },
    {
        id: '02',
        type: 'circle',
        d: 'M0,15 A15,15 0 0,0 0,-15',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '03',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-11.5',
        y2: '-113.5',
        transform: '',
    },
    {
        id: '04',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-11.5',
        y2: '-113.5',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '05',
        type: 'circle',
        d: 'M110,3.5 A110,110 0 0,0 -3.5,-110',
        transform: '',
    },
    {
        id: '06',
        type: 'circle',
        d: 'M110,3.5 A110,110 0 0,0 -3.5,-110',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '07',
        type: 'circle',
        d: 'M110,3.5 A110,110 0 0,0 -3.5,-110',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '08',
        type: 'circle',
        d: 'M110,3.5 A110,110 0 0,0 -3.5,-110',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '09',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-106.5',
        y2: '-223.5',
        transform: '',
    },
    {
        id: '10',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-106.5',
        y2: '-223.5',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '11',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-106.5',
        y2: '-223.5',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '12',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-106.5',
        y2: '-223.5',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '13',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: '',
    },
    {
        id: '14',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: '15',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '16',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: '17',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '18',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: '19',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '20',
        type: 'circle',
        d: 'M157.31199999999998,-153.81199999999998 A220,220 0 0,0 -3.5,-220',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: '21',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: '',
    },
    {
        id: '22',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: '23',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '24',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: '25',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '26',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: '27',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '28',
        type: 'line',
        x1: '0',
        x2: '0',
        y1: '-216.5',
        y2: '-333.5',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: '29',
        type: 'circle',
        d: 'M230,-235 A330,330 0 0,0 -3.5,-330',
        transform: '',
    },
    {
        id: '30',
        type: 'circle',
        d: 'M230,-235 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: '31',
        type: 'circle',
        d: 'M230,-235 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '32',
        type: 'circle',
        d: 'M230,-235 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: '33',
        type: 'circle',
        d: 'M230,-235 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '34',
        type: 'circle',
        d: 'M230,-235 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: '35',
        type: 'circle',
        d: 'M230,-235 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '36',
        type: 'circle',
        d: 'M230,-235 A330,330 0 0,0 -3.5,-330',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    // {
    //     id: '37',
    //     type: 'circle',
    //     d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
    //     transform: 'matrix(-1,0,0,-1,0,0)',
    // },
    // {
    //     id: '38',
    //     type: 'circle',
    //     d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
    //     transform: 'matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)',
    // },
    // {
    //     id: '39',
    //     type: 'circle',
    //     d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
    //     transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    // },
    // {
    //     id: '40',
    //     type: 'circle',
    //     d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
    //     transform: 'matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)',
    // },
    // {
    //     id: '41',
    //     type: 'circle',
    //     d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
    //     transform: 'matrix(0,-1,1,0,0,0)',
    // },
    // {
    //     id: '42',
    //     type: 'circle',
    //     d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
    //     transform: 'matrix(0.3827,-0.9239,0.9239,0.3827,0,0)',
    // },
    // {
    //     id: '43',
    //     type: 'circle',
    //     d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
    //     transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    // },
    // {
    //     id: '44',
    //     type: 'circle',
    //     d: 'M129.791,-303.137 A330,330 0 0,0 -3.5,-330',
    //     transform: 'matrix(0.9239,-0.3827,0.3827,0.9239,0,0)',
    // },
    // {
    //     id: '45',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: '',
    // },
    // {
    //     id: '46',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(0.9239,0.3827,-0.3827,0.9239,0,0)',
    // },
    // {
    //     id: '47',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    // },
    // {
    //     id: '48',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(0.3827,0.9239,-0.9239,0.3827,0,0)',
    // },
    // {
    //     id: '49',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(0,1,-1,0,0,0)',
    // },
    // {
    //     id: '50',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)',
    // },
    // {
    //     id: '51',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    // },
    // {
    //     id: '52',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)',
    // },
    // {
    //     id: '53',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(-1,0,0,-1,0,0)',
    // },
    // {
    //     id: '54',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)',
    // },
    // {
    //     id: '55',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    // },
    // {
    //     id: '56',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)',
    // },
    // {
    //     id: '57',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(0,-1,1,0,0,0)',
    // },
    // {
    //     id: '58',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(0.3827,-0.9239,0.9239,0.3827,0,0)',
    // },
    // {
    //     id: '59',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    // },
    // {
    //     id: '60',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-326.5',
    //     y2: '-441.75',
    //     transform: 'matrix(0.9239,-0.3827,0.3827,0.9239,0,0)',
    // },
    // {
    //     id: '61',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: '',
    // },
    // {
    //     id: '62',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(0.9239,0.3827,-0.3827,0.9239,0,0)',
    // },
    // {
    //     id: '63',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    // },
    // {
    //     id: '64',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(0.3827,0.9239,-0.9239,0.3827,0,0)',
    // },
    // {
    //     id: '65',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(0,1,-1,0,0,0)',
    // },
    // {
    //     id: '66',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)',
    // },
    // {
    //     id: '67',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    // },
    // {
    //     id: '68',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)',
    // },
    // {
    //     id: '69',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(-1,0,0,-1,0,0)',
    // },
    // {
    //     id: '70',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)',
    // },
    // {
    //     id: '71',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    // },
    // {
    //     id: '72',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)',
    // },
    // {
    //     id: '73',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(0,-1,1,0,0,0)',
    // },
    // {
    //     id: '74',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(0.3827,-0.9239,0.9239,0.3827,0,0)',
    // },
    // {
    //     id: '75',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    // },
    // {
    //     id: '76',
    //     type: 'circle',
    //     d: 'M171.888,-404.766 A440,440 0 0,0 -3.5,-440',
    //     transform: 'matrix(0.9239,-0.3827,0.3827,0.9239,0,0)',
    // },
    // {
    //     id: '77',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-436.5',
    //     y2: '-553.5',
    //     transform: '',
    // },
    // {
    //     id: '78',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-436.5',
    //     y2: '-553.5',
    //     transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    // },
    // {
    //     id: '79',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-436.5',
    //     y2: '-553.5',
    //     transform: 'matrix(0,1,-1,0,0,0)',
    // },
    // {
    //     id: '80',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-436.5',
    //     y2: '-553.5',
    //     transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    // },
    // {
    //     id: '81',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-436.5',
    //     y2: '-553.5',
    //     transform: '',
    // },
    // {
    //     id: '82',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-436.5',
    //     y2: '-553.5',
    //     transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    // },
    // {
    //     id: '83',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-436.5',
    //     y2: '-553.5',
    //     transform: 'matrix(0,-1,1,0,0,0)',
    // },
    // {
    //     id: '84',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-436.5',
    //     y2: '-553.5',
    //     transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    // },
    // {
    //     id: '85',
    //     type: 'circle',
    //     d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
    //     transform: '',
    // },
    // {
    //     id: '86',
    //     type: 'circle',
    //     d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
    //     transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    // },
    // {
    //     id: '87',
    //     type: 'circle',
    //     d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
    //     transform: 'matrix(0,1,-1,0,0,0)',
    // },
    // {
    //     id: '88',
    //     type: 'circle',
    //     d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
    //     transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    // },
    // {
    //     id: '89',
    //     type: 'circle',
    //     d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
    //     transform: 'matrix(-1,0,0,-1,0,0)',
    // },
    // {
    //     id: '90',
    //     type: 'circle',
    //     d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
    //     transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    // },
    // {
    //     id: '91',
    //     type: 'circle',
    //     d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
    //     transform: 'matrix(0,-1,1,0,0,0)',
    // },
    // {
    //     id: '92',
    //     type: 'circle',
    //     d: 'M390.655,-387.155 A550,550 0 0,0 -3.5,-550',
    //     transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    // },
    // {
    //     id: '93',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-546.5',
    //     y2: '-663.5',
    //     transform: '',
    // },
    // {
    //     id: '94',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-546.5',
    //     y2: '-663.5',
    //     transform: 'matrix(0,1,-1,0,0,0)',
    // },
    // {
    //     id: '95',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-546.5',
    //     y2: '-663.5',
    //     transform: 'matrix(-1,0,0,-1,0,0)',
    // },
    // {
    //     id: '96',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-546.5',
    //     y2: '-663.5',
    //     transform: 'matrix(0,-1,1,0,0,0)',
    // },
    // {
    //     id: '97',
    //     type: 'circle',
    //     d: 'M660,3.5 A660,660 0 0,0 -3.5,-660',
    //     transform: '',
    // },
    // {
    //     id: '98',
    //     type: 'circle',
    //     d: 'M660,3.5 A660,660 0 0,0 -3.5,-660',
    //     transform: 'matrix(0,1,-1,0,0,0)',
    // },
    // {
    //     id: '99',
    //     type: 'circle',
    //     d: 'M660,3.5 A660,660 0 0,0 -3.5,-660',
    //     transform: 'matrix(-1,0,0,-1,0,0)',
    // },
    // {
    //     id: '100',
    //     type: 'circle',
    //     d: 'M660,3.5 A660,660 0 0,0 -3.5,-660',
    //     transform: 'matrix(0,-1,1,0,0,0)',
    // },
    // {
    //     id: '101',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-656.5',
    //     y2: '-773.5',
    //     transform: '',
    // },
    // {
    //     id: '102',
    //     type: 'line',
    //     x1: '0',
    //     x2: '0',
    //     y1: '-656.5',
    //     y2: '-773.5',
    //     transform: 'matrix(-1,0,0,-1,0,0)',
    // },
    // {
    //     id: '103',
    //     type: 'circle',
    //     d: 'M0,770 A770,770 0 0,0 0,-770',
    //     transform: '',
    // },
    // {
    //     id: '104',
    //     type: 'circle',
    //     d: 'M0,770 A770,770 0 0,0 0,-770',
    //     transform: 'matrix(-1,0,0,-1,0,0)',
    // },
];
