import React, {createContext, useContext, useEffect, useState} from 'react';
import qs from 'query-string';
import {db} from '../firebaseConfig';
import {API_GENIAM, insightMapColl, insightMapShareColl} from "../config/constants";
import rtn from 'reactn'
import moment from "moment";
import {useHistory, useParams} from 'react-router-dom'
import {useInsightMindUserContext} from "./InsightMindUserProvider";
import _ from 'lodash'
import {v4 as uuidv4} from "uuid";
import axios from "axios";
import {DB_COLL} from "../common/envSettings";
import {toast} from "react-toastify";
import {findIndex} from "lodash/array";

const insightMindContext = createContext({});

insightMindContext.displayName = 'InsightMap';

export function InsightMapsProvider({children}) {
    const map = useProviderInsightMap();
    return <insightMindContext.Provider value={map}>{children}</insightMindContext.Provider>;
}

export const useInsightMaps = () => useContext(insightMindContext)

// const isFooterCaptureNeed = ['quantizing', "process", "task", "tag_id", "memo", "years"]

export function useProviderInsightMap() {
    const history = useHistory();
    const [map, setMap] = useState(null);
    const [role, setRole] = useState("");
    const [loadingMap, setLoadingMap] = useState(false);
    const [otherUsers, setOtherUsers] = useState([])
    const [histories, setHistories] = useState([])
    const query = qs.parse(window.location.search);
    const [user] = rtn.useGlobal('user')
    const [folders] = rtn.useGlobal('folders')
    const [tagsShare, setTagsShare] = useState([])
    const params = useParams();

    const {mapsShare, maps} = useInsightMindUserContext()

    const updateField = async (field, value, callback, isNoCapture = false) => {
        if (!user.user_id)
            return null;
        if (field === "step" && map?.owner !== user.user_id)
            return
        if (!map) {
            if (window.location.pathname === "/insightMaps" && folders.length) {
                // create new map
                const id = Date.now()
                const token = `token_${uuidv4()}`
                try {
                    const data = {
                        title: "無題のインサイトマップ",
                        image: "img/map-default.png",
                        folder_id: folders[0].id,
                        is_delete: false,
                        id,
                        role: 0,
                        token,
                        createdAt: moment().utc().format(),
                        updatedAt: moment().utc().format(),
                        copy: 0,
                        [field]: value
                    }
                    const batch = db.batch()
                    const mapRef = db.doc(`${DB_COLL}/${user.user_id}/insightmaps/${id}`)
                    const historyId = uuidv4()
                    const historyRef = db.doc(`${DB_COLL}/${user.user_id}/histories/${historyId}`)
                    batch.set(mapRef, data)
                    batch.set(historyRef, {role: 0, mapId: `${id}`, updatedAt: Date.now(), isDefault: true})
                    await batch.commit()
                    history.push(`insightmap?id=${id}`)
                } catch (e) {
                    toast.error(e.toString())
                }
            }
            return
        }
        const time = Date.now()
        const batch = db.batch()
        const mapRef = db.doc(`${insightMapColl}/${map.owner}/insightmaps/${map.id}`)

        try {
            if (field !== "step" && field !== "tag_id") {
                const historyId = uuidv4()
                const historyRef = db.doc(`${insightMapColl}/${map.owner}/histories/${historyId}`)
                batch.set(historyRef, {
                    mapId: map.id,
                    updatedAt: time,
                    preValue: map[field] || null,
                    fieldName: field,
                    value,
                    role: map.role
                })
            }

            batch.set(mapRef, {
                ...map,
                [field]: value,
                updatedAt: moment().utc().format(),

            })
            await batch.commit()

            if (callback && typeof callback === "function")
                callback({...map, [field]: value});

        } catch (e) {
            console.log(e);
        }
    };
    const updateMultiFiled = async (fields = [], values = [], callback) => {
        try {
            if (!map || !user.user_id || !fields.length)
                return null;
            const time = Date.now()
            const batch = db.batch()
            const mapRef = db.doc(`${insightMapColl}/${map.owner}/insightmaps/${map.id}`)
            fields.forEach((field, index) => {
                // set histories
                if (field !== "step" && field !== "tag_id") {
                    const historyId = uuidv4()
                    const historyRef = db.doc(`${insightMapColl}/${map.owner}/histories/${historyId}`)
                    batch.set(historyRef, {
                        mapId: map.id,
                        updatedAt: time,
                        preValue: map[field] || null,
                        fieldName: field,
                        value: values[index] || null,
                        role: map.role
                    })
                }
            })
            const data = {...map}
            fields.forEach((field, index) => {
                data[field] = values[index]
            })
            batch.set(mapRef, {
                ...data,
                updatedAt: moment().utc().format(),
            })
            await batch.commit()
            if (callback && typeof callback === "function") {
                callback(data)
            }

        } catch (e) {
            console.log(e);
        }
    }

    const reloadMap = () => {

    };

    const joinMapShare = async (uid, token, id) => {
        const mapRef = db.doc(`${insightMapColl}/${uid}/insightmaps/${id}`)
        const snap = await mapRef.get()
        if (!snap.exists)
            return window.location = "/filemanager"
        const data = snap.data()
        if (data.token !== token) {
            return window.location = "/filemanager"
        }
        const shareId = uuidv4()
        // const inviteId = uuidv4()
        const batch = db.batch()
        const shareRef = db.doc(`${insightMapShareColl}/${shareId}`)
        batch.set(shareRef, {
            mapId: id,
            id: shareId,
            isDeleted: false,
            owner: uid,
            userInvite: user.user_id
        })
        await batch.commit()
        history.push("/insightmap?id=" + id)
    }

    useEffect(() => {
        let subMap;
        let historySub
        let tagSub
        if (!query?.id || !user?.user_id || window.location.pathname !== '/insightmap') {
            setMap(null);
            setLoadingMap(false);
            setRole("")
            setHistories([])
            setTagsShare([])
            setOtherUsers([])
            return;
        }

        if (query?.id && query.id !== map?.id) {
            let mapRef
            let historyRef
            let owner
            let role
            let tagRef
            let idx = findIndex(maps, {id: query.id})
            if (idx === -1) {
                let shareIdx = findIndex(mapsShare, {mapId: query.id})
                if (shareIdx !== -1) {
                    role = "share"
                    owner = mapsShare[shareIdx].owner
                    mapRef = db.doc(`${insightMapColl}/${mapsShare[shareIdx].owner}/insightmaps/${query.id}`)
                    historyRef = db.collection(`${insightMapColl}/${mapsShare[shareIdx].owner}/histories`)
                    tagRef = db.collection(`${insightMapColl}/${mapsShare[shareIdx].owner}/tags`)
                        .where("is_delete", "==", false)
                }
            } else {
                owner = user.user_id
                role = "owner"
                mapRef = db.doc(`${insightMapColl}/${user.user_id}/insightmaps/${query.id}`)
                historyRef = db.collection(`${insightMapColl}/${user.user_id}/histories`)
            }

            if (!mapRef)
                return;

            subMap = mapRef.onSnapshot((snapshot) => {
                    if (!snapshot.exists || snapshot.data().isDeleted) {
                        return (window.location = '/filemanager');
                    }
                    const mapData = {...snapshot.data(), id: snapshot.id, owner};
                    setMap(mapData);
                    setRole(role)
                },
            );

            historySub = historyRef
                .where("mapId", "==", query.id)
                .orderBy("updatedAt", "asc")
                .onSnapshot(snapshot => {
                    const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
                    // console.log(data);
                    setHistories(data)
                })

            if (tagRef) {
                tagSub = tagRef.onSnapshot(snapshot => {
                    const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
                    setTagsShare(data)
                })
            } else {
                setTagsShare([])
            }
        }

        return () => {
            if (subMap) subMap();
            if (historySub) historySub()
            if (tagSub) tagSub()
        };

        // eslint-disable-next-line
    }, [query?.id, user?.user_id, mapsShare]);

    //join map share from user
    useEffect(() => {
        if (query?.id && query?.uid && query?.validToken) {
            let mapRef
            let idx = findIndex(maps, {id: query.id})
            if (idx === -1) {
                let shareIdx = findIndex(mapsShare, {mapId: query.id})
                if (shareIdx !== -1) {
                    mapRef = db.doc(`${insightMapColl}/${mapsShare[shareIdx].owner}/insightmaps/${query.id}`)
                }
            } else {
                mapRef = db.doc(`${insightMapColl}/${user.user_id}/insightmaps/${query.id}`)
            }

            if (!mapRef) {
                // not exist
                const {uid, validToken} = query
                if (uid && validToken) {
                    return joinMapShare(uid, validToken, query.id)
                }
                console.log('not exits')
            }
        }
        // eslint-disable-next-line
    }, [query?.id, query?.uid, query?.validToken, user?.user_id]);

    useEffect(() => {
        if (map?.id) {
            getOtherUsers(map)
        }
    }, [map?.id])

    const getOtherUsers = async (map) => {
        let userIds = []
        try {
            if (map.owner !== user.user_id) {
                // this map is shared by another user. just load owner data
                userIds = [map.owner]

            } else {
                // this is owner of map. load all user shared
                const inviteRef = db.collection(`${insightMapShareColl}`)
                    .where("mapId", "==", map.id)
                    .where("isDeleted", "==", false)
                const snap = await inviteRef.get()
                userIds = snap.docs.map(doc => doc.data().userInvite)
            }
            if (!userIds.length) {
                setOtherUsers([])
                return
            }

            const {data} = await axios.post(`${API_GENIAM}/insightmindgetUserShare`, {
                userIds
            })
            setOtherUsers(data)
        } catch (e) {
            console.log(e);
        }

    }
    const generateHistory = () => {
        if (!histories.length || !map)
            return []
        let mapInfo = {}
        // find start point
        let idx = _.findIndex(histories, {isDefault: true})
        if (idx !== -1) {
            mapInfo = {...histories[idx]}
        }
        let result = []
        histories.forEach(item => {
            if (item.fieldName && !item.isDefault) {
                if (item.isRestore) {
                    mapInfo = {...item}
                    result.push({...item, updateField: "restore"})
                } else {
                    mapInfo[item.fieldName] = item.value
                    result.push({
                        ...mapInfo,
                        role: map.role,
                        id: item.updatedAt,
                        historyId: item.id,
                        isDeleted: Boolean(item.isDeleted),
                        updateField: item.fieldName
                    })
                }

            }
        })
        return result
    }
    return {
        map,
        setMap,
        role,
        loading: loadingMap,
        updateField,
        reloadMap,
        generateHistory,
        otherUsers,
        tagsShare,
        updateMultiFiled
    };
}

