import axios from 'axios';
import {refreshToken} from '../actions/user';
import React from 'react';
import localToken from '../config/getLocalToken';
import {COOKIE_GENIAM_ACCESS_TOKEN_KEY, COOKIE_GENIAM_REFRESH_TOKEN_KEY, removeCookie} from "./cookies";

export default () => {
    //REQUEST
    axios.interceptors.request.use(
        (config) => {
            const {token} = localToken();
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            if (error && error.request) {
                console.log(error);
                console.log(error.request);
                // toast.error(<ToastFormat error={error}/>)
            }
            return Promise.reject(error);
        },
    );
    //RESPONSE
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            const originalRequest = error.config;
            // const query = qs.parse(window.location.search);
            if (!error.response) {
                // toast.error(<ToastFormat error={error}/>);
                return Promise.reject(error);
            }
            if (
                (error.response.status === 400 && error.response.data.error_code === 'NotAuthorizedException') ||
                (error.response.status === 401 && error.response.data.error === 'login required')
            ) {
                if (
                    error.response.data.error_message === 'Invalid Refresh Token' ||
                    error.response.data.error_message === 'Refresh Token has been revoked' ||
                    error.response.data.error_message === 'Refresh Token has expired'
                ) {
                    removeCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY);
                    removeCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY);
                    // window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
                    return Promise.reject(error);
                }
                const data = await refreshToken();
                if (data) {
                    originalRequest.headers['Authorization'] = `Bearer ${data.accessToken}`;
                    return axios(originalRequest);
                } else {
                    return Promise.reject(error);
                }
            }
            return Promise.reject(error);
        },
    );
};
//
// function ToastFormat({error}) {
//     const {url} = error.config;
//     const msg = error.toString();
//     return (
//         <div>
//             <div>{url}</div>
//             <div>{msg}</div>
//         </div>
//     );
// }
