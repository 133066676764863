import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {Grid} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import handleSearch from "./actions/handleSearch";
// import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import ButtonCustom from "../ButtonCustom";
import qs from 'query-string'
import {useHistory} from 'react-router-dom';
import moment from "moment";
import jaLocale from "date-fns/locale/ja"

const localeMap = {
    ja: jaLocale
};

function AppBarSearch(props) {
    const classes = useStyles();
    const history = useHistory()
    const [search, setSearch] = useState('');
    const [free_word, setFree_word] = useState('');
    const [tag, setTag] = useState('');
    const [openDropDown, setOpenDropDown] = useState(false)
    const [startDay, setStartDay] = useState(null);
    const [endDay, setEndDay] = useState(null);
    const [locale,] = useState("ja");
    // const [isEnterPressed] = useKeyboardJs('enter');
    //
    // useEffect(() => {
    //     // if (isEnterPressed && (search || free_word || tag || startDay || endDay)) {
    //     //     if (search && !free_word && !tag && !startDay && !endDay) {
    //     //         handleSearch(search)
    //     //     } else if (free_word || tag || startDay || endDay) {
    //     //         handleSearch('', tag, free_word, startDay, endDay)
    //     //     } else {
    //     //         handleSearch(null)
    //     //     }
    //     // }
    //     // if (isEnterPressed && !search && !free_word && !tag && !startDay && !endDay)
    //     //     handleSearch(null)
    //     onSearch()
    //     // eslint-disable-next-line
    // }, [isEnterPressed])

    const handleDateChangeStart = (date) => {
        setStartDay(date)
    };
    const handleDateChangeEnd = (date) => {
        setEndDay(date)
    };
    const handleClickAway = () => {
        setOpenDropDown(false);
        setSearch('')
        setTag('')
        setFree_word('')
        setStartDay(null)
        setEndDay(null)
    };
    const handleClick = () => {
        setOpenDropDown((openDropDown) => !openDropDown);
        setSearch('')
        setTag('')
        setFree_word('')
        setStartDay(null)
        setEndDay(null)
    };
    const onSearch = () => {
        const params = {}
        if (free_word || tag || startDay || endDay) {
            if (free_word) {
                params.search = free_word
            }
            if (tag) {
                params.tag = tag
            }
            if (startDay) {
                params.from = moment(startDay).format('YYYY-MM-DD')
            }
            if (endDay) {
                params.to = moment(endDay).format('YYYY-MM-DD')
            }
            const query = qs.stringify(params)
            history.push("/filemanager?" + query)
            return
        }
        if (search) {
            history.push("/filemanager?search=" + search)
            return
        }
        history.push("/filemanager")

    }
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.Hidden}>
                <div className={classes.inputSearch}>
                    <input
                        className={classes.searchStyle}
                        value={search}
                        type="search"
                        name={"search"}
                        id={"search"}
                        autoComplete={"off"}
                        placeholder={"Enter your search..."}
                        onChange={(e) => setSearch(e.target.value)}
                        onFocus={handleClickAway}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                onSearch()
                            }
                        }}
                    />
                    <div className={classes.iconDropdown}>
                        <ArrowDropDownIcon onClick={handleClick}/>
                    </div>
                </div>
                <div className={classes.iconSearch} onClick={onSearch}>
                    <span className="glyphicon glyphicon-search"/>
                </div>
                {
                    openDropDown &&
                    <div className={classes.inputDropdown}>
                        <div className={classes.marginLeft_right}>
                            <p className={classes.text_title}>フリーワード</p>
                            <input
                                placeholder="フリーワード"
                                className={classes.inputStyle}
                                value={free_word}
                                onChange={(e) => setFree_word(e.target.value)}
                            />
                        </div>
                        <div className={classes.marginLeft_right}>
                            <p className={classes.text_title}>タグ</p>
                            <input
                                placeholder="タグ"
                                className={classes.inputStyle}
                                value={tag}
                                onChange={(e) => setTag(e.target.value)}
                            />
                        </div>
                        <div className={classes.marginLeft_right}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                <div className={classes.marginStyle}>
                                    <p className={classes.text_title}>Day</p>
                                    <Grid container>
                                        <Grid item xs={5} className={classes.girdPosition}>
                                            <DatePicker
                                                autoOk
                                                disableToolbar
                                                variant="inline"
                                                inputVariant={"outlined"}
                                                value={startDay}
                                                onChange={handleDateChangeStart}
                                                size={"small"}
                                                format={"yyyy年MM月dd日"}

                                            />
                                            <div className={classes.iconDate}>
                                                <span className="glyphicon glyphicon-calendar"/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <p className={classes.textDate}>~</p>
                                        </Grid>
                                        <Grid item xs={5} className={classes.girdPosition}>
                                            <DatePicker
                                                autoOk
                                                disableToolbar
                                                variant="inline"
                                                inputVariant={"outlined"}
                                                value={endDay}
                                                onChange={handleDateChangeEnd}
                                                size={"small"}
                                                format={"yyyy年MM月dd日"}
                                            />
                                            <div className={classes.iconDate}>
                                                <span className="glyphicon glyphicon-calendar"/>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <div className={classes.footerDropdown}>

                                        <ButtonCustom
                                            className={classes.customMore}
                                            onClick={onSearch}
                                            backgroundColor={"rgba(43, 187, 173, 1)"}
                                            backgroundBlur={"rgba(43, 187, 173, 0.5)"}
                                        >
                                            <SearchIcon/>
                                        </ButtonCustom>
                                    </div>
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                }
            </div>
        </ClickAwayListener>
    );
}

export default AppBarSearch;

const useStyles = makeStyles((theme) => ({
    Hidden: {
        [theme.breakpoints.down('sm')]: {
            display: "none",
        },
    },
    inputSearch: {
        display: "inline-block",
        position: "relative",
        border: 'none'

    },
    searchStyle: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        padding: '6px 12px',
        width: 400,
        fontSize: 14,
        border: '1px solid #ccc',
        color: 'black',
        outline: 'none'
    },
    iconSearch: {
        display: "inline-block",
        backgroundColor: '#019fea',
        color: '#fff',
        borderColor: '#019fea',
        border: '1px solid #ccc',
        borderRadius: 4,
        padding: '6px 12px',
        fontSize: 14,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    iconDropdown: {
        position: "absolute",
        top: '15%',
        right: 10,
        color: 'black'
    },
    inputDropdown: {
        minWidth: 160,
        width: 425,
        // height: 200,
        backgroundColor: "white",
        position: 'absolute',
        borderRadius: 3,
        border: '1px solid gray'
    },
    inputStyle: {
        width: '100%',
        padding: '6px 12px',
        borderRadius: 6,
        outline: "none",
        border: '1px solid #ccc',
        color: 'black'
    },
    text_title: {
        color: 'black',
        fontSize: 14,
        listStyle: 'none',
        fontWeight: 700
    },
    marginLeft_right: {
        marginLeft: 15,
        marginRight: 15
    },
    iconSearch1: {
        width: 38,
        height: 30,
        backgroundColor: "#2bbbad",
        color: '#fff',
        borderRadius: 8,
        boxShadow: '0 4px rgba(43, 187, 173, 0.5)',
        "&:hover": {
            cursor: "pointer"
        }
    },
    footerDropdown: {
        color: 'black',
        textAlign: 'center',
        paddingTop: 30,
        paddingBottom: 15,

    },
    iconDate: {
        position: "absolute",
        color: 'black',
        top: '22%',
        right: 10,
        fontSize: 18
    },
    textDate: {
        color: 'black',
        textAlign: 'center',
        lineHeight: "34px",
        margin: '0 0 10px'
    },
    girdPosition: {
        position: "relative",
        borderRadius: 8
    },
    marginStyle: {
        marginTop: 30,
        marginBottom: 20,

    },
    customMore: {
        padding: '4px 8px 0px !important'
    }

}))
