import {getGlobal} from 'reactn'
import {barRef, boardRef} from './firebaseRef/annoucement';
import {db} from "../firebaseConfig";

export const barDefault = {
    displayBar: true,
    announcementBar: true,
    highLightBar: false,
    remindBar: false,
    rotationInterval: 1,
    displayTips: true,
    reminds: [],
    announcementSets: {
        times: 3,
        data: [],
        startDate: null,
    },
    highlight: "",
    geniamNews: true,
    isShowHighLight: false,
    isShowTodayHighLight: false,
    indexHighLight: 0,
    indexDayTarget: 1,
    indexWeeksTarget: 2,
    indexMonthTarget: 3,
    indexRemind: 4
}

export const boardDefault = {
    display: true,
    rotationInterval: 1,
    displayTips: true,
    displaySets: true,
    displayCommunity: true,
    community: [
        {
            image: "",
            content: ""
        },
        // {
        //     image: "",
        //     content: ""
        // },
        // {
        //     image: "",
        //     content: ""
        // },
        // {
        //     image: "",
        //     content: ""
        // },
        // {
        //     image: "",
        //     content: ""
        // },
    ],
}
export default async () => {
    try {
        const {user} = getGlobal()
        if (!user?.user_id)
            return;
        const batch = db.batch()

        const myBarRef = barRef(user.user_id)
        const myBoardRef = boardRef(user.user_id)
        const barSnap = await myBarRef.get()
        const boardSnap = await myBoardRef.get()
        if (!barSnap.exists) {
            batch.set(myBarRef, barDefault)
        }
        if (!boardSnap.exists) {
            batch.set(myBoardRef, boardDefault)
        }
        await batch.commit()
    } catch (e) {

    }

}
