import {capture, footerCapture} from "./capture";
import FileSaver from "file-saver"
import {jsPDF} from "jspdf"

export const downLoadFile = (mimeType = "PNG", map, callback) => {
    if (!map) {
        if (callback && typeof callback === "function")
            callback(null)
        return
    }
    capture(map.role, (mapUrl) => {
        if (mimeType === "PNG") {
            //save png image
            FileSaver.saveAs(mapUrl, `${map.title}.png`)
            if (callback && typeof callback === "function")
                callback(null)
            return
        }
        // load footer to save pdf
        footerCapture((footer) => {
            const canvas = document.createElement("canvas")
            canvas.width = 1300
            canvas.height = 55
            const ctx = canvas.getContext("2d");
            ctx.font = "20px Meiryo";
            ctx.fillText(map.title, 10, 20);
            const text = canvas.toDataURL("image/png")
            const mapWidth = map.role === 1 ? 1600 : 1300
            const mapHeight = map.role === 1 ? 1850 : 1300
            const paddingLeft = 20
            const footerheight = mapWidth * footer.height / footer.width
            const pageWidth = mapWidth + paddingLeft * 2
            const pageHeight = mapHeight + footerheight + 60 + 20
            const doc = new jsPDF({
                unit: "px",
                format: [pageWidth, pageHeight]
            });
            doc.addImage(text, 'PNG', 10, 5, 1300, 55)
            doc.addImage(mapUrl, "PNG", paddingLeft, 60, mapWidth, mapHeight)
            doc.addImage(footer.dataUrl, "PNG", paddingLeft, mapHeight + 60, mapWidth, footerheight)

            doc.save(`${map.title}.pdf`)
            if (callback && typeof callback === "function")
                callback(null)
        })
    })
}
