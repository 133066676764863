import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {makeStyles} from '@material-ui/core/styles';
import {useInsightMaps} from "../../../context/InsightMindMapsContext";

const useStyles = makeStyles((theme) => ({
    divInput: {
        marginBottom: '30px',
    },
    error: {
        color: '#a94442',
        fontStyle: 'italic',
    },
    label: {
        display: 'inline-block',
        maxWidth: '100%',
        marginBottom: '5px',
        fontWeight: 700,
    },
    input: {
        display: 'block',
        width: '80%',
        height: '30px',
        padding: '6px 12px',
        fontSize: '14px',
        lineHeight: '1.42857143',
        color: '#555',
        backgroundColor: '#fff',
        backgroundImage: 'none',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
        transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
    },
    pen: {
        background: 'url(/img/icon/pen.png) no-repeat 8px 8px',
        paddingLeft: '30px',
    },
}));

export default function BlockConflictInput({conflict, setConflict, handleSave}) {
    const classes = useStyles();
    const {register, handleSubmit, errors} = useForm();
    const {map, updateField} = useInsightMaps()
    const [conflict1, setConflict1] = useState(conflict?.mapping_01 || '')
    const [conflict2, setConflict2] = useState(conflict?.mapping_02 || '')

    const onSubmit = (data) => {

        let conflict = map?.map || {}
        conflict.mapping_01 = conflict1 || ''
        conflict.mapping_02 = conflict2 || ''
        updateField("map", conflict)
        handleSave();
    };

    // const handleChange = (name, value) => {
    //     conflict[name] = value;
    //     setConflict({...conflict});
    // };
    return (
        <form id="conflictForm" onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.divInput}>
                <label className={classes.label}>陽</label>
                <input
                    value={conflict2}
                    className={`${classes.input} ${classes.pen}`}
                    type="text"
                    name="mapping_02"
                    maxLength="20"
                    ref={register({required: true, maxLength: 20})}
                    onChange={(e) => setConflict2(e.target.value)}
                />
                {errors?.mapping_02 && <span className={classes.error}>このフィールドは必須です。</span>}
            </div>
            <div className={classes.divInput}>
                <label className={classes.label}> 陰</label>
                <input
                    autoFocus
                    className={`${classes.input} ${classes.pen}`}
                    type="text"
                    value={conflict1}
                    name="mapping_01"
                    maxLength="20"
                    ref={register({required: true, maxLength: 20})}
                    onChange={(e) => setConflict1(e.target.value)}
                />
                {errors?.mapping_01 && <span className={classes.error}>このフィールドは必須です。</span>}
            </div>
        </form>
    );
}
