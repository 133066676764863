import React from 'react';
import {maps} from "../common/map";
import {linesMap} from "../common/line";
import _ from "lodash";
import {barrierMap} from "../common/barrier";
import {images} from "../common/images";
import {getVector} from "../common/vector";
import {getTextBoxPath, getTextPositon, identities, identitySize} from "../common/identitites";
import {getQuantizingUrl} from "../common/quantizing";
import {clearsMap} from "../common/clear";
import {keyMap} from "../common/keys";
import {getTextPath} from "../common/conflict";
import {makeStyles} from "@material-ui/core/styles";

const strokeWidth = 10
const stroke = "#a4a4a4"

function CaptureInsightMind({map, width = "100%", height = "100%", box = "0 0 1300 1300", captureId, minWidth}) {
    const classes = useStyles()
    return (
        <div>
            <svg
                width={width}
                // height={height}
                style={{display: "block", margin: 'auto'}}
                // transform={"scale(0.2)"}
                viewBox={box}
                id={captureId}
            >
                <defs>
                    <marker viewBox="0 0 20 20" markerWidth="10" markerHeight="10" orient="auto" refX="0"
                            refY="3"
                            id="arrow_gray" markerUnits="strokeWidth" fill="#a3b3b3" stroke="none">
                        <path d="M0,0 L0,6 L9,3 z"/>
                    </marker>
                    <marker viewBox="0 0 20 20" markerWidth="10" markerHeight="10" orient="auto" refX="0"
                            refY="3"
                            id="arrow_yellow" markerUnits="strokeWidth" fill="#f1c500" stroke="none">
                        <path d="M0,0 L0,6 L9,3 z"/>
                    </marker>
                    <marker viewBox="0 0 20 20" markerWidth="10" markerHeight="10" orient="auto" refX="0"
                            refY="3"
                            id="arrow_red" markerUnits="strokeWidth" fill="#e84c3d" stroke="none">
                        <path d="M0,0 L0,6 L9,3 z"/>
                    </marker>
                    <marker viewBox="0 0 20 20" markerWidth="10" markerHeight="10" orient="auto" refX="0"
                            refY="3"
                            id="arrow_blue" markerUnits="strokeWidth" fill="#267ebe" stroke="none">
                        <path d="M0,0 L0,6 L9,3 z"/>
                    </marker>
                    <marker viewBox="0 0 20 20" markerWidth="10" markerHeight="10" orient="auto" refX="0"
                            refY="3"
                            id="arrow_green" markerUnits="strokeWidth" fill="#27ae61" stroke="none">
                        <path d="M0,0 L0,6 L9,3 z"/>
                    </marker>
                </defs>
                <g transform="matrix(1,0,0,1,650,650)" id="group_mappings" fill={"none"}>
                    {
                        maps.map(item => {
                            if (!item || Number(item.id.split("_")[1]) > 30)
                                return null
                            return (
                                <g id={`group_${item.id}`} key={item.id}
                                >
                                    <path
                                        d={item.path}
                                        id={item.id}
                                        fill={item.fill}
                                        stroke={item.stroke || stroke}
                                        strokeWidth={strokeWidth}
                                        transform={item.transform}/>
                                </g>)
                        })
                    }
                </g>
                <g transform="matrix(1,0,0,1,650,650)" id="group_line">
                    {
                        map?.line && map?.line?.split(",").map(id => {
                            let idx = _.findIndex(linesMap, {id})
                            if (idx === -1) return null
                            const item = linesMap[idx]
                            return (
                                <g key={item.id} id={`line_${item.id}`}>
                                    {
                                        item.type === "circle" ?
                                            <path d={item.d}
                                                  transform={item.transform}
                                                  strokeWidth={10}
                                                  stroke={"#756bb0"}
                                                  fill={"none"}
                                                  className={classes.borderLineActive}
                                            />
                                            :
                                            <line
                                                x1={item.x1}
                                                x2={item.x2}
                                                y1={item.y1}
                                                y2={item.y2}
                                                transform={item.transform}
                                                strokeWidth={10}
                                                stroke={"#756bb0"}
                                                fill={"none"}
                                                className={classes.borderLineActive}
                                            />

                                    }
                                </g>
                            )
                        })
                    }
                </g>
                <g transform="matrix(1,0,0,1,650,650)" id="group_barriers">
                    {
                        map?.barrier && map?.barrier.split(",").map(id => {
                            let idx = _.findIndex(barrierMap, {id})
                            if (idx === -1)
                                return null
                            const item = barrierMap[idx]
                            return (
                                <g key={item.id} transform={item.transform}>
                                    <image href={"img/barrier.png"} width={"80"} height={"20"}
                                           x={item.x} y={item.y}
                                    />
                                </g>
                            )
                        })
                    }
                </g>
                <g transform="matrix(1,0,0,1,650,650)" id="group_vectors">
                    {
                        map?.vector && map?.vector.map((item, idx) => {
                            const data = getVector(item)
                            if (!data)
                                return null
                            return <g key={idx} style={{opacity: 0.8}}>
                                <path
                                    d={data.path}
                                    strokeWidth={data.stroke_width}
                                    stroke={data.color}
                                    strokeLinecap={"round"}
                                    strokeDasharray={data.strokeDasharray}
                                    markerEnd={data.maker}
                                    fill={"none"}
                                />
                            </g>
                        })
                    }

                </g>
                <g transform="matrix(1,0,0,1,650,650)" id="group_identities">
                    {map?.identity && Object.keys(map?.identity).map((key) => {
                        if (!map?.identity[key])
                            return null
                        const value = map.identity[key].split(',');
                        const img = value[1];
                        const display = identities[key];
                        const id = Number(display.id.split('_')[1]);
                        const text1 = value[2]?.substr(0, 10) || '';
                        const text2 = value[2]?.substr(10, 10) || '';
                        const text3 = value[2]?.substr(20, 10) || '';
                        const dataUri = images['pawn'][img]
                        return (
                            <g
                                id={display.id}
                                key={key}
                            >
                                <image
                                    href={dataUri}
                                    {...identitySize}
                                    x={display.x}
                                    y={display.y}
                                    id={`image_${display.id}`}
                                    // preserveAspectRatio={"none"}
                                    data-uri={dataUri}

                                />
                                {value[0] && value[0] !== 'none' ? (
                                        <image
                                            href={images['pawnmark'][value[0]]}
                                            x={Number(display.x) + identitySize.width / 4 - 2}
                                            y={Number(display.y) + identitySize.height / 2}
                                            data-uri={images['pawnmark'][value[0]]}
                                        />
                                    ) :
                                    null
                                }
                                <path
                                    d={getTextBoxPath(display.x, display.y, id)}
                                    strokeWidth={3}
                                    fill={'#ffffff'}
                                    stroke={'#c1c1c1'}
                                    style={
                                        value[2] && Number(key.split("_")[1] > 30)
                                            ? {}
                                            : {display: 'none'}
                                    }
                                />
                                <text
                                    x={'0'}
                                    y={'0'}
                                    style={value[2] && (Number(key.split("_")[1] > 30)) ?
                                        {
                                            lineHeight: 1,
                                            fontSize: 12,
                                            fontFamily: "Roboto, sans-serif"
                                        }
                                        : {display: 'none'}
                                    }
                                >
                                    {text1 ? (
                                        <tspan {...getTextPositon(display.x, display.y, id, 1)}>{text1}</tspan>
                                    ) : null}
                                    {text2 ? (
                                        <tspan {...getTextPositon(display.x, display.y, id, 2)}>{text2}</tspan>
                                    ) : null}
                                    {text3 ? (
                                        <tspan {...getTextPositon(display.x, display.y, id, 3)}>{text3}</tspan>
                                    ) : null}
                                </text>
                            </g>
                        );
                    })}
                </g>
                <g transform="matrix(1,0,0,1,650,650)" id="group_quantizing">
                    {map?.quantizing && Object.keys(map?.quantizing).map((key) => {
                        if (!map.quantizing[key]) return null;
                        const value = map.quantizing[key];
                        const img = getQuantizingUrl(key, value);
                        if (!img) return null;
                        return (
                            <g
                                id={`quantizing_${key}`}
                                key={key}>
                                <image
                                    href={img.dataUri}
                                    x={img.x}
                                    y={img.y}
                                    data-uri={img.dataUri}
                                />
                                <text
                                    style={{fontSize: img.fontSize, fontWeight: 100, lineHeight: 1.5}}
                                    x={img.x + img.size.width / 2}
                                    y={img.y + img.size.height / 2}
                                    stroke={'#ffffff'}
                                    fill={'#ffffff'}
                                    dominantBaseline="middle"
                                    textAnchor="middle"
                                >
                                    {value.split(',')[2]}
                                </text>
                            </g>
                        );
                    })}
                </g>
                <g transform="matrix(1,0,0,1,650,650)" id="group_clear">
                    {map?.clear && Object.keys(map.clear).map((key) => {
                        if (!map.clear[key]) return null;
                        const {x, y} = clearsMap[key];
                        const clearUri = images["clear"][map.clear[key]]
                        return (
                            <g
                                id={`clear_${key}`}
                                key={key}>
                                <image
                                    href={`img/clear/${map.clear[key]}.png`}
                                    width={'44'}
                                    height={'42'}
                                    x={x}
                                    y={y}
                                    data-uri={clearUri}
                                />
                            </g>
                        );
                    })}
                </g>
                <g transform="matrix(1,0,0,1,650,650)" id="group_key">
                    {map.key && Object.keys(map.key).map((key) => {
                        if (!map.key[key]) return null;
                        const {x, y} = keyMap[key];

                        const dataUri = images['key'][map.key[key]]
                        return (
                            <g
                                id={`key_${key}`}
                                key={key}>

                                <image
                                    href={dataUri}
                                    width={'35'}
                                    height={'36'}
                                    x={x}
                                    y={y}
                                    data-uri={dataUri}
                                />
                            </g>
                        );
                    })}
                </g>
                <g transform="matrix(1,0,0,1,650,650)" id="group_conflict">
                    {map.map && Object.keys(map.map).map((key) => {
                        if (!map.map[key]) return null;
                        return (
                            <g id={`conflict_${key}`} key={key}
                            >
                                {getTextPath(key, map.map[key])}
                            </g>
                        );
                    })}
                </g>
            </svg>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    borderLineActive: {
        '&:hover': {
            cursor: 'pointer',
        },
    }
}));
export default CaptureInsightMind;
