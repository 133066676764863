import React, {useEffect, useState} from 'react';
import styled from 'styled-components'

const Button = styled.button`
    background-color: ${props => props.backgroundColor};
    display: inline-block;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    color: #fff;
    position: relative;
    border-radius: 5px;
    box-shadow: 0 4px ${props => props.backgroundBlur};
    outline: none;
    &:after {
        content: '';
        transition-property: right;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
        position: absolute;
        left: 0;
        right: 100%;
        height: 4px;
        bottom: 0;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 4px ${props => props.backgroundBlur};
    }
    &:disabled {
        opacity: 0.5;
        pointer-event: none;
        box-shadow: none;
        cursor: not-allowed;
    }
     &:disabled:after{
        box-shadow: none;
        width: 0
    }
     &:disabled:hover{
          background-color: ${props => props.backgroundColor};
    }
    &:focus{
        outline: none;
    }
    &:hover{
        background-color: ${props => props.backgroundBlur}
    }
    &:hover:after{
        right: 0;
    }
`;


function ButtonCustom(props) {
    const {backgroundColor, backgroundBlur, loading} = props
    const [blur, setBlur] = useState(null)
    const hexToRgb = (hex) => {
        hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
            , (m, r, g, b) => '#' + r + r + g + g + b + b)
            .substring(1).match(/.{2}/g)
            .map(x => parseInt(x, 16))
    };
    // console.log(hexToRgb(backgroundColor), "blur")
    // console.log(backgroundColor);

    useEffect(() => {
        // console.log(blur, "blur");
    }, [backgroundColor])
    return (
        <Button
            backgroundColor={`${backgroundColor}`}
            backgroundBlur={`${backgroundBlur}`}
            {...props}
            loading={loading}
        />
    );
}

export default ButtonCustom;
