import axios from "axios";
import {API_GENIAM} from "../config/constants";
import rtn from 'reactn'
import _ from 'lodash'

const {setGlobal} = rtn

export const getSerivce = async () => {
    try {
        const {data} = await axios.get(`${API_GENIAM}/service`)
        if (!data.length)
            return null
        let idx = _.findIndex(data, {service_id: 1, service_name: "インサイトマインド"})
        if (idx === -1)
            return null
        setGlobal({
            expDate: data[idx].max_exp
        })
    } catch (e) {
        return null
    }
}
