import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {DialogActions, IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import rtn from "reactn";
import {db} from '../../firebaseConfig'
import SubjectIcon from '@material-ui/icons/Subject';
import DescriptionIcon from '@material-ui/icons/Description';
import ButtonCustom from "../ButtonCustom";

import {v4 as uuidv4} from 'uuid'
import {emailAdmin} from "../../common/setting";
import {getContenEmailUser, getContentEmailAdmin} from "./emailTemplate";
import {toast} from "react-toastify";
import useKeyboardJs from 'react-use/lib/useKeyboardJs';

const {useGlobal} = rtn


function DialogSupport(props) {
    const classes = useStyles()
    const [openDialogSupport, setOpenDialogSupport] = rtn.useGlobal('openDialogSupport');
    const [user] = rtn.useGlobal('user');
    const [subject, setSubject] = useState('')
    const [content, setContent] = useState('')
    const [loading, setLoading] = useState(false)
    const [isPressed] = useKeyboardJs('enter')

    const handleClose = () => {
        setOpenDialogSupport(false)
    };

    const handleSend = async () => {
        if (!subject || !content || loading)
            return
        try {
            setLoading(true)
            // send email to admin
            const adminEmailId = "support_" + uuidv4()
            const userEmailId = "support_" + uuidv4()
            const batch = db.batch()
            const adminEmailRef = db.doc(`mails/${adminEmailId}`)
            const userEmailRef = db.doc(`mails/${userEmailId}`)
            batch.set(adminEmailRef, {
                from: "no-reply<geniamevents@geniam.com>",
                to: emailAdmin,
                message: {
                    subject,
                    html: getContentEmailAdmin({content, nickname: user.nickname, email: user.email})
                }

            })
            batch.set(userEmailRef, {
                from: "no-reply<geniamevents@geniam.com>",
                to: user.email,
                message: {
                    subject: `Re:${subject}`,
                    html: getContenEmailUser({nickname: user.nickname})
                }
            })
            await batch.commit()
            toast.success("email send")
        } catch (e) {

        } finally {
            setLoading(false)
            setContent('')
            setSubject('')
            setOpenDialogSupport(false)
        }
    }

    useEffect(() => {
        if (isPressed && content && subject) {
            handleSend()
        }
    }, [isPressed])

    return (
        <Dialog
            className={classes.root}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openDialogSupport}>
            <DialogTitle>
                お問い合わせフォーム
                <IconButton className={classes.close}
                            onClick={handleClose}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div className={classes.dialog_body}>
                    <div className={classes.marginInput}>
                        <input className={classes.inputDialogSupport} placeholder="件名"
                               value={subject}
                               onChange={(e) => setSubject(e.target.value)}
                        />
                        <div className={classes.iconSubject}>
                            <SubjectIcon/>
                        </div>
                    </div>
                    <div className={classes.positionRelative}>
                        <textarea className={classes.inputDialogSupport1} placeholder="お問い合わせ内容"
                                  value={content}
                                  onChange={(e) => setContent(e.target.value)}
                        />
                        <div className={classes.iconDescription}>
                            <DescriptionIcon/>
                        </div>
                    </div>

                </div>


            </DialogContent>
            <DialogActions style={{backgroundColor: '#f0f0f0'}}>
                <div className={classes.supportFooter}>
                    <ButtonCustom
                        backgroundColor={'rgba(17, 160, 194, 1)'}
                        backgroundBlur={"rgba(17, 160, 194, 0.5)"}
                        onClick={handleSend}
                        disabled={!subject || !content || loading}
                    >
                        送信する
                    </ButtonCustom>
                </div>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiDialogTitle-root": {
            background: "linear-gradient(45deg, rgba(17, 160, 194, 1) 0%, rgba(17, 160, 194, 0.5) 49%, rgba(17, 160, 194, 0.25) 50%, rgba(17, 160, 194, 0.75) 100%)",
            color: "white",
            fontWeight: "bold",
            padding: 15,
            position: 'relative'
        },
        width: '100%',
        '& .MuiDialog-paper': {
            position: 'absolute',
            top: "50px",
            right: 0,
            left: 0,
            margin: "auto",
            [theme.breakpoints.down('sm')]: {
                left: 10,
                right: 10,
            },
        },

    },
    close: {
        float: "right",
        padding: 0
    },
    dialog_body: {
        maxHeight: 'calc(100vh - 200px)',
        padding: '2em',
        overflowY: 'auto',
        overflowX: 'hidden',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
    },
    inputDialogSupport: {
        paddingLeft: 35,
        display: 'block',
        width: '100%',
        height: '34px',
        padding: '6px 12px',
        fontSize: '14px',
        lineHeight: 1.42857143,
        color: '#555',
        backgroundColor: '#fff',
        backgroundImage: 'none',
        border: '1px solid #ccc',
        borderRadius: '4px'
    },
    inputDialogSupport1: {
        paddingLeft: 35,
        display: 'block',
        width: '100%',
        height: '200px',
        padding: '6px 12px',
        fontSize: '14px',
        lineHeight: 1.42857143,
        color: '#555',
        backgroundColor: '#fff',
        backgroundImage: 'none',
        border: '1px solid #ccc',
        borderRadius: '4px'
    },
    marginInput: {
        marginBottom: 15,
        position: 'relative'
    },
    iconSubject: {
        position: 'absolute',
        top: 6,
        left: 12
    },
    iconDescription: {
        position: "absolute",
        top: 5,
        left: 10
    },
    positionRelative: {
        position: "relative"
    },
    supportFooter: {
        width: '100%',
        textAlign: 'center',
        padding: 15,

    },
}))

export default DialogSupport;
