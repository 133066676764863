import * as d3 from "d3"
import html2canvas from "html2canvas"

export async function capture(role = 0, callback) {
    cloneMap()
    const doctype = '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';
    const source = (new XMLSerializer()).serializeToString(d3.select('#insight-map-clone').node());
    const blob = new Blob([doctype + source], {
        type: 'image/svg+xml;charset=utf-8'
    });
    const img = d3.select('#export-image-content').append('img').node();

    img.onload = function () {
        const canvas = d3.select('#export-image-content').append('canvas').node();
        canvas.width = role === 1 ? 1600 : 1300;
        canvas.height = role === 1 ? 1850 : 1300;
        canvas.style.backgroundColor = '#fff';
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        //bug on ie and edge when call drawImage in onload
        try {

            const dataUrl = canvas.toDataURL('image/png')
            if (callback && typeof callback === "function") {
                callback(dataUrl)
            }
        } catch (err) {
            console.log(err);
        } finally {
            document.getElementById("insight-map-clone").remove()
            canvas.remove()
            img.remove()
        }
    };
    img.src = window.URL.createObjectURL(blob);

}

const cloneMap = () => {
    const svg = document.getElementById("insightmap_svg").cloneNode(true)
    svg.setAttribute("id", "insight-map-clone")
    const images = svg.getElementsByTagName("image")
    const length = images.length
    if (length) {
        for (let i = 0; i < length; i++) {
            const item = images.item(i)
            if (item) {
                const uri = item.getAttribute("data-uri")
                if (uri) {
                    item.setAttribute("href", uri)
                }
                // if(item.style.display === "none"){
                //     item.remove()
                // }
            }

        }
    }
    const maps = svg.getElementById("group_mappings")
    if (maps)
        maps.childNodes.forEach(item => {
            if (item.childNodes[0]?.getAttribute("fill") === '#f7f7f7') {
                item.childNodes[0].setAttribute("fill", "none")
            }
        })

    document.body.appendChild(svg)
}

export const footerCapture = (callback) => {
    const element = document.querySelector("#map-footer-capture").cloneNode(true)
    // element.parentNode.style.overflow = 'visible'
    element.style.position = "inherit"
    document.body.appendChild(element)
    const width = element.offsetWidth;
    const height = element.offsetHeight
    html2canvas(element).then(canvas => {
        // document.body.appendChild(canvas)
        const dataUrl = canvas.toDataURL("image/png")
        element.remove()
        if (callback && typeof callback === "function") {
            callback({dataUrl, width, height})
        }
    })


}

export const captureMapId = async (id, role) => {
    const svg = document.getElementById(id).cloneNode(true)
    svg.setAttribute("id", "clone-" + id)
    const div = document.createElement("div")
    const cloneDiv = document.createElement("div")
    cloneDiv.setAttribute("id", "export-history-" + id)
    div.appendChild(svg)
    document.body.appendChild(div)
    document.body.appendChild(cloneDiv)
    const doctype = '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';
    const source = (new XMLSerializer()).serializeToString(d3.select("#clone-" + id).node());
    const blob = new Blob([doctype + source], {
        type: 'image/svg+xml;charset=utf-8'
    });
    const img = d3.select("#export-history-" + id).append('img').node();
    img.src = window.URL.createObjectURL(blob);
    return await new Promise((resolve, reject) => {
        img.onload = function () {
            const canvas = d3.select("#export-history-" + id).append('canvas').node();
            canvas.width = role === 1 ? 1600 : 1300
            canvas.height = role === 1 ? 1850 : 1300
            canvas.style.backgroundColor = '#fff';
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            //bug on ie and edge when call drawImage in onload
            try {
                const dataUrl = canvas.toDataURL(`image/png`)
                // FileSaver.saveAs(dataUrl,`${historyId}.png`)
                svg.remove()
                canvas.remove()
                img.remove()
                cloneDiv.remove()
                div.remove()
                resolve(dataUrl)
            } catch (err) {
                console.log(err);
                svg.remove()
                div.remove()
                cloneDiv.remove()
                canvas.remove()
                img.remove()
                reject("")
            }
        };
    })

}
