import React, {memo, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Fire, {db, storage} from "../../firebaseConfig";
import {useInsightMindUserContext} from "../../context/InsightMindUserProvider";
import {DB_COLL} from "../../common/envSettings";
import {capture} from "./common/capture";
import moment from "moment";
import {useInsightMaps} from "../../context/InsightMindMapsContext";
import {insightMapColl} from "../../config/constants";
import {toast} from "react-toastify";
import CaptureListDialog from "./CaptureList";

function RightMenu(props) {
    const classes = useStyles()
    const {insightMindUser} = useInsightMindUserContext()
    const [loading, setLoading] = useState(false)
    const [capturing, setCapturing] = useState(false)
    const [open, setOpen] = useState(false)
    const {map} = useInsightMaps()
    const handleUpdateAutoCapture = async () => {
        if (!insightMindUser?.id || loading)
            return
        setLoading(true)
        try {
            const userRef = db.doc(`${DB_COLL}/${insightMindUser.id}`)
            await userRef.set({
                is_auto_capture: !Boolean(insightMindUser.is_auto_capture)
            }, {merge: true})
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
        }

    }
    const handleCapture = () => {

        if (capturing || !insightMindUser?.id || !map?.id)
            return
        capture(map.role, async (base64) => {
            // upload to firebase storage
            try {
                setCapturing(true)
                const storeRef = storage.ref()
                const imgRef = storeRef.child(`insightMind/${insightMindUser.id}/${map.id}/${moment().format("YYYY_MM_DD")}_${Date.now()}.png`)
                const snap = await imgRef.putString(base64, 'data_url')
                const url = await snap.ref.getDownloadURL()
                // save url to captures
                const captureRef = Fire.firestore().doc(`${DB_COLL}/${insightMindUser.id}/captures/${map.id}`)
                await captureRef.set({
                    data: Fire.firestore.FieldValue.arrayUnion(url)
                }, {merge: true})
                // update map new image default
                const mapRef = db.doc(`${insightMapColl}/${insightMindUser.id}/insightmaps/${map.id}`)
                await mapRef.set({
                    image: url
                }, {merge: true})
                toast.success("Capture successfull !")
            } catch (e) {
                console.log(e);
            } finally {
                setCapturing(false)
            }

        })

    }
    return (
        <div className={"print-hidden"}>
            <div className={classes.iconFixed}>
                {/*<Tooltip title="画面のキャプチャ（Ctrl+Enter）">*/}
                {/*    <div className={classes.iconCamera}*/}
                {/*         onClick={handleCapture}*/}
                {/*    >*/}
                {/*        <img src="img/btn/camera.png" alt=""/>*/}
                {/*        <img src="img/btn/camera-hover.png" alt=""/>*/}
                {/*    </div>*/}
                {/*</Tooltip>*/}
                {/*<div className={classes.iconCamera}*/}
                {/*     onClick={handleUpdateAutoCapture}*/}
                {/*>*/}
                {/*    <img*/}
                {/*        src={insightMindUser?.is_auto_capture ? "img/btn/auto-camera-hover.png" : "img/btn/auto-camera.png"}*/}
                {/*        alt=""/>*/}
                {/*    <img src="img/btn/auto-camera-hover.png" alt=""/>*/}
                {/*</div>*/}

                <div className={classes.iconCamera}
                     onClick={() => setOpen(true)}
                >
                    <img src="img/btn/export.png" alt=""/>
                    <img src="img/btn/export-hover.png" alt=""/>
                </div>
            </div>
            {
                open && <CaptureListDialog open={open} setOpen={setOpen}/>
            }
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    deleteBtn: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    borderLineActive: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    iconFixed: {
        width: 200,
        position: "fixed",
        right: 0,
        top: 280,
        zIndex: 999
    },
    iconCamera: {
        width: 70,
        height: 70,
        display: "block",
        margin: "5px auto",
        position: "relative",
        cursor: 'pointer',
        "& img": {
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
        },
        "& img:nth-child(2)": {
            display: "none"
        },
        "&:hover img:nth-child(2)": {
            display: "block"
        }
    }
}));
export default memo(RightMenu)
