import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Typography
} from '@material-ui/core';
import {useInsightMaps} from '../../../context/InsightMindMapsContext';
import {db} from '../../../firebaseConfig';
import rtn from 'reactn';
import {DB_COLL} from '../../../common/envSettings';
import {Close, Create, Replay} from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {makeStyles} from '@material-ui/core/styles';
import ButtonCustom from '../../ButtonCustom';
import {insightMapColl} from '../../../config/constants';
import CaptureInsightMind from './CaptureInsightMind';
import CaptureInsightMindOne from './CaptureInsightMindOne';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import jsPDF from 'jspdf';
import * as d3 from 'd3';
import GIF from 'gif.js.optimized';
import moment from 'moment';
import {useInsightMindUserContext} from '../../../context/InsightMindUserProvider';
import _ from 'lodash';
import CarouseInsightMind from './CarouseInsightMind';
import {toast} from 'react-toastify';
import {v4 as uuidv4} from 'uuid';
import {updatedName} from '../../../common/updatedName';
import CaptureInsightMini from './CaptureInsightMini';

const {useGlobal} = rtn
const sizes = [
    {
        key: 0,
        value: "640px",
        data: 640
    },
    {
        key: 1,
        value: "1080px",
        data: 1080
    },
];
const formats = [
    {
        key: 0,
        value: "gifアニメ",
        data: "GIF"
    },
    {
        key: 1,
        value: "jpg",
        data: "JPG"
    },
    {
        key: 2,
        value: "png",
        data: "PNG"
    },
    {
        key: 3,
        value: "pdf",
        data: "PDF"
    },
]

function CaptureListDialog(props) {
    const {open, setOpen} = props
    const classes = useStyles()
    const {map, generateHistory} = useInsightMaps()
    const [user] = useGlobal("user")
    const [captures] = useState([])
    const [sizeId, setSizeId] = useState(1)
    const [formatId, setFormatId] = useState(2)
    const [loading, setLoading] = useState(false)
    const [error] = useState(false)
    const [selected, setSelected] = useState([])
    const [openDelete, setOpenDelete] = useState(false)
    const [openRollBack, setOpenRollBack] = useState(null)
    const histories = generateHistory()
    const {insightMindUser} = useInsightMindUserContext()
    const [openCarousel, setOpenCarousel] = useState(false)
    const [indexValue, setIndexValue] = useState(0)
    const handleClose = async () => {
        if (!map?.id) {
            setOpen(false)
            return
        }
        try {
            const watched = Date.now()
            const mapRef = db.doc(`${insightMapColl}/${insightMindUser.id}/insightmaps/${map.id}`)
            await mapRef.set({
                watched: watched
            }, {merge: true})
            setOpen(false)
        } catch (e) {
            console.log(e);
        }
    }
    const handleCheck = (capture) => {
        let idx = selected.indexOf(capture)
        if (idx !== -1) {
            // remove
            selected.splice(idx, 1)
        } else {
            selected.push(capture)
        }
        setSelected([...selected])
    }

    // useEffect(() => {
    //     // console.log({open, map, user})
    //     if (open && map?.id && user?.user_id) {
    //         // console.log('get data')
    //         getCapture()
    //     }
    //
    // }, [map, open, user])
    // const getCapture = () => {
    //     db.doc(`${DB_COLL}/${user.user_id}/captures/${map.id}`)
    //         .get()
    //         .then(doc => {
    //             if (!doc.exists)
    //                 return
    //             const {data} = doc.data()
    //             setCaptures(data)
    //         })
    // }
    const onDelete = async () => {
        try {
            setLoading(true)
            if (!selected.length)
                return
            const batch = db.batch()
            selected.forEach(id => {
                let idx = _.findIndex(histories, {id})
                if (idx !== -1) {
                    const ref = db.doc(`${DB_COLL}/${user.user_id}/histories/${histories[idx].historyId}`)
                    batch.set(ref, {
                        isDeleted: true
                    }, {merge: true})
                }
            })
            await batch.commit()
            setSelected([])
            setOpenDelete(false)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }
    const handleSelectAll = () => {
        if (selected.length === histories.length) {
            setSelected([])
            return
        }
        setSelected(histories.map(item => item.id))
    }
    // const handleDownload = async () => {
    //     try {
    //         setLoading(true)
    //         const id = Date.now()
    //         const downloadRef = db.doc(`${DB_COLL}/${user.user_id}/downloads/${id}`)
    //         const data = {
    //             files: selected,
    //             env: IS_PRODUCTION ? "production" : "stg",
    //             extension: formats[formatId].data,
    //             resolution: sizes[sizeId].data,
    //             foldername: user.nickname || (user.first_name + user.last_name)
    //         }
    //         await downloadRef.set(data)
    //         window.open(`${API_GENIAM}/insightmindDownloadCapture?user=${user.user_id}&id=${id}`, "_self")
    //     } catch (e) {
    //
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    const downloadSVGHistories = async () => {
        if (!selected.length || loading)
            return
        try {
            setLoading(true)
            let type = "png"
            if (formats[formatId].data === "JPG") {
                type = "jpg"
            }
            let gif
            if (formats[formatId].data === "GIF") {
                gif = new GIF({
                    workers: selected.length,
                    quality: 1,
                    background: "#dcdedd",
                    repeat: 1
                })
            }
            const dataUrls = await Promise.all(selected.sort((a, b) => a - b).map(async historyId => {
                try {
                    const svg = document.getElementById(`history_${historyId}`).cloneNode(true)
                    svg.setAttribute("id", "clone-history-" + historyId)
                    const div = document.createElement("div")
                    const cloneDiv = document.createElement("div")
                    cloneDiv.setAttribute("id", "export-history-" + historyId)
                    div.appendChild(svg)
                    document.body.appendChild(div)
                    document.body.appendChild(cloneDiv)
                    const doctype = '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';
                    const source = (new XMLSerializer()).serializeToString(d3.select("#clone-history-" + historyId).node());
                    const blob = new Blob([doctype + source], {
                        type: 'image/svg+xml;charset=utf-8'
                    });
                    const img = d3.select("#export-history-" + historyId).append('img').node();
                    img.src = window.URL.createObjectURL(blob);
                    return await new Promise((resolve, reject) => {
                        img.onload = function () {
                            const canvas = d3.select("#export-history-" + historyId).append('canvas').node();
                            const width = sizes[sizeId].data
                            canvas.width = width
                            canvas.height = map.role === 1 ? width * 1.15625 : width
                            canvas.style.backgroundColor = '#fff';
                            const ctx = canvas.getContext('2d');
                            ctx.drawImage(img, 0, 0);
                            //bug on ie and edge when call drawImage in onload
                            try {
                                if (gif) {
                                    gif.addFrame(canvas, {delay: 200});
                                }
                                const dataUrl = canvas.toDataURL(`image/${type}`)
                                // FileSaver.saveAs(dataUrl,`${historyId}.png`)
                                svg.remove()
                                canvas.remove()
                                img.remove()
                                cloneDiv.remove()
                                resolve({dataUrl, id: historyId})
                            } catch (err) {
                                console.log(err);
                                svg.remove()
                                div.remove()
                                cloneDiv.remove()
                                canvas.remove()
                                img.remove()
                                reject("")
                            }
                        };
                    })

                } catch (e) {
                    console.log(e);
                }
            }))
            if (formats[formatId].data === "PDF") {
                // width height  size image

                const pageSizeWidth = 250
                const pageSizeheight = map.role === 1 ? pageSizeWidth * 1.15625 : pageSizeWidth
                const imgWidth = map.role === 1 ? 200 : 180
                const imgHeight = map.role === 1 ? imgWidth * 1.15625 : imgWidth
                const paddingLeft = (pageSizeWidth - imgWidth) / 2
                const paddingTop = (pageSizeheight - imgHeight) / 2
                const doc = new jsPDF({
                    orientation: "portrait",
                    unit: "mm",
                    format: [pageSizeWidth, pageSizeheight]
                })
                dataUrls.forEach((url, index) => {
                    if (index > 0)
                        doc.addPage([pageSizeWidth, pageSizeheight])
                    doc.addImage(url.dataUrl, type, paddingLeft, paddingTop, imgWidth, imgHeight)
                })
                doc.save(`Insight-Mind-${user.nickname}.pdf`)
                return
            }
            if (formats[formatId].data === "GIF") {

                gif.render()
                return await new Promise((resolve, reject) => {
                    gif.on('finished', function (blob) {
                        FileSaver.saveAs(blob, `Insight-Mind-${user.nickname}.gif`);
                        resolve("")
                    })
                })
            }
            const zip = new JSZip()
            dataUrls.forEach((url, idx) => {
                if (url) {
                    const name = `${moment(Number(url.id)).format("YYYY-MM-DD")}_${url.id}`
                    zip.file(`${name}.${type}`, url.dataUrl.split(`base64,`)[1], {base64: true})
                }

            })
            const blob = await zip.generateAsync({type: "blob"})
            FileSaver.saveAs(blob, `Insight-Mind-${user.nickname}.zip`);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
        }


    }
    const handleShare = name => e => {
        let url
        const width = 700;
        var height = 450;
        const top = (window.innerHeight / 2) - (height / 2);
        const left = (window.innerWidth / 2) - (width / 2);
        let shareLink = encodeURIComponent(window.location.href)
        // let idx = shareLink.indexOf('?');
        // if (idx !== -1) {
        //     shareLink = shareLink.substr(0, idx);
        // }
        switch (name) {
            case "facebook":
                url = "https://www.facebook.com/sharer/sharer.php?u="
                break;
            case "google":
                url = "https://plus.google.com/share?url="
                break;
            case "twitter":
                url = `https://twitter.com/intent/tweet?text=insightMind&url=`
                break;
            case "linkedin":
                url = "https://www.linkedin.com/shareArticle?mini=true&title=Insight%20Mind&summary=&source=&url="
                break;
        }
        if (!url)
            return
        window.open(url + shareLink, 'sharer', 'top=' + top + ',left=' + left + ',toolbar=0,status=0,width=' + width + ',height=' + height)
    }
    const handleRollBack = async (type) => {
        if (!openRollBack)
            return
        try {
            setLoading(true)
            const batch = db.batch()
            const mapRef = db.doc(`${insightMapColl}/${insightMindUser.id}/insightmaps/${map.id}`)
            const nextMapData = {
                ...map,
                map: openRollBack.map || null,
                line: openRollBack.line || '',
                barrier: openRollBack.barrier || '',
                clear: openRollBack.clear || null,
                key: openRollBack.key || null,
                identity: openRollBack.identity || null,
                process: openRollBack.process || null,
                quantizing: openRollBack.quantizing || null,
                task: openRollBack.task || null,
                vector: openRollBack.vector || null,
                memo: openRollBack.memo || "",
                years: openRollBack.years || 0,
                updatedAt: moment().utc().format()
            }
            // set new Info to map
            batch.set(mapRef, nextMapData)
            if (type === "all") {
                // delete histories after revert image
                histories.forEach(history => {
                    if (openRollBack.id < history.id) {
                        const ref = db.doc(`${insightMapColl}/${map.owner}/histories/${history.historyId}`)
                        batch.delete(ref)
                    }
                })
            } else {
                // just add more one history with revert option
                const historyId = uuidv4()
                const historyRef = db.doc(`${insightMapColl}/${map.owner}/histories/${historyId}`)
                batch.set(historyRef, {
                    ...nextMapData,
                    mapId: map.id,
                    isRestore: true,
                    fieldName: 'restore',
                    isDefault: false,
                    updatedAt: Date.now()
                })
            }

            await batch.commit()
            toast.success("restore successfully")
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
            setOpenRollBack(null)
        }


    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} className={classes.root} maxWidth={"lg"}>
                <DialogTitle>
                    画像のダウンロード
                    <IconButton className={classes.close}
                                onClick={handleClose}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3} id={"histories_list"}>
                        {
                            (histories && histories.length !== 0) ? histories.map((capture, index) => {
                                    let idx = selected.indexOf(capture.id)
                                    if (capture.isDeleted)
                                        return null
                                    return (
                                        <Grid item xs={6} sm={4} md={3} className={classes.gridImg} key={index}
                                        >
                                            <div className={classes.imgContainer}
                                                 style={{border: (idx !== -1) ? "solid 2px #26a69a" : "solid 2px #777"}}>
                                                {
                                                    moment(map.watched).isBefore(moment(capture.id)) &&
                                                    <span className={classes.new} />
                                                }
                                                <Checkbox
                                                    checked={selected.includes(capture.id)}
                                                    onChange={() => handleCheck(capture.id)}
                                                    className={classes.checkBox}
                                                    style={{color: selected.includes(capture.id) ? "#26a69a" : "#ccc"}}
                                                    disabled={loading}
                                                />
                                                <div className={classes.adobe}>
                                                    <img src={"/img/icon/adobe.png"} alt='' />
                                                </div>
                                                {index !== histories.length - 1 && map?.owner === user.user_id &&
                                                <IconButton className={classes.rollbackBtn}
                                                            onClick={() => {
                                                                setOpenRollBack(capture)
                                                            }}
                                                            disabled={loading}
                                                >
                                                    <Replay />
                                                </IconButton>
                                                }
                                                <div
                                                    onClick={() => {
                                                        setOpenCarousel(true)
                                                        setIndexValue(index)
                                                    }}>
                                                    {
                                                        capture?.role === 1 ?
                                                            <div className={classes.mapOneContainer}>
                                                                <CaptureInsightMindOne map={capture}
                                                                                       captureId={"history_" + capture.id} />
                                                            </div>
                                                            :
                                                            capture?.role === 2 ?
                                                                <CaptureInsightMini map={capture}
                                                                                    captureId={"history_" + capture.id} />
                                                                :
                                                                <CaptureInsightMind map={capture}
                                                                                    captureId={"history_" + capture.id} />
                                                    }
                                                </div>

                                                <div className={classes.updated}>
                                                    <Create color={"secondary"}
                                                            fontSize={"small"} /> {updatedName[capture.updateField] || capture.updateField}
                                                </div>
                                            </div>
                                        </Grid>
                                    )

                                })
                                :
                                <div style={{height: 100, lineHeight: "100px", color: "#ff0000"}}>
                                    No content
                                </div>
                        }

                    </Grid>

                </DialogContent>
                <DialogActions className={classes.actionsContainer}>
                    <div className={classes.flexContainer}>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selected.length === histories.length}
                                        onChange={handleSelectAll}
                                        name='checkedB'
                                        style={{color: selected.length === captures.length ? "#26a69a" : "#ccc"}}
                                        disabled={loading}
                                    />
                                }
                                label='全てチェックする'
                            />
                        </div>
                        <div>
                            {
                                sizes.map((size, index) => {
                                    return (
                                        <button
                                            key={index}
                                            style={sizeId === index ? {
                                                background: "#5a5a5a",
                                                color: "#fff"
                                            } : {background: "#ffffff", color: "#aaa"}}
                                            onClick={() => setSizeId(index)}
                                            disabled={loading}
                                            className={classes.buttonSize}>{size.value}</button>
                                    )
                                })
                            }
                            {
                                formats.map((f, index) => {
                                    return (
                                        <button
                                            key={index}
                                            style={formatId === index ? {
                                                background: "#5a5a5a",
                                                color: "#fff"
                                            } : {background: "#fff", color: "#aaa"}}
                                            onClick={() => setFormatId(index)}
                                            disabled={loading}
                                            className={classes.buttonFormat}>{f.value}</button>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <Divider light className={classes.divider} />

                    <Grid container alignItems={"center"}>
                        <Grid item sm={3} xs={12} style={{textAlign: "center"}}>
                            {
                                error && <div className={classes.error}>
                                    <strong>Please add your wordpress blog in settings !</strong>
                                </div>
                            }
                            <ButtonCustom className={classes.btnWordpress}
                                          backgroundColor={"rgba(43, 187, 173, 1)"}
                                          backgroundBlur={"rgba(43, 187, 173, 0.5)"}
                            >
                                {
                                    loading && <i className={"fas fa-spinner fa-pulse"} />
                                }
                                Wordpress</ButtonCustom>
                        </Grid>
                        <Grid item sm={4} xs={12} style={{textAlign: "center"}}>
                            <ButtonCustom className={classes.btnDownload}
                                          backgroundColor={"rgba(43, 187, 173, 1)"}
                                          backgroundBlur={"rgba(43, 187, 173, 0.5)"}
                                          disabled={!selected.length}
                                          onClick={downloadSVGHistories}
                            >
                                {
                                    loading && <i className={"fas fa-spinner fa-pulse"}/>
                                }
                            </ButtonCustom>
                            <ButtonCustom className={classes.button}
                                          backgroundColor={"rgba(217, 83, 79, 1)"}
                                          backgroundBlur={"rgba(217, 83, 79, 0.7)"}
                                          disabled={!selected.length || loading}
                                          onClick={() => setOpenDelete(true)}
                            >削除</ButtonCustom>
                        </Grid>

                        <Grid item sm={5} xs={12} style={{textAlign: "center"}}>
                            <ButtonCustom className={classes.btnFb}
                                          backgroundColor={"rgba(52, 85, 143, 1)"}
                                          backgroundBlur={"rgba(52, 85, 143, 0.5)"}
                                          onClick={handleShare("facebook")}
                                          disabled={!selected.length || loading}
                            >
                                <i className='fab fa-facebook-f' />
                                Share</ButtonCustom>
                            <ButtonCustom className={classes.btnTw}
                                          backgroundColor={"rgba(5, 163, 214, 1)"}
                                          backgroundBlur={"rgba(5, 163, 214, 0.5)"}
                                          onClick={handleShare("twitter")}
                                          disabled={!selected.length || loading}
                            >
                                <i className='fab fa-twitter' />
                                Share</ButtonCustom>
                            <ButtonCustom className={classes.btnIn}
                                          backgroundColor={"rgba(4, 116, 174, 1)"}
                                          backgroundBlur={"rgba(4, 116, 174, 0.5)"}
                                          onClick={handleShare("linkedin")}
                                          disabled={!selected.length || loading}
                            >
                                <i className='fab fa-linkedin-in' />
                                Share</ButtonCustom>
                            <ButtonCustom className={classes.btnGoogle}
                                          backgroundColor={"rgba(195, 9, 7, 1)"}
                                          backgroundBlur={"rgba(195, 9, 7, 0.5)"}
                                          onClick={handleShare("google")}
                                          disabled={!selected.length || loading}
                            >
                                <i className='fab fa-google-plus-g' />
                                Share</ButtonCustom>
                        </Grid>
                    </Grid>

                </DialogActions>

            </Dialog>
            {
                openDelete &&
                <DialogDeleteCapture open={openDelete} setOpen={setOpenDelete} onDelete={onDelete} loading={loading} />
            }
            {
                openCarousel &&
                <CarouseInsightMind
                    open={openCarousel}
                    setOpen={setOpenCarousel}
                    histories={histories}
                    indexValue={indexValue}
                    selected={selected}
                    setSelected={setSelected}
                    loading={loading}
                />
            }
            <DialogRollBack open={openRollBack} setOpen={setOpenRollBack} loading={loading} onOk={handleRollBack} />
        </div>

    );
}

function DialogDeleteCapture({open, setOpen, onDelete, loading}) {
    const classes = useStyles()
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent className={classes.deleteMessage}>
                Are you sure ?
            </DialogContent>
            <Divider light className={classes.divider} />
            <DialogActions className={classes.actionsContainer}>
                <Button
                    variant={"contained"}
                    className={classes.deleteBtn}
                    onClick={onDelete}
                    disabled={loading}
                >
                    削除
                </Button>
                <Button onClick={handleClose}
                        variant={"contained"}
                        disabled={loading}
                >
                    キャンセル
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function DialogRollBack({open, setOpen, loading, onOk}) {
    const classes = useStyles()
    const [type, setType] = useState("all")
    const handleClose = () => {
        setOpen(null)
    }
    if (!open)
        return null
    return (
        <Dialog open={Boolean(open)} onClose={handleClose}
                className={classes.rollbackRoot}
        >
            <DialogContent>
                <Typography>Do you want to restore the data at this point ?</Typography>
                <FormControl component='fieldset' className={classes.formRestoreData}>
                    <FormLabel component='legend'>Options :</FormLabel>
                    <RadioGroup value={type} onChange={(e => setType(e.target.value))}>
                        <FormControlLabel value='all' control={<Radio />}
                                          label='restore data to this point and delete all histories after this point' />
                        <FormControlLabel value='data-only' control={<Radio />}
                                          label='just restore data to this point' />
                    </RadioGroup>
                </FormControl>
                <div className={classes.rollbackImg}>
                    {
                        open?.role === 1 ?
                            <CaptureInsightMindOne map={open} captureId={"rollback_" + open.id} />
                            :
                            open?.role === 2 ?
                                <CaptureInsightMini map={open} captureId={"rollback_" + open.id} />
                                :
                                <CaptureInsightMind map={open} captureId={"rollback_" + open.id} />
                    }
                </div>
            </DialogContent>
            <Divider light className={classes.divider} />
            <DialogActions className={classes.actionsContainer}>
                <Button
                    variant={"contained"}
                    className={classes.deleteBtn}
                    disabled={loading}
                    onClick={() => onOk(type)}
                >
                    Yes
                </Button>
                <Button onClick={handleClose}
                        variant={"contained"}
                        disabled={loading}
                >
                    No
                </Button>
            </DialogActions>
        </Dialog>
    )

}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialogTitle-root': {
            background:
                '-webkit-linear-gradient(45deg, rgba(43, 187, 173, 1) 0%, rgba(43, 187, 173, 0.5) 49%, rgba(43, 187, 173, 0.25) 50%, rgba(43, 187, 173, 0.75) 100%)',
            // textAlign: 'left',
            color: 'white',
            fontWeight: 'bold',
        },
        '& .MuiDialogActions-root': {
            justifyContent: 'center',
        },
    },
    button: {
        color: 'white',
        fontWeight: 'bold',
        margin: '10px auto',
        height: 50,
        width: 93,
        marginLeft: 10,
    },
    actionsContainer: {
        backgroundColor: '#bdbdbd',
        display: "block",
        textAlign: "center",
    },
    divider: {
        backgroundColor: 'black',
    },
    close: {
        float: 'right',
        padding: 0,
    },
    img: {
        width: 200,
        margin: "auto",
    },
    gridImg: {
        padding: 10,
        textAlign: "center",
    },
    imgContainer: {
        // border: "2px solid black",
        position: "relative",
        width: "100%",
        // height: 200,
        margin: "auto",
        cursor: "pointer"
    },
    flexContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 10
    },
    checkBox: {
        position: "absolute",
        top: 0,
        right: 0
    },
    adobe: {
        position: "absolute",
        top: 38,
        right: 10,
        cursor: "pointer",
        "& img": {
            width: 20,
            height: "auto",
            objectFit: "cover",
            display: "block"
        }
    },
    rollbackBtn: {
        position: "absolute",
        top: 10,
        left: 0,
    },
    sizeBox: {
        "& .MuiSvgIcon-root": {
            display: "none",
        }
    },
    buttonSize: {
        display: "inline-block",
        border: "solid 1px #5a5a5a",
        background: "#fff",
        outline: "none",
        margin: "0 5px",
        textAlign: "center",
        cursor: "pointer",
        fontSize: 15,
        width: 90,
        height: 23,
        lineHeight: "23px",
        boxSizing: "content-box",

    },
    buttonFormat: {
        display: "inline-block",
        border: "solid 1px #5a5a5a",
        background: "#fff",
        outline: "none",
        margin: "0 5px",
        textAlign: "center",
        cursor: "pointer",
        fontSize: 15,
        width: 90,
        borderRadius: 6,
        height: 23,
        lineHeight: "23px",
        boxSizing: "content-box",
    },
    btnWordpress: {
        background: "url(/img/icon/wordpress.png) no-repeat center left",
        paddingLeft: "30px !important",
    },
    error: {
        color: "#a94442",
        backgroundColor: "#f2dede",
        borderColor: "#ebccd1",
        padding: 15,
        marginBottom: 20,
        border: "1px solid transparent",
        borderRadius: "4px",
    },
    btnDownload: {
        background: "url(/img/btn/download.png) no-repeat center",
        height: "50px",
        width: "186px",

    },
    btnFb: {
        margin: "5px 2px",
        "& i": {
            fontSize: 16,
            marginRight: 5
        },
    },
    btnTw: {
        margin: "5px 2px",
        "& i": {
            fontSize: 16,
            marginRight: 5
        },
    },
    btnIn: {
        margin: "5px 2px",
        "& i": {
            fontSize: 16,
            marginRight: 5
        },
    },
    btnGoogle: {
        margin: "5px 2px",
        "& i": {
            fontSize: 16,
            marginRight: 5
        },
    },
    deleteBtn: {
        backgroundColor: "#d9534f",
        borderColor: "#d43f3a",
        boxShadown: "0 4px rgba(217, 83, 79, 0.5)"
    },
    deleteMessage: {
        minWidth: 300
    },
    mapOneContainer: {
        width: 160,
        margin: "auto"
    },
    new: {
        background: "url(/img/icon/new.png) no-repeat center",
        width: 32,
        height: 32,
        position: "absolute",
        left: 5
    },
    updated: {
        width: "100%",
        height: 30,
        background: "aquamarine",
        position: "absolute",
        bottom: 0,
        left: 0
    },
    rollbackImg: {
        // width:"600px",
    },
    rollbackRoot: {
        width: "100%",
        '& .MuiDialog-paper': {
            maxWidth: '700px',
            position: 'absolute',
            top: "50px",
            right: 20,
            left: 20,
            margin: "auto"
        },
    },
    formRestoreData: {
        paddingLeft: 30
    }
}));

export default CaptureListDialog;
