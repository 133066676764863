import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Grid, IconButton, Typography} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Background from "../../../common/image/file-management.png"
import SortBoth from '../../../common/image/sort-both.png'
import SortAsc from '../../../common/image/sort-asc.png'
import SortDesc from '../../../common/image/sort-desc.png'
import rtn from "reactn";
import Divider from '@material-ui/core/Divider';
import CreateNewIdentityTag from "./CreateNewIdentityTag";
import {useInsightMindUserContext} from "../../../context/InsightMindUserProvider";
import {db} from "../../../firebaseConfig";
import {DB_COLL} from "../../../common/envSettings";
import EditNewIdentityTag from "./EditNewIdentityTag";
import Button from "@material-ui/core/Button";

const {useGlobal} = rtn;

function DialogIdentityTag(props) {
    const classes = useStyles()
    const [openDialogIdentityTag, setOpenDialogIdentityTag] = useGlobal('openDialogIdentityTag');
    const [, setOpenNewIdentityTag] = useGlobal('openNewIdentityTag');
    const [, setOpenEditIdentityTag] = rtn.useGlobal('openEditIdentityTag');
    const {tagsNormal, tagsFavorite, sort, setSort} = useInsightMindUserContext();
    const [items, setItems] = useState('')
    const [user] = useGlobal('user')
    const [page, setPage] = useState(1)
    const perPage = 12


    const hasMore = () => {
        return page < Math.ceil(tagsNormal?.length / perPage)
    }


    const addFavorite = async (item) => {
        if (!item) return null
        const tag_id = item.tag_id
        try {
            const addFavoriteDocRef = db.doc(`${DB_COLL}/${user.user_id}/tags/${tag_id}`)

            await addFavoriteDocRef.set({
                is_favorite: true,
            }, {merge: true})

        } catch (e) {
            console.log(e);
        }
    };

    const removeFavorite = async (item) => {
        if (!item) return null
        const tag_id = item.tag_id
        try {
            const addFavoriteDocRef = db.doc(`${DB_COLL}/${user.user_id}/tags/${tag_id}`)

            await addFavoriteDocRef.set({
                is_favorite: false,
            }, {merge: true})

        } catch (e) {
            console.log(e);
        }
    }

    const handleClose = () => {
        setOpenDialogIdentityTag(false)
    };


    return (
        <Dialog
            className={classes.root}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openDialogIdentityTag}>
            <DialogTitle>
                <Typography className={classes.title_dialog}>
                    アイデンティティ・タグの管理
                </Typography>
                <IconButton className={classes.close}
                            onClick={handleClose}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>

                <div className={classes.dialog_body}>
                    <div>
                        <h4 className={classes.text_favourite}>お気に入り</h4>
                        <div className={classes.iconStar}>
                            <span style={{color: '#ebd018'}} className="glyphicon glyphicon-star"/>
                        </div>
                    </div>
                    <div className={classes.marginBottom10}>
                        <Grid container>
                            {
                                tagsFavorite.map((item, idx) => {
                                    if (!item || !item.color)
                                        return null
                                    return (
                                        <Grid
                                            onDoubleClick={() => {
                                                setItems(item)
                                                setOpenEditIdentityTag(true)

                                            }}
                                            item
                                            md={2} sm={2} xs={4}
                                            className={classes.identity_Tag}
                                            key={idx}
                                        >
                                            <img
                                                alt={item}
                                                src={`img/pawn/${item.color}.png`}
                                            />
                                            <p className={classes.itemContent}>{item.content}</p>
                                            <div className={classes.identity_icon_star}>
                                                <span
                                                    style={{color: '#ebd018'}}
                                                    onClick={() => removeFavorite(item)}
                                                    className="glyphicon glyphicon-star"/>
                                            </div>
                                        </Grid>
                                    )
                                })
                            }

                        </Grid>

                    </div>

                    <div>
                        <h4 className={classes.text}>最近使った自己</h4>
                        <div className={classes.menuRight}>
                            <h5 className={classes.displayStyle}>表示順</h5>
                            <div
                                onClick={() => {
                                    if (sort === "nameDown") {
                                        setSort("nameUp")
                                        return
                                    }
                                    setSort("nameDown")
                                }}
                                className={sort === "nameDown" ? classes.image_desc : (sort === "nameUp" ? classes.image_asc : classes.image_identity)}
                            >日付
                            </div>
                            <div
                                onClick={() => {
                                    if (sort === "dateDown") {
                                        setSort("dateUp")
                                        return
                                    }
                                    setSort("dateDown")

                                }}
                                className={sort === "dateDown" ? classes.image_desc : (sort === "dateUp" ? classes.image_asc : classes.image_identity)}>名前
                            </div>
                        </div>
                    </div>
                    <div className={classes.identity_files}>
                        <Grid container>
                            {
                                tagsNormal && tagsNormal.map((item, idx) => {
                                    if (!item || !item.color)
                                        return null
                                    if (idx < perPage * page)
                                        return (
                                            <Grid
                                                onDoubleClick={() => {
                                                    setItems(item)
                                                    setOpenEditIdentityTag(true)

                                                }}
                                                item
                                                md={2} sm={2} xs={4}
                                                className={classes.identity_Tag}
                                                key={idx}
                                            >
                                                <img
                                                    alt={item}
                                                    src={`img/pawn/${item.color}.png`}
                                                />
                                                <p className={classes.itemContent}>{item.content}</p>
                                                <div className={classes.identity_icon_star}>
                                                <span
                                                    onClick={() => addFavorite(item)}
                                                    className="glyphicon glyphicon-star-empty"/>
                                                </div>
                                            </Grid>
                                        )
                                    return null
                                })
                            }

                        </Grid>
                        {
                            hasMore() ?
                                <div className={classes.div_btn}>
                                    <Button
                                        onClick={() => {
                                            setPage(page + 1)
                                        }}
                                        className={classes.btn_load_more}
                                        variant={"outlined"}
                                    >
                                        さらに読み込む
                                    </Button>
                                </div>
                                :
                                null
                        }

                    </div>
                    <Divider/>
                    <div
                        className={classes.identity_recent}>
                        <a>
                            <img
                                onClick={() => setOpenNewIdentityTag(true)}
                                src={"/img/pawn/gray.png"} alt={""}/>
                        </a>

                        <div className={classes.identityIcon}>
                            <span
                                onClick={() => setOpenNewIdentityTag(true)}
                                className="glyphicon glyphicon-plus"/>
                        </div>
                        <p className={classes.textIdentity}>新規作成</p>
                    </div>
                </div>
            </DialogContent>
            <CreateNewIdentityTag/>
            <EditNewIdentityTag items={items}/>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiDialogTitle-root": {
            background: "-webkit-linear-gradient(45deg, rgba(43, 187, 173, 1) 0%, rgba(43, 187, 173, 0.5) 49%, rgba(43, 187, 173, 0.25) 50%, rgba(43, 187, 173, 0.75) 100%)",
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bold',
        },
        width: '100%',
        '& .MuiDialog-paper': {
            maxWidth: 900,
            position: 'absolute',
            top: "50px",
            right: 20,
            left: 20,
            margin: "auto",
            [theme.breakpoints.down('sm')]: {
                left: 10,
                right: 10,
                minWidth: "auto",
            }
        },
        '& .MuiDialogActions-root': {
            justifyContent: 'center',
        },
        '& .MuiDialogContent-root': {
            padding: 5
        }
    },
    title_dialog: {
        fontSize: 18
    },
    close: {
        // float: "right",
        padding: 0,
        position: "absolute",
        top: 15,
        right: 10
    },
    dialog_body: {
        maxHeight: 'calc(100vh - 200px)',
        padding: '2em',
        "& li": {
            display: "inline-block",
            margin: '0 2px',
            cursor: "pointer",
        },

    },
    iconStar: {
        position: 'relative',
        top: 1,
        display: 'inline-block',
        fontFamily: 'Glyphicons Halflings',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 1
    },
    text_favourite: {
        paddingLeft: 0,
        display: 'inline-block',
        fontStyle: '15px',

    },
    menuRight: {
        float: "right",
    },
    img_menuRight: {
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        width: 33,
        height: 30,
        position: "relative",
        display: "block",

    },
    icon1: {
        backgroundPosition: '-6px -6px'
    },
    icon2: {
        backgroundPosition: '-178px -262px',
        width: 47,
        height: 47
    },
    text: {
        paddingLeft: 0,
        display: 'inline-block',
        fontStyle: '15px',
    },
    text_error: {
        marginTop: 30,
        paddingBottom: 30,
        fontStyle: '15px',
        textAlign: 'center',

    },
    identity_files: {
        clear: 'both',
        position: 'relative',
        minHeight: 110,
        padding: '20px 0',
        cursor: 'pointer',

    },
    identity_recent: {
        clear: 'both',
        position: 'relative',
        minHeight: 110,
        padding: '20px 0',
        marginLeft: 50,
        cursor: 'pointer',

    },
    image_identity: {
        display: "inline-block",
        backgroundImage: `url(${SortBoth})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'right 0 center',
        color: '#333',
        border: '1px solid #808080',
        borderRadius: 5,
        padding: '2.5px 25px 2.5px 15px',
        backgroundColor: '#fff',
        textDecoration: 'none',
        marginLeft: 5,
        "&:hover ": {
            textDecoration: 'none',
            cursor: 'pointer'
        }
    },
    image_desc: {
        display: "inline-block",
        backgroundImage: `url(${SortDesc})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'right 0 center',
        color: '#333',
        border: '1px solid #808080',
        borderRadius: 5,
        padding: '2.5px 25px 2.5px 15px',
        backgroundColor: '#fff',
        textDecoration: 'none',
        marginLeft: 5,
        "&:hover ": {
            textDecoration: 'none',
            cursor: 'pointer'
        }
    },
    image_asc: {
        display: "inline-block",
        backgroundImage: `url(${SortAsc})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'right 0 center',
        color: '#333',
        border: '1px solid #808080',
        borderRadius: 5,
        padding: '2.5px 25px 2.5px 15px',
        backgroundColor: '#fff',
        textDecoration: 'none',
        marginLeft: 5,
        "&:hover ": {
            textDecoration: 'none',
            cursor: 'pointer'
        }
    },
    tooltip_custom: {
        position: 'absolute',
        color: '#fff',
        background: '#808080',
        height: 20,
        margin: 'auto',
        marginTop: 10,
        lineHeight: '20px',
        fontSize: 11,
        textAlign: 'center',
        display: 'none',
        zIndex: 100,
    },
    identityIcon: {
        position: 'absolute',
        top: 50,
        left: 12,
        color: '#fff',
        "&:hover ": {
            textDecoration: 'none',
            cursor: 'pointer'
        }
    },
    textIdentity: {
        position: 'absolute',
        top: 76,
        left: -10,
    },
    identity_Tag: {
        position: "relative",
        display: 'inline-block',
        textAlign: "center",
        '&:hover': {
            cursor: 'pointer',
        },
        paddingBottom: 10,
    },
    identity_icon_star: {
        position: "absolute",
        top: 11,
        left: "20%",
        fontSize: 18
    },
    displayStyle: {
        display: 'inline-block',
    },
    marginBottom10: {
        marginBottom: 10
    },
    btn_load_more: {
        padding: '10px 16px',
        fontSize: '18px',
        lineHeight: 1.3333333,
        borderRadius: '6px',
        color: ' #333',
        backgroundColor: '#fff',
        borderColor: '#ccc',
    },
    div_btn: {
        textAlign: 'center'
    },
    itemContent: {
        textOverflow: "ellipsis",
        overflow: "hidden",
    }
}))

export default DialogIdentityTag;
