export const dotMaps = [
    {
        id: "01",
        x: "15",
        y: "0",
        transform: "",
    },
    {
        id: "02",
        x: "15",
        y: "0",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "05",
        x: "77.78099999999999",
        y: "-77.78099999999999",
        transform: "",
    },
    {
        id: "06",
        x: "77.78099999999999",
        y: "-77.78099999999999",
        transform: "matrix(0,1,-1,0,0,0)",
    },
    {
        id: "07",
        x: "77.78099999999999",
        y: "-77.78099999999999",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "08",
        x: "77.78099999999999",
        y: "-77.78099999999999",
        transform: "matrix(0,-1,1,0,0,0)",
    },
    {
        id: "13",
        x: "84.194",
        y: "-203.258",
        transform: "",
    },
    {
        id: "14",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(0.7071,0.7071,-0.7071,0.7071,0,0)",
    },
    {
        id: "15",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(0,1,-1,0,0,0)",
    },
    {
        id: "16",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)",
    },
    {
        id: "17",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "18",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)",
    },
    {
        id: "19",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(0,-1,1,0,0,0)",
    },
    {
        id: "20",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(0.7071,-0.7071,0.7071,0.7071,0,0)",
    },
    {
        id: "29",
        x: "64.383",
        y: "-323.664",
        transform: "",
    },
    {
        id: "30",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0.9239,0.3827,-0.3827,0.9239,0,0)",
    },
    {
        id: "31",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0.7071,0.7071,-0.7071,0.7071,0,0)",
    },
    {
        id: "32",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0.3827,0.9239,-0.9239,0.3827,0,0)",
    },
    {
        id: "33",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0,1,-1,0,0,0)",
    },
    {
        id: "34",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)",
    },
    {
        id: "35",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)",
    },
    {
        id: "36",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)",
    },
    {
        id: "37",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "38",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)",
    },
    {
        id: "39",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)",
    },
    {
        id: "40",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)",
    },
    {
        id: "41",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0,-1,1,0,0,0)",
    },
    {
        id: "42",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0.3827,-0.9239,0.9239,0.3827,0,0)",
    },
    {
        id: "43",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0.7071,-0.7071,0.7071,0.7071,0,0)",
    },
    {
        id: "44",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0.9239,-0.3827,0.3827,0.9239,0,0)",
    },
    {
        id: "61",
        x: "85.844",
        y: "-431.552",
        transform: "",
    },
    {
        id: "62",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0.9239,0.3827,-0.3827,0.9239,0,0)",
    },
    {
        id: "63",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0.7071,0.7071,-0.7071,0.7071,0,0)",
    },
    {
        id: "64",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0.3827,0.9239,-0.9239,0.3827,0,0)",
    },
    {
        id: "65",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0,1,-1,0,0,0)",
    },
    {
        id: "66",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)",
    },
    {
        id: "67",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)",
    },
    {
        id: "68",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)",
    },
    {
        id: "69",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "70",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)",
    },
    {
        id: "71",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)",
    },
    {
        id: "72",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)",
    },
    {
        id: "73",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0,-1,1,0,0,0)",
    },
    {
        id: "74",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0.3827,-0.9239,0.9239,0.3827,0,0)",
    },
    {
        id: "75",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0.7071,-0.7071,0.7071,0.7071,0,0)",
    },
    {
        id: "76",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0.9239,-0.3827,0.3827,0.9239,0,0)",
    },
    {
        id: "81",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "",
    },
    {
        id: "82",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(0.9239,0.3827,-0.3827,0.9239,0,0)",
    },
    {
        id: "83",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(0.7071,0.7071,-0.7071,0.7071,0,0)",
    },
    {
        id: "84",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(0.3827,0.9239,-0.9239,0.3827,0,0)",
    },
    {
        id: "85",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(0,1,-1,0,0,0)",
    },
    {
        id: "86",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)",
    },
    {
        id: "87",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)",
    },
    {
        id: "88",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)",
    },
    {
        id: "89",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "90",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)",
    },
    {
        id: "91",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)",
    },
    {
        id: "92",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)",
    },
    {
        id: "93",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(0,-1,1,0,0,0)",
    },
    {
        id: "94",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(0.3827,-0.9239,0.9239,0.3827,0,0)",
    },
    {
        id: "95",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(0.7071,-0.7071,0.7071,0.7071,0,0)",
    },
    {
        id: "96",
        x: "107.30499999999999",
        y: "-539.44",
        transform: "matrix(0.9239,-0.3827,0.3827,0.9239,0,0)",
    },
]

export const dotMiniMaps = [
    {
        id: "01",
        x: "15",
        y: "0",
        transform: "",
    },
    {
        id: "02",
        x: "15",
        y: "0",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "05",
        x: "77.78099999999999",
        y: "-77.78099999999999",
        transform: "",
    },
    {
        id: "06",
        x: "77.78099999999999",
        y: "-77.78099999999999",
        transform: "matrix(0,1,-1,0,0,0)",
    },
    {
        id: "07",
        x: "77.78099999999999",
        y: "-77.78099999999999",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "08",
        x: "77.78099999999999",
        y: "-77.78099999999999",
        transform: "matrix(0,-1,1,0,0,0)",
    },
    {
        id: "13",
        x: "84.194",
        y: "-203.258",
        transform: "",
    },
    {
        id: "14",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(0.7071,0.7071,-0.7071,0.7071,0,0)",
    },
    {
        id: "15",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(0,1,-1,0,0,0)",
    },
    {
        id: "16",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)",
    },
    {
        id: "17",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "18",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)",
    },
    {
        id: "19",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(0,-1,1,0,0,0)",
    },
    {
        id: "20",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(0.7071,-0.7071,0.7071,0.7071,0,0)",
    },
    {
        id: "29",
        x: "129",
        y: "-303",
        transform: "",
    },
    {
        id: '30',
        x: "129",
        y: "-303",
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: '31',
        x: "129",
        y: "-303",
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: '32',
        x: "129",
        y: "-303",
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: '33',
        x: "129",
        y: "-303",
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: '34',
        x: "129",
        y: "-303",
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: '35',
        x: "129",
        y: "-303",
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: '36',
        x: "129",
        y: "-303",
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
]

export const dotMapsAdvance = [
    {
        id: "01",
        x: "15",
        y: "0",
        transform: "",
    },
    {
        id: "02",
        x: "15",
        y: "0",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "05",
        x: "77.78099999999999",
        y: "-77.78099999999999",
        transform: "",
    },
    {
        id: "06",
        x: "77.78099999999999",
        y: "-77.78099999999999",
        transform: "matrix(0,1,-1,0,0,0)",
    },
    {
        id: "07",
        x: "77.78099999999999",
        y: "-77.78099999999999",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "08",
        x: "77.78099999999999",
        y: "-77.78099999999999",
        transform: "matrix(0,-1,1,0,0,0)",
    },
    {
        id: "13",
        x: "84.194",
        y: "-203.258",
        transform: "",
    },
    {
        id: "14",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(0.7071,0.7071,-0.7071,0.7071,0,0)",
    },
    {
        id: "15",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(0,1,-1,0,0,0)",
    },
    {
        id: "16",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)",
    },
    {
        id: "17",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "18",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)",
    },
    {
        id: "19",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(0,-1,1,0,0,0)",
    },
    {
        id: "20",
        x: "84.194",
        y: "-203.258",
        transform: "matrix(0.7071,-0.7071,0.7071,0.7071,0,0)",
    },
    {
        id: "29",
        x: "64.383",
        y: "-323.664",
        transform: "",
    },
    {
        id: "30",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0.9239,0.3827,-0.3827,0.9239,0,0)",
    },
    {
        id: "31",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0.7071,0.7071,-0.7071,0.7071,0,0)",
    },
    {
        id: "32",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0.3827,0.9239,-0.9239,0.3827,0,0)",
    },
    {
        id: "33",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0,1,-1,0,0,0)",
    },
    {
        id: "34",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)",
    },
    {
        id: "35",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)",
    },
    {
        id: "36",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)",
    },
    {
        id: "37",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "38",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)",
    },
    {
        id: "39",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)",
    },
    {
        id: "40",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)",
    },
    {
        id: "41",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0,-1,1,0,0,0)",
    },
    {
        id: "42",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0.3827,-0.9239,0.9239,0.3827,0,0)",
    },
    {
        id: "43",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0.7071,-0.7071,0.7071,0.7071,0,0)",
    },
    {
        id: "44",
        x: "64.383",
        y: "-323.664",
        transform: "matrix(0.9239,-0.3827,0.3827,0.9239,0,0)",
    },
    {
        id: "61",
        x: "85.844",
        y: "-431.552",
        transform: "",
    },
    {
        id: "62",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0.9239,0.3827,-0.3827,0.9239,0,0)",
    },
    {
        id: "63",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0.7071,0.7071,-0.7071,0.7071,0,0)",
    },
    {
        id: "64",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0.3827,0.9239,-0.9239,0.3827,0,0)",
    },
    {
        id: "65",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0,1,-1,0,0,0)",
    },
    {
        id: "66",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)",
    },
    {
        id: "67",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)",
    },
    {
        id: "68",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)",
    },
    {
        id: "69",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "70",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)",
    },
    {
        id: "71",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)",
    },
    {
        id: "72",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)",
    },
    {
        id: "73",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0,-1,1,0,0,0)",
    },
    {
        id: "74",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0.3827,-0.9239,0.9239,0.3827,0,0)",
    },
    {
        id: "75",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0.7071,-0.7071,0.7071,0.7071,0,0)",
    },
    {
        id: "76",
        x: "85.844",
        y: "-431.552",
        transform: "matrix(0.9239,-0.3827,0.3827,0.9239,0,0)",
    },
    {
        id: "85",
        x: "210.48499999999999",
        y: "-508.14500000000004",
        transform: "",
    },
    {
        id: "86",
        x: "210.48499999999999",
        y: "-508.14500000000004",
        transform: "matrix(0.7071,0.7071,-0.7071,0.7071,0,0)",
    },
    {
        id: "87",
        x: "210.48499999999999",
        y: "-508.14500000000004",
        transform: "matrix(0,1,-1,0,0,0)",
    },
    {
        id: "88",
        x: "210.48499999999999",
        y: "-508.14500000000004",
        transform: "matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)",
    },
    {
        id: "89",
        x: "210.48499999999999",
        y: "-508.14500000000004",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "90",
        x: "210.48499999999999",
        y: "-508.14500000000004",
        transform: "matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)",
    },
    {
        id: "91",
        x: "210.48499999999999",
        y: "-508.14500000000004",
        transform: "matrix(0,-1,1,0,0,0)",
    },
    {
        id: "92",
        x: "210.48499999999999",
        y: "-508.14500000000004",
        transform: "matrix(0.7071,-0.7071,0.7071,0.7071,0,0)",
    },
    {
        id: "97",
        x: "466.686",
        y: "-466.686",
        transform: "",
    },
    {
        id: "98",
        x: "466.686",
        y: "-466.686",
        transform: "matrix(0,1,-1,0,0,0)",
    },
    {
        id: "99",
        x: "466.686",
        y: "-466.686",
        transform: "matrix(-1,0,0,-1,0,0)",
    },
    {
        id: "100",
        x: "466.686",
        y: "-466.686",
        transform: "matrix(0,-1,1,0,0,0)",
    },
    {
        id: "103",
        x: "770",
        y: "0",
        transform: "",
    },
    {
        id: "104",
        x: "770",
        y: "0",
        transform: "matrix(-1,0,0,-1,0,0)",
    },

]
