import React, {useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";

const tasksDefault = [
    {
        content: "", process: false
    },
    {
        content: "", process: false
    },
    {
        content: "", process: false
    },
    {
        content: "", process: false
    },
    {
        content: "", process: false
    },
    {
        content: "", process: false
    }
]

function TasksData(props) {
    const {classes, map, updateField, disabled = false} = props;
    const [tasksData, setTasksData] = useState(map?.task || tasksDefault);
    const handleChange = (idx, data) => {
        if (disabled)
            return
        tasksData[idx].content = data;
        setTasksData([...tasksData])
    };

    const handleChangeCheck = (idx, data) => {
        if (disabled || tasksData[idx].content === "")
            return
        tasksData[idx].process = data;
        setTasksData([...tasksData]);
        updateField('task', tasksData, null, true)
    };

    const handleBlur = (index) => {
        if (disabled)
            return
        if (!map.task || !map.task[index] || map.task[index].content !== tasksData[index].content)
            updateField('task', tasksData, null, true)
    };
    const handleKeyDown = async (e) => {
        if (disabled)
            return
        if (e.keyCode === 13) {
            const id = Number(e.target.id.split("_")[1])
            if (id < tasksData.length - 1) {
                const el = document.getElementById(`task-input_${id + 1}`)
                if (el)
                    el.focus()

            } else {
                await tasksData.push({content: "", process: false})
                await setTasksData([...tasksData])
                const el = document.getElementById(`task-input_${id + 1}`)
                if (el)
                    el.focus()
                // updateField('task', tasksData,null,true)
            }

        }
    }

    return (
        <div>
            <div className={classes.titleProcess}><span>タスク</span></div>
            <div className={classes.contentBox}>
                {
                    tasksData.map((f, index) => {
                        return (
                            <FormControl className={classes.formControl} key={index}>
                                <input className={"task-checkbox"} type={"checkbox"} checked={false}
                                       onChange={() => handleChangeCheck(index, !Boolean(f.process))}
                                />
                                {
                                    Boolean(f.process) &&
                                    <label>
                                        <span className={"task-checked"}/>
                                    </label>
                                }
                                <input
                                    id={"task-input_" + index}
                                    className={"task-input"}
                                    value={f?.content || ''}
                                    maxLength={29}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    onBlur={(e) => handleBlur(index)}
                                    onKeyDown={handleKeyDown}
                                />
                            </FormControl>
                        );
                    })}
                <Button
                    className={classes.button}
                    startIcon={<PlaylistAddIcon/>}
                    onClick={() => {
                        if (disabled)
                            return
                        tasksData.push({content: "", process: false})
                        setTasksData([...tasksData])
                        updateField('task', tasksData, null, true)
                    }}
                >
                    さらに追加
                </Button>
            </div>
        </div>
    );
}

export default TasksData;
