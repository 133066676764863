import React, {useEffect, useState} from 'react';
import {useInsightMaps} from '../../context/InsightMindMapsContext';
import InsightMindAdvance from './mapOne';
import InsightMind from './index';
import InsightMindMini from "./mapMini";
import qs from 'query-string';
import {downLoadFile} from './common/download';
import {useHistory} from 'react-router-dom';
import {useGlobal} from 'reactn';
import {redirectLoginPage} from '../../actions/user';
import {LinearProgress} from '@material-ui/core';
import CaptureFooter from './CaptureList/CaptureFooter';

const mimeTypes = ["PNG", "PDF"]

function InsightMapComponent(props) {
    const {map} = useInsightMaps()
    const history = useHistory()
    const [made, setMade] = useState(false)
    const [anonymous] = useGlobal("anonymous")

    useEffect(() => {
        if (map) {
            const query = qs.parse(window.location.search)
            const {type, mimeType, id} = query
            if (id === map.id && type === "download" && mimeType && mimeTypes.includes(mimeType) && !made) {
                // download
                setTimeout(() => {
                    downLoadFile(mimeType, map, () => {
                        console.log("downloaded")
                        // window.close()
                        history.push(`/insightmap?id=${id}`)
                    })
                }, 1000)
                setMade(true)
            }
            if (type === "print" && !made) {
                setTimeout(() => {
                    window.print()
                }, 2000)
                setMade(true)
            }

        }
    }, [map])


    useEffect(() => {
        if (anonymous) {
            redirectLoginPage()
        }
    }, [anonymous])

    if (!map)
        return <LinearProgress />
    return (
        <div>
            {
                map?.role === 1 ?
                    <InsightMindAdvance />
                    :
                    map?.role === 2 ?
                        <InsightMindMini />
                        :
                        <InsightMind />
            }
            <CaptureFooter map={map} />
        </div>
    );
}

export default InsightMapComponent;
