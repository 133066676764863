import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Close, Delete} from '@material-ui/icons';
import {useInsightMaps} from "../../../context/InsightMindMapsContext";
import useKeyboardJs from "react-use/lib/useKeyboardJs";
import ButtonCustom from "../../ButtonCustom";
import PopConfirmCustom from "../../Custom/PopConfirmCustom";

function SubjectDialog({open, setOpen, identity, setIdentity}) {
    const classes = useStyles();
    const [text, setText] = useState('');
    const [color, setColor] = useState('gray');
    const [subject, setSubject] = useState('diamond');
    const {map, updateField} = useInsightMaps();
    const [isPressed] = useKeyboardJs('enter');

    useEffect(() => {
        if (isPressed) {
            onSave()
        }
    }, [isPressed])

    useEffect(() => {
        if (open) {
            setText(open.text);
            setColor(open.color);
            setSubject(open.subject);
        }
    }, [open]);
    const handleClose = () => {
        setOpen(null);
    };
    const onSave = () => {
        if (!open) return null;
        setIdentity({...identity, [open.id]: `${subject},${color},${text}`});
        updateField("identity", {...identity, [open.id]: `${subject},${color},${text}`})
        setOpen(null);
    };
    const onRemoveSubject = () => {
        if (!open) return null;
        setIdentity({...identity, [open.id]: `none,${color},${text}`});
        updateField("identity", {...identity, [open.id]: `none,${color},${text}`})
        setOpen(null);
    }

    const onDelete = async () => {
        try {
            delete identity[open.id];
            setIdentity({...identity});
            updateField("identity", identity)
            let tagIds = map?.tag_id || {};
            if (tagIds[open.id]) {
                delete tagIds[open.id]
                updateField("tag_id", tagIds);
            }
            handleClose()
        } catch (e) {
            console.log(e);
        }
    }

    const onCancel = () => {

    }

    return (
        <Dialog open={Boolean(open)} onClose={handleClose} className={classes.root}>
            <DialogTitle>
                9 主体の選択
                <IconButton className={classes.close} onClick={handleClose}>
                    <Close />
                </IconButton>
                {
                    Boolean(identity[open?.id]) &&
                    <PopConfirmCustom
                        title={"Do you want to delete?"}
                        onConfirm={onDelete}
                        onCancel={onCancel}
                        okText={'Yes'}
                        cancelText={'No'}
                    >
                        <IconButton className={classes.delete}>
                            <Delete />
                        </IconButton>
                    </PopConfirmCustom>
                }
            </DialogTitle>
            <DialogContent>
                <div className={classes.formContent}>
                    <TextField
                        autoFocus
                        fullWidth
                        variant={'outlined'}
                        className={classes.textField}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <img alt={'penIco'} src={'img/icon/pen.png'} />
                                </InputAdornment>
                            ),
                        }}
                        value={text}
                        onChange={(e) => {
                            if (e.target.value?.length > 30) return null;
                            setText(e.target.value);
                        }}
                    />
                </div>
                <div
                    className={classes.formContent}
                    style={{
                        backgroundImage: `url(img/keyhole/${color}.png)`,
                        height: '201px',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        opacity: 0.75,
                    }}
                >
                    <div
                        className={classes.diamond}
                        onClick={() => setSubject('diamond')}
                        style={subject === 'diamond' ? {borderBottom: '5px solid white'} : {}}
                    />

                    <div
                        className={classes.heart}
                        onClick={() => setSubject('heart')}
                        style={subject === 'heart' ? {borderBottom: '5px solid white'} : {}}
                    />

                    <div
                        className={classes.flame}
                        onClick={() => setSubject('flame')}
                        style={subject === 'flame' ? {borderBottom: '5px solid white'} : {}}
                    />
                </div>
            </DialogContent>

            <Divider light className={classes.divider} />
            <DialogActions className={classes.actionsContainer}>
                <div>
                    <ButtonCustom
                        onClick={onRemoveSubject}
                        className={classes.cancelBtn}
                        backgroundColor={"rgba(204, 204, 204, 1)"}
                        backgroundBlur={"rgba(204, 204, 204, 0.5)"}
                    >
                        解除
                    </ButtonCustom>

                    <ButtonCustom
                        onClick={onSave}
                        className={classes.button}
                        backgroundColor={"rgba(234, 223, 71,1)"}
                        backgroundBlur={"rgba(234, 223, 71, 0.5)"}
                    >
                        Ok
                    </ButtonCustom>
                </div>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialogTitle-root': {
            minWidth: 600,
            background:
                '-webkit-linear-gradient(45deg, rgba(234, 223, 71, 1) 0%, rgba(234, 223, 71, 0.5) 49%, rgba(234, 223, 71, 0.25) 50%, rgba(234, 223, 71, 0.75) 100%)',
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bold',
        },
        '& .MuiDialogActions-root': {
            justifyContent: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            '& .MuiDialogTitle-root': {
                minWidth: 'auto',
            },
        },
    },
    button: {
        backgroundColor: '#eadf47',
        color: 'white',
        fontWeight: 'bold',
        margin: '10px',

    },
    cancelBtn: {
        backgroundColor: 'white',
        color: 'black',
        margin: '10px',
    },
    actionsContainer: {
        backgroundColor: '#f0f0f0',
    },
    divider: {
        backgroundColor: 'black',
    },
    formContent: {
        marginTop: 10,
        marginBottom: 10,
        padding: '0px 10px',
        // width: 300,
        margin: 'auto',
    },

    textField: {

        '& input': {
            textAlign: 'center',
            padding: 10,
            marginRight: 40
        },
    },
    formCheckbox: {
        display: 'flex',
        alignItems: 'center',
    },
    close: {
        float: 'right',
        padding: 0,
    },
    delete: {
        float: "right",
        padding: 0,
        marginRight: 10
    },
    diamond: {
        backgroundImage: 'url(img/pawnmark/diamond.png)',
        width: '40px',
        height: '40px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        margin: 'auto',
        top: '20px',
        cursor: 'pointer',
        paddingBottom: 10,
    },
    heart: {
        backgroundImage: 'url(img/pawnmark/heart.png)',
        width: '40px',
        height: '40px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        margin: 'auto',
        top: '35px',
        cursor: 'pointer',
        paddingBottom: 10,
    },
    flame: {
        backgroundImage: 'url(img/pawnmark/flame.png)',
        width: '40px',
        height: '40px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        margin: 'auto',
        top: '45px',
        cursor: 'pointer',
        paddingBottom: 10,
    },
}));

export default SubjectDialog;
