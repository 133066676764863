import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

function Footer(props) {
    const classes = useStyles();
    return (
        <footer className={classes.root}>
            <p>© 2018 Invenstor.inc All Rights Reserved.</p>
        </footer>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 1080,
        backgroundColor: '#262626',
        padding: '30px 0',
        margin: '0 auto 50px auto',
        textAlign: 'center',
        color: '#fff',
    },
}));
export default Footer;
