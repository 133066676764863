import React from 'react';
import {Popconfirm} from "antd";
import {makeStyles} from "@material-ui/core/styles";

function PopConfirmCustom({children, title, okText, cancelText, onConfirm, onCancel}) {
    const classes = useStyles();

    return (
        <Popconfirm
            title={title}
            onConfirm={onConfirm}
            onCancel={onCancel}
            okText={okText}
            cancelText={cancelText}
            overlayClassName={classes.confirmStyle}
            destroyTooltipOnHide={{keepParent: false}}
        >
            {children}
        </Popconfirm>
    );
}

const useStyles = makeStyles(theme => ({
    confirmStyle: {
        zIndex: '9999 !important'
    },
}))
export default PopConfirmCustom;
