import React, {useEffect, useState} from 'react';
import {Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Close} from '@material-ui/icons';

const list = ['gray', 'yellow', 'red', 'blue', 'green'];

function ProcessDialog({open, setOpen, processArray, onOk}) {
    const classes = useStyles();

    const [color, setColor] = useState('gray');
    useEffect(() => {
        if (open) {
            setColor(processArray[open - 1]?.color);
        }
    }, [open]);
    const handleClose = () => {
        setOpen(null);
    };

    return (
        <Dialog open={Boolean(open)} onClose={handleClose} className={classes.root}>
            <DialogTitle>
                プロセスのカラー
                <IconButton className={classes.close} onClick={handleClose}>
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Typography>エネルギーのカラー</Typography>
                <Grid container spacing={4} justify={"space-around"}
                      className={classes.gridContainer}
                >
                    {list.map((item) => {
                        return (
                            <Grid
                                key={item}
                                item
                                xs={2}
                                style={color === item ? {
                                    borderBottom: `5px solid ${color}`,
                                    padding: 0,
                                    minWidth: 50
                                } : {padding: 0, minWidth: 50}}
                                onClick={() => {
                                    onOk(open - 1, item)
                                }}
                            >
                                <div
                                    className={`${classes.circle} bg_${item}`}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialogTitle-root': {
            minWidth: 600,
            background:
                '-webkit-linear-gradient(45deg, rgba(142, 201, 117, 1) 0%, rgba(142, 201, 117, 0.5) 49%, rgba(142, 201, 117, 0.25) 50%, rgba(142, 201, 117, 0.75) 100%)',
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bold',
        },
        '& .MuiDialogActions-root': {
            justifyContent: 'center',
        },
        '& .MuiDialogContent-root': {
            overflowY: 'hidden'
        },
        [theme.breakpoints.down('xs')]: {
            '& .MuiDialogTitle-root': {
                minWidth: 'auto',
            },
        },
    },
    img: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    close: {
        float: 'right',
        padding: 0,
    },
    gridContainer: {
        padding: 15, marginTop: 20
    },
    content: {
        marginBottom: 20
    },
    circle: {
        width: 60,
        height: 60,
        borderRadius: '50%',
        marginBottom: 5,
        marginLeft: 17,
        [theme.breakpoints.down('xs')]: {
            width: 40,
            height: 40,
            margin: "0 auto 5px auto",
        }
    }
}));

export default ProcessDialog;
