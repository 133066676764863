export const mappingListRight = [
    "mapping_01",
    "mapping_03",
    "mapping_04",
    "mapping_07",
    "mapping_08",
    "mapping_09",
    "mapping_10",
    "mapping_15",
    "mapping_16",
    "mapping_17",
    "mapping_18",
    "mapping_19",
    "mapping_20",
    "mapping_21",
    "mapping_22",
    "mapping_31",
    "mapping_32",
    "mapping_33",
    "mapping_34",
    "mapping_39",
    "mapping_40",
    "mapping_43",
];
export const mappingListLeft = [
    "mapping_02",
    "mapping_05",
    "mapping_06",
    "mapping_11",
    "mapping_12",
    "mapping_13",
    "mapping_14",
    "mapping_23",
    "mapping_24",
    "mapping_25",
    "mapping_26",
    "mapping_27",
    "mapping_28",
    "mapping_29",
    "mapping_30",
    "mapping_35",
    "mapping_36",
    "mapping_37",
    "mapping_38",
    "mapping_41",
    "mapping_42",
    "mapping_44",
];


export const widthImg = {
    "flame": [31, 37, 43, 50, 55, 60],
    "diamond": [44, 51, 58, 66, 72, 79],
    "heart": [39, 45, 51, 56, 62, 68],
}
