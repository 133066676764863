import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Slider,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Close, Delete} from '@material-ui/icons';
import {useInsightMaps} from "../../../context/InsightMindMapsContext";
import ButtonCustom from "../../ButtonCustom";
import PopConfirmCustom from "../../Custom/PopConfirmCustom";

const list = ['gray', 'yellow', 'red', 'blue', 'green'];

const PrettoSlider = withStyles({
    root: {
        color: '#52af77',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

function QuantizingDialog({open, setOpen, quantizing, setQuantizing}) {
    const classes = useStyles();
    const [color, setColor] = useState('gray');
    const [type, setType] = useState('diamond');
    const [value, setValue] = useState(0);
    const {updateField} = useInsightMaps()
    useEffect(() => {
        if (open) {
            if (open.color) {
                setColor(open.color);
            }
            if (open.type) {
                setType(open.type);
            }
            setValue(open.value || 0);
        }
    }, [open]);
    const handleClose = () => {
        setOpen(null);
    };
    const onSave = () => {
        if (!open?.id) return null;
        quantizing[open.id] = `${type},${color},${value}`;
        setQuantizing({...quantizing});
        updateField("quantizing", quantizing)
        handleClose();
    };

    const onDelete = async () => {
        try {
            delete quantizing[open.id];
            setQuantizing({...quantizing});
            updateField("quantizing", quantizing)
            handleClose();
        } catch (e) {
            console.log(e);
        }
    }

    const onCancel = () => {

    }

    return (
        <Dialog open={Boolean(open)} onClose={handleClose} className={classes.root}>
            <DialogTitle>
                ４ エネルギー
                <IconButton className={classes.close} onClick={handleClose}>
                    <Close />
                </IconButton>
                {Boolean(quantizing[open?.id]) && <PopConfirmCustom
                    title={"Do you want to delete?"}
                    onConfirm={onDelete}
                    onCancel={onCancel}
                    okText={'Yes'}
                    cancelText={'No'}
                >
                    <IconButton className={classes.delete}>
                        <Delete />
                    </IconButton>
                </PopConfirmCustom>}
            </DialogTitle>
            <DialogContent>
                <div className={classes.formContent}>
                    <Typography>エネルギーのマーク</Typography>
                    <div className={classes.quantizing}>
                        <div
                            className={classes.diamond}
                            onClick={() => setType('diamond')}
                            style={
                                type === 'diamond'
                                    ? {
                                        borderBottom: '5px solid #c1c1c1',
                                        backgroundImage: `url(img/energymark/negative-1-19/diamond/${color}.png)`,
                                    }
                                    : {}
                            }
                        />

                        <div
                            className={classes.heart}
                            onClick={() => setType('heart')}
                            style={
                                type === 'heart'
                                    ? {
                                        borderBottom: '5px solid #c1c1c1',
                                        backgroundImage: `url(img/energymark/negative-1-19/heart/${color}.png)`,
                                    }
                                    : {}
                            }
                        />

                        <div
                            className={classes.flame}
                            onClick={() => setType('flame')}
                            style={
                                type === 'flame'
                                    ? {
                                        borderBottom: '5px solid #c1c1c1',
                                        backgroundImage: `url(img/energymark/negative-1-19/flame/${color}.png)`,
                                    }
                                    : {}
                            }
                        />
                    </div>
                </div>
                <div className={classes.formContent}>
                    <Typography>エネルギーのカラー</Typography>
                    <Grid container spacing={2} justify={'center'} className={classes.gridContainer}>
                        {list.map((item) => {
                            return (
                                <Grid
                                    key={item}
                                    item
                                    xs={2}
                                    style={color === item ? {borderBottom: `3px solid ${color}`} : {}}
                                    onClick={() => {
                                        setColor(item);
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 60,
                                            height: 60,
                                            backgroundColor: item,
                                            borderRadius: '50%',
                                            marginBottom: 5,
                                            marginLeft: 7
                                        }}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
                <div className={classes.formContent}>
                    <Typography>エネルギーの強さ</Typography>
                    <PrettoSlider
                        value={value ? value : 0}
                        max={100}
                        min={-100}
                        onChange={(e, value) => {
                            setValue(value);
                        }}
                    />
                    <div className={classes.inputContainer}>
                        <TextField
                            variant={'outlined'}
                            className={classes.textField}
                            value={value}
                            type={'number'}
                            onChange={(e) => {
                                if (!e) return null;
                                if (Number(e.target.value) < -100) {
                                    return setValue(-100);
                                }

                                if (Number(e.target.value) > 100) {
                                    return setValue(100);
                                }
                                setValue(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </DialogContent>
            <Divider light className={classes.divider} />
            <DialogActions className={classes.actionsContainer}>
                <ButtonCustom onClick={onSave}
                              className={classes.button}
                              backgroundColor={"rgb(154, 109, 176, 1)"}
                              backgroundBlur={"rgba(154, 109, 176, 0.5)"}
                >Ok</ButtonCustom>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialogTitle-root': {
            minWidth: 600,
            background:
                '-webkit-linear-gradient(45deg, rgba(154, 109, 176, 1) 0%, rgba(154, 109, 176, 0.5) 49%, rgba(154, 109, 176, 0.25) 50%, rgba(154, 109, 176, 0.75) 100%)',
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bold',
        },
        '& .MuiDialogActions-root': {
            justifyContent: 'center',
        },
    },
    button: {
        margin: '10px',
    },
    cancelBtn: {
        margin: '10px',
        boxShadow: '0 4px rgba(204, 204, 204, 0.5)',
    },
    actionsContainer: {
        backgroundColor: '#f0f0f0',
    },
    divider: {
        backgroundColor: 'black',
    },
    formContent: {
        marginTop: 10,
        marginBottom: 10,
        padding: '0px 10px',
        margin: 'auto',
    },

    textField: {
        '& input': {
            textAlign: 'center',
            padding: 10,
        },
        margin: 'auto',
        width: 100,
    },
    formCheckbox: {
        display: 'flex',
        alignItems: 'center',
    },
    close: {
        float: 'right',
        padding: 0,
    },
    delete: {
        float: "right",
        padding: 0,
        marginRight: 10
    },
    diamond: {
        backgroundImage: 'url(img/diamond.png)',
        width: '40px',
        height: '40px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        margin: 'auto',
        top: '20px',
        cursor: 'pointer',
        paddingBottom: 5,
    },
    heart: {
        backgroundImage: 'url(img/heart.png)',
        width: '40px',
        height: '40px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        margin: 'auto',
        top: '35px',
        cursor: 'pointer',
        paddingBottom: 5,
    },
    flame: {
        backgroundImage: 'url(img/flame.png)',
        width: '40px',
        height: '40px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        margin: 'auto',
        top: '45px',
        cursor: 'pointer',
        paddingBottom: 5,
    },
    gridContainer: {
        margin: '30px auto',
        width: '100%',
    },
    quantizing: {
        backgroundImage: `url(img/keyhole/quantizing.png)`,
        height: '201px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        // opacity: 0.75,
        marginTop: 10,
        marginBottom: 10,
        padding: '0px 10px',
        margin: 'auto',
    },
    inputContainer: {
        textAlign: 'center',
    },
}));

export default QuantizingDialog;
