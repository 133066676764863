import React from 'react';
import Grid from "@material-ui/core/Grid";

function TagUsedRecentList(props) {
    const {classes, sources, tagSelected, setTagSelected} = props;

    const isSelected = (tagData) => {
        return tagSelected?.id === tagData?.id;
    };

    return (
        <Grid container spacing={2}>
            {
                sources.map((tag, index) => {
                    return (
                        <Grid key={index} item md={2} sm={2} xs={4} className={classes.divRecentGrid}>
                            <div className={classes.divRecent} onClick={() => {
                                setTagSelected(tag)
                            }}>
                                <div
                                    className={classes.divImg}
                                    style={{backgroundImage: `url(/img/pawn/${tag?.color || 'gray'}.png)`}}
                                >

                                    <div style={{backgroundColor: tag.color, width: isSelected(tag) ? "40px" : "0px"}}
                                         className={classes.IdentitySelected}/>

                                </div>
                                <div>{tag?.content || "none"}
                                </div>
                            </div>
                        </Grid>
                    )
                })
            }
        </Grid>
    );
}

export default TagUsedRecentList;
