import React, {useEffect, useState} from 'react';
import {IconButton, Popover, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Close} from '@material-ui/icons';
import {useInsightMaps} from '../../../context/InsightMindMapsContext';

function ConflictDialog({open, setOpen, conflict, setConflict}) {
    const classes = useStyles();
    const {updateField} = useInsightMaps()
    const [text, setText] = useState('');
    // const [textDisplay, setTextDisplya]=us
    // const length = open?.id?.includes("callout") ? 50 : 20
    useEffect(() => {
        if (open) {
            setText(open.text);
        }
    }, [open]);
    const handleClose = () => {
        setOpen(null);
    };
    const onSave = () => {
        if (open && text !== open.text) setConflict({...conflict, [open.id]: text});
        updateField("map", {...conflict, [open.id]: text})
        handleClose();
    };
    return (
        <Popover
            open={Boolean(open)}
            onClose={onSave}
            anchorEl={open?.target}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div className={classes.content}>
                <div className={classes.header}>
                    <IconButton onClick={onSave} className={classes.closeIcon}>
                        <Close/>
                    </IconButton>
                </div>

                <TextField
                    className={classes.textArea}
                    value={text}
                    onChange={(e) => {
                        // if (e.target.value?.length > length) return null;
                        setText(e.target.value);
                    }}
                    multiline={true}
                    rows={3}
                    autoFocus={true}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            onSave();
                        }
                    }}
                />
                <div className={classes.footer}>{text?.length || 0}/20</div>
            </div>
        </Popover>
    );
}

const useStyles = makeStyles((theme) => ({
    content: {
        minWidth: 300,
        "& .MuiFormControl-root": {
            display: "block",
            padding: "5px 10px"
        },
        "& .MuiInput-root": {
            width: "100%"
        }
    },
    calloutContent: {
        minWidth: 500,
    },
    header: {
        height: 30,
        backgroundColor: '#6783c2',
        padding: '0px 15px',
    },
    closeIcon: {
        float: 'right',
        padding: '5px 0',
    },
    textArea: {
        '& .MuiInput-underline:before': {
            border: 'none !important',
        },
        '& .MuiInput-underline:after': {
            border: 'none !important',
        },
        '& input': {
            textAlign: 'center'
        }
    },
    footer: {
        float: 'right',
        marginTop: 10,
        marginBottom: 5,
        padding: "0 5px"
    },
}));

export default ConflictDialog;
