import React from 'react';
import Button from "@material-ui/core/Button";

function ButtonIdentity(props) {
    const {classes, setShowRecent, isLoadMore = false, nextData = null, hasMore = true} = props;

    const handleActions = () => {
        setShowRecent(true)
    }
    return (
        <div className={classes.buttonRecent}>
            <Button
                variant={"outlined"}
                className={isLoadMore ? classes.buttonLoadMore : classes.buttonRecentDiv}
                onClick={isLoadMore ? nextData : handleActions}
                disabled={isLoadMore ? !hasMore : false}
            >
                {
                    isLoadMore ? "さらに読み込む" : "最近使ったアイデンティティ・タグ"
                }
            </Button>
        </div>
    );
}

export default ButtonIdentity;
