import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AppsIcon from '@material-ui/icons/Apps';
import MenuIcon from '@material-ui/icons/Menu';
import AllOutIcon from '@material-ui/icons/AllOut';
import Avatar from '@material-ui/core/Avatar';
import Item from "./Item";
import Divider from '@material-ui/core/Divider';
import {ACCESS_TOKEN_KEY, LOGIN_URL, REFRESH_TOKEN_KEY, REGISTER_URL} from '../../config/constants';
import AppListPopup from './AppListPopup';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountPopup from './AccountPopup';
import {useHistory} from 'react-router-dom';
import rtn from 'reactn';
import AppBarSearch from "./AppBarSearch";
import {useInsightMaps} from "../../context/InsightMindMapsContext";
import {DB_COLL, mapRef} from "../../common/envSettings";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import moment from "moment";
import {downLoadFile} from "../InsightMind/common/download";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
// const {useGlobal} = globalValue.default;
import {PermIdentity} from '@material-ui/icons'
import {List, ListItem, ListItemIcon, ListItemText, Popover} from "@material-ui/core";
import {createQueryParams} from "../../common/handleSomes";
import {db} from '../../firebaseConfig'
import {v4 as uuidv4} from 'uuid'
import {toast} from "react-toastify";

const copyFlat = [
    {
        iconSub: "/img/map-one-default.png",
        textSub: "インサイトマップOneへ拡張",
        data: "mapOne"
    },
]

const copyMini = [
    {
        iconSub: "/img/map-default.png",
        textSub: "インサイトマップの通常版（4層版）",
        data: "mapDefault"
    },
    {
        iconSub: "/img/map-one-default.png",
        textSub: "インサイトマップ・ワン",
        data: "mapOne"
    },
]

function imgLoad(url) {

    return new Promise(function (resolve, reject) {
        const request = new XMLHttpRequest();
        request.open('GET', url);
        request.responseType = 'blob';

        request.onload = function () {
            if (request.status === 200) {
                resolve(request.response)
            } else {
                reject(new Error('Image didn\'t load successfully; error code:' + request.statusText));
            }
        };

        request.onerror = function () {
            reject(new Error('There was a network error.'))
        };
        request.send();
    });
}


export default function AppbarScreen(props) {
    const classes = useStyles();
    const history = useHistory();
    const [user] = rtn.useGlobal('user');
    const [, setMenuMapSetting] = rtn.useGlobal("menuMapSetting");
    const [appListPopupAnchorEl, setAppListPopupAnchorEl] = useState(null);
    const [accountPopupAnchorEl, setAccountPopupAnchorEl] = useState(null);
    const [, setOpenDialogIdentityTag] = rtn.useGlobal('openDialogIdentityTag');
    const [, setOpenDialogSupport] = rtn.useGlobal('openDialogSupport');
    const [, setOpenInvite] = rtn.useGlobal('openInvite');
    const {map, otherUsers} = useInsightMaps();
    // const {tags} = useInsightMindUser();
    const [titleInput, setTitleInput] = useState(map?.title)
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const [openOtheruser, setOpenOtheruser] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPopper = Boolean(anchorEl);
    const id = openPopper ? 'transitions-popper' : undefined;

    const handleClickMenu = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const insightHome = () => {
        if (window.location.pathname === "/starterpack") {
            if (user?.user_id) {
                window.location.assign(`${window.location.origin}/filemanager`)
                return;
            }
            window.location.assign(`${window.location.origin}/`)
            return
        }
        history.push('/filemanager')
    };
    const closeAppListPopup = (e) => {
        setAppListPopupAnchorEl(null);
    };
    const showAppList = (e) => {
        setAppListPopupAnchorEl(e.currentTarget);
    };
    const openAccountPopup = (e) => {
        setAccountPopupAnchorEl(e.currentTarget);
    };
    const closeAccountPopup = (e) => {
        setAccountPopupAnchorEl(null);
    };
    const onDownload = async (item) => {
        if (!map)
            return null
        setOpen(false)
        downLoadFile(item.textSub, map, () => {
            console.log("done!")
        })
    }
    const onCopy = async (item) => {
        try {
            setOpen(false)
            if (!map || loading)
                return null
            setLoading(true)
            let copiedMap = null
            const id = Date.now()
            const token = `token_${uuidv4()}`
            if (item.data === "normal") {
                copiedMap = {
                    ...map,
                    id,
                    title: "無題のインサイトマップ",
                    token,
                    createdAt: moment().utc().format(),
                    updatedAt: moment().utc().format(),
                    is_delete: false,
                }
            }
            if ((item.data === "mapOne" && !map.role) || (item.data === "mapOne" && map.role === 2)) {
                copiedMap = {
                    id,
                    title: "無題のインサイトマップ",
                    token,
                    createdAt: moment().utc().format(),
                    updatedAt: moment().utc().format(),
                    role: 1,
                    is_delete: false,
                    map: map?.map || {},
                    line: map?.line || "",
                    folder_id: map?.folder_id || "",
                    image: map?.image || ""
                }
            }
            if (item.data === "mapDefault") {
                copiedMap = {
                    id,
                    title: "無題のインサイトマップ",
                    token,
                    createdAt: moment().utc().format(),
                    updatedAt: moment().utc().format(),
                    role: 0,
                    is_delete: false,
                    map: map?.map || {},
                    line: map?.line || "",
                    folder_id: map.folder_id,
                    image: map.image
                }
            }
            if (!copiedMap)
                return null
            const batch = db.batch()
            const mapRef = db.doc(`${DB_COLL}/${user.user_id}/insightmaps/${id}`)
            const historyId = Date.now()
            const historyRef = db.doc(`${DB_COLL}/${user.user_id}/histories/${historyId}`)
            batch.set(mapRef, copiedMap)
            batch.set(historyRef, {...copiedMap, isDefault: true, id: historyId, updatedAt: historyId, mapId: `${id}`})
            await batch.commit()
            setTitleInput(copiedMap.title)
            toast.success("copied")
            history.push(`/insightmap?id=${id}`)
        } catch (e) {
            console.log(e);
            toast.error(e.toString())
        } finally {
            setLoading(false)
        }

    }


    return (
        <div className={classes.root}>
            {user.user_id ? (
                <div className={"print-hidden"}>
                    <AppBar className={classes.appbarStyle}>
                        <Toolbar className={classes.justifyContent_between}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <div className={classes.toolbarStyle}>
                                    {
                                        window.location.pathname === "/starterpack" ? null :
                                            <>
                                                <IconButton
                                                    edge='start'
                                                    className={classes.menuButton}
                                                    color='inherit'
                                                    aria-label='open drawer'
                                                    onClick={handleClick}
                                                >
                                                    <MenuIcon className={classes.iconSize} />
                                                </IconButton>

                                                {
                                                    open && <div className={classes.menuPopup}>
                                                        <Item
                                                            className={classes.hoverItem}
                                                            onClick={() => {
                                                                window.location.assign(`${window.location.origin}/filemanager`)
                                                                setOpen(false);
                                                            }}
                                                            text={'ファイル管理'}
                                                            icon={<img src={"/img/icon/file.png"} alt={""} />}
                                                        />
                                                        <Item
                                                            className={classes.hoverItem}
                                                            onClick={() => {
                                                                window.location.assign(`${window.location.origin}/insightMaps`)
                                                                setOpen(false);
                                                            }}
                                                            text={'新規作成'}
                                                            icon={<img src={"/img/icon/plus.png"} alt={""} />}
                                                        />
                                                        {
                                                            window.location.pathname === '/insightmap' &&
                                                            <div>
                                                                {
                                                                    user.user_id === map?.owner &&
                                                                    <Item
                                                                        className={classes.hoverItem}
                                                                        onClick={() => {
                                                                            setOpen(false);
                                                                            setOpenInvite(true)

                                                                        }}
                                                                        text={'ファイルを共有する'}
                                                                        icon={<img src={"/img/icon/invite.png"}
                                                                                   alt={""} />}
                                                                    />
                                                                }

                                                                <Item
                                                                    className={classes.hoverItem}
                                                                    text={'コピーして新規作成'}
                                                                    icon={<img src={"/img/icon/copy.png"} alt={""} />}
                                                                    minWidth={300}
                                                                    onClick={() => onCopy({data: 'normal'})}
                                                                />
                                                                {
                                                                    map?.role !== 1 &&
                                                                    <Item
                                                                        className={classes.hoverItem}
                                                                        text={'拡張'}
                                                                        icon={<AllOutIcon opacity={0.6} />}
                                                                        minWidth={330}
                                                                        subMenu={
                                                                            map?.role === 2 ? copyMini : copyFlat
                                                                        }
                                                                        onSubClick={onCopy}
                                                                    />
                                                                }
                                                                <Item
                                                                    onClick={() => {
                                                                        window.print()
                                                                    }}
                                                                    className={classes.hoverItem}
                                                                    text={'印刷'}
                                                                    icon={<img src={"/img/icon/print.png"} alt={""} />}
                                                                />
                                                                <Item
                                                                    className={classes.hoverItem}
                                                                    text={'書き出し'}
                                                                    icon={<img src={"/img/icon/download.png"}
                                                                               alt={""} />}
                                                                    onSubClick={onDownload}
                                                                    subMenu={[
                                                                        {
                                                                            iconSub: "/img/icon/pdf.png",
                                                                            textSub: "PDF"
                                                                        },
                                                                        {
                                                                            iconSub: "/img/icon/png.png",
                                                                            textSub: "PNG"
                                                                        }
                                                                    ]}
                                                                />
                                                                <Item
                                                                    className={classes.hoverItem}
                                                                    onClick={() => {
                                                                        window.location.assign(`${window.location.origin}/filemanager`)
                                                                        setOpen(false);
                                                                    }}
                                                                    text={'終了'}
                                                                    icon={<img src={"/img/icon/close.png"} alt={""} />}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            window.location.pathname === "/insightMaps" &&
                                                            <div>
                                                                <Item
                                                                    className={classes.hoverItem}
                                                                    text={'印刷'}
                                                                    icon={<img src={"/img/icon/print.png"} alt={""} />}
                                                                />
                                                                <Item
                                                                    onClick={() => {
                                                                        history.push('/filemanager')
                                                                        setOpen(false);
                                                                    }}
                                                                    className={classes.hoverItem}
                                                                    text={'終了'}
                                                                    icon={<img src={"/img/icon/close.png"} alt={""} />}
                                                                />
                                                            </div>
                                                        }
                                                        <Divider color={'#fff'} style={{background: "#fff"}} />

                                                        <Item
                                                            className={classes.hoverItem}
                                                            text={"アイデンティティ・タグ管理"}
                                                            icon={<img src={"/img/icon/identity-tag.png"} alt={""} />}
                                                            onClick={() => {
                                                                setOpen(false);
                                                                setOpenDialogIdentityTag(true)

                                                            }}
                                                        />
                                                        <Item
                                                            className={classes.hoverItem}
                                                            text={"設定"}
                                                            icon={<img src={"/img/icon/setting.png"} alt={""} />}
                                                            onClick={() => {
                                                                setOpen(false);
                                                                setMenuMapSetting(true)
                                                            }}
                                                        />
                                                        <Item
                                                            className={classes.hoverItem}
                                                            text={'サポート'}
                                                            icon={<img src={"/img/icon/support.png"} alt={""} />}
                                                            onClick={() => {
                                                                setOpen(false);
                                                                setOpenDialogSupport(true)
                                                            }}
                                                        />

                                                    </div>
                                                }
                                            </>
                                    }
                                    <Typography className={classes.title} onClick={insightHome}>
                                        Insight Mind
                                    </Typography>
                                    {
                                        window.location.pathname === '/filemanager' &&
                                        <div className={classes.search}>
                                            <AppBarSearch />
                                        </div>
                                    }
                                </div>
                            </ClickAwayListener>

                            <div className={classes.display_flex}>
                                {
                                    window.location.pathname === '/insightMaps' || window.location.pathname === '/insightmap' &&
                                    <div className={classes.inputAppbar}>
                                        <input
                                            value={titleInput}
                                            className={classes.titleInput}
                                            placeholder={map.title}
                                            onChange={onMapTitleChange}
                                            // onKeyDown={saveTitle('enter')}
                                            onBlur={saveTitle('blur')}
                                        />
                                    </div>

                                }
                            </div>
                            <div className={
                                window.location.pathname === '/filemanager' ?
                                    classes.rightAppbarStyle : classes.rightAppbarStyleMobie
                            }>
                                {
                                    window.location.pathname === '/insightMaps' || window.location.pathname === '/insightmap' &&
                                    <div className={classes.last_update}>
                                        Last edit at {moment(map?.updatedAt).format("YYYY年MM月DD日")}
                                    </div>
                                }
                                {
                                    otherUsers?.length ?
                                        <IconButton onClick={(e) => setOpenOtheruser(e.currentTarget)}>
                                            <PermIdentity className={classes.iconSize + " " + classes.iconColor} />
                                        </IconButton>
                                        :
                                        null
                                }

                                <IconButton
                                    aria-label='account of current user'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    color={'inherit'}
                                    onClick={() => {
                                        const token = localStorage.getItem(ACCESS_TOKEN_KEY);
                                        const refreshToken = localStorage.getItem(
                                            REFRESH_TOKEN_KEY);
                                        window.open(
                                            `${process.env.REACT_APP_CART_URL}/?${createQueryParams(
                                                {
                                                    token,
                                                    refreshToken,
                                                })}`, '_blank');
                                    }}
                                >
                                    <ShoppingCartIcon className={classes.iconSize} />
                                </IconButton>

                                <IconButton
                                    onClick={showAppList}
                                    aria-label='account of current user'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    color='inherit'
                                >
                                    <AppsIcon className={classes.iconSize} />
                                </IconButton>

                                <AppListPopup anchorEl={appListPopupAnchorEl} onClose={closeAppListPopup} />

                                <IconButton
                                    id={'user_avatar_button'}
                                    aria-owns={'menu-appbar'}
                                    aria-haspopup='true'
                                    onClick={openAccountPopup}
                                    color='inherit'
                                >
                                    {user.avatar ? (
                                        <Avatar className={classes.avatarStyle} src={user.avatar} />
                                    ) : (
                                        <AccountCircle className={classes.avatarStyle} />
                                    )}
                                </IconButton>
                                <AccountPopup anchorEl={accountPopupAnchorEl} onClose={closeAccountPopup} />
                            </div>

                        </Toolbar>

                    </AppBar>

                </div>

            ) : (
                <div>
                    <AppBar className={classes.appbarStyle}>
                        <Toolbar className={classes.justifyContent_between}>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <IconButton onClick={handleClickMenu} className={classes.iconMenu}>
                                    <MenuIcon style={{color: "#fff"}} />
                                </IconButton>
                                <Popper id={id} open={openPopper} anchorEl={anchorEl} transition>
                                    {({TransitionProps}) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <div className={classes.paper}>
                                                <Button
                                                    className={classes.btnLogin1}
                                                    color={'default'}
                                                    variant={'outlined'}
                                                    onClick={() => {
                                                        window.location = LOGIN_URL + '?redirect_url=' + window.location.href;
                                                    }}
                                                >
                                                    ログイン
                                                </Button>
                                                <Button
                                                    className={classes.btnLogin2}
                                                    color={'default'}
                                                    variant={'outlined'}
                                                    onClick={() => {
                                                        window.location = REGISTER_URL + '?redirect_url=' + window.location.href;
                                                    }}
                                                >
                                                    新規登録
                                                </Button>
                                            </div>
                                        </Fade>
                                    )}
                                </Popper>
                            </Hidden>
                            <div className={classes.toolbarStyle}>
                                <Typography variant='h6' className={classes.title} onClick={insightHome}>
                                    Insight Mind
                                </Typography>
                            </div>

                            <div className={classes.rightAppbarStyle}>
                                <IconButton
                                    aria-label='account of current user'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    color='inherit'
                                    onClick={() => {
                                        const token = localStorage.getItem(ACCESS_TOKEN_KEY);
                                        const refreshToken = localStorage.getItem(
                                            REFRESH_TOKEN_KEY);
                                        window.open(
                                            `${process.env.REACT_APP_CART_URL}/?${createQueryParams(
                                                {
                                                    token,
                                                    refreshToken,
                                                })}`, '_blank');
                                    }}
                                >
                                    <ShoppingCartIcon className={classes.iconSize} />
                                </IconButton>

                                <IconButton
                                    onClick={showAppList}
                                    aria-label='account of current user'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    color='inherit'
                                >
                                    <AppsIcon className={classes.iconSize} />
                                </IconButton>

                                <AppListPopup anchorEl={appListPopupAnchorEl} onClose={closeAppListPopup} />
                                <Hidden only={['xs', 'sm']}>
                                    <Button
                                        className={classes.btnLogin1}
                                        color={'default'}
                                        variant={'outlined'}
                                        onClick={() => {
                                            window.location = LOGIN_URL + '?redirect_url=' + window.location.href;
                                        }}
                                    >
                                        ログイン
                                    </Button>
                                    <Button
                                        className={classes.btnLogin2}
                                        color={'default'}
                                        variant={'outlined'}
                                        onClick={() => {
                                            window.location = REGISTER_URL + '?redirect_url=' + window.location.href;
                                        }}
                                    >
                                        新規登録
                                    </Button>
                                </Hidden>
                            </div>
                        </Toolbar>
                    </AppBar>
                </div>
            )}
            <PopoverOtherusers open={openOtheruser} setOpen={setOpenOtheruser} users={otherUsers} />
        </div>
    );

    function saveTitle(act) {
        return (e) => {
            function save() {
                mapRef(map.id)
                    .set({
                            title: titleInput
                        },
                        {merge: true}
                    )
                    .catch(console.log)
                // insertTagNamesForSearching({
                //     ...map,
                //     title: titleInput
                // }, tags).catch(console.log)
            }

            if (act === 'enter' && e.key === 'Enter') {
                save()
            } else if (act === 'blur') {
                save()
            }

        }
    }

    function onMapTitleChange(event) {
        return setTitleInput(event.target.value);
    }
}

function PopoverOtherusers({open, setOpen}) {
    const classes = useStyleOtherUsers()
    const {map, otherUsers} = useInsightMaps();
    const [user] = rtn.useGlobal('user');
    const handleClose = () => {
        setOpen(null)
    }
    if (!open)
        return null
    return (
        <Popover open={Boolean(open)} onClose={handleClose}
                 anchorEl={open}
                 anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'center',
                 }}
                 transformOrigin={{
                     vertical: 'top',
                     horizontal: 'center',
                 }}
        >
            <Typography className={classes.title}>
                This file has been shared {map?.owner === user.user_id ? "for" : "by"}
            </Typography>
            <List className={classes.list}>
                {
                    otherUsers.map((user, idx) => {
                        if (user)
                            return (
                                <ListItem button key={idx}>
                                    <ListItemIcon>
                                        <Avatar src={user.avatar || null}>
                                            {user?.nickname?.substr(0, 1)}
                                        </Avatar>
                                    </ListItemIcon>
                                    <ListItemText>
                                        {user.nickname}
                                    </ListItemText>

                                </ListItem>
                            )
                    })
                }
            </List>
        </Popover>
    )
}

const useStyleOtherUsers = makeStyles(theme => ({
    list: {
        minWidth: 250
    },
    title: {
        padding: 10,
        fontSize: 12
    }
}))
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    iconColor: {
        color: "white"
    },
    appbarStyle: {
        backgroundColor: 'black',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    display_flex: {
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    title: {
        // flexGrow: 1,
        fontFamily: 'inherit',
        fontWeight: 500,
        lineHeight: 1.1,
        color: 'inherit',
        fontSize: 20,
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: 15,
        // },
        "&:hover": {
            cursor: "pointer"
        }
    },
    btnLogin1: {
        margin: 'auto',
        height: 36,
        marginLeft: 10,
        color: 'white',
        backgroundColor: '#3f51b5',
        "&:hover": {
            backgroundColor: '#3f51b5',
        }
    },
    btnLogin2: {
        height: 36,
        marginLeft: 10,
        margin: 'auto',
        color: 'white',
        backgroundColor: '#f50057',
        "&:hover": {
            backgroundColor: '#f50057',
        }
    },
    icons: {
        color: 'white',
    },
    avatarStyle: {
        margin: 'auto',
        // marginLeft: 20
    },
    rightAppbarStyle: {
        display: 'flex',
        "& .MuiIconButton-root": {
            padding: 10
        },
    },
    rightAppbarStyleMobie: {
        display: 'flex',
        "& .MuiIconButton-root": {
            padding: 10
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    iconSize: {
        fontSize: 30,
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: 25,
        // },
    },
    menuPopup: {
        fontSize: 14,
        backgroundColor: '#222',
        color: 'white',
        position: "absolute",
        top: 70,
        border: "1px solid rgba(0,0,0,.15)",
        boxShadow: "0 6px 12px rgba(0,0,0,.175)",
        borderRadius: "4px",
        '& .MuiListItemIcon-root': {
            minWidth: 30,
            color: 'white',
        }
    },
    hoverItem: {
        "&:hover": {
            backgroundColor: '#000'
        }
    },
    justifyContent_between: {
        justifyContent: "space-between"
    },
    toolbarStyle: {
        display: "flex",
        alignItems: 'center'
    },
    search: {
        marginLeft: 20,

    },
    titleInput: {
        margin: 0,
        border: '0 none',
        padding: '0 10px',
        display: 'block',
        height: '29px',
        // width: '100%',
        textAlign: 'center',
        backgroundColor: 'transparent',
        color: '#fff',
        fontSize: 15,
        "&::placeholder": {
            color: "#fff"
        }
    },
    inputAppbar: {
        height: '30px',
        border: '1px solid #6d6d6d',
        maxWidth: '500px',

    },
    paper: {
        padding: "15px 10px 5px 10px",
        backgroundColor: "#222",
        display: "flex",
        flexDirection: "column",
        "& .MuiButton-root": {
            margin: "5px auto"
        }
    },
    iconMenu: {
        backgroundColor: "#333 !important",
        borderRadius: "4px",
        padding: "7px 14px"
    },
    last_update: {
        display: 'flex',
        alignItems: 'center'
    }
}));
