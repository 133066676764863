import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Slider,
    TextField,
    Typography,
    withStyles
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Close, Delete} from '@material-ui/icons';
import _ from 'lodash';
import {useInsightMaps} from '../../../context/InsightMindMapsContext';
import ButtonCustom from "../../ButtonCustom";
import useKeyboardJs from "react-use/lib/useKeyboardJs";
import PopConfirmCustom from "../../Custom/PopConfirmCustom";

const list = ["gray", "yellow", "red", "blue", "green"]
const styleList = ['solid', "dot"]
const PrettoSlider = withStyles({
    root: {
        color: '#52af77',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

function VectorDialog({open, setOpen, vector, setVector}) {
    const classes = useStyles()
    const [value, setValue] = useState(200)
    const [color, setColor] = useState("gray")
    const [style, setStyle] = useState("solid")
    const {updateField} = useInsightMaps()
    const [isPressed] = useKeyboardJs('enter')

    useEffect(() => {
        if (isPressed && Boolean(open)) {
            onSave()
        }
    }, [isPressed])

    useEffect(() => {
        if (open) {
            setValue(Number(open.width))
            setColor(open.vcolor)
            setStyle(open.style)
        }
    }, [open])

    const handleClose = () => {
        setOpen(null)
    }

    const onSave = async () => {
        if (!open)
            return null
        let idx = _.findIndex(vector, item => item.head === open.head && item.rail === open.rail)
        if (idx === -1) {
            vector.push({
                head: open.head,
                rail: open.rail,
                width: value,
                vcolor: color,
                style
            })
            setVector([...vector])
            updateField("vector", vector)
            handleClose()
            return
        }

        vector[idx] = {
            head: `${open.head}`,
            rail: `${open.rail}`,
            width: value,
            vcolor: color,
            style
        }
        setVector([...vector])
        updateField("vector", vector)
        handleClose()
    }

    const onDelete = async () => {
        try {
            let idx = _.findIndex(vector, item => item.head === open.head && item.rail === open.rail)

            if (idx !== -1) {
                vector.splice(idx, 1)
                setVector([...vector])
                updateField("vector", vector)
                handleClose()
            }
        } catch (e) {
            console.log(e);
        }
    }

    const onCancel = () => {

    }

    return (
        <Dialog open={Boolean(open)} onClose={handleClose} className={classes.root}>
            <DialogTitle>
                ５ バイアス
                <IconButton className={classes.close}
                            onClick={handleClose}
                >
                    <Close />
                </IconButton>
                {
                    _.findIndex(vector, item => item.head === open?.head && item.rail === open?.rail) !== -1 &&
                    <PopConfirmCustom
                        title={"Do you want to delete?"}
                        onConfirm={onDelete}
                        onCancel={onCancel}
                        okText={'Yes'}
                        cancelText={'No'}
                    >
                        <IconButton className={classes.delete}>
                            <Delete />
                        </IconButton>
                    </PopConfirmCustom>
                }
            </DialogTitle>
            <DialogContent>
                <div className={classes.formContent}>
                    <Typography>
                        バイアスのカラー
                    </Typography>
                    <Grid container spacing={2} justify={"center"} className={classes.gridContainer}>
                        {
                            list.map(item => {
                                return (
                                    <Grid key={item}
                                          item
                                          xs={2}
                                          style={color === item ? {
                                              borderBottom: `3px solid ${color}`,
                                              cursor: 'pointer'
                                          } : {cursor: 'pointer'}}
                                          onClick={() => {
                                              setColor(item)
                                          }}
                                    >
                                        <img className={classes.iconLine} alt={""} src={`img/vector/${item}.png`} />
                                    </Grid>


                                )
                            })
                        }
                    </Grid>
                </div>
                <div className={classes.formContent}>
                    <Typography>
                        エネルギーのカラー
                    </Typography>
                    <Grid container spacing={2} justify={"center"} alignItems={"center"}
                          className={classes.gridContainer}>
                        {
                            styleList.map(item => {
                                return (
                                    <Grid key={item}
                                          item
                                          xs={6}
                                          className={classes.girdStyle}
                                    >
                                        <div style={{
                                            width: 100,
                                            height: 20,
                                            cursor: 'pointer',
                                            borderTop: `5px ${item === "solid" ? item : "dashed"} ${color}`,
                                            borderBottom: style === item ? `5px solid ${color}` : "none"
                                        }}
                                             onClick={() => {
                                                 setStyle(item)
                                             }}
                                        />
                                    </Grid>)
                            })
                        }
                    </Grid>
                </div>
                <div className={classes.formContent}>
                    <Typography>
                        バイアスの強さ
                    </Typography>
                    <PrettoSlider
                        value={value || 0}
                        max={400}
                        min={0}
                        onChange={(e, value) => {
                            setValue(value)
                        }}
                    />
                    <div className={classes.inputContainer}>
                        <TextField
                            variant={"outlined"}
                            className={classes.textField}
                            value={value}
                            type={"number"}
                            onChange={(e) => {
                                if (!e)
                                    return null
                                if (Number(e.target.value) < 0) {
                                    return setValue(0)
                                }

                                if (Number(e.target.value) > 400) {
                                    return setValue(400)
                                }
                                setValue(e.target.value)

                            }}
                        />
                    </div>

                </div>
            </DialogContent>
            <Divider light className={classes.divider} />
            <DialogActions className={classes.actionsContainer}>
                <ButtonCustom onClick={onSave}
                              backgroundColor={"rgb(214, 113, 173, 1)"}
                              backgroundBlur={"rgba(214, 113, 173, 0.5)"}
                >Ok</ButtonCustom>

            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiDialogTitle-root": {
            minWidth: 600,
            background: "-webkit-linear-gradient(45deg, rgba(214, 113, 173, 1) 0%, rgba(214, 113, 173, 0.5) 49%, rgba(214, 113, 173, 0.25) 50%, rgba(214, 113, 173, 0.75) 100%)",
            textAlign: "center",
            color: "white",
            fontWeight: "bold",
        },
        "& .MuiDialogActions-root": {
            justifyContent: "center"
        },
        [theme.breakpoints.down('sm')]: {
            "& .MuiDialogContent-root": {
                padding: 10,
            },
            "& .MuiDialogTitle-root": {
                minWidth: "auto"
            }
        },
    },
    button: {
        margin: "10px",
    },
    cancelBtn: {
        margin: "10px",
        boxShadow: "0 4px rgba(204, 204, 204, 0.5)"
    },
    actionsContainer: {
        backgroundColor: "#f0f0f0",
    },
    divider: {
        backgroundColor: "black"
    },
    formContent: {
        marginTop: 10,
        marginBottom: 10,
        padding: "0px 10px",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    girdStyle: {
        display: 'flex',
        justifyContent: 'center'
    },
    textField: {
        "& input": {
            textAlign: "center",
            padding: 10,
        },
        margin: 'auto',
        width: 100
    },
    formCheckbox: {
        display: "flex",
        alignItems: "center"
    },
    close: {
        float: "right",
        padding: 0
    },
    delete: {
        float: "right",
        padding: 0,
        marginRight: 10
    },
    gridContainer: {
        margin: "30px auto",
        width: "100%"
    },
    inputContainer: {
        textAlign: "center"
    },
    iconLine: {
        width: "100%",
        maxWidth: 82,
        margin: "auto"
    }
}))

export default VectorDialog;
