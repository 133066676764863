import React, {useEffect, useRef, useState} from 'react';
import {maps} from "./common/map";
import {getTextBoxPath, getTextPositon, identities, identitySize} from "./common/identitites";
import MenuMapsBar from "../MenuMapsBar";
import IdentityDialog from "./IdentityDialog";
import SubjectDialog from "./FindingDialog";
import ConflictDialog from "./ConflictDialog";
import {clearsMap} from "./common/clear";
import RemovingDialog from "./RemovingDialog";
import {makeStyles} from "@material-ui/core/styles";
import {keyMap} from "./common/keys";
import KeyDialog from "./KeyDialog";
import {getTextPath} from "./common/conflict";
import {getQuantizingUrl, quantizingMaps} from "./common/quantizing";
import QuantizingDialog from "./QuantizingDialog";
import {linesMap} from "./common/line";
import _ from 'lodash'
import {barrierMap} from "./common/barrier";
import {dotMaps} from "./common/dot";
import {getVector} from "./common/vector";
import VectorDialog from "./VectorDialog";
import {images} from "./common/images";
import {AppBarScreen} from "../LazyLoadComponent";
import DialogSetting from "../DialogSetting/DialogSetting";
import {useInsightMaps} from "../../context/InsightMindMapsContext";
import Process from "../Process/Process";
import DialogIdentityTag from "../FilesManager/DialogManager/DialogIdentityTag";
import DialogSupport from "../FilesManager/DialogSupport";
import RightMenu from "./RightMenu";
import InvitePopup from "../AppBarScreen/MenuPopup/InvitePopup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import rtn from "reactn";
import CreateInsightMapFlatDialog from "../FilesManager/DialogManager/CreateInsightMapFlatDialog";

function InsightMind(props) {
    const classes = useStyles();
    const {map, updateField} = useInsightMaps();
    const strokeWidth = 10
    const stroke = "#a4a4a4"
    const [user] = rtn.useGlobal("user")
    const [selection, setSelection] = useState('conflict');
    const editRef = useRef(null)
    const [hoverId, setHoverId] = useState('')
    const [openIdentity, setOpenIdentity] = useState(null)
    const [openSubject, setOpenSubject] = useState(null)
    const [openConfict, setOpenConflict] = useState(null)
    const [openClear, setOpenClear] = useState(null)
    const [openKey, setOpenKey] = useState(null)
    const [openQuantizing, setOpenQuantizing] = useState(null);
    const [openVector, setOpenVector] = useState(null)
    const [identity, setIdentity] = useState({});
    const [conflict, setConflict] = useState({});
    const [keys, setKeys] = useState({});
    const [clear, setClear] = useState({});
    const [quantizing, setQuantizing] = useState({})
    const [line, setLine] = useState([""])
    const [barrier, setBarrier] = useState([""])
    const [vectorHead, setVectorHead] = useState('')
    const [vector, setVector] = useState([])
    const [folders,] = rtn.useGlobal('folders');


    useEffect(() => {
        if (!map) {
            setIdentity({})
            setConflict({})
            setKeys({})
            setClear({})
            setQuantizing({})
            setLine([])
            setBarrier([])
            setVector([])
            return
        }
        if (map.owner === user.user_id) {
            setSelection(map?.step?.block || 'conflict');
        }
        setIdentity(map.identity || {})
        setConflict(map.map || {})
        setKeys(map.key || {})
        setClear(map.clear || {})
        setQuantizing(map.quantizing || {})
        setLine(map?.line?.split(",") || [])
        setBarrier(map?.barrier?.split(",") || [])
        setVector(map.vector || [])
        if (map.vector && Array.isArray(map.vector)) {
            setVector(map.vector)
        } else {
            setVector([])
        }
    }, [map]);

    // useEffect(() => {
    //     const svgElement = document.getElementById("insightmap_svg")
    //     if (editRef?.current?.idG) {
    //         let idElement = `#${editRef.current.idG}`
    //         let element = document.createElementNS("w3.org/2000/svg", 'use'); //Create a path in SVG's namespace
    //         element.setAttribute("id", "use")
    //         element.setAttribute("xlink:href", idElement)
    //         svgElement.appendChild(element)
    //     } else {
    //         const element = document.getElementById('use')
    //         if (element)
    //             element.remove()
    //     }
    //
    // }, [editRef?.current])

    return (
        <div>
            <AppBarScreen />
            <MenuMapsBar selectStep={selection} setSelectStep={setSelection} conflict={conflict}
                         setConflict={setConflict} />
            <div className={"titleMap"}>
                <h2>{map?.title}</h2>
            </div>
            <div className={"mapContainer"}>
                <ClickAwayListener onClickAway={() => {
                    if (vectorHead) {
                        setVectorHead("")
                    }
                }}>
                    <svg width={1300} height={1300} style={{display: "block", margin: 'auto'}}
                         id={"insightmap_svg"}
                         className={"mapContent"}>
                        <defs>
                            <marker viewBox='0 0 20 20' markerWidth='10' markerHeight='10' orient='auto' refX='0'
                                    refY='3'
                                    id='arrow_gray' markerUnits='strokeWidth' fill='#a3b3b3' stroke='none'>
                                <path d='M0,0 L0,6 L9,3 z' />
                            </marker>
                            <marker viewBox='0 0 20 20' markerWidth='10' markerHeight='10' orient='auto' refX='0'
                                    refY='3'
                                    id='arrow_yellow' markerUnits='strokeWidth' fill='#f1c500' stroke='none'>
                                <path d='M0,0 L0,6 L9,3 z' />
                            </marker>
                            <marker viewBox='0 0 20 20' markerWidth='10' markerHeight='10' orient='auto' refX='0'
                                    refY='3'
                                    id='arrow_red' markerUnits='strokeWidth' fill='#e84c3d' stroke='none'>
                                <path d='M0,0 L0,6 L9,3 z' />
                            </marker>
                            <marker viewBox='0 0 20 20' markerWidth='10' markerHeight='10' orient='auto' refX='0'
                                    refY='3'
                                    id='arrow_blue' markerUnits='strokeWidth' fill='#267ebe' stroke='none'>
                                <path d='M0,0 L0,6 L9,3 z' />
                            </marker>
                            <marker viewBox='0 0 20 20' markerWidth='10' markerHeight='10' orient='auto' refX='0'
                                    refY='3'
                                    id='arrow_green' markerUnits='strokeWidth' fill='#27ae61' stroke='none'>
                                <path d='M0,0 L0,6 L9,3 z' />
                            </marker>
                        </defs>
                        <g transform='matrix(1,0,0,1,650,650)' id='group_mappings' fill={"none"}>
                            {
                                maps.map(item => {
                                    if (!item)
                                        return null
                                    return (
                                        <g id={`group_${item.id}`} key={item.id}
                                           onMouseEnter={() => {
                                               setHoverId(item.id)
                                           }}
                                           onMouseLeave={() => {
                                               setHoverId("")
                                           }}
                                           onClick={(e) => {
                                               if (selection === "identity" && !identity[item.id]) {
                                                   setOpenIdentity({
                                                       id: item.id,
                                                       isCreate: true,
                                                       isEdit: false,
                                                       text: "",
                                                       tag: "",
                                                       color: "gray"
                                                   })
                                               }
                                               if ((selection === "conflict" || selection === "mapping") && Number(item.id.split("_")[1]) < 31) {
                                                   setOpenConflict({
                                                       target: e.currentTarget,
                                                       id: item.id,
                                                       text: conflict[item.id] || ''
                                                   })
                                               }
                                               if (selection === "removing" && Number(item.id.split("_")[1]) < 31) {
                                                   setOpenClear({
                                                       id: item.id,
                                                       item: clear[item.id] || ''

                                                   })
                                               }
                                               if (selection === "key" && Number(item.id.split("_")[1]) < 31) {
                                                   setOpenKey({
                                                       id: item.id,
                                                       item: keys[item.id] || ''
                                                   })
                                               }
                                               if (selection === "quantizing" && Number(item.id.split("_")[1]) < 31) {
                                                   setOpenQuantizing({
                                                       id: item.id,
                                                       color: quantizing[item.id]?.split(",")[1] || '',
                                                       type: quantizing[item.id]?.split(",")[0] || '',
                                                       value: Number(quantizing[item.id]?.split(",")[2] || 0),
                                                   })
                                               }
                                               if (selection === "space" && vectorHead) {
                                                   setVectorHead("")
                                               }

                                           }}
                                           className={Number(item.id.split("_")[1]) > 30 ? "print-hidden" : ""}
                                        >
                                            <path
                                                d={item.path}
                                                id={item.id}
                                                fill={((selection === "conflict" || selection === "mapping") && hoverId === item.id && Number(item.id.split("_")[1]) < 31) ? "#9a9a9a" : item.fill}
                                                stroke={item.stroke || stroke}
                                                strokeWidth={strokeWidth}
                                                transform={item.transform} />
                                        </g>)
                                })
                            }
                        </g>
                        <g transform='matrix(1,0,0,1,650,650)' id='group_line'>
                            {
                                linesMap.map(item => {
                                    if (line.includes(item.id))
                                        return null
                                    if (Number(item.id) > 76)
                                        return null

                                    return (
                                        <g key={item.id} id={`line_${item.id}`}>
                                            {
                                                item.type === "circle" ?
                                                    <path d={item.d}
                                                          transform={item.transform}
                                                          strokeWidth={10}
                                                          stroke={((selection === "mapping" || selection === "borderline") && editRef.current?.key === item.id) ? "#756bb0" : "#a4a4a4"}
                                                          fill={"none"}
                                                          className={(selection === "mapping" || selection === "borderline") ? classes.borderLineActive : ''}
                                                          onMouseEnter={() => {
                                                              if ((selection === "mapping" || selection === "borderline"))
                                                                  editRef.current = {
                                                                      type: "borderline",
                                                                      key: item.id,
                                                                      // idG: "group_line"
                                                                  }
                                                              if (selection === "barrier" || selection === "space") {
                                                                  setHoverId(item.id)
                                                              }
                                                          }}
                                                          onMouseLeave={() => {
                                                              if ((selection === "mapping" || selection === "borderline"))
                                                                  editRef.current = null
                                                              if (selection === "barrier" || selection === "space") {
                                                                  setHoverId('')
                                                              }
                                                          }}
                                                          onClick={() => {
                                                              if ((selection === "mapping" || selection === "borderline")) {
                                                                  line.push(item.id)
                                                                  setLine([...line])
                                                                  updateField("line", line.join(","))
                                                              }
                                                              if (selection === "barrier" && !barrier.includes(item.id)) {
                                                                  barrier.push(item.id)
                                                                  setBarrier([...barrier])
                                                                  updateField("barrier", barrier.join(","))
                                                              }
                                                              if (selection === "space") {
                                                                  if (!vectorHead)
                                                                      return setVectorHead(item.id)
                                                                  if (vectorHead === item.id)
                                                                      return null
                                                                  setOpenVector({
                                                                      head: `dot_${vectorHead}`,
                                                                      rail: `dot_${item.id}`,
                                                                      vcolor: "gray",
                                                                      style: "solid",
                                                                      width: 200
                                                                  })
                                                                  setVectorHead("")
                                                              }
                                                          }}
                                                    />
                                                    :
                                                    <line
                                                        x1={item.x1}
                                                        x2={item.x2}
                                                        y1={item.y1}
                                                        y2={item.y2}
                                                        strokeWidth={10}
                                                        transform={item.transform}
                                                        stroke={((selection === "mapping" || selection === "borderline") && editRef.current?.key === item.id) ? "#756bb0" : "#a4a4a4"}
                                                        fill={"none"}
                                                        className={(selection === "mapping" || selection === "borderline") ? classes.borderLineActive : ''}
                                                        onMouseEnter={() => {
                                                            if ((selection === "mapping" || selection === "borderline"))
                                                                editRef.current = {
                                                                    type: "borderline",
                                                                    key: item.id,
                                                                    // idG: "group_line"
                                                                }
                                                        }}
                                                        onMouseLeave={() => {
                                                            if ((selection === "mapping" || selection === "borderline"))
                                                                editRef.current = null
                                                        }}
                                                        onClick={() => {
                                                            if ((selection === "mapping" || selection === "borderline")) {
                                                                line.push(item.id)
                                                                setLine([...line])
                                                                updateField("line", line.join(","))
                                                            }
                                                            if (selection === "space" && vectorHead) {
                                                                setVectorHead("")
                                                            }
                                                        }}
                                                    />

                                            }
                                        </g>
                                    )
                                })
                            }
                            {
                                line.map(id => {
                                    let idx = _.findIndex(linesMap, {id})
                                    if (idx === -1) return null
                                    const item = linesMap[idx]
                                    return (
                                        <g key={item.id} id={`line_${item.id}`}>
                                            {
                                                item.type === "circle" ?
                                                    <path d={item.d}
                                                          transform={item.transform}
                                                          strokeWidth={10}
                                                          stroke={"#756bb0"}
                                                          fill={"none"}
                                                          className={classes.borderLineActive}
                                                          onClick={() => {
                                                              if ((selection === "mapping" || selection === "borderline")) {
                                                                  _.remove(line, i => i === item.id)
                                                                  setLine([...line])
                                                                  updateField("line", line.join(","))
                                                              }
                                                              if (selection === "barrier" && !barrier.includes(item.id)) {
                                                                  barrier.push(item.id)
                                                                  setBarrier([...barrier])
                                                                  updateField("barrier", barrier.join(","))
                                                              }
                                                              if (selection === "space") {
                                                                  if (!vectorHead)
                                                                      return setVectorHead(item.id)
                                                                  if (vectorHead === item.id)
                                                                      return null
                                                                  setOpenVector({
                                                                      head: `dot_${vectorHead}`,
                                                                      rail: `dot_${item.id}`,
                                                                      vcolor: "gray",
                                                                      style: "solid",
                                                                      width: 200
                                                                  })
                                                                  setVectorHead("")
                                                              }
                                                          }}
                                                          onMouseEnter={() => {
                                                              if (selection === "barrier") {
                                                                  setHoverId(item.id)
                                                              }
                                                          }}
                                                          onMouseLeave={() => {
                                                              if (selection === "barrier") {
                                                                  setHoverId("")
                                                              }
                                                          }}
                                                    />
                                                    :
                                                    <line
                                                        x1={item.x1}
                                                        x2={item.x2}
                                                        y1={item.y1}
                                                        y2={item.y2}
                                                        transform={item.transform}
                                                        strokeWidth={10}
                                                        stroke={"#756bb0"}
                                                        fill={"none"}
                                                        className={classes.borderLineActive}
                                                        onClick={() => {
                                                            if ((selection === "mapping" || selection === "borderline")) {
                                                                _.remove(line, i => i === item.id)
                                                                setLine([...line])
                                                                updateField("line", line.join(","))
                                                            }
                                                            if (selection === "space" && vectorHead) {
                                                                setVectorHead("")
                                                            }
                                                        }}
                                                    />

                                            }
                                        </g>
                                    )
                                })
                            }
                        </g>
                        <g transform='matrix(1,0,0,1,650,650)' id='group_barriers'>
                            {
                                barrier.map(id => {
                                    let idx = _.findIndex(barrierMap, {id})
                                    if (idx === -1)
                                        return null
                                    const item = barrierMap[idx]
                                    return (
                                        <g key={item.id} transform={item.transform}>
                                            <image href={"img/barrier.png"} width={"80"} height={"20"}
                                                   x={item.x} y={item.y}
                                                   onMouseEnter={() => {
                                                       editRef.current = {
                                                           type: "barrier",
                                                           key: item.id,
                                                           idG: "group_barriers"
                                                       }
                                                   }}
                                                   data-uri={images['barrier']}
                                                   onMouseLeave={() => {
                                                       editRef.current = null
                                                   }}
                                            />
                                            <image href={"img/btn/delete.png"} width={'14'} height={"14"}
                                                   x={Number(item.x) - 5} y={Number(item.y) - 5}
                                                   style={(editRef.current?.type === "barrier" && editRef.current?.key === item.id) ? {display: "block"} : {display: "none"}}
                                                   className={classes.deleteBtn}
                                                   onClick={() => {
                                                       _.remove(barrier, id => id === item.id)
                                                       setBarrier([...barrier])
                                                       updateField("barrier", barrier.join(","))
                                                   }}
                                            />
                                        </g>
                                    )
                                })
                            }
                            {
                                barrierMap.map(item => {
                                    if (selection === "barrier" && hoverId === item.id && !barrier.includes(hoverId))
                                        return (
                                            <g key={item.id} transform={item.transform}>
                                                <image href={"img/barrier.png"} width={"80"} height={"20"}
                                                       x={item.x} y={item.y}
                                                       className={classes.deleteBtn}
                                                       onMouseEnter={() => {
                                                           if (selection === "barrier") {
                                                               setHoverId(item.id)
                                                           }
                                                       }}
                                                       onClick={() => {
                                                           barrier.push(item.id)
                                                           setBarrier([...barrier])
                                                           updateField("barrier", barrier.join(","))
                                                       }}
                                                />
                                            </g>
                                        )
                                    return null
                                })
                            }

                        </g>
                        <g transform='matrix(1,0,0,1,650,650)' id='group_dots'>
                            {
                                dotMaps.map((item, index) => {
                                    if (vectorHead === item.id) return (
                                        <circle
                                            key={item.id}
                                            id={`dot_${item.id}`}
                                            transform={item.transform}
                                            cx={item.x}
                                            cy={item.y}
                                            r={"5"}
                                            fill={"#ff0000"}
                                            stroke={"#656565"}
                                            strokeWidth={"5"}
                                        />
                                    )
                                    return (
                                        <circle
                                            id={`dot_${item.id}`}
                                            transform={item.transform}
                                            cx={item.x}
                                            cy={item.y}
                                            r={"5"}
                                            fill={"#ff0000"}
                                            stroke={"#ff0000"}
                                            strokeWidth={"5"}
                                            style={(hoverId === item.id && vectorHead !== item.id && selection === "space") ? {display: "block"} : {display: "none"}}
                                            key={item.id}
                                            onMouseEnter={() => {
                                                setHoverId(item.id)
                                            }}
                                            onMouseLeave={() => {
                                                setHoverId("")
                                            }}
                                            onClick={() => {
                                                if (selection === "space") {
                                                    if (!vectorHead)
                                                        return setVectorHead(item.id)
                                                    if (vectorHead === item.id)
                                                        return null
                                                    setOpenVector({
                                                        head: `dot_${vectorHead}`,
                                                        rail: `dot_${item.id}`,
                                                        vcolor: "gray",
                                                        style: "solid",
                                                        width: 200
                                                    })
                                                    setVectorHead("")
                                                }
                                            }}
                                        />
                                    )
                                })
                            }
                        </g>
                        <g transform='matrix(1,0,0,1,650,650)' id='group_vectors'>
                            {
                                vector.map((item, idx) => {
                                    const data = getVector(item)
                                    if (!data)
                                        return null
                                    return <g key={idx} style={{opacity: 0.8}}>
                                        <path
                                            d={data.path}
                                            strokeWidth={data.stroke_width}
                                            stroke={data.color}
                                            strokeLinecap={"round"}
                                            strokeDasharray={data.strokeDasharray}
                                            markerEnd={data.maker}
                                            fill={"none"}
                                            onMouseEnter={() => {
                                                editRef.current = {
                                                    type: "vector",
                                                    key: idx,
                                                    idG: "group_vectors"
                                                }
                                            }}
                                            onMouseLeave={() => {
                                                editRef.current = null
                                            }}
                                            onClick={(event) => {
                                                if (selection === "space") {
                                                    if (vectorHead) {
                                                        setVectorHead("")
                                                    }
                                                    setOpenVector(item)
                                                }
                                            }}
                                        />
                                        {
                                            data.deletePoint && editRef.current?.type === "vector" && editRef.current?.key === idx ?
                                                <image href={"img/btn/delete.png"} width={'14'} height={"14"}
                                                       x={data.deletePoint.x}
                                                       y={data.deletePoint.y}
                                                       className={classes.deleteBtn}
                                                       onClick={(event) => {
                                                           vector.splice(idx, 1)
                                                           setVector([...vector])
                                                           updateField("vector", vector)
                                                       }}
                                                />
                                                :
                                                null
                                        }

                                    </g>
                                })
                            }

                        </g>
                        <g transform='matrix(1,0,0,1,650,650)' id='group_identities'>
                            {Object.keys(identity).map((key) => {
                                if (!identity[key])
                                    return null
                                const value = identity[key].split(',');
                                const img = value[1];
                                const display = identities[key];
                                const id = Number(display.id.split('_')[1]);
                                const text1 = value[2]?.substr(0, 10) || '';
                                const text2 = value[2]?.substr(10, 10) || '';
                                const text3 = value[2]?.substr(20, 10) || '';
                                const dataUri = images['pawn'][img]
                                return (
                                    <g
                                        id={display.id}
                                        key={key}
                                        onMouseEnter={() => {
                                            editRef.current = {
                                                type: 'identity',
                                                key: display.key,
                                                idG: "group_identities"
                                            };
                                        }}
                                        onMouseLeave={() => {
                                            editRef.current = null;
                                        }}
                                    >
                                        <image
                                            href={`img/pawn/${img}.png`}
                                            {...identitySize}
                                            x={display.x}
                                            y={display.y}
                                            id={`image_${display.id}`}
                                            // preserveAspectRatio={"none"}
                                            data-uri={dataUri}
                                            onClick={() => {
                                                if (selection === "space" && vectorHead) {
                                                    setVectorHead("")
                                                }
                                                if (selection === 'identity') {
                                                    setOpenIdentity({
                                                        id: key,
                                                        isCreate: false,
                                                        isEdit: true,
                                                        text: value[2],
                                                        subject: value[0],
                                                        color: img,
                                                    });
                                                    return;
                                                }
                                                if (selection === 'finding') {
                                                    setOpenSubject({
                                                        id: key,
                                                        text: value[2],
                                                        subject: value[0],
                                                        color: img,
                                                    });
                                                    return;
                                                }
                                            }}
                                        />
                                        {value[0] && value[0] !== 'none' ? (
                                            <image
                                                href={`img/pawnmark/${value[0]}.png`}
                                                x={Number(display.x) + identitySize.width / 4 - 2}
                                                y={Number(display.y) + identitySize.height / 2}
                                                data-uri={images['pawnmark'][value[0]]}
                                                onClick={() => {
                                                    if (selection === "space" && vectorHead) {
                                                        setVectorHead("")
                                                    }
                                                    if (selection === 'identity') {
                                                        setOpenIdentity({
                                                            id: key,
                                                            isCreate: true,
                                                            isEdit: false,
                                                            text: value[2],
                                                            subject: value[0],
                                                            color: img,
                                                        });
                                                        return;
                                                    }
                                                    if (selection === 'finding') {
                                                        setOpenSubject({
                                                            id: key,
                                                            text: value[2],
                                                            subject: value[0],
                                                            color: img,
                                                        });
                                                        return;
                                                    }
                                                }}
                                            />
                                        ) : null}
                                        <image
                                            href={'img/btn/delete.png'}
                                            width={'14'}
                                            height={'14'}
                                            x={Number(display.x) + 20}
                                            y={Number(display.y) - 10}
                                            // preserveAspectRatio={"none"}
                                            style={
                                                editRef.current?.key === display.key && editRef.current?.type === 'identity'
                                                    ? {}
                                                    : {display: 'none'}
                                            }
                                            onClick={() => {
                                                if (selection === "space" && vectorHead) {
                                                    setVectorHead("")
                                                }
                                                delete identity[display.key];
                                                setIdentity({...identity});
                                                updateField("identity", identity)
                                                let tagIds = map?.tag_id || {};
                                                if (tagIds[display.key]) {
                                                    delete tagIds[display.key]
                                                    updateField("tag_id", tagIds);
                                                }
                                            }}
                                            className={classes.deleteBtn}
                                        />
                                        <path
                                            d={getTextBoxPath(display.x, display.y, id)}
                                            strokeWidth={3}
                                            fill={'#ffffff'}
                                            stroke={'#c1c1c1'}
                                            style={
                                                value[2] && (
                                                    (editRef.current?.key === display.key &&
                                                        editRef.current?.type === 'identity')
                                                    || Number(key.split("_")[1] > 30)
                                                )
                                                    ? {}
                                                    : {display: 'none'}
                                            }
                                        />
                                        <text
                                            x={'0'}
                                            y={'0'}
                                            style={
                                                value[2] && (
                                                    (editRef.current?.key === display.key &&
                                                        editRef.current?.type === 'identity')
                                                    || Number(key.split("_")[1] > 30)
                                                )
                                                    ? {
                                                        lineHeight: 1,
                                                        fontSize: 12,
                                                        fontFamily: "Roboto, sans-serif"
                                                    }
                                                    : {display: 'none'}
                                            }

                                        >
                                            {text1 ? (
                                                <tspan {...getTextPositon(display.x, display.y, id, 1)}>{text1}</tspan>
                                            ) : null}
                                            {text2 ? (
                                                <tspan {...getTextPositon(display.x, display.y, id, 2)}>{text2}</tspan>
                                            ) : null}
                                            {text3 ? (
                                                <tspan {...getTextPositon(display.x, display.y, id, 3)}>{text3}</tspan>
                                            ) : null}
                                        </text>
                                    </g>
                                );
                            })}
                            {hoverId && selection === 'identity' && identities[hoverId] && !identity[hoverId] ? (
                                <g
                                    id={hoverId}
                                    onClick={() => {
                                        setOpenIdentity({
                                            id: hoverId,
                                            isCreate: true,
                                            isEdit: false,
                                            text: '',
                                            tag: '',
                                            color: 'gray',
                                        });
                                    }}
                                    onMouseEnter={() => {
                                        setHoverId(hoverId);
                                    }}
                                >
                                    <image
                                        href={`img/pawn/gray.png`}
                                        {...identitySize}
                                        x={identities[hoverId].x}
                                        y={identities[hoverId].y}
                                        style={{opacity: 0.5}}
                                        // id={`image_${display.id}`}
                                    />
                                </g>
                            ) : null}
                        </g>
                        <g transform='matrix(1,0,0,1,650,650)' id='group_quantizing'>
                            {Object.keys(quantizing).map((key) => {
                                if (!quantizing[key]) return null;
                                const value = quantizing[key];
                                const img = getQuantizingUrl(key, value);
                                if (!img) return null;
                                return (
                                    <g
                                        id={`quantizing_${key}`}
                                        onMouseEnter={() => {
                                            editRef.current = {
                                                type: 'quantizing',
                                                key,
                                                idG: "group_quantizing"
                                            };
                                        }}
                                        onMouseLeave={() => {
                                            editRef.current = null;
                                        }}
                                        key={key}>

                                        <image
                                            href={img.url}
                                            x={img.x}
                                            y={img.y}
                                            data-uri={img.dataUri}
                                            onClick={() => {
                                                setOpenQuantizing({
                                                    id: key,
                                                    color: value.split(',')[1],
                                                    type: value.split(',')[0],
                                                    value: Number(value.split(',')[2]),
                                                });
                                            }}
                                        />
                                        <image
                                            href={'img/btn/delete.png'}
                                            width={'14'}
                                            height={'14'}
                                            x={Number(img.x) + 20}
                                            y={Number(img.y) - 10}
                                            className={classes.deleteBtn}
                                            style={
                                                editRef.current?.key === key && editRef.current?.type === 'quantizing'
                                                    ? {}
                                                    : {display: 'none'}
                                            }
                                            onClick={() => {
                                                delete quantizing[key];
                                                setQuantizing({...quantizing});
                                                updateField("quantizing", quantizing)
                                            }}
                                        />
                                        <text
                                            style={{fontSize: img.fontSize, fontWeight: 100, lineHeight: 1.5}}
                                            x={img.x + img.size.width / 2}
                                            y={img.y + img.size.height / 2}
                                            stroke={'#ffffff'}
                                            fill={'#ffffff'}
                                            dominantBaseline='middle'
                                            textAnchor='middle'
                                            onClick={() => {
                                                setOpenQuantizing({
                                                    id: key,
                                                    color: value.split(',')[1],
                                                    type: value.split(',')[0],
                                                    value: Number(value.split(',')[2]),
                                                });
                                            }}
                                        >
                                            {value.split(',')[2]}
                                        </text>
                                    </g>
                                );
                            })}
                            {hoverId && selection === 'quantizing' && Number(hoverId.split("_")[1] < 31) && quantizingMaps[hoverId] && !quantizing[hoverId] ? (
                                <g
                                    id={hoverId}
                                    onClick={() => {
                                        setOpenQuantizing({
                                            id: hoverId,
                                        });
                                    }}
                                    onMouseEnter={() => {
                                        setHoverId(hoverId);
                                    }}
                                >
                                    <image
                                        href={`img/energymark/100/heart/gray.png`}
                                        x={quantizingMaps[hoverId].x}
                                        y={quantizingMaps[hoverId].y}
                                        style={{opacity: 0.5}}
                                        // id={`image_${display.id}`}
                                    />
                                    <text
                                        style={{fontSize: 20, fontWeight: 100, lineHeight: 1.5}}
                                        x={Number(quantizingMaps[hoverId].x) + 15}
                                        y={Number(quantizingMaps[hoverId].y) + 33}
                                        stroke={'#ffffff'}
                                        fill={'#ffffff'}
                                    >
                                        100
                                    </text>
                                </g>
                            ) : null}
                        </g>
                        <g transform='matrix(1,0,0,1,650,650)' id='group_clear'>
                            {Object.keys(clear).map((key) => {
                                if (!clear[key]) return null;
                                const {x, y} = clearsMap[key];
                                const clearUri = images["clear"][clear[key]]
                                return (
                                    <g
                                        id={`clear_${key}`}
                                        onMouseEnter={() => {
                                            editRef.current = {
                                                type: 'removing',
                                                key,
                                                idG: "group_clear"
                                            };
                                        }}
                                        onMouseLeave={() => {
                                            editRef.current = null;
                                        }}
                                        key={key}>

                                        <image
                                            href={`img/clear/${clear[key]}.png`}
                                            width={'44'}
                                            height={'42'}
                                            x={x}
                                            y={y}
                                            data-uri={clearUri}
                                            onClick={() => {
                                                if (selection === "removing")
                                                    setOpenClear({
                                                        id: key,
                                                        item: clear[key] || '',
                                                    });
                                            }}
                                        />
                                        <image
                                            href={'img/btn/delete.png'}
                                            width={'14'}
                                            height={'14'}
                                            x={Number(x) + 10}
                                            y={Number(y) + 35}
                                            // preserveAspectRatio={"none"}
                                            className={classes.deleteBtn}
                                            style={
                                                editRef.current?.key === key && editRef.current?.type === 'removing'
                                                    ? {}
                                                    : {display: 'none'}
                                            }
                                            onClick={() => {
                                                delete clear[key];
                                                setClear({...clear});
                                                updateField('clear', clear)
                                            }}
                                        />
                                    </g>
                                );
                            })}
                            {hoverId && Number(hoverId.split("_")[1] < 31) && selection === 'removing' && clearsMap[hoverId] && !clear[hoverId] ? (
                                <g
                                    id={hoverId}
                                    onClick={() => {
                                        setOpenClear({
                                            id: hoverId,
                                            item: '',
                                        });
                                    }}
                                    onMouseEnter={() => {
                                        setHoverId(hoverId);
                                    }}
                                >
                                    <image
                                        href={`img/clear/gray.png`}
                                        width={'44'}
                                        height={'42'}
                                        x={clearsMap[hoverId].x}
                                        y={clearsMap[hoverId].y}
                                        style={{opacity: 0.5}}
                                    />
                                </g>
                            ) : null}
                        </g>
                        <g transform='matrix(1,0,0,1,650,650)' id='group_key'>
                            {Object.keys(keys).map((key) => {
                                if (!keys[key]) return null;
                                const {x, y} = keyMap[key];
                                const dataUri = images['key'][keys[key]]
                                return (
                                    <g
                                        id={`key_${key}`}
                                        onMouseEnter={() => {
                                            editRef.current = {
                                                type: 'key',
                                                key,
                                                idG: "group_key"
                                            };
                                        }}
                                        onMouseLeave={() => {
                                            editRef.current = null;
                                        }}
                                        key={key}>

                                        <image
                                            href={`img/key/${keys[key]}.png`}
                                            width={'35'}
                                            height={'36'}
                                            x={x}
                                            y={y}
                                            data-uri={dataUri}
                                            onClick={() => {
                                                setOpenKey({
                                                    id: key,
                                                    item: keys[key] || '',
                                                });
                                            }}
                                        />
                                        <image
                                            href={'img/btn/delete.png'}
                                            width={'14'}
                                            height={'14'}
                                            x={Number(x) + 10}
                                            y={Number(y) + 35}
                                            // preserveAspectRatio={"none"}
                                            className={classes.deleteBtn}
                                            style={
                                                editRef.current?.key === key && editRef.current?.type === 'key'
                                                    ? {}
                                                    : {display: 'none'}
                                            }
                                            onClick={() => {
                                                delete keys[key];
                                                setKeys({...keys});
                                                updateField("key", keys)
                                            }}
                                        />
                                    </g>
                                );
                            })}
                            {hoverId && Number(hoverId.split("_")[1] < 31) && selection === 'key' && keyMap[hoverId] && !keys[hoverId] ? (
                                <g
                                    id={hoverId}
                                    onClick={() => {
                                        setOpenKey({
                                            id: hoverId,
                                            item: '',
                                        });
                                    }}
                                    onMouseEnter={() => {
                                        setHoverId(hoverId);
                                    }}
                                >
                                    <image
                                        href={`img/key/gray.png`}
                                        width={'35'}
                                        height={'36'}
                                        x={keyMap[hoverId].x}
                                        y={keyMap[hoverId].y}
                                        style={{opacity: 0.5}}
                                    />
                                </g>
                            ) : null}
                        </g>
                        <g transform='matrix(1,0,0,1,650,650)' id='group_conflict'
                           style={editRef?.current?.idG ? {pointerEvents: "none"} : {pointerEvents: "auto"}}>
                            {Object.keys(conflict).map((key) => {
                                if (!conflict[key]) return null;
                                return (
                                    <g id={`conflict_${key}`} key={key}
                                       onClick={(e) => {
                                           if (selection === "space" && vectorHead) {
                                               setVectorHead("")
                                           }
                                           if ((selection === "conflict" || selection === "mapping") && Number(key.split("_")[1]) < 31) {
                                               setOpenConflict({
                                                   target: e.currentTarget,
                                                   id: key,
                                                   text: conflict[key] || ''
                                               })
                                           }
                                       }}
                                       onMouseEnter={() => {
                                           setHoverId(key)
                                       }}
                                        // onMouseLeave={() => {
                                        //     setHoverId("")
                                        // }}
                                    >
                                        {getTextPath(key, conflict[key])}
                                    </g>
                                );
                            })}
                        </g>
                        {
                            editRef?.current?.idG &&
                            <use id='use' xlinkHref={`#${editRef.current.idG}`} style={{pointerEvents: "none"}} />
                        }
                    </svg>
                </ClickAwayListener>
            </div>
            <RightMenu />
            <Process />
            <IdentityDialog
                open={openIdentity}
                setOpen={setOpenIdentity}
                identity={identity}
                setIdentity={setIdentity}
            />
            <SubjectDialog open={openSubject} setOpen={setOpenSubject} identity={identity} setIdentity={setIdentity} />
            <ConflictDialog
                open={openConfict}
                setOpen={setOpenConflict}
                conflict={conflict}
                setConflict={setConflict}
            />
            <RemovingDialog open={openClear} setOpen={setOpenClear} clear={clear} setClear={setClear} />
            <KeyDialog open={openKey} setOpen={setOpenKey} keys={keys} setKeys={setKeys} />
            <QuantizingDialog
                open={openQuantizing}
                setOpen={setOpenQuantizing}
                quantizing={quantizing}
                setQuantizing={setQuantizing}
            />
            <VectorDialog open={openVector} setOpen={setOpenVector} vector={vector} setVector={setVector} />
            <div id={"export-image-content"} />
            <DialogSetting />
            <DialogIdentityTag />
            <DialogSupport />
            <InvitePopup />
            <DialogSupport />
            <CreateInsightMapFlatDialog folders={folders} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    deleteBtn: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    borderLineActive: {
        '&:hover': {
            cursor: 'pointer',
        },
    }
}));
export default InsightMind;
