import React from 'react';
import {Carousel} from "react-responsive-carousel";
import "./carousel.min.css";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CaptureInsightMind from "./CaptureInsightMind";
import CaptureInsightMindOne from "./CaptureInsightMindOne";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import {Close} from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import CaptureInsightMini from "./CaptureInsightMini";

function CarouseInsightMind({open, setOpen, histories, indexValue, selected, setSelected, loading}) {
    const classes = useStyles()
    const handleCheck = (capture) => {
        let idx = selected.indexOf(capture)
        if (idx !== -1) {
            // remove
            selected.splice(idx, 1)
        } else {
            selected.push(capture)
        }
        setSelected([...selected])
    }
    return (
        <Dialog open={open} onClose={() => setOpen(false)} className={classes.root} maxWidth={"lg"}>
            <DialogTitle>
                <IconButton className={classes.close}
                            onClick={() => setOpen(false)}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Carousel
                    className={classes.carouselRoot}
                    selectedItem={indexValue}
                    renderArrowNext={(onClickHandler, hasNext) => {
                        return (
                            <IconButton className={classes.arrowRight} onClick={onClickHandler} disabled={!hasNext}>
                                <KeyboardArrowRightIcon fontSize={"large"} />
                            </IconButton>
                        )
                    }}
                    renderArrowPrev={(onClickHandler, hasPrev) => {
                        return (
                            <IconButton className={classes.arrowLeft} onClick={onClickHandler} disabled={!hasPrev}>
                                <KeyboardArrowLeftIcon fontSize={"large"} />
                            </IconButton>
                        )
                    }}
                    renderThumbs={() => {
                        return null
                    }}
                >

                    {
                        histories && histories.length !== 0 && histories.map((capture, index) => {
                            return (
                                <div key={index} style={{width: "80%", minWidth: 700, margin: "auto"}}>
                                    {
                                        capture?.role === 1 ?
                                            <div style={{width: "80%", margin: "auto"}}>
                                                <CaptureInsightMindOne map={capture}
                                                                       captureId={"history_" + capture.id}
                                                />
                                            </div>
                                            :
                                            capture?.role === 2 ?
                                                <div style={{transform: "scale(1.25)"}}>
                                                    <CaptureInsightMini map={capture}
                                                                        captureId={"history_" + capture.id}
                                                    />
                                                </div>
                                                :
                                                <div style={{transform: "scale(1.25)"}}>
                                                    <CaptureInsightMind map={capture}
                                                                        captureId={"history_" + capture.id}
                                                    />
                                                </div>
                                    }
                                    <Checkbox
                                        checked={selected.includes(capture.id)}
                                        onChange={() => handleCheck(capture.id)}
                                        className={classes.checkBox}
                                        style={{color: selected.includes(capture.id) ? "#26a69a" : "#ccc"}}
                                        disabled={loading}
                                    />
                                </div>
                            )
                        })
                    }
                </Carousel>
            </DialogContent>


        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDialogTitle-root": {
            display: "flex",
            justifyContent: "flex-end",
            padding: "8px 20px"
        }
    },
    carouselRoot: {
        "& .carousel .slide": {
            background: "#fff"
        },
        "& .carousel .carousel-status": {
            color: "#000",
            textShadow: "none",
            // right: "auto",
            // left: 0
        },
        "& .carousel .control-dots .dot": {
            background: "#000",
            boxShadow: "none"
        },
        "& .carousel.carousel-slider": {
            paddingBottom: 15
        }
    },
    arrowRight: {
        position: "absolute",
        top: "50%",
        right: 0,
        transform: "translateY(-50%)",
        zIndex: 2
    },
    arrowLeft: {
        position: "absolute",
        top: "50%",
        left: 0,
        transform: "translateY(-50%)",
        zIndex: 2
    },
    checkBox: {
        position: "absolute",
        top: 0,
        right: 50
    },
}))
export default CarouseInsightMind;
