import React, {useState} from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import makeStyles from '@material-ui/core/styles/makeStyles';
import rtn from "reactn";
import {useInsightMaps} from "../../context/InsightMindMapsContext";
import Years from "./HandleActions/Years";
import ProcessData from "./HandleActions/ProcessData";
import TasksData from "./HandleActions/TasksData";
import MemoData from "./HandleActions/MemoData";
import TagsData from "./HandleActions/TagsData";
import QuantizingDrawing from "./HandleActions/QuantizingDrawing";
import _ from "lodash";

function Process(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [array,] = useState([1, 2, 3, 4]);
    const [insightMindUser = {}] = rtn.useGlobal('insightMindUser');
    const {map, updateField} = useInsightMaps();
    const [value, setValue] = useState(map?.years || 0);

    return (
        <div className={classes.root}>
            <div className={classes.center}>
                <div className={classes.btnArrow}>
                    {open ? <ArrowDropUpIcon
                        onClick={() => setOpen(!open)}
                    /> : <ArrowDropDownIcon
                        onClick={() => setOpen(!open)}
                    />}
                </div>
            </div>
            <div id={"map-footer"}>
                <div style={{display: open ? 'none' : 'block', transition: 'all ease 0.5s'}} id={"footer-content"}>
                    <Years
                        classes={classes}
                        value={value}
                        setValue={setValue}
                        updateField={updateField}
                        map={map}
                    />
                    {
                        Boolean(map?.quantizing) && !_.isEmpty(map.quantizing) &&
                        <QuantizingDrawing map={map}/>
                    }

                    <div
                        className={Boolean(insightMindUser?.menu_position) ? classes.containerLeft : classes.container}>
                        <div className={classes.tableBox}>
                            <div className={`${classes.tableCell66} process`}>
                                <div className={classes.titleProcess}><span>プロセス</span></div>
                                <ProcessData
                                    classes={classes}
                                    map={map}
                                    updateField={updateField}
                                />
                            </div>
                            <TagsData classes={classes} map={map}/>
                        </div>
                        <div className={classes.tableBox} style={{marginTop: 20}}>
                            <div className={`${classes.tableCell50} task`}>
                                <TasksData
                                    classes={classes}
                                    map={map}
                                    updateField={updateField}
                                />
                            </div>
                            <div className={`${classes.tableCell50} task`}>
                                <MemoData
                                    classes={classes}
                                    map={map}
                                    updateField={updateField}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <footer className={classes.footer}>Copyright © 2016 Andom.inc. All Rights Reserved.</footer>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#e0e0e0',
    },
    center: {
        textAlign: 'center',
    },
    btnArrow: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '84px !important',
            color: '#b5b5a6',
        },
        "& svg": {
            cursor: 'pointer',
        }
    },
    textField: {
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        verticalAlign: 'middle',
        marginLeft: 40,
        '& input': {
            padding: 10,
            textAlign: 'center',
        },
        '& input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: 4
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#66afe9",
            outline: 0
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
            borderColor: "#66afe9",
        }
    },
    container: {
        padding: "30px 50px",
        [theme.breakpoints.down('sm')]: {
            padding: "30px 0"
        }
    },
    containerLeft: {
        padding: "30px 30px 30px 60px",
    },
    titleProcess: {
        background: '#e0e0e0',
        "& span": {
            background: '#fff',
            display: 'inline-block',
            padding: '10px 10px 3px',
            borderRadius: '5px 5px 0 0',
        }
    },
    contentBox: {
        background: '#fff',
        padding: 10,
        minHeight: 170,
    },
    input: {
        maxHeight: '100%',
        display: 'inline-block',
        float: 'none',
        width: 'auto',
        minWidth: 60,
        overflowY: 'auto',
        marginLeft: 30,
        marginRight: 1,
        fontSize: 17,
        textAlign: 'center',
        padding: "5px 10px",
        resize: 'none',
        border: 'none',
        lineHeight: 1.3,
        '&:focus': {
            outline: 0,
        },
        "&::-webkit-scrollbar": {
            width: "8px",
        },
        "&::-webkit-scrollbar-track": {
            background: "#fff",
            transition: "all 0.2s ease-in-out",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#fff",
            transition: "all 0.2s ease-in-out",
        },
        // "&::-webkit-scrollbar-thumb:hover":{
        //     background: "#555",
        // },
        "&:hover::-webkit-scrollbar-track": {
            borderRadius: "6px",
        },
        "&:hover::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "6px",
        }
    },
    processContent: {
        backgroundColor: '#fff',
        position: 'relative',
        border: '1px solid #000',
        margin: 10,
        height: 130,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& span': {
            fontSize: 40,
            position: 'absolute',
            top: 0,
            left: 10,
            opacity: 0.7,
            zIndex: 2,
            cursor: 'pointer',
        },
    },
    borderWhite: {
        borderColor: 'rgba(0, 0, 0, 0)',
        borderLeftColor: '#000',
        borderWidth: '65px 0 65px 15px',
        marginTop: '-65px',
        left: '100%',
        top: '50%',
        border: 'solid transparent',
        height: 0,
        width: 0,
        position: 'absolute',
    },
    borderBlack: {
        borderColor: 'rgba(255, 255, 255, 0)',
        borderLeftColor: '#fff',
        borderWidth: '66px 0 66px 15px',
        marginTop: '-66px',
        marginLeft: '-1px',
        left: '100%',
        top: '50%',
        border: 'solid transparent',
        height: 0,
        width: 0,
        position: 'absolute',
    },
    formControl: {
        width: '100%',
        height: "30px",
        '& .MuiInput-root': {
            position: 'relative',
            margin: '0 20px 0 50px',
        },
        '& .MuiInputAdornment-positionStart': {
            position: 'absolute',
            right: '100%',
        },
    },
    button: {
        outline: 'none',
    },
    textArea: {
        maxHeight: '100%',
        float: 'none',
        overflowY: 'auto',
        fontSize: 17,
        resize: 'none',
        width: '100%',
        border: 'none',

        '&:focus': {
            outline: 0,
        },
    },
    footer: {
        textAlign: 'center',
        padding: '20px 0',
        backgroundColor: '#e0e0e0',
        color: '#9e9e9e',
    },
    yearInfo: {
        fontFamily: "Rubik",
        fontSize: "20px",
        lineHeight: "33px",
        color: "#333 !important",
        marginLeft: 20,
        [theme.breakpoints.down('sm')]: {
            display: "block",
            margin: "10px auto 0px auto"
        },
    },
    notchedOutline: {
        borderColor: "#ccc",
    },
    valueText: {
        backgroundColor: '#fff',
    },
    tableBox: {
        display: "table",
        width: "100%"
    },
    tableCell: {
        display: "table-cell",
        background: "#fff",
        border: "solid 10px #e0e0e0"
    },
    tableCell66: {
        display: "table-cell",
        background: "#fff",
        border: "solid 10px #e0e0e0",
        width: "66%",
        [theme.breakpoints.down('md')]: {
            width: "100%",
            display: "block"
        },
    },
    tableCell34: {
        display: "table-cell",
        background: "#fff",
        border: "solid 10px #e0e0e0",
        width: "34%",
        [theme.breakpoints.down('md')]: {
            width: "100%",
            display: "block"
        },
    },
    tableCell50: {
        display: "table-cell",
        background: "#fff",
        border: "solid 10px #e0e0e0",
        width: "50%",
        [theme.breakpoints.down('md')]: {
            width: "100%",
            display: "block"
        },
    },
    smCenter: {
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
        },
    }
}));
export default Process;
