import {images} from "./images";

export const quantizingMaps = {
    mapping_01: {
        x: '5',
        y: '-100',
    },
    mapping_02: {
        x: '-65',
        y: '-100',
    },
    mapping_03: {
        x: '5',
        y: '-210',
    },
    mapping_04: {
        x: '150',
        y: '5',
    },
    mapping_05: {
        x: '-215',
        y: '5',
    },
    mapping_06: {
        x: '-66',
        y: '-210',
    },
    mapping_07: {
        x: '5',
        y: '-320',
    },
    mapping_08: {
        x: '210.77783530759893',
        y: '-213.343',
    },
    mapping_09: {
        x: '265',
        y: '5',
    },
    mapping_10: {
        x: '150.343',
        y: '210',
    },
    mapping_11: {
        x: '-220',
        y: '210',
    },
    mapping_12: {
        x: '-324.9936706151979',
        y: '5',
    },
    mapping_13: {
        x: '-252.781',
        y: '-213.343',
    },
    mapping_14: {
        x: '-65',
        y: '-319.9936706151979',
    },
    mapping_15: {
        x: '5',
        y: '-430.0000008800812',
    },
    mapping_16: {
        x: '163.7191732',
        y: '-386.516',
    },
    mapping_17: {
        x: '275',
        y: '-291.12399999999997',
    },
    mapping_18: {
        x: '362.45461',
        y: '-148.388',
    },
    mapping_19: {
        x: '370',
        y: '5',
    },
    mapping_20: {
        x: '325',
        y: '148',
    },
    mapping_21: {
        x: '230',
        y: '285',
    },
    mapping_22: {
        x: '95',
        y: '357.45461',
    },
    mapping_23: {
        x: '-153.388',
        y: '367.44350044004057',
    },
    mapping_24: {
        x: '-286.1473464',
        y: '299.93476680000003',
    },
    mapping_25: {
        x: '-396.5146184',
        y: '182.89815439999998',
    },
    mapping_26: {
        x: '-435.02222',
        y: '5',
    },
    mapping_27: {
        x: '-407.44350044004057',
        y: '-148.388',
    },
    mapping_28: {
        x: '-329.93476680000003',
        y: '-291.1473464',
    },
    mapping_29: {
        x: '-208.7046158',
        y: '-386.5146184',
    },
    mapping_30: {
        x: '-65',
        y: '-430.02222',
    },
    mapping_31: {
        x: '5',
        y: '-540',
    },
    mapping_32: {
        x: '350.556725512665',
        y: '-373.905',
    },
    mapping_33: {
        x: '475',
        y: '5',
    },
    mapping_34: {
        x: '298.905',
        y: '355.556725512665',
    },
    mapping_35: {
        x: '-373.905',
        y: '355.562',
    },
    mapping_36: {
        x: '-544.98945102533',
        y: '5',
    },
    mapping_37: {
        x: '-425.562',
        y: '-373.905',
    },
    mapping_38: {
        x: '-75',
        y: '-539.98945102533',
    },
    mapping_39: {
        x: '5',
        y: '-650',
    },
    mapping_40: {
        x: '585',
        y: '5',
    },
    mapping_41: {
        x: '-655',
        y: '5',
    },
    mapping_42: {
        x: '-75',
        y: '-650',
    },
    mapping_43: {
        x: '5',
        y: '-760',
    },
    mapping_44: {
        x: '-75',
        y: '-760',
    },
};

const folder = ['1-19', '20-39', '40-59', '60-79', '80-99', '100'];
const uri = ['1_19', '20_39', '40_59', '60_79', '80_99', '100'];
const fontSize = [12.5, 14, 15.5, 17, 18.5, 20];
const size = {
    heart: [
        {width: 40, height: 39, mr: 20},
        {width: 45, height: 39, mr: 15},
        {width: 51, height: 43, mr: 9},
        {
            width: 56,
            height: 48,
            mr: 4,
        },
        {width: 56, height: 48, mr: 4},
        {width: 68, height: 59, mr: 0},
    ],
    diamond: [
        {width: 44, height: 37, mr: 15},
        {width: 51, height: 43, mr: 10},
        {width: 58, height: 50, mr: 0},
        {
            width: 66,
            height: 55,
            mr: 0,
        },
        {width: 72, height: 62, mr: -5},
        {width: 79, height: 68},
    ],
    flame: [
        {width: 31, height: 54, mr: 28},
        {width: 37, height: 64, mr: 22},
        {width: 43, height: 76, mr: 16},
        {
            width: 50,
            height: 86,
            mr: 9,
        },
        {width: 55, height: 94, mr: 4},
        {width: 60, height: 100, mr: 0},
    ],
};
const reCalcX = [2, 4, 6, 14, 30, 9, 10, 17, 19, 21, 22];
const validQuantizing = ["diamond", "flame", "heart"]
export const getQuantizingUrl = (id, value) => {
    try {
        if (!value || !id || !quantizingMaps[id]) return null;
        const data = value.split(',');
        if (validQuantizing.indexOf(data[0]) === -1)
            return null
        const idx = Math.abs(Math.floor(Number(data[2]) / 20));
        const forderName = folder[idx];
        const folderUri = uri[idx]
        let x = Number(quantizingMaps[id].x);
        let y = Number(quantizingMaps[id].y);
        if (reCalcX.includes(Number(id.split('_')[1]))) {
            x += size[data[0]][idx].mr || 0;
        }
        if (Number(data[2]) === 0) {
            return {
                url: `img/energymark/negative-1-19/${data[0]}/${data[1]}.png`,
                fontSize: fontSize[0],
                size: size[data[0]][0],
                x,
                y,
                dataUri: images["energymark"]["negative_1_19"][data[0]][data[1]]
            };
        }
        return {
            url: `img/energymark/${Number(data[2]) > 0 ? '' : 'negative-'}${forderName}/${data[0]}/${data[1]}.png`,
            fontSize: fontSize[idx],
            size: size[data[0]][idx],
            x,
            y,
            dataUri: images["energymark"][`${Number(data[2]) > 0 ? 'positive_' : 'negative_'}${folderUri}`][data[0]][data[1]]
        };
    } catch (e) {
        console.log(e);
        return null
    }

};
