export const createQueryParams = (params = {}) => {
    return Object.keys(params)
        .map((k) => {
            let val = params[k];
            if (val) {
                val = val.replace('"', ''); //replace double quotation
                return `${k}=${val}`;
            }
            return '';
        })
        .join('&');
}
