import React from 'react';
import {Button, Card, CardActions, CardContent, Popover, Typography} from '@material-ui/core';
import Cookies from 'js-cookie';
import axios from 'axios';
import {ACCESS_TOKEN_KEY, API_GENIAM, GENIAM_REFRESH_TOKEN} from '../../config/constants';
import {makeStyles} from '@material-ui/core/styles';
import rtn from 'reactn';
import Avatar from "@material-ui/core/Avatar";
import {AccountCircle} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    avatarStyle: {
        margin: 'auto',
        width: 50,
        height: 50,
        marginTop: 20
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'space-around'
    }
}));

function AccountPopup(props) {
    const classes = useStyles();
    const {anchorEl, onClose} = props;
    const [user] = rtn.useGlobal('user');

    const myAccount = () => {
        window.open(`${process.env.REACT_APP_MYACCOUNT}/account`);
    };
    const onSwitchAccount = () => {
        window.open(
            `${process.env.REACT_APP_MYACCOUNT}/login/select-account?redirect_url=` + window.location.href,
        );
    };
    const onClickLogout = async () => {
        try {
            await axios.post(API_GENIAM + '/logout');
        } catch (e) {
        }
        const isProd = process.env.REACT_APP_ENV === 'production';
        const isStg = process.env.REACT_APP_ENV === 'stg';
        const domain = isProd || isStg ? '.geniam.com' : null;
        localStorage.clear();
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        Cookies.remove(GENIAM_REFRESH_TOKEN, {domain});
        window.location = '/'

        return null;
    };

    return (
        <div>
            <Popover
                id={'AccountPopup'}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card>
                    <CardContent className={classes.cardContainer}>
                        <div>
                            <Typography variant={"subtitle1"} gutterBottom>
                                {`${user.last_name || ''} ${user.first_name || ''}`}
                            </Typography>
                            <div>
                                {user.avatar ?
                                    <Avatar className={classes.avatarStyle} src={user.avatar}/>
                                    :
                                    <AccountCircle
                                        className={classes.avatarStyle}/>
                                }
                            </div>

                        </div>
                        <div>
                            <Button
                                variant={'outlined'}
                                style={{backgroundColor: '#3f51b5', fontSize: 14, color: 'white'}}
                                onClick={myAccount}
                            >
                                アカウント
                            </Button>
                        </div>
                    </CardContent>
                    <CardActions style={{display: 'flex', justifyContent: 'center'}}>
                        <Button variant={'outlined'} className={'noUnderline'} onClick={onSwitchAccount}>
                            アカウントの切り替え
                        </Button>
                        <Button variant={'outlined'} onClick={onClickLogout}>
                            ログアウト
                        </Button>
                    </CardActions>
                </Card>
            </Popover>
        </div>
    );
}

export default AccountPopup;
