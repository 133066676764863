import {db} from "../firebaseConfig";
import {insightMapColl, insightMapShareColl} from "../config/constants";
import {getGlobal} from "reactn";
import {get} from "lodash";

const getUserId = (prop) => {
    return get(getGlobal(), 'user.user_id')
}

export const getMapCollRef = () => {
    const userId = getUserId()
    return db.collection(`${insightMapColl}/${userId}/insightmaps`).where("is_delete", "==", false)
}

export const getShareMapCollRef = () => {
    const userId = getUserId()
    return db.collection(`${insightMapShareColl}`).where("isDeleted", "==", false).where("userInvite", "==", userId)
}

export const getFolderCollRef = () => {
    const userId = getUserId()
    return db.collection(`${insightMapColl}/${userId}/folder`).where("is_delete", "==", false)
}

export const getTagCollRef = () => {
    const userId = getUserId()
    return db.collection(insightMapColl + `/${userId}/tags`)
}

export const getIsmUserDocRef = () => {
    const userId = getUserId()
    return db.doc(insightMapColl + `/${userId}`)
}
