import React from 'react';
import {InputAdornment, TextField, Typography} from "@material-ui/core";

function InputTag(props) {
    const {classes, tag, setTag} = props
    return (
        <div className={classes.formContent}>
            <Typography>アイデンティティ・タグ</Typography>
            <TextField
                fullWidth
                variant={'outlined'}
                className={classes.textField}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img alt={'penIco'} src={'img/icon/pawn.png'}/>
                        </InputAdornment>
                    ),
                }}
                value={tag ? tag : ""}
                onChange={(e) => setTag(e.target.value)}
            />
        </div>
    );
}

export default InputTag;
 
