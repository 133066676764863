import React from 'react';
import Grid from "@material-ui/core/Grid";

function Years(props) {
    const {classes, value, setValue, updateField, map} = props;

    const handleBlur = () => {
        if (value < 0 || typeof value === "undefined" || !value) {
            setValue(map?.years || 0);
            return null;
        }
        if (Number(value) !== Number(map?.years))
            updateField('years', Number(value), null, true)
    };

    const onChange = (e) => {
        setValue(e.target.value)
    };

    return (
        <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={6} sm={12}/>
            <Grid item md={6} sm={12} className={classes.smCenter}>
                <input
                    value={value}
                    onChange={onChange}
                    onBlur={handleBlur}
                    className={"year-input"}
                    type={"number"}
                />
                年間
                {
                    value > 0 &&
                    <span className={classes.yearInfo}>{value * 365}日間{value * 52}週間{value * 12}ヶ月間{value}年間</span>
                }
            </Grid>
            {/*<Grid item xs={2} style={{marginLeft: "41.66666667%"}}>*/}
            {/*    <TextField*/}
            {/*        value={value}*/}
            {/*        onChange={onChange}*/}
            {/*        onBlur={handleBlur}*/}
            {/*        className={classes.textField}*/}
            {/*        type={'number'}*/}
            {/*        variant="outlined"*/}
            {/*        InputProps={{*/}
            {/*            classes: {*/}
            {/*                root: classes.valueText,*/}
            {/*                notchedOutline: classes.notchedOutline*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    />*/}
            {/*    年間*/}
            {/*</Grid>*/}

            {/*<Grid item xs={5}>*/}
            {/*    {*/}
            {/*        value > 0 &&*/}
            {/*        <>*/}
            {/*            <span className={classes.yearInfo}>{value * 365}</span>日間*/}
            {/*            <span className={classes.yearInfo}>{value * 52}</span>週間*/}
            {/*            <span className={classes.yearInfo}>{value * 12}</span>ヶ月間*/}
            {/*            <span className={classes.yearInfo}>{value}</span>年間*/}
            {/*        </>*/}
            {/*    }*/}
            {/*</Grid>*/}
        </Grid>
    );
}

export default Years;
