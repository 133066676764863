import React, {createContext, useContext, useEffect, useState} from 'react';
import {useGlobal} from 'reactn'
import {barRef, boardRef} from '../common/firebaseRef/annoucement';
import {barDefault, boardDefault} from '../common/initAnnouncement';
import axios from 'axios';
import {API_GENIAM} from '../config/constants';
import {uniq} from 'lodash'
import getCalendarPlan, {plans} from '../common/getCalendarPlan';

export const AnnouncementContext = createContext()

export const useAnnouncement = () => {
    return useContext(AnnouncementContext)
}
export const AnnouncementProvider = ({children}) => {
    const value = useAnnouncementData()
    return (
        <AnnouncementContext.Provider value={value}>
            {children}
        </AnnouncementContext.Provider>
    )
}


const useAnnouncementData = () => {
    const [user] = useGlobal('user')
    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [lock, setLock] = useState(true)
    const [bar, setBar] = useState(barDefault)
    const [board, setBoard] = useState(boardDefault)
    const [plan, setPlan] = useState(plans[0])
    const getSubs = async () => {
        try {
            const {data} = await axios.get(`${API_GENIAM}/subscriptions/list`)
            if (data?.data?.length) {
                setLock(false)
                const prices = uniq(data.data.map(item => item?.plan?.id || '').filter(x => x))
                setData(data.data)
                setPlan(getCalendarPlan(prices))
            } else {
                setLock(true)
                setPlan(getCalendarPlan([]))
            }
        } catch (e) {
            console.log(e);
            setLock(true)
            setPlan(getCalendarPlan([]))
        } finally {
            setLoaded(true)
        }
    }

    useEffect(() => {
        if (!user?.user_id)
            return
        getSubs()
        const barSub = barRef(user.user_id)
            .onSnapshot(snapshot => {
                if (!snapshot.exists)
                    return;
                const barData = snapshot.data()
                if (!barData.highlight) {
                    barData.highlight = barDefault.highlight
                }
                if (barData.geniamNews === undefined) {
                    barData.geniamNews = true
                }
                setBar(barData)
            })
        const boardSub = boardRef(user.user_id)
            .onSnapshot(snapshot => {
                if (!snapshot.exists)
                    return;
                setBoard(snapshot.data())
            })
        return () => {
            if (barSub) barSub()
            if (boardSub) boardSub()
        }
    }, [user?.user_id])
    return {data, loaded, lock, bar, plan, board, getSubs}
}
