import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {AppBarScreen} from "../LazyLoadComponent";
import Footer from "../Footer";

function Display(props) {
    const classes = useStyles()
    return (
        <div>
            <AppBarScreen/>
            <div className={classes.root}>
                <div className={classes.body}>
                    <div> 特定商取引法に基づく表記</div>
                    <ul>
                        <li>販売業者株式会社：<span className={classes.text_in_line}>Andom</span></li>
                        <li>代表責任者：生田知久</li>
                        <li>
                            所在地：〒<span className={classes.text_in_line}>171-0014</span>
                            東京都豊島区池袋<span className={classes.text_in_line}>2-14-2</span>池袋<span
                            className={classes.text_in_line}>2</span>丁目ビル <span
                            className={classes.text_in_line}>2F</span>
                        </li>
                        <li>
                            電話番号：<span className={classes.text_in_line}>03-4405-5177</span>
                        </li>
                        <p>（原則お電話での対応は受け付けておりません。）</p>
                        <li>話受付時間：<span className={classes.text_in_line}>9:00～18:00</span></li>
                        <li>公開メールアドレス：<span className={classes.text_in_line}>info-insigntmind@wowway.co.jp</span></li>
                        <li>ホームページURL: <span className={classes.text_in_line}>http://www.wowway.co.jp</span></li>
                        <li>販売価格：商品紹介ページをご参照ください。</li>
                        <li> 商品代金以外の必要料金：消費税、送料（全国一律630円）</li>
                        <li> 引き渡し時期：お申込み即時</li>
                        <li>お支払方法：銀行振込、クレジットカード、コンビニオンライン</li>
                        <li>
                            キャンセル；利用開始後は、キャンセルはできません。試用期間中
                        </li>
                        <p>に、ご確認ください。</p>
                    </ul>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#f7f7f7',
        minHeight: 'calc(100vh - 160px)',
        marginTop: 65,
        outline: 'none',
        fontFamily: "メイリオ Meiryo ヒラギノ角ゴ Pro W3 Hiragino Kaku Gothic Pro Verdana MS P Gothic ＭＳ Ｐゴシック sans-serif",
        overflowX: 'hidden',
        "& p": {
            margin: 0
        },
    },
    body: {
        padding: '20px 0',
        maxWidth: '60%',
        margin: 'auto',
        fontSize: 15
    },
    text_in_line: {
        fontWeight: 500,
        lineHeight: '1.1px',
        color: "inherit",
    }
}))
export default Display;
