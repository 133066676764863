import React, {memo, Suspense, useEffect} from 'react';
import './App.css';
import {Route, Switch, useHistory} from 'react-router-dom';
import {FilesManager, HomeMaps} from './components/LazyLoadComponent';
import LinearProgress from '@material-ui/core/LinearProgress';
import {InsightMapsProvider} from './context/InsightMindMapsContext';
import {useGlobal} from 'reactn';
import InsightMapComponent from "./components/InsightMind/insightMapComponent";
import {getSerivce} from "./common/getServiceDate";
import CreateMap from "./components/CreateMap";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Privacy from "./components/Pages/Privacy";
import Rule from "./components/Pages/Rule";
import Display from "./components/Pages/Display";
import {Helmet} from "react-helmet";

function App() {
    const [user] = useGlobal("user")
    const history = useHistory();
    const matches = useMediaQuery('(min-width:767px)');

    useEffect(() => {
        window.document.addEventListener('scroll', (ev) => {
            const top = document.body.scrollTop || document.documentElement.scrollTop;
            // const bottom = top + window.innerHeight
            let elements = document.querySelectorAll('.scrollHidden');
            if (elements.length !== 0) {
                if (top >= 70 && matches === true) {
                    document.getElementById('menu-maps-bar').style.position = 'fixed';
                    elements.forEach((element) => {
                        element.style.display = 'none';
                    });
                } else {
                    document.getElementById('menu-maps-bar').style.position = 'relative';
                    elements.forEach((element) => {
                        element.style.display = 'block';
                    });
                }
            }

        });
    }, [matches]);
    useEffect(() => {
        if (user?.user_id && window.location.pathname === "/" || window.location.pathname === "/starterpack") {
            history.push("/filemanager")
        }
        if (!user?.user_id && window.location.pathname === "/starterpack") {
            history.push("/")
        }
        if (user?.user_id)
            getSerivce()

    }, [user?.user_id])

    function renderHelmet() {

        return (
            <Helmet>
                {
                    window.location.pathname === '/filemanager' ?
                        <title>Insight Mind : Files</title> :
                        window.location.pathname === '/insightmap' || window.location.pathname === '/insightMaps' ?
                            <title>Insight Mind : Insightmaps</title> :
                            window.location.pathname === '/starterpack' ?
                                <title>Insight Map® スターターパック</title> :
                                window.location.pathname === '/' ?
                                    <title>Insight Mind : Login </title> :
                                    <title>Insight Mind : Pages</title>
                }
            </Helmet>
        )
    }

    return (
        <Suspense fallback={<SuspenseLoader />}>
            <InsightMapsProvider>
                <div className='App'>
                    {renderHelmet()}
                    <Switch>
                        <Route exact path={'/'} component={HomeMaps} />
                        <Route path={'/insightmap'} component={InsightMapComponent} />
                        <Route path={'/insightMaps'} component={CreateMap} />
                        <Route exact path={'/filemanager'} component={FilesManager} />
                        {/*<Route exact path={'/starterpack'} component={StarterPack}/>*/}
                        <Route exact path={'/privacy'} component={Privacy} />
                        <Route exact path={'/rule'} component={Rule} />
                        <Route exact path={'/display'} component={Display} />
                    </Switch>
                </div>
            </InsightMapsProvider>
        </Suspense>
    );
}

export default App;

const SuspenseLoader = memo(() => {
    return <LinearProgress />;
});
