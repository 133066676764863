import axios from 'axios';
import {API_GENIAM, GET_USER_INFO, LOGIN_URL} from '../config/constants';
import Cookies from 'js-cookie';
import {COOKIE_GENIAM_ACCESS_TOKEN_KEY, COOKIE_GENIAM_REFRESH_TOKEN_KEY, removeCookie} from '../config/cookies';
import Fire from '../firebaseConfig';
import * as Toast from 'react-toastify';
import {setGlobal} from "reactn";

const {toast} = Toast;
// const {setGlobal} = globalValue.default;

export const getUserData = async () => {
    const token = localStorage.getItem('accessToken');
    const firebaseToken = localStorage.getItem('firebaseToken');
    if (!token || !firebaseToken) await refreshToken();
    try {
        const {data} = await axios.get(GET_USER_INFO);
        setGlobal({user: data});
        localStorage.setItem('userInfo', JSON.stringify(data));
        return data;
    } catch (e) {
        console.log(e);
        // toast.error(e.message);
        return null;
    }
};

export const refreshToken = async () => {
    console.log("refresh")
    const refreshToken = Cookies.get(COOKIE_GENIAM_REFRESH_TOKEN_KEY) || localStorage.getItem('refreshToken');

    if (!refreshToken) {
        // redirectLoginPage()
        return;
    }

    try {
        const res = await axios.post(API_GENIAM + '/refresh', {refreshToken});
        const data = res.data;
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('firebaseToken', data.firebaseToken);
        localStorage.setItem('refreshToken', refreshToken);
        return data;
    } catch (e) {
        removeCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY);
        removeCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY);
        // redirectLoginPage()
        return null;
    }


};

export function redirectLoginPage() {
    window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
}
export const signInFirebase = async () => {
    try {
        console.log('firebase login')
        const data = await refreshToken();
        const res = await Fire.auth().signInWithCustomToken(data.firebaseToken);
        console.log(res);
    } catch (e) {
        console.log(e);
    }
};
