import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import './globalData';
import './firebaseConfig';
import axiosConfig from './config/axios';
import {BrowserRouter} from 'react-router-dom';
import {InsightMindUserProvider} from './context/InsightMindUserProvider';
import {LanguageMessageProvider} from './context/LanguageMessageContext';
import {createMuiTheme, ThemeProvider} from '@material-ui/core';
import {ToastContainer} from 'react-toastify';
import './toastCss.css';
import 'antd/dist/antd.css';
import {AnnouncementProvider} from './context/AnnouncementContext';

axiosConfig();

const theme = createMuiTheme({
    props: {
        // Name of the component ⚛️
        MuiButtonBase: {
            // The properties to apply
            disableRipple: true, // No more ripple, on the whole application 💣!
        },
    },
    transitions: {
        // So we have `transition: none;` everywhere
        create: () => 'none',
    },
});

ReactDOM.render(
    <>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <InsightMindUserProvider>
                    <LanguageMessageProvider>
                        <AnnouncementProvider>
                            <App />
                        </AnnouncementProvider>
                    </LanguageMessageProvider>
                </InsightMindUserProvider>
            </BrowserRouter>
        </ThemeProvider>
        <ToastContainer
            hideProgressBar={true}
            closeOnClick={false}
            autoClose={2000}
            position='bottom-center'
        />
    </>
    ,
    document.getElementById('root'),
);

// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
// Learn more: https://www.snowpack.dev/#hot-module-replacement
// if (import.meta.hot) {
//     import.meta.hot.accept();
// }
