export const getContentEmailAdmin = ({content, nickname, email}) => {
    return `
    <div>
        <p>Dear Sir / Madam,</p>
        <br />
        <p>${content}</p>
        <br />
        <p>Best,</p>
        <p>${nickname}</p>
        <p>${email}</p>
    </div>
    `
}

export const getContenEmailUser = ({nickname}) => {
    return `
            <div>
                <p>Dear ${nickname},</p>
                <br />
                <p>Thank you for your email.</p>
                <br />
                <p>株式会社 ＷＯＷＷＡＹ</p>
                <p>Insight Mind 開発チーム</p>
            </div>
    `
}
