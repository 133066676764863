import React from 'react';

import {Avatar, Button, Card, CardActions, CardContent, Grid, Popover, Typography} from '@material-ui/core';

import './AppListPopup.css';
import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY} from '../../config/constants';

import {makeStyles} from '@material-ui/core/styles';
import {Home} from '@material-ui/icons';
import {isStaging} from '../../common/envSettings';
import {createQueryParams} from "../../common/handleSomes";
import {COOKIE_GENIAM_REFRESH_TOKEN_KEY, getCookie} from "../../config/cookies";

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 700,
        // display: 'flex',
        textAlign: 'center',
        '& .MuiCardContent-root': {
            padding: '0 !important',
        },
    },
    info_card: {
        display: 'flex',
        // marginBottom: 5,
        boxShadow:
            '0px -1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        color: '#5d5257',
        fontSize: 14,
        lineHeight: 1.6,
    },
    cover: {
        minWidth: 80,
        height: 80,
    },
    serviceIcons: {
        width: 60,
        height: 60,
        marginTop: 10,
        marginBottom: 10,
    },
    lager: {
        fontSize: 24,
        padding: ' 20px 15px',
        textAlign: 'left',
    },
    h_title: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 29,
        fontFamily: 'Roboto, Helvetica ,Arial ,sans-serif ',
        marginBottom: 10,
    },
    h_item: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: '#fff',
        padding: '5px 15px 10px',
        justifyContent: 'space-between',
    },
    h_text_left: {
        textAlign: 'left',
        width: 'calc(100% - 90px)',
    },
    h_image_right: {
        width: 80,
        height: 80,
        overflow: 'hidden',
        float: 'right',
        margin: '0 0 0 0',
    },
    styleButton: {
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'left',
        minHeight: 32,
        color: 'rgba(0, 0, 0, 0.87)',
    },
}));

function CardWithImageOnRight(props) {
    const {classes, title, content, image, link} = props;
    return (
        <Card className={classes.info_card}>
            <a href={link ? link : '#'} style={{color: '#111', textDecoration: 'none'}}>
                <div>
                    <CardContent>
                        <div className={classes.lager}>{title}</div>
                        <div className={classes.h_item}>
                            <div className={classes.h_text_left} style={{fontSize: 'small'}}>
                                {content}
                            </div>
                            <div>
                                <img src={image} className={classes.h_image_right} alt={'right_img'}/>
                            </div>
                        </div>
                    </CardContent>
                </div>
            </a>
        </Card>
    );
}

function AppListPopup(props) {
    const classes = useStyles();
    const {anchorEl, onClose} = props;
    const isOpen = Boolean(anchorEl);
    if (!isOpen) return null;

    const applist = [
        {
            name: 'enecolor',
            img: '/img/enecolor.png',
            url: isStaging() ? 'https://enecolor-stg.geniam.com' : 'https://enecolor.geniam.com',
            // url: "https://enecolor.geniam.com"
        },
        {
            name: 'insight-mind',
            img: '/img/insight-mind.png',
            url: isStaging() ? 'https://insightmind-stg.geniam.com' : 'https://insightmind.geniam.com',
            // url: "https://insightmind.geniam.com"
        },
    ];

    function onClickServiceIcon(name) {
        applist.forEach((app) => {
            if (app.name === name) {
                const token = localStorage.getItem(ACCESS_TOKEN_KEY);
                const refreshToken = getCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY) || localStorage.getItem(REFRESH_TOKEN_KEY);
                let searchMark = '?';
                if (app.url && app.url.indexOf('?') > -1) {
                    searchMark = '&';
                }
                window.open(app.url + searchMark + createQueryParams({token, refreshToken}), '_blank');
            }
        });
    }

    return (
        <Popover
            id={'AppListPopup'}
            open={isOpen}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Card className={classes.card}>
                <div>
                    <CardContent>
                        <Typography className={classes.h_title}>Geniamサービス</Typography>

                        <Grid container>
                            {applist &&
                            applist.map((app) => {
                                return (
                                    <Grid
                                        item
                                        xs={3}
                                        key={app.name}
                                        style={{cursor: 'pointer'}}
                                        className={'AppListPopup-ServiceList'}
                                        onClick={() => {
                                            onClickServiceIcon(app.name);
                                        }}
                                    >
                                        <Avatar className={classes.serviceIcons} src={app.img}/>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </CardContent>
                </div>
                <Grid container>
                    <Grid item xs={12} sm={6} className={classes.info_card}>
                        <CardWithImageOnRight
                            classes={classes}
                            title={`オススメNews`}
                            content={`Enecolor の解析結果を基に、AI がパフォーマンス向上のヒントを毎日メールで見ることができます。`}
                            image={'/img/togo_logo.png'}
                            link="https://togo.geniam.com"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.info_card}>
                        <CardWithImageOnRight
                            classes={classes}
                            title={'New Contents'}
                            content={
                                'Enecolor 診断の個別解析を基に、数兆通りのパターンから、あなたのためだけの自己発揮への５０のポイントが、１３０Pの冊子になってお手元に届きます。'
                            }
                            image={'/img/en_book.png'}
                            link="https://enecolor.geniam.com/campaign1#book"
                        />
                    </Grid>
                </Grid>
                <CardActions className="AppListPopup-CardActions">
                    <Button
                        size="small"
                        color="default"
                        className={classes.styleButton}
                        onClick={() => {
                            window.open('https://geniam.com', '_blank');
                        }}
                    >
                        <Home/>
                        Geniamとは？
                    </Button>
                    <Button
                        size="small"
                        color="default"
                        className={classes.styleButton}
                        onClick={() => {
                            window.open('https://invenstor.co.jp', '_blank');
                        }}
                    >
                        運営会社Andom
                    </Button>
                </CardActions>
            </Card>
        </Popover>
    );
}

export default AppListPopup;
