export const clearsMap = {
    mapping_01: {
        x: '33.08205407667282',
        y: '-21',
    },
    mapping_02: {
        x: '-77.08205407667282',
        y: '-21',
    },
    mapping_03: {
        x: '88',
        y: '-141',
    },
    mapping_04: {
        x: '88',
        y: '89',
    },
    mapping_05: {
        x: '-132',
        y: '89',
    },
    mapping_06: {
        x: '-132',
        y: '-141',
    },
    mapping_07: {
        x: '94.6715',
        y: '-263.781',
    },
    mapping_08: {
        x: '220.77783530759893',
        y: '-137.67149999999998',
    },
    mapping_09: {
        x: '220.781',
        y: '95.6715',
    },
    mapping_10: {
        x: '94.6715',
        y: '221.77783530759893',
    },
    mapping_11: {
        x: '-138.67149999999998',
        y: '221.781',
    },
    mapping_12: {
        x: '-264.77783530759893',
        y: '95.6715',
    },
    mapping_13: {
        x: '-264.781',
        y: '-137.67149999999998',
    },
    mapping_14: {
        x: '-138.67149999999998',
        y: '-263.77783530759893',
    },
    mapping_15: {
        x: '62.194',
        y: '-393.44350044004057',
    },
    mapping_16: {
        x: '196.7191732',
        y: '-340.93476680000003',
    },
    mapping_17: {
        x: '297.9288092',
        y: '-239.7046158',
    },
    mapping_18: {
        x: '350.45461',
        y: '-105.19399999999999',
    },
    mapping_19: {
        x: '350.44350044004057',
        y: '63.194',
    },
    mapping_20: {
        x: '297.93476680000003',
        y: '197.7191732',
    },
    mapping_21: {
        x: '196.7046158',
        y: '298.9288092',
    },
    mapping_22: {
        x: '62.194',
        y: '351.45461',
    },
    mapping_23: {
        x: '-106.194',
        y: '351.44350044004057',
    },
    mapping_24: {
        x: '-240.7191732',
        y: '298.93476680000003',
    },
    mapping_25: {
        x: '-341.9288092',
        y: '197.7046158',
    },
    mapping_26: {
        x: '-394.45461',
        y: '63.194',
    },
    mapping_27: {
        x: '-394.44350044004057',
        y: '-105.194',
    },
    mapping_28: {
        x: '-341.93476680000003',
        y: '-239.7191732',
    },
    mapping_29: {
        x: '-240.7046158',
        y: '-340.9288092',
    },
    mapping_30: {
        x: '-106.19399999999999',
        y: '-393.45461',
    },
    mapping_31: {
        x: '172.4525',
        y: '-471.562',
    },
    mapping_32: {
        x: '428.556725512665',
        y: '-215.4525',
    },
    mapping_33: {
        x: '428.562',
        y: '173.4525',
    },
    mapping_34: {
        x: '172.4525',
        y: '429.556725512665',
    },
    mapping_35: {
        x: '-216.4525',
        y: '429.562',
    },
    mapping_36: {
        x: '-472.556725512665',
        y: '173.4525',
    },
    mapping_37: {
        x: '-472.562',
        y: '-215.4525',
    },
    mapping_38: {
        x: '-216.4525',
        y: '-471.556725512665',
    },
    mapping_39: {
        x: '404.68600000000004',
        y: '-447.686',
    },
    mapping_40: {
        x: '404.686',
        y: '405.68600000000004',
    },
    mapping_41: {
        x: '-448.68600000000004',
        y: '405.686',
    },
    mapping_42: {
        x: '-448.686',
        y: '-447.68600000000004',
    },
    mapping_43: {
        x: '698',
        y: '-21',
    },
    mapping_44: {
        x: '-742',
        y: '-21',
    },
};
