import React, {useEffect, useState} from 'react';
import "../../../FilesManager/styles.css"
import Grid from "@material-ui/core/Grid";
import {useInsightMindUserContext} from "../../../../context/InsightMindUserProvider";
import ButtonIdentity from "./ButtonIdentity";
import _ from "lodash";
import TagUsedRecentList from "./TagUsedRecentList";

function ListRecentIdentities(props) {
    const {
        classes,
        showHistoryIdentity,
        setShowHistoryIdentity,
        showRecent,
        setShowRecent,
        tagSelected,
        setTagSelected
    } = props;
    const {tags, tagsNormal, tagsFavorite} = useInsightMindUserContext();
    const [sources, setSources] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [step, setStep] = useState(1);

    useEffect(() => {
        generateData()
    }, [tags, showRecent]);

    const generateData = () => {
        if (!showRecent || (tagsNormal && !tagsNormal.length))
            return;
        let data = [];
        tagsNormal.forEach((source, index) => {
            let numSourceCheck = (index + 1);
            if (numSourceCheck <= 12)
                data.push(source)
        });
        setSources(data);
        setStep(2);
        if (data.length >= tagsNormal.length) {
            setHasMore(false)
        } else {
            setHasMore(true)
        }
    };

    const nextData = () => {
        if (!showRecent || (tagsNormal && !tagsNormal.length))
            return
        if (sources.length >= tagsNormal.length) {
            setHasMore(false);
            return;
        }
        let data = sources;
        tagsNormal.forEach((source, index) => {
            let numSourceCheck = (index + 1);
            let idx = _.findIndex(data, s => s.id === source.id);
            if (idx !== -1)
                return;
            if (numSourceCheck <= (12 * step))
                data.push(source)
        });
        setTimeout(() => {
            setSources(data);
            setStep(step + 1)
        }, 500);
    };

    return (
        <div className={classes.divHistoryIdentities}>
            <div className={classes.startTitle}>
                <p>お気に入り<span className="glyphicon glyphicon-star yellow"/></p>
            </div>
            {
                tagsFavorite && tagsFavorite.length !== 0 &&
                <TagUsedRecentList
                    classes={classes}
                    sources={tagsFavorite}
                    tagSelected={tagSelected}
                    setTagSelected={setTagSelected}
                />
            }
            {
                showRecent ?
                    <div className={classes.divShowRecent}>
                        <div className={classes.startTitle}>
                            <p>最近使ったアイデンティティ・タグ</p>
                        </div>

                        <TagUsedRecentList
                            classes={classes}
                            sources={sources}
                            tagSelected={tagSelected}
                            setTagSelected={setTagSelected}
                        />

                        <ButtonIdentity
                            hasMore={hasMore}
                            nextData={nextData}
                            classes={classes}
                            setShowRecent={setShowRecent}
                            isLoadMore={true}
                        />
                    </div>
                    :
                    <ButtonIdentity
                        classes={classes}
                        setShowRecent={setShowRecent}
                    />
            }
            <Grid container>
                <Grid item md={3} className={classes.divRecentGrid}>
                    <div className={classes.divRecent} onClick={() => {
                        setShowRecent(false);
                        setShowHistoryIdentity(!showHistoryIdentity)
                    }}>
                        <div className={classes.divImg} style={{backgroundImage: "url(/img/pawn/gray.png)"}}>
                            <span className={`${classes.glyphiconCSS} glyphicon glyphicon-plus`}/>
                        </div>
                        <div>新規作成</div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default ListRecentIdentities;
