import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {FormControlLabel, IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import rtn from 'reactn';
import _ from "lodash";
import Fire from "../../firebaseConfig";
import {insightMapColl} from "../../config/constants";
import moment from "moment";

// const {useGlobal} = globalValue.default;

function DialogSetting(props) {
    const classes = useStyles()
    // const [arrangement, setArrangement] = rtn.useGlobal("arrangement")
    const [user] = rtn.useGlobal("user");
    const [menuMapSetting, setMenuMapSetting] = rtn.useGlobal("menuMapSetting");
    const [insightMindUser = {}] = rtn.useGlobal('insightMindUser');

    const handleClose = () => {
        setMenuMapSetting(false)
    };

    const handleUpDateSetting = async (name, value) => {
        try {
            const userRef = Fire.firestore().doc(insightMapColl + `/${user.user_id}`)
            await userRef.set({
                [name]: value,
                updatedAt: moment().format(),
            }, {merge: true})
        } catch (e) {
            console.log(e.toString())
        }
    }

    if (_.isEmpty(insightMindUser)) return null;
    return (
        <Dialog className={classes.root} onClose={handleClose} aria-labelledby="customized-dialog-title"
                open={menuMapSetting}>
            <DialogTitle>
                設定
                <IconButton className={classes.close}
                            onClick={handleClose}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div className={classes.box}>
                    <span>プロセスバーの配置 </span>
                    <Button
                        style={{background: !Boolean(insightMindUser.menu_position) ? "#90c53b" : "#fff"}}
                        className={classes.btnSelect}
                        onClick={() => handleUpDateSetting("menu_position", false)}
                    >上</Button>
                    <Button
                        style={{background: Boolean(insightMindUser.menu_position) ? "#90c53b" : "#fff"}}
                        onClick={() => handleUpDateSetting("menu_position", true)}
                        className={classes.btnSelect}
                    >左</Button>
                </div>
                <FormControlLabel
                    className={classes.formControl}
                    control={
                        <Checkbox
                            checked={Boolean(insightMindUser.show_guide)}
                            onChange={() => handleUpDateSetting("show_guide", !Boolean(insightMindUser.show_guide))}
                            style={{color: "#26a69a"}}
                        />
                    }
                    label="ガイドのポップアップ表示"
                />
                <FormControlLabel
                    className={classes.formControl}
                    control={
                        <Checkbox
                            checked={Boolean(insightMindUser.is_auto_capture)}
                            onChange={() => handleUpDateSetting("is_auto_capture", !Boolean(insightMindUser.is_auto_capture))}
                            style={{color: "#26a69a"}}
                        />
                    }
                    label="オートキャプチャを常にON"
                />
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"}
                        className={classes.button}
                        onClick={handleClose}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiDialogTitle-root": {
            background: "-webkit-linear-gradient(45deg, rgba(43, 187, 173, 1) 0%, rgba(43, 187, 173, 0.5) 49%, rgba(43, 187, 173, 0.25) 50%, rgba(43, 187, 173, 0.75) 100%)",
            color: "white",
            fontWeight: "bold",
            padding: 15,
            position: 'relative'
        },
        width: '100%',
        '& .MuiDialog-paper': {
            minWidth: '600px',
            position: 'absolute',
            top: "50px",
            right: 0,
            left: 0,
            margin: "auto",
            [theme.breakpoints.down('sm')]: {
                left: 10,
                right: 10,
                minWidth: "auto",
            },
        }
    },
    close: {
        float: "right",
        padding: 0
    },
    button: {
        backgroundColor: "#2bbbad",
        color: "white",
        fontWeight: "bold",
        margin: "10px auto",
        boxShadow: "0 4px rgba(43, 187, 173, 0.5)",
        "&:hover": {
            backgroundColor: "rgba(43, 187, 173, 0.5)",
            boxShadow: "0 4px #2bbbad",
        }
    },
    box: {
        margin: "20px 0"
    },
    btnSelect: {
        padding: 5,
        border: "1px solid #ccc",
        cursor: "pointer",
        textAlign: "center",
        borderRadius: 5,
        lineHeight: "18px",
        display: "inline-block",
        margin: "0 5px"
    },
    checkBox: {
        color: "#26a69a"
    },
    formControl: {
        display: "block"
    }
}))

export default DialogSetting;
