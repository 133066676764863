import React, {useEffect} from 'react';
import InsightMind from "../InsightMind";
import rtn from 'reactn'

const {setGlobal} = rtn

function CreateMap(props) {

    useEffect(() => {
        setGlobal({openCreateInsightMapFlatDialog: true})
    }, [])
    return (
        <div>
            <InsightMind/>
        </div>
    );
}

export default CreateMap;
