export const identities = {
    mapping_01: {
        id: 'identity_01',
        x: '60.16410815334564',
        y: '-110',
        key: 'mapping_01',
    },
    mapping_02: {
        id: 'identity_02',
        x: '-100.16410815334564',
        y: '-110',
        key: 'mapping_02',
    },
    mapping_03: {
        id: 'identity_03',
        x: '170',
        y: '-75',
        key: 'mapping_03',
    },
    mapping_04: {
        id: 'identity_04',
        x: '5',
        y: '105',
        key: 'mapping_04',
    },
    mapping_05: {
        id: 'identity_05',
        x: '-45',
        y: '105',
        key: 'mapping_05',
    },
    mapping_06: {
        id: 'identity_06',
        x: '-210',
        y: '-75',
        key: 'mapping_06',
    },
    mapping_07: {
        id: 'identity_07',
        x: '163.343',
        y: '-282.781',
        key: 'mapping_07',
    },
    mapping_08: {
        id: 'identity_08',
        x: '279.9936706151979',
        y: '-75',
        key: 'mapping_08',
    },
    mapping_09: {
        id: 'identity_09',
        x: '165.56199999999998',
        y: '91.6715',
        key: 'mapping_09',
    },
    mapping_10: {
        id: 'identity_10',
        x: '5',
        y: '214.9936706151979',
        key: 'mapping_10',
    },
    mapping_11: {
        id: 'identity_11',
        x: '-45',
        y: '215',
        key: 'mapping_11',
    },
    mapping_12: {
        id: 'identity_12',
        x: '-205.56199999999998',
        y: '91.6715',
        key: 'mapping_12',
    },
    mapping_13: {
        id: 'identity_13',
        x: '-320',
        y: '-75',
        key: 'mapping_13',
    },
    mapping_14: {
        id: 'identity_14',
        x: '-203.343',
        y: '-282.77783530759893',
        key: 'mapping_14',
    },
    mapping_15: {
        id: 'identity_15',
        x: '118.388',
        y: '-460.0000008800812',
        key: 'mapping_15',
    },
    mapping_16: {
        id: 'identity_16',
        x: '261.1473464',
        y: '-369.93476680000003',
        key: 'mapping_16',
    },
    mapping_17: {
        id: 'identity_17',
        x: '356.5146184',
        y: '-238.7046158',
        key: 'mapping_17',
    },
    mapping_18: {
        id: 'identity_18',
        x: '390.02222',
        y: '-74.99999999999997',
        key: 'mapping_18',
    },
    mapping_19: {
        id: 'identity_19',
        x: '329.887',
        y: '-15',
        key: 'mapping_19',
    },
    mapping_20: {
        id: 'identity_20',
        x: '289.93476680000003',
        y: '111.291',
        key: 'mapping_20',
    },
    mapping_21: {
        id: 'identity_21',
        x: '208.7046158',
        y: '218.343',
        key: 'mapping_21',
    },
    mapping_22: {
        id: 'identity_22',
        x: '89.194',
        y: '284.887',
        key: 'mapping_22',
    },
    mapping_23: {
        id: 'identity_23',
        x: '-129.19400000000002',
        y: '284.887',
        key: 'mapping_23',
    },
    mapping_24: {
        id: 'identity_24',
        x: '-248.7191732',
        y: '218.3535336',
        key: 'mapping_24',
    },
    mapping_25: {
        id: 'identity_25',
        x: '-329.9288092',
        y: '111.2852316',
        key: 'mapping_25',
    },
    mapping_26: {
        id: 'identity_26',
        x: '-377.45461',
        y: '-15',
        key: 'mapping_26',
    },
    mapping_27: {
        id: 'identity_27',
        x: '-430.0000008800812',
        y: '-75',
        key: 'mapping_27',
    },
    mapping_28: {
        id: 'identity_28',
        x: '-396.516',
        y: '-238.7191732',
        key: 'mapping_28',
    },
    mapping_29: {
        id: 'identity_29',
        x: '-301.12399999999997',
        y: '-369.9288092',
        key: 'mapping_29',
    },
    mapping_30: {
        id: 'identity_30',
        x: '-158.388',
        y: '-460.02222',
        key: 'mapping_30',
    },
    mapping_31: {
        id: 'identity_31',
        x: '70.24249999999999',
        y: '-518.2580005500507',
        key: 'mapping_31',
    },
    mapping_32: {
        id: 'identity_32',
        x: '243.6610915',
        y: '-449.64152240000004',
        key: 'mapping_32',
    },
    mapping_33: {
        id: 'identity_33',
        x: '374.63363649999997',
        y: '-318.64265439999997',
        key: 'mapping_33',
    },
    mapping_34: {
        id: 'identity_34',
        x: '443.27188750000005',
        y: '-145.2425',
        key: 'mapping_34',
    },
    mapping_35: {
        id: 'identity_35',
        x: '443.2580005500507',
        y: '65.24249999999999',
        key: 'mapping_35',
    },
    mapping_36: {
        id: 'identity_36',
        x: '374.64152240000004',
        y: '238.6610915',
        key: 'mapping_36',
    },
    mapping_37: {
        id: 'identity_37',
        x: '243.64265439999997',
        y: '369.63363649999997',
        key: 'mapping_37',
    },
    mapping_38: {
        id: 'identity_38',
        x: '70.24249999999999',
        y: '443.27188750000005',
        key: 'mapping_38',
    },
    mapping_39: {
        id: 'identity_39',
        x: '-110.24249999999999',
        y: '443.2580005500507',
        key: 'mapping_39',
    },
    mapping_40: {
        id: 'identity_40',
        x: '-283.6610915',
        y: '369.64152240000004',
        key: 'mapping_40',
    },
    mapping_41: {
        id: 'identity_41',
        x: '-414.63363649999997',
        y: '238.64265439999997',
        key: 'mapping_41',
    },
    mapping_42: {
        id: 'identity_42',
        x: '-483.27188750000005',
        y: '65.24249999999999',
        key: 'mapping_42',
    },
    mapping_43: {
        id: 'identity_43',
        x: '-483.2580005500507',
        y: '-145.2425',
        key: 'mapping_43',
    },
    mapping_44: {
        id: 'identity_44',
        x: '-414.64152240000004',
        y: '-318.6610915',
        key: 'mapping_44',
    },
    mapping_45: {
        id: 'identity_45',
        x: '-283.64265439999997',
        y: '-449.63363649999997',
        key: 'mapping_45',
    },
    mapping_46: {
        id: 'identity_46',
        x: '-110.24249999999999',
        y: '-518.2718875',
        key: 'mapping_46',
    },
};
export const miniIdentities = {
    mapping_01: {
        id: 'identity_01',
        x: '60.16410815334564',
        y: '-110',
        key: 'mapping_01',
    },
    mapping_02: {
        id: 'identity_02',
        x: '-100.16410815334564',
        y: '-110',
        key: 'mapping_02',
    },
    mapping_03: {
        id: 'identity_03',
        x: '170',
        y: '-75',
        key: 'mapping_03',
    },
    mapping_04: {
        id: 'identity_04',
        x: '5',
        y: '105',
        key: 'mapping_04',
    },
    mapping_05: {
        id: 'identity_05',
        x: '-45',
        y: '105',
        key: 'mapping_05',
    },
    mapping_06: {
        id: 'identity_06',
        x: '-210',
        y: '-75',
        key: 'mapping_06',
    },
    mapping_07: {
        id: 'identity_07',
        x: '163.343',
        y: '-282.781',
        key: 'mapping_07',
    },
    mapping_08: {
        id: 'identity_08',
        x: '279.9936706151979',
        y: '-75',
        key: 'mapping_08',
    },
    mapping_09: {
        id: 'identity_09',
        x: '165.56199999999998',
        y: '91.6715',
        key: 'mapping_09',
    },
    mapping_10: {
        id: 'identity_10',
        x: '5',
        y: '214.9936706151979',
        key: 'mapping_10',
    },
    mapping_11: {
        id: 'identity_11',
        x: '-45',
        y: '215',
        key: 'mapping_11',
    },
    mapping_12: {
        id: 'identity_12',
        x: '-205.56199999999998',
        y: '91.6715',
        key: 'mapping_12',
    },
    mapping_13: {
        id: 'identity_13',
        x: '-320',
        y: '-75',
        key: 'mapping_13',
    },
    mapping_14: {
        id: 'identity_14',
        x: '-203.343',
        y: '-282.77783530759893',
        key: 'mapping_14',
    },
    mapping_15: {
        id: 'identity_15',
        x: '118.388',
        y: '-420.0000008800812',
        key: 'mapping_15',
    },
    mapping_16: {
        id: 'identity_16',
        x: '340.5146184',
        y: '-230.7046158',
        key: 'mapping_16',
    },
    mapping_17: {
        id: 'identity_17',
        x: '340.5146184',
        y: '111.291',
        key: 'mapping_17',
    },
    mapping_18: {
        id: 'identity_18',
        x: '89.194',
        y: '300.887',
        key: 'mapping_18',
    },
    mapping_19: {
        id: 'identity_19',
        x: '-129.19400000000002',
        y: '300.887',
        key: 'mapping_19',
    },
    mapping_20: {
        id: 'identity_20',
        x: '-380.9288092',
        y: '111.291',
        key: 'mapping_20',
    },
    mapping_21: {
        id: 'identity_21',
        x: '-380.9288092',
        y: '-230.7191732',
        key: 'mapping_21',
    },
    mapping_22: {
        id: 'identity_22',
        x: '-158.388',
        y: '-420.02222',
        key: 'mapping_22',
    }
};
export const identitiesAdvance = {
    mapping_01: {
        id: 'identity_01',
        x: '60.16410815334564',
        y: '-110',
        key: 'mapping_01',
    },
    mapping_02: {
        id: 'identity_02',
        x: '-100.16410815334564',
        y: '-110',
        key: 'mapping_02',
    },
    mapping_03: {
        id: 'identity_03',
        x: '170',
        y: '-75',
        key: 'mapping_03',
    },
    mapping_04: {
        id: 'identity_04',
        x: '5',
        y: '105',
        key: 'mapping_04',
    },
    mapping_05: {
        id: 'identity_05',
        x: '-45',
        y: '105',
        key: 'mapping_05',
    },
    mapping_06: {
        id: 'identity_06',
        x: '-210',
        y: '-75',
        key: 'mapping_06',
    },
    mapping_07: {
        id: 'identity_07',
        x: '163.343',
        y: '-282.781',
        key: 'mapping_07',
    },
    mapping_08: {
        id: 'identity_08',
        x: '279.9936706151979',
        y: '-75',
        key: 'mapping_08',
    },
    mapping_09: {
        id: 'identity_09',
        x: '165.56199999999998',
        y: '91.6715',
        key: 'mapping_09',
    },
    mapping_10: {
        id: 'identity_10',
        x: '5',
        y: '214.9936706151979',
        key: 'mapping_10',
    },
    mapping_11: {
        id: 'identity_11',
        x: '-45',
        y: '215',
        key: 'mapping_11',
    },
    mapping_12: {
        id: 'identity_12',
        x: '-205.56199999999998',
        y: '91.6715',
        key: 'mapping_12',
    },
    mapping_13: {
        id: 'identity_13',
        x: '-320',
        y: '-75',
        key: 'mapping_13',
    },
    mapping_14: {
        id: 'identity_14',
        x: '-203.343',
        y: '-282.77783530759893',
        key: 'mapping_14',
    },
    mapping_15: {
        id: 'identity_15',
        x: '118.388',
        y: '-460.0000008800812',
        key: 'mapping_15',
    },
    mapping_16: {
        id: 'identity_16',
        x: '261.1473464',
        y: '-369.93476680000003',
        key: 'mapping_16',
    },
    mapping_17: {
        id: 'identity_17',
        x: '356.5146184',
        y: '-238.7046158',
        key: 'mapping_17',
    },
    mapping_18: {
        id: 'identity_18',
        x: '390.02222',
        y: '-74.99999999999997',
        key: 'mapping_18',
    },
    mapping_19: {
        id: 'identity_19',
        x: '329.887',
        y: '-15',
        key: 'mapping_19',
    },
    mapping_20: {
        id: 'identity_20',
        x: '289.93476680000003',
        y: '111.291',
        key: 'mapping_20',
    },
    mapping_21: {
        id: 'identity_21',
        x: '208.7046158',
        y: '218.343',
        key: 'mapping_21',
    },
    mapping_22: {
        id: 'identity_22',
        x: '89.194',
        y: '284.887',
        key: 'mapping_22',
    },
    mapping_23: {
        id: 'identity_23',
        x: '-129.19400000000002',
        y: '284.887',
        key: 'mapping_23',
    },
    mapping_24: {
        id: 'identity_24',
        x: '-248.7191732',
        y: '218.3535336',
        key: 'mapping_24',
    },
    mapping_25: {
        id: 'identity_25',
        x: '-329.9288092',
        y: '111.2852316',
        key: 'mapping_25',
    },
    mapping_26: {
        id: 'identity_26',
        x: '-377.45461',
        y: '-15',
        key: 'mapping_26',
    },
    mapping_27: {
        id: 'identity_27',
        x: '-430.0000008800812',
        y: '-75',
        key: 'mapping_27',
    },
    mapping_28: {
        id: 'identity_28',
        x: '-396.516',
        y: '-238.7191732',
        key: 'mapping_28',
    },
    mapping_29: {
        id: 'identity_29',
        x: '-301.12399999999997',
        y: '-369.9288092',
        key: 'mapping_29',
    },
    mapping_30: {
        id: 'identity_30',
        x: '-158.388',
        y: '-460.02222',
        key: 'mapping_30',
    },
    mapping_31: {
        id: 'identity_31',
        x: '323.905',
        y: '-440.562',
        key: 'mapping_31',
    },
    mapping_32: {
        id: 'identity_32',
        x: '499.98945102533',
        y: '-75',
        key: 'mapping_32',
    },
    mapping_33: {
        id: 'identity_33',
        x: '326.12399999999997',
        y: '258.905',
        key: 'mapping_33',
    },
    mapping_34: {
        id: 'identity_34',
        x: '5',
        y: '434.98945102533',
        key: 'mapping_34',
    },
    mapping_35: {
        id: 'identity_35',
        x: '-45',
        y: '435',
        key: 'mapping_35',
    },
    mapping_36: {
        id: 'identity_36',
        x: '-366.12399999999997',
        y: '258.905',
        key: 'mapping_36',
    },
    mapping_37: {
        id: 'identity_37',
        x: '-540',
        y: '-75',
        key: 'mapping_37',
    },
    mapping_38: {
        id: 'identity_38',
        x: '-358.905',
        y: '-440.556725512665',
        key: 'mapping_38',
    },
    mapping_39: {
        id: 'identity_39',
        x: '610',
        y: '-75',
        key: 'mapping_39',
    },
    mapping_40: {
        id: 'identity_40',
        x: '5',
        y: '545',
        key: 'mapping_40',
    },
    mapping_41: {
        id: 'identity_41',
        x: '-45',
        y: '545',
        key: 'mapping_41',
    },
    mapping_42: {
        id: 'identity_42',
        x: '-650',
        y: '-75',
        key: 'mapping_42',
    },
    mapping_43: {
        id: 'identity_43',
        x: '5',
        y: '655',
        key: 'mapping_43',
    },
    mapping_44: {
        id: 'identity_44',
        x: '-45',
        y: '655',
        key: 'mapping_44',
    },
};

export const identitySize = {
    width: 40,
    height: 70,
};
const boxSize = {
    width: 140,
    height: 60,
};
const bottomTextBox = [10, 11, 12, 16, 17, 19, 20, 21, 22, 23, 24, 25, 26, 28, 29, 30, 35, 36, 37, 38, 39, 40, 41, 42];

export const getTextBoxPath = (x, y, id) => {
    if (bottomTextBox.indexOf(id) !== -1) {
        const Sx = Number(x) + identitySize.width / 2;
        const Sy = Number(y) + identitySize.height + 5;
        const {width, height} = boxSize;
        return `M${Sx},${Sy}
         L${Sx - 5},${Sy + 15}
         L${Sx - width / 2 + 5},${Sy + 15}
         A5,5 0 0,0 ${Sx - width / 2},${Sy + 15 + 5}
         L${Sx - width / 2},${Sy + 15 + height - 5}
         A5,5 0 0,0 ${Sx - width / 2 + 5},${Sy + 15 + height}
         L${Sx + width / 2 - 5},${Sy + 15 + height}
         A5,5 0 0,0 ${Sx + width / 2},${Sy + 15 + height - 5}
         L${Sx + width / 2},${Sy + 15 + 5}
          A5,5 0 0,0 ${Sx + width / 2 - 5},${Sy + 15}
         L${Sx + 5},${Sy + 15}Z`;
    }
    const Sx = Number(x) + identitySize.width / 2;
    const Sy = Number(y) - 5;
    const {width, height} = boxSize;
    return `M${Sx},${Sy}
         L${Sx - 5},${Sy - 15}
         L${Sx - width / 2 + 5},${Sy - 15}
         A5,5 0 0,1 ${Sx - width / 2},${Sy - 15 - 5}
         L${Sx - width / 2},${Sy - 15 - height + 5}
         A5,5 0 0,1 ${Sx - width / 2 + 5},${Sy - 15 - height}
         L${Sx + width / 2 - 5},${Sy - 15 - height}
         A5,5 0 0,1 ${Sx + width / 2},${Sy - 15 - height + 5}
         L${Sx + width / 2},${Sy - 15 - 5}
         A5,5 0 0,1 ${Sx + width / 2 - 5},${Sy - 15}
         L${Sx + 5},${Sy - 15}Z`;
};
export const getTextPositon = (x, y, id, line) => {
    const {width, height} = boxSize;
    const lineHeight = height / 3;
    if (bottomTextBox.indexOf(id) !== -1) {
        const Sx = Number(x) + identitySize.width / 2;
        const Sy = Number(y) + identitySize.height;
        return {
            x: Sx - width / 2 + 10,
            y: Sy + height - 25 + lineHeight * (line - 1),
        };
    }
    const Sx = Number(x);
    const Sy = Number(y);
    return {
        x: Sx - width / 2 + 30,
        y: Sy - height - 5 + lineHeight * (line - 1),
    };
};
