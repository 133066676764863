export const dialogData = [
    {
        index: 1,
        title: '葛藤',
        titleEng: 'Conflict',
        color: '#70c3ed',
        memo1: '扱いたいテーマを決め、中央に陰陽を入力します。',
        memo2: '陽はどちらかと言うと望んでいる方、陰は望んでいない方を置きます。',
        memo3: '①やりたいけどできない葛藤（例：早起きしたいけど起きれない場合は、陽：早起きする、陰：早起きしない）',
        memo4: '②進む道をA or Bで意志決定したい（例：陽：開発コミット、陰：数字コミット）',
        memo5: null,
        memo6: null,
        memo7: null,
        background:
            '-webkit-linear-gradient(45deg, rgba(112, 195, 237, 1) 0%, rgba(112, 195, 237, 0.5) 49%, rgba(112, 195, 237, 0.25) 50%, rgba(112, 195, 237, 0.75) 100%)',
        lightBubData: [
            {
                img: '/img/popup/popup_1.png',
            },
        ],
    },
    {
        index: 2,
        title: 'ストーリー',
        buttonText: '入力開始',
        titleEng: 'Story',
        color: '#6783c2',
        colorBlur: 'rgba(103, 131, 194, 0.5)',
        memo1: '以下のような問いかけを自分に投げかけながら、出てきた答えをマスに入力し、ストーリーを外へ広げる。',
        memo2: '陽のマスに進むときは「～すること（内側のマスの内容）でプラスな側面があるとしたら、どんなこと？」',
        memo3: '陰のマスに進むときは「～すること（内側のマスの内容）でのマイナスな側面があるとしたら、どんなこと？」',
        memo4: null,
        memo5: null,
        memo6: null,
        memo7: null,
        background:
            '-webkit-linear-gradient(45deg, rgba(103, 131, 194, 1) 0%, rgba(103, 131, 194, 0.5) 49%, rgba(103, 131, 194, 0.25) 50%, rgba(103, 131, 194, 0.75) 100%)',
        lightBubData: [
            {
                img: '/img/popup/popup_2.png',
                title: '直観的に浮かんだ言葉をシンプルに20文字以内で入力しましょう。',
            },
        ],
    },
    {
        index: 3,
        title: '境界線',
        buttonText: '入力開始',
        titleEng: 'Conscious Boundar',
        color: '#756bb0',
        colorBlur: 'rgba(117, 107, 176, 0.5)',
        memo1: 'マスの枠をクリックして、顕在領域と潜在領域の境界線を引く。',
        memo2:
            '普段、当たり前に思考・意識していたマスと、今回のセッションでの内観により、初めて出てきたマスの間に境界線を引き、マップ上で顕在意識と潜在意識のエリア分けをする。',
        memo3: null,
        memo4: null,
        memo5: null,
        memo6: null,
        memo7: null,
        background:
            '-webkit-linear-gradient(45deg, rgba(117, 107, 176, 1) 0%, rgba(117, 107, 176, 0.5) 49%, rgba(117, 107, 176, 0.25) 50%, rgba(117, 107, 176, 0.75) 100%)',
        lightBubData: [
            {
                img: '/img/popup/popup_3.png',
                title: '境界線を引き直したい場合は、境界線をクリックすると線を消したり、引き直すことが出来ます。',
            },
        ],
    },
    {
        index: 4,
        title: 'エネルギー',
        buttonText: '数値化の開始',
        titleEng: 'Energy',
        color: '#9a6db0',
        colorBlur: 'rgba(154, 109, 176, 0.5)',
        memo1: '❶強く感情・エネルギーが動くマスを選択します。',
        memo2: '❷エネルギーを感じる部位を選びます。',
        memo3: '❸エネルギーのカラーを選びます。',
        memo4: '❹エネルギーの強さを決めます。',
        memo5: null,
        memo6: null,
        memo7: null,
        background:
            '-webkit-linear-gradient(45deg, rgba(154, 109, 176, 1) 0%, rgba(154, 109, 176, 0.5) 49%, rgba(154, 109, 176, 0.25) 50%, rgba(154, 109, 176, 0.75) 100%)',
        lightBubData: [
            {
                index: 1,
                img: '/img/popup/popup_4_1.png',
                title:
                    '感情的にすごく望んでいる「プラスのエネルギー」、これは嫌だなという「マイナスのエネルギー」を感じるマスがあれば選択し、クリックする。',
            },
            {
                index: 2,
                img: '/img/popup/popup_4_2.png',
                title: 'エネルギーを感じる部位を選ぶ。',
                title1:
                    '例：マイナスのエネルギーの場合、頭で「思考的に嫌！」、胸で「感情的に嫌！」、腹で「精神・魂的に嫌！」の内、最も近い感覚のカラダの部位を選ぶ。',
            },
            {
                index: 3,
                img: '/img/popup/popup_4_3.png',
                title: 'エネカラー（下記参照）を使って、エネルギーのカラーを選びましょう。',
            },
            {
                index: 4,
                img: '/img/popup/popup_4_4.png',
                title:
                    'エネルギーの強さを決めます。最大値を100（－100）とした時、どれくらい強く「嫌！」「望んでいる！」か、数値を決めます。',
            },
            {
                index: 5,
                img: '/img/popup/popup_4_5.png',
                title: 'マイナスのエネルギーには斜線が入り、エネルギーの数値に応じて、部位のサイズも変わります。',
            },
        ],
    },
    {
        index: 5,
        title: 'バイアス',
        buttonText: '入力開始',
        titleEng: 'Vector',
        color: '#d671ad',
        colorBlur: 'rgba(214, 113, 173, 0.5)',
        memo1: '内的なエネルギーの流れを線で表します。',
        memo2: '内なる自己がもっている意識、エネルギーの流れである「バイアス」を線を引くことで表します。',
        memo3:
            'これは嫌！という「マイナスのエネルギー」を強く感じるマスを始点とし、陰陽反対側に向かってそのエネルギーの主体である内なる自己が最も望んでいるマスが終点になるように線を引きます。',
        memo4: null,
        memo5: null,
        memo6: null,
        memo7: null,
        background:
            '-webkit-linear-gradient(45deg, rgba(214, 113, 173, 1) 0%, rgba(214, 113, 173, 0.5) 49%, rgba(214, 113, 173, 0.25) 50%, rgba(214, 113, 173, 0.75) 100%)',
        lightBubData: [
            {
                index: 1,
                img: '/img/popup/popup_5_1.png',
                title: '強くマイナスを感じるマスの手前の境界線クリックし、始点を選択する。',
            },
            {
                index: 2,
                img: '/img/popup/popup_5_2.png',
                title: '陰陽反対側半分を俯瞰してみて、エネルギーがどの方向に向かっていくかを感じる。',
                title1:
                    '❶で選択した強くマイナスを感じる、内なる自己が「望んでいる！」と思うマスを見つけ、その境界線の内側をクリックする。',
                title2:
                    '4層目のマスを選択した場合、エネルギーが円の外側に突き抜けていると感じる時は外側の境界線をクリックする。',
            },
            {
                index: 3,
                img: '/img/popup/popup_5_3.png',
                title: 'このバイアスのエネルギーを4つのエネカラーから選ぶ。',
            },
            {
                index: 4,
                img: '/img/popup/popup_5_4.png',
                title: '線のスタイルの説明。',
            },
            {
                index: 5,
                img: '/img/popup/popup_5_5.png',
                title: '始点と終点のエネルギーの強さを合算してバイアスの強さの数値を入力する。',
                title1: '例：始点－80、終点＋70の場合、80＋70＝150',
            },
            {
                index: 'hide',
                img: '/img/popup/popup_5_6.png',
                title: '❺の数値に準じ、線の太さが変わります。',
            },
        ],
    },
    {
        index: 6,
        title: '主体',
        buttonText: '配置開始',
        titleEng: 'Identity',
        color: '#f15f90',
        colorBlur: 'rgba(241, 95, 144, 0.5)',
        memo1:
            'それぞれのバイアスの主体となる、内なる自己が「何者であるか？」を言語化し、「何を望んでいるか？、感じているか？」を吹き出しに書き込みます。',
        memo2: null,
        memo3: null,
        memo4: null,
        memo5: null,
        memo6: null,
        memo7: null,
        background:
            '-webkit-linear-gradient(45deg, rgba(241, 95, 144, 1) 0%, rgba(241, 95, 144, 0.5) 49%, rgba(241, 95, 144, 0.25) 50%, rgba(241, 95, 144, 0.75) 100%)',
        lightBubData: [
            {
                index: 1,
                img: '/img/popup/popup_6_1.png',
                title:
                    '主体が「プラスのエネルギー」、または「マイナスのエネルギー」を感じていると思うマスをクリックして選ぶ。',
                title1: 'エネルギーが枠の外側に突き抜けている時は枠の外側を選ぶ。',
            },
            {
                index: 2,
                img: '/img/popup/popup_6_2.png',
                title: '内なる自己のエネルギーを4つのエネカラーから選ぶ。',
            },
            {
                index: 3,
                img: '/img/popup/popup_6_3.png',
                title: '内なる自己に名前をつけ、「アイデンティティ・タグ」に入力する。',
            },
            {
                index: 4,
                img: '/img/popup/popup_6_4.png',
                title: '内なる自己がここで「何を言ってるか、感じているか？」を想像し、吹き出しに入力します。',
            },
            {
                index: 'hide',
                img: '/img/popup/popup_6_5.png',
                title: '吹き出しとエネカラーが付いた主体が配置される。',
            },
        ],
    },
    {
        index: 7,
        title: 'クリア',
        buttonText: '開始',
        titleEng: 'Resolved',
        color: '#f15b40',
        colorBlur: 'rgba(241, 91, 64, 0.5)',
        memo1: '解決済みだと感じるマスを選びます。',
        memo2: '陽のマス：「既に充分に出来ている」「人生で充分満たされてきている」というマスがあれば選びます。',
        memo3:
            '陰のマス：「確かに嫌だけど、解決済み」「その問題は起きてないし、今後も起きないし」というマスがあれば選びます。',
        memo4: null,
        memo5: null,
        memo6: null,
        memo7: null,
        background:
            '-webkit-linear-gradient(45deg, rgba(241, 91, 64, 1) 0%, rgba(241, 91, 64, 0.5) 49%, rgba(241, 91, 64, 0.25) 50%, rgba(241, 91, 64, 0.75) 100%)',
        lightBubData: [
            {
                index: 1,
                img: '/img/popup/popup_7_1.png',
                title: '解決済みと感じるマスをチェックします。',
            },
            {
                index: 2,
                img: '/img/popup/popup_7_2.png',
                title: 'クリアした感覚を感じたときのエネカラーのチェックマークを選びます。',
            },
            {
                index: 'hide',
                img: '/img/popup/popup_7_3.png',
                title: 'チェックが配置されます。',
            },
        ],
    },
    {
        index: 8,
        title: 'ブロック',
        buttonText: '配置開始',
        titleEng: 'Barrier',
        color: '#fdb64e',
        colorBlur: 'rgba(253, 182, 78, 0.5)',
        memo1:
            'メンタルブロック：すごく嫌だと思うマスがあり、ブロックをかけて跳ね返すようなエネルギーが発生している場所があれば、そのマスの手前の境界線上にブロックを置きます。',
        memo2:
            '乗り越えたい壁：陽から陽、陰から陽に抜けたいけどそこに壁があり、抜けきれない感じがするところがあれば、その壁がある境界線上にブロックを置きます。',
        memo3: null,
        memo4: null,
        memo5: null,
        memo6: null,
        memo7: null,
        background:
            '-webkit-linear-gradient(45deg, rgba(253, 182, 78, 1) 0%, rgba(253, 182, 78, 0.5) 49%, rgba(253, 182, 78, 0.25) 50%, rgba(253, 182, 78, 0.75) 100%)',
        lightBubData: [
            {
                img: '/img/popup/popup_8.png',
                title: 'ブロックは境界線上をクリックすることで配置できます。',
            },
        ],
    },
    {
        index: 9,
        title: '主体の選択',
        buttonText: '開始',
        titleEng: 'Core Roll',
        color: '#eadf47',
        colorBlur: 'rgba(234, 223, 71, 0.5)',
        memo1:
            'このテーマを扱う上で「いったい何者として自分はこのテーマを扱っていきたいのか？」という主体を、バイアスを発生させている複数の内なる自己の中から特に選択したいものを選びます。（最大で2～3つ）',
        memo2: null,
        memo3: null,
        memo4: null,
        memo5: null,
        memo6: null,
        memo7: null,
        background:
            '-webkit-linear-gradient(45deg, rgba(234, 223, 71, 1) 0%, rgba(234, 223, 71, 0.5) 49%, rgba(234, 223, 71, 0.25) 50%, rgba(234, 223, 71, 0.75) 100%)',
        lightBubData: [
            {
                index: 1,
                img: '/img/popup/popup_9_1.png',
                title:
                    '選択した主体のポーンをクリックし、その主体を選択した際に強いエネルギーを感じた身体の部位を選びます。',
            },
            {
                index: 2,
                img: '/img/popup/popup_9_2.png',
                title: '選択した主体にエネルギーのマークが付きます。',
            },
        ],
    },
    {
        index: 10,
        title: '変革の鍵',
        buttonText: '配置開始',
        titleEng: 'Key',
        color: '#aed361',
        colorBlur: 'rgba(174, 211, 97, 0.5)',
        memo1: '選択した内なる自己にとって、このテーマを扱う上で大事な鍵となるマスを選びます。',
        memo2: '陰のマス：「このマスを乗り越えるのが鍵」と感じる場所。',
        memo3: '陽のマス：「このマスを得ていくのが鍵」と感じる場所。',
        memo4: null,
        memo5: null,
        memo6: null,
        memo7: null,
        background:
            '-webkit-linear-gradient(45deg, rgba(174, 211, 97, 1) 0%, rgba(174, 211, 97, 0.5) 49%, rgba(174, 211, 97, 0.25) 50%, rgba(174, 211, 97, 0.75) 100%)',
        lightBubData: [
            {
                index: 1,
                img: '/img/popup/popup_10_1.png',
                title: '鍵となるマスをクリックし、その鍵を得た時の感情のエネカラーを選ぶ。',
            },
            {
                index: 2,
                img: '/img/popup/popup_10_2.png',
                title: '鍵が配置される。',
            },
        ],
    },
    {
        index: 11,
        title: 'プロセス',
        buttonText: '入力開始',
        titleEng: 'Process',
        color: '#8ec975',
        colorBlur: 'rgba(142, 201, 117, 0.5)',
        memo1: 'セッションでの気づきを、4ステップのプロセスとしてまとめます。',
        memo2:
            'AorBどちらか一つしか選べないとなると「葛藤」になりますが、Aの次にBをやればいいという風に、単なる順番であることが意外に多かったりします。「直感的」にどういう順番で何をしていくのが良いかを考えます。',
        memo3: null,
        memo4: null,
        memo5: null,
        memo6: null,
        memo7: null,
        background:
            '-webkit-linear-gradient(45deg, rgba(142, 201, 117, 1) 0%, rgba(142, 201, 117, 0.5) 49%, rgba(142, 201, 117, 0.25) 50%, rgba(142, 201, 117, 0.75) 100%)',
        lightBubData: [
            {
                img: '/img/popup/popup_11.png',
                title:
                    '1から4のプロセスを、ボトムアップ（1→2→3→4）やトップダウン（4→3→2→1）など、決めやすい順番で入力していきます。',
                title1: '次に、各プロセスの数字をクリックし、実行する上で発揮するエネカラーを選択しましょう。',
                title2: 'どのエネカラーで、各プロセスを実行するかが結果に影響します。',
            },
        ],
    },
    {
        index: 12,
        title: 'タスク',
        buttonText: '開始',
        titleEng: 'Task',
        color: '#8ed0b5',
        colorBlur: 'rgba(142, 208, 181, 0.5)',
        memo1: 'プロセスを実行する上で、具体的に何のために、何をしたらいいか、タスクレベルに落とし込みましょう。',
        memo2: null,
        memo3: null,
        memo4: null,
        memo5: null,
        memo6: null,
        memo7: null,
        background:
            '-webkit-linear-gradient(45deg, rgba(142, 208, 181, 1) 0%, rgba(142, 208, 181, 0.5) 49%, rgba(142, 208, 181, 0.25) 50%, rgba(142, 208, 181, 0.75) 100%)',
        lightBubData: [
            {
                img: '/img/popup/popup_12.png',
                title:
                    'このタスクを完了することで、プロセスが「本当に実行できるか？」を問いかけながら、リストを作成しましょう。',
            },
        ],
    },
];
