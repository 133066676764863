import React, {useEffect, useState} from 'react';
import {useInsightMaps} from "../../../context/InsightMindMapsContext";
import {useInsightMindUserContext} from "../../../context/InsightMindUserProvider";
import _ from "lodash";
import "./styles.css";
import Grid from "@material-ui/core/Grid";
import rtn from 'reactn'

const {useGlobal} = rtn

function TagsData(props) {
    const {classes, isFileManagerShow = false, isListView = false, map} = props;
    const {tagsShare} = useInsightMaps();

    const {tags} = useInsightMindUserContext();
    const [showTags, setShowTags] = useState([]);
    const [user] = useGlobal("user")
    useEffect(() => {
        initTagsShow()
    }, [map?.tag_id, tags, tagsShare]);

    const initTagsShow = () => {
        let tagIdArray = Object.values(map?.tag_id || {})
        let mapTagData = [];
        tagIdArray.forEach(tag => {
            let data = map?.owner === user.user_id ? tags : tagsShare
            let index = _.findIndex(data, t => `${t.id}` === `${tag}`);
            if (index !== -1)
                mapTagData.push(data[index])
        });
        setShowTags(_.orderBy(mapTagData, 'reg_date', 'desc'))
    };

    return (
        <>
            {
                !isFileManagerShow ?
                    <div className={`${classes.tableCell34} identity-tag`}>
                        <div className={classes.titleProcess}><span>アイデンティティ・タグ</span></div>
                        <div className={classes.contentBox}>
                            {
                                showTags.length !== 0 && showTags.map((tagInfo, idx) => {
                                    if (idx <= 5)
                                        return (
                                            <div key={idx} className={`show-tag-box ${tagInfo.color}`}>
                                                <label style={{borderColor: tagInfo.color}}> {tagInfo.content}</label>
                                            </div>
                                        )
                                    return null
                                })
                            }
                        </div>
                    </div>
                    :
                    <Grid container>
                        {
                            showTags.length !== 0 && showTags.map((tagInfo, idx) => {
                                if (idx <= 5)
                                    return (
                                        <Grid key={idx} item xs={isListView ? 4 : 6}
                                              className={`show-tag-box ${tagInfo.color}`}>
                                            <label style={{
                                                borderColor: tagInfo.color,
                                                width: "calc(100% - 5px)",
                                            }}> {tagInfo.content}</label>
                                        </Grid>
                                    )
                                return null
                            })
                        }
                    </Grid>
            }
        </>
    );
}

export default TagsData;
