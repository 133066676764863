export const getInviteEmailBody = ({nickname, email, url, homePage}) => {
    return `
    <div>
        <p>${nickname}(<a href="mailto:${email}">${email}</a>)
            からインサイトマインドのファイルへ招待がありました。</p>
        <p>インサイトマインドにログインした状態で、下記のURLをクリックしてください。</p>
        <p><a href="${url}">${url}</a></p>
        <br />
        <p>または、</p>
        <br />
        <p>ご自身でインサイトマインドのファイルを作成することもできます。</p>
        <p>もしインサイトマインドのアカウントをお持ちでない場合、</p>
        <p>こちらから登録をお願いします。</p>
        <p><a href="${homePage}">${homePage}</a></p>
        <br />
        <p>株式会社 ＷＯＷＷＡＹ</p>
    </div>
`
}
