import React from 'react';
import {Checkbox, Typography} from "@material-ui/core";

function CheckedShowHistoryIdentity(props) {
    const {classes, showHistoryIdentity, setShowHistoryIdentity} = props
    return (
        <div className={classes.formContent}>
            <div className={classes.formCheckbox}>
                <Checkbox
                    checked={showHistoryIdentity}
                    onChange={() => setShowHistoryIdentity(!showHistoryIdentity)}
                />
                <Typography>既存のアイデンティティ・タグを主に使用する</Typography>
            </div>
        </div>
    );
}

export default CheckedShowHistoryIdentity;
