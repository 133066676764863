import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {AppBarScreen} from "../LazyLoadComponent";
import Footer from "../Footer";

function Rule(props) {
    const classes = useStyles();
    return (
        <div>
            <AppBarScreen/>
            <div className={classes.root}>
                <div className={classes.body}>
                    <h4 className={classes.textHeader}>Insight Mind利用規約</h4>
                    <p>
                        本規約は、株式会社<span className={classes.text_in_line}>Andom</span>（以下「当社」といいます。）が提供する本サービスの利用者へのご提供と利用者のご利用の条件について定めるものです。本サービスの利用にあたっては、事前に本規約をよくお読みいただき本規約の内容を遵守することに同意いただくものとし、本サービスのご利用を開始した場合には、当該利用者は本規約を遵守することに同意したものとみなします。
                    </p>
                    <p>
                        なお、未成年の方は、本規約について親権者等の法定代理人の事前の同意を得た上で、本サービスのご利用を行ってください。
                    </p>
                    <h4 className={classes.text_title}>
                        第１条 【定義】
                    </h4>
                    <p>
                        １&emsp;「本サイト」とは、そのドメインを<span className={classes.text_in_line}>「insight-mind.com」</span>とする、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
                    </p>
                    <p>
                        ２&emsp;「本サービス」とは、当社が本サイトを通じて提供するサービス<span
                        className={classes.text_in_line}>「Insight Mind」</span>を意味します。
                    </p>
                    <p>
                        ３&emsp;「登録希望者」とは、本サービスの利用を希望し、登録を申し込んだ者又は登録を申し込もうとする者を意味します。
                    </p>
                    <p>
                        ４&emsp;「会員」とは、本規約に同意した登録希望者のうち当社から登録の承諾を受けた個人又は法人を意味します。
                    </p>
                    <p>
                        ５&emsp;「利用者」とは、会員又は非会員を問わず、本サイトの閲覧者を含む本サービスの提供を受ける個人又は法人を意味します。
                    </p>
                    <p>
                        ６&emsp;「登録情報」とは、会員登録手続で入力された一切の情報を意味します。
                    </p>
                    <p>
                        ７&emsp;「本契約」とは、本規約及び当社と利用者との間で締結する、本サービスの利用契約を意味します。
                    </p>
                    <p>
                        ８&emsp;「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。
                    </p>
                    <h4 className={classes.text_title}>
                        第２条 【規約の変更】
                    </h4>
                    <p>
                        １&emsp;当社は、当社の判断において、いつでも本規約の内容を変更又は追加できるものとします。変更後の利用規約は、当社が別途定める場合を除いて、当社サイトに掲示し、当社は当該変更の内容その他一切について利用者に対し個別の通知を行いません。
                    </p>
                    <p>
                        ２&emsp;利用者が本規約の変更後も本サービスの利用を継続する場合、当該利用者は、変更後の本規約に同意したものとみなされます。利用者は、変更後の本規約に同意しない場合は、直ちに本サービスの利用を停止し、会員においては退会を行うものとします。
                    </p>
                    <h4 className={classes.text_title}>第３条 【会員登録】</h4>
                    <p>
                        １&emsp; 登録希望者は、会員となる本人に限るものとし、代理人による登録申請は認められないものとします。</p>

                    <p>
                        ２&emsp; 登録希望者は、その登録情報の入力にあたり、入力した情報は全て真実であることを保証するものとします。</p>

                    <p>
                        ３&emsp; 登録した情報の正確性・真実性・最新性等内容の一切について、登録希望者自らが保証するものとします。</p>

                    <p>
                        ４&emsp; 登録希望者が未成年者である場合には、本サービスの利用の一切につき、親権者等の法定代理人の同意を得た上で本サービスの利用を申し込まなければなりません。</p>
                    <p>
                        ５&emsp;当社は、未成年者の会員による有償コンテンツの購入につき、年齢に応じて利用金額の上限又は支払方法等の制限を設けることがあります。未成年者の会員は当該制限に従って有償コンテンツを利用しなければなりません。</p>
                    <p>
                        ６&emsp;当社は、登録申請を行った個人又は法人が以下の各号に該当する場合、利用者として登録することを承諾しない場合があります。また、承諾・登録後であっても、会員が以下の各号に該当する事実が判明した場合には、承諾・登録を取り消す場合があります。これらの場合において、当社は理由の開示義務を一切負いません。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(1)&emsp;</span>入力された登録情報に虚偽の情報があることが判明した場合
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(2)&emsp;</span>当社からのメールが受信できない場合
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(3)&emsp;</span>本利用規約に違反する行為を行った場合
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(4)&emsp;</span>反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(5)&emsp;</span>その他当社が当該利用者の登録が不適当であると判断した場合
                    </p>
                    <p>
                        ７&emsp; 会員が登録に際して当社に提供した会員の個人情報については、別途各サービスごとに定めるプライバシーポリシー等に従って取り扱われるものとし、会員はこれに同意するものとします。
                    </p>
                    <p>
                        ８&emsp; 会員は、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。
                    </p>
                    <h4 className={classes.text_title}>第４条 【パスワード及びIDの管理】</h4>
                    <p>
                        １&emsp; 会員は、自己の責任において、本サービスに関するパスワード及び利用者IDを適切に管理及び保管するものとし、これを第三者に利用させ、又は貸与、譲渡、名義変更、売買等をしてはならないものとします。
                    </p>
                    <p>
                        ２&emsp; パスワード及び利用者IDの管理不十分、利用上の過誤、第三者の利用等によって生じた損害に関する責任は会員が負うものとし、当社は一切の責任は負いません。
                    </p>
                    <h4 className={classes.text_title}>第５条 【本サービスの利用】</h4>
                    <p>
                        １&emsp; 利用者は、本規約に規定された条件の下で、本サービスを利用することができます。ただし、非会員の利用者は本サービスのうち会員登録不要のサービスのみを利用することができるものとします。
                    </p>
                    <p>
                        ２&emsp; 本サービスが有償で提供されるものである場合、本条の利用の許諾は、当該料金の支払いを行うことを条件とします。
                    </p>
                    <p>
                        ３&emsp; 利用者は、有償・無償にかかわらず、当社から許諾された本サービスの利用権（会員としての地位を含む）を第三者に譲渡又は転貸したりすることはできません。また、本サービスに関するプログラム（オブジェクトコードであるとソースコードであるとを問いません）を複製したり又は第三者に開示したりすることはできません。
                    </p>
                    <p>
                        ４&emsp; 本サービスは、利用者が個人で利用する目的でのみ利用することができます。利用者は、当社が許諾した場合を除き、本サービス並びにそのコンテンツ及び本サービスに付随するサービスを、販売、配布又は開発などの目的若しくはその他営利目的（出版、類似サービスの提供、セミナーの開催等を含みますがこれらに限定されません）で利用することはできません。
                    </p>
                    <p>
                        ５&emsp; 利用者は、本サービスについて、当社から提供された状態でのみ利用するものとします。利用者は、本サービスの複製、修正、変更、改変又は翻案等の行為を行ってはならないものとします。
                    </p>
                    <p>
                        ６&emsp; 本サービスは、日本国及び他の関連国の不正競争防止法で保護されています。利用者は、本サービスの全部又は一部のリバースエンジニアリング、逆コンパイル又は逆アセンブルを行い、又はその他の方法でソースコードを抽出してはならないものとし、またいかなる方法であっても本サービスを機械読み取り可能な状態に改変、修正又は翻訳してはなりません。
                    </p>
                    <h4 className={classes.text_title}>第６条 【ご利用料金（有償の場合）】</h4>
                    <p>
                        １&emsp; 本サービスが有償で提供される場合、会員は、本サービス利用の対価として、別途当社が定め、本サイトに表示する利用料金を、当社が指定する支払方法により当社に支払うものとします。
                    </p>
                    <p>
                        ２&emsp; 利用者が利用料金の支払いを遅滞した場合、利用者は年１４．６％の割合による遅延損害金を当社に支払うものとします。
                    </p>
                    <p>
                        ３&emsp; 当社は、理由の如何にかかわらず、すでに支払われた代金を利用者に一切返還する義務を負いません。
                    </p>
                    <h4 className={classes.text_title}>第７条 【利用環境・稼働環境】</h4>
                    <p>
                        １&emsp; 本サービスを利用するために必要なソフトウェア・プログラム、携帯端末、通信機器、通信手段などは、利用者の費用と責任で備えるものとします。
                    </p>
                    <p>
                        ２&emsp; 次の場合、本サービスの利用が制限される場合があります。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(1)&emsp;</span>本サービスの一部には、本サービスの利用資格を確認することを目的とした、いわゆるアクティベーション機能を有するものがあり、利用者について、この機能を有する本サービスについて利用資格の確認ができない場合。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(2)&emsp;</span>インターネット接続ができない場所において本サービスを利用する場合。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(3)&emsp;</span>通信状況によってリアルタイム通信ができない場合。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(4)&emsp;</span>１年以上本サービスを利用しなかった場合。
                    </p>
                    <h4 className={classes.text_title}>第８条 【知的財産権】</h4>
                    <p>
                        本サイト及び本サービスに関する知的財産権は、全て当社又は当社にライセンスを許諾している者に帰属しており、これらの知的財産権は日本国及び関連諸国の著作権法及び著作権に関する条約、並びにその他知的財産権に関する法律及び条約によって保護されています。本規約に基づく本サービスの利用許諾は、本サイト及び本サービスに関する当社又は当社にライセンスを許諾している者の知的財産の取得又は利用許諾を意味するものではありません。
                    </p>
                    <h4 className={classes.text_title}>第９条 【免責条項】</h4>
                    <p>
                        １&emsp; 本サービスの内容、機能及び性能は、利用者が本サービスの利用を開始した時点において当社が合理的に提供可能な範囲のものに限定されます。当社は、本サービスを現状有姿にて利用者に提供するものであり、利用者に対し、本サービスがいかなる利用者の利用環境のもとでも正確に作動すること、すべての機能が発揮されることに関する一切の保証を行いません。また、本サービスが利用者の特定の目的に対して有用であること、及び商業的有用性を有していること、その他本サービスに関して一切の保証を行いません。
                    </p>
                    <p>
                        ２&emsp; 当社は、本サービス及び本サービスに付随するサービスに対する次の各号をはじめとする一切の保証を行いません。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(1)&emsp;</span>ＯＳのバージョンアップ等をしたことによって、本サービスに関し一切の不具合も問題も発生しないこと。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(2)&emsp;</span>本サービスが常に正しく機能すること、十分な品質を満たすこと。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(3)&emsp;</span>本サービスの利用に起因して利用者等の機器に不具合、誤作動や障害が生じないこと。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(4)&emsp;</span>本サービスが永続して利用できること。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(5)&emsp;</span>本サービスの利用に中断又はエラーが発生しないこと。
                    </p>
                    <p>
                        ３&emsp; 当社は、本サービスに対する利用者からの個別の問い合わせや利用方法に関する個別のサポートに応じる義務はございません。
                    </p>
                    <p>
                        ４&emsp; 本条の規定は、当社が本サービスに関して行う保証のすべてであり、本規約において明示されているものを除き一切の保証を行いません。
                    </p>
                    <p>
                        ５&emsp; 当社が本サービスを通じて提供する本サービスは、利用者に対する提案やアドバイスを提示するコンテンツを含みますが、利用者は、利用者自身の責任において本サービスから出力された提案やアドバイス等に基づいて行動するものとし、当該提案やアドバイス等に基づいて行動したことによって生ずる結果について、一切その責任を負いません。
                    </p>
                    <h4 className={classes.text_title}>第１０条 【禁止行為】</h4>
                    <p>利用者は、本サービスの利用にあたり、以下の行為を行わないものとします。</p>
                    <p>
                        <span className={classes.numberStyle}>(1)&emsp;</span>法令に違反する、又はそのおそれのある行為、あるいはそれに類似する行為。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(2)&emsp;</span>当社あるいは第三者を差別もしくは誹謗中傷し、又はその名誉、信用、プライバシー等の人格的・財産的権利を侵害する行為、又はそのおそれのある行為。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(3)&emsp;</span>個人情報その他第三者に関する情報を偽りその他不正な手段を用い収集、取得、開示、提供、改ざん、消去その他不正に利用する行為又はその類似行為。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(4)&emsp;</span>犯罪行為、犯罪行為をそそのかしもしくは容易にさせる行為、又はそれらのおそれのある行為。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(5)&emsp;</span>虚偽の情報を意図的に提供する行為、あるいはそれに類似する行為。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(6)&emsp;</span>公職選挙法に違反する行為、又はそのおそれのある行為。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(7)&emsp;</span>無限連鎖講（「ねずみ講」）あるいはそれに類似する行為、又はこれを勧誘する行為。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(8)&emsp;</span>わいせつ、児童売春、児童ポルノ、児童虐待にあたるコンテンツを発信する行為、児童の保護等に関する法律に違反する行為、又はその類似行為。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(9)&emsp;</span>風俗営業等の規制及び適正化に関する法律（以下、「風営適正化法」といいます。）が規定する映像送信型性風俗特殊営業、又はその類似行為。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(10)&emsp;</span>出会い系サイトの規制に関する規制法律等が規定するインターネット異性紹介事業、あるいはそれに類似する行為。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(11)&emsp;</span>第三者の通信に支障を与える方法あるいは態様において本サービスを利用する行為、あるいはそのおそれのある行為。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(12)&emsp;</span>当社あるいは第三者の運用するコンピュータ、電気通信設備等に不正にアクセスする行為、クラッキング行為、アタック行為、あるいはそれに類似する行為。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(13)&emsp;</span>第三者が嫌悪感を抱く、もしくはそのおそれのあるチャット又は電子メール（「嫌がらせメール」、「迷惑メール」）を送信する行為、及びそれに類似する行為。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(14)&emsp;</span>本サービスを通じて、コンピュータウィルス等を第三者に提供する行為、あるいはそのおそれのある行為。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(15)&emsp;</span>その他、他人の法的利益を侵害したり、公序良俗に反する方法あるいは態様において本サービスを利用する行為。
                    </p>
                    <h4 className={classes.text_title}>第１１条 【当社の監査】</h4>
                    <p>
                        当社は、利用者が本規約に基づいて本サービスの利用等を行っているか否かについて、合理的な証拠に基づいて疑念をもった場合、利用者からそれに関する報告を提出させることが出来るものとします。
                    </p>
                    <h4 className={classes.text_title}>第１２条 【本契約の終了】</h4>
                    <p>
                        １&emsp; 利用者は、本サービスの利用を停止することで何時にても本契約を終了することができます。ただし、利用者が会員である場合には、退会手続を行うことで初めて本契約を終了することができます。
                    </p>
                    <p>
                        ２&emsp; 当社は、利用者に以下の事由のどれかが生じた場合、何らの通知なくまた何らの賠償をすることなく、利用者に許諾された本サービスの利用権の全部又は一部を直ちに停止させ又は本契約を終了させることができるものとします。本契約が終了した場合、利用者は速やかに本サービスに関するプログラム及びデータを破棄するものとします。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(1)&emsp;</span>利用者が本規約のいずれかの条項に違反したか又はその恐れがあるとき。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(2)&emsp;</span>利用者が本サービスその他の取り扱いに関して、著作権法、特許法を含む法律又は条例の定めに違反したとき。
                    </p>
                    <p>
                        ３&emsp; 前項に基づく当社による本契約の終了は、利用者に対して当社が被った損失及び損害の賠償を請求する権利を妨げないものとします。
                    </p>
                    <h4 className={classes.text_title}>第１３条 【損害賠償】</h4>
                    <p>
                        １&emsp; 利用者が本規約に違反した場合、当社は利用者に対し損害賠償請求をすることができるものとします。
                    </p>
                    <p>
                        ２&emsp; 当社は、利用者が本サービスを利用したこと又は本サービスが利用できないことに起因して、利用者に生じたいかなる直接損害、間接損害、付随的・派生的損害、特別損害、逸失利益その他の損害（予見可能性の有無にかかわらない）について、一切その責任を負いません。
                    </p>
                    <p>
                        ３&emsp; 本規約又は個別規約等その他の規約（以下「本規約等」といいます。）が消費者契約法（平成１２年法律第６１号）第２条第３項の消費者契約に該当する場合には、前項の規定は適用しないものとします。但し、利用者に発生した損害が当社の故意又は重過失に基づく債務不履行又は不法行為と因果関係のある損害である場合に限ります。
                    </p>
                    <p>
                        ４&emsp; 前項の規定が適用される場合等の事情により、当社が利用者に対し損害賠償義務を負う場合における賠償額の上限は、その原因の如何を問わず、いかなる場合であっても、本サービスに対して利用者が当社に対して実際に支払った本サービスの利用料合計額を超えないものとします。
                    </p>
                    <h4 className={classes.text_title}>第１４条 【不可抗力】</h4>
                    <p>
                        本規約に基づく義務の不履行又は履行遅滞が、当社の制御可能下になく、当社の合理的な注意によって回避できない何らかの性質の事情（以下「不可抗力」という。）による場合、当社は、当該不履行又は遅滞の責任を負わないものとし、当該不履行又は遅滞は本規約の違反とは看做されないものとします。なお、当該不可抗力には、以下に限定されるものではありませんが、天災地変、政府又は政府機関の行為、法律・規則・命令の順守、火災、嵐、洪水、地震、津波、稲妻、台風、疫病、戦争（宣戦布告の有無を問わない）戦争状態、敵対行為、テロ、反乱、革命、暴動、爆発、海難、ストライキ、工場閉鎖、その他労働争議、エネルギー供給又は原材料の不足又は統制を含むものとします。
                    </p>
                    <h4 className={classes.text_title}>第１５条 【準拠法】</h4>
                    <p>
                        本規約は、法の適用法ルールにも関わらず、日本法に準拠し、同法に基づいて解釈されるものとします。
                    </p>
                    <h4 className={classes.text_title}>第１６条 【管轄裁判所】</h4>
                    <p>
                        本規約に関連して当社と利用者との間で生じた紛争については、東京地方裁判所を第一審の専属的合意裁判所とします。
                    </p>
                    <h4 className={classes.text_title}>第１７条 【その他】</h4>
                    <p>
                        １&emsp; 利用者は、本サービスが米国商務省の輸出規制又は日本、ＥＵその他関係国の同種の法又は規制に基づく輸出規制の対象となるものである可能性を認識していることを表明し保証するものとします。また、利用者は、当該法及び規制により要求される有効な輸出許可をまず取得しない限り、当社から本契約に基づき提供を受ける本サービス及びそれに関連する技術データや技術情報（以下に限定されないが、あらゆる技術情報、ソフトウェア、図面、取扱説明書、データ、書類等を含むものとします。）を利用者の国外に持ち出し、又は国外の第三者に提供しないことを表明し保証します。
                    </p>
                    <p>
                        ２&emsp; 利用者は、当社の書面による事前の同意のない限り、本ご利用契約上の地位及び本規約から生ずる権利の全部又は一部を第三者に譲渡し若しくは担保の用に供し、又は本規約から生ずる義務の全部又は一部を第三者に引き受けさせてはならないものとします。
                    </p>
                    <p>
                        ３&emsp; いずれかの当事者が本規約の条項のいずれか、又はこれに関する権利のいずれかの実行を行わず、又は本規約上の選択権の行使を行わない場合であっても、かかる条項・権利・選択権を放棄したものとみなされることはなく、また、いかなる意味でも、本規約の有効性に影響を与えないものとします。
                        また、いずれかの当事者がかかる条項、権利、又は選択権の行使を怠った場合であっても、後に同一又は他の条項、権利又は本規約上の選択権を実施し又は行使することを妨げないものとします。
                    </p>
                    <p>
                        ４&emsp; 本規約の条項のいずれかが、管轄権を有する裁判所によって違法又は無効と判断された場合であっても、本規約の残りの条項はなお有効であるものとします。
                    </p>
                    <p>
                        ５&emsp; 本規約は、本サービスの利用する権利の許諾に関して、当事者間の完全かつ唯一の合意を構成しており、当事者間に存している従前の書面・口頭による一切の合意は効力を失うものとします。
                    </p>
                    <p>【附則】</p>
                    <p>このご利用規約は、平成２９年４月３０日から適用します。</p>
                    <p>株式会社<span className={classes.text_in_line}>Andom</span></p>
                    <p>
                        <a className={classes.a_Style} onClick={() => {
                            window.location = "/"
                        }}> 戻る</a>
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#f7f7f7',
        paddingTop: 60,
        outline: 'none',
        fontFamily: "メイリオ Meiryo ヒラギノ角ゴ Pro W3 Hiragino Kaku Gothic Pro Verdana MS P Gothic ＭＳ Ｐゴシック sans-serif",
        overflowY: 'auto',
        overflowX: 'hidden',
        "& p": {
            margin: '0 0 10px'
        }
    },
    body: {
        padding: '20px 0',
        maxWidth: '60%',
        margin: 'auto',
        fontSize: 15
    },
    textHeader: {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '1.1px',
        color: "inherit",
        marginTop: 10,
        marginBottom: 20
    },
    text_in_line: {
        fontWeight: 500,
        lineHeight: '1.1px',
        color: "inherit",
    },
    text_title: {
        fontWeight: 600,
        fontSize: 15,
        lineHeight: '1.1px',
        color: "inherit",
        marginTop: 20,
    },
    numberStyle: {
        fontWeight: 600,

    },
    a_Style: {
        color: ' #337ab7',
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'revert'
        }
    },
}))
export default Rule;
