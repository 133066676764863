export const keyMap = {
    mapping_01: {
        x: '5',
        y: '65',
    },
    mapping_02: {
        x: '-40',
        y: '65',
    },
    mapping_03: {
        x: '115',
        y: '-45',
    },
    mapping_04: {
        x: '5',
        y: '175',
    },
    mapping_05: {
        x: '-40',
        y: '175',
    },
    mapping_06: {
        x: '-145',
        y: '-45',
    },
    mapping_07: {
        x: '146.67149999999998',
        y: '-205.56199999999998',
    },
    mapping_08: {
        x: '227.77783530759893',
        y: '-45',
    },
    mapping_09: {
        x: '222.781',
        y: '183.343',
    },
    mapping_10: {
        x: '5',
        y: '284.9936706151979',
    },
    mapping_11: {
        x: '-40',
        y: '285',
    },
    mapping_12: {
        x: '-242.77783530759893',
        y: '183.343',
    },
    mapping_13: {
        x: '-257.781',
        y: '-45',
    },
    mapping_14: {
        x: '-161.67149999999998',
        y: '-205.56199999999998',
    },
    mapping_15: {
        x: '99.194',
        y: '-354.887',
    },
    mapping_16: {
        x: '223.7191732',
        y: '-283.3535336',
    },
    mapping_17: {
        x: '304.9288092',
        y: '-176.2852316',
    },
    mapping_18: {
        x: '337.45461',
        y: '-44.99999999999997',
    },
    mapping_19: {
        x: '377.44350044004057',
        y: '118.388',
    },
    mapping_20: {
        x: '299.93476680000003',
        y: '261.1473464',
    },
    mapping_21: {
        x: '171.2852316',
        y: '356.5146184',
    },
    mapping_22: {
        x: '5',
        y: '395.02222',
    },
    mapping_23: {
        x: '-40',
        y: '395.0000008800812',
    },
    mapping_24: {
        x: '-192.90978213333335',
        y: '356.516',
    },
    mapping_25: {
        x: '-319.9288092',
        y: '261.12399999999997',
    },
    mapping_26: {
        x: '-405.02222',
        y: '118.38800000000003',
    },
    mapping_27: {
        x: '-367.44350044004057',
        y: '-45',
    },
    mapping_28: {
        x: '-329.93476680000003',
        y: '-176.291',
    },
    mapping_29: {
        x: '-238.7046158',
        y: '-283.34299999999996',
    },
    mapping_30: {
        x: '-124.19399999999999',
        y: '-354.887',
    },
    mapping_31: {
        x: '298.905',
        y: '-361.12399999999997',
    },
    mapping_32: {
        x: '449.98945102533',
        y: '-45',
    },
    mapping_33: {
        x: '376.12399999999997',
        y: '338.905',
    },
    mapping_34: {
        x: '5',
        y: '504.98945102533',
    },
    mapping_35: {
        x: '-40',
        y: '505',
    },
    mapping_36: {
        x: '-400.556725512665',
        y: '338.905',
    },
    mapping_37: {
        x: '-480.562',
        y: '-45',
    },
    mapping_38: {
        x: '-323.905',
        y: '-361.12399999999997',
    },
    mapping_39: {
        x: '560',
        y: '-45',
    },
    mapping_40: {
        x: '5',
        y: '615',
    },
    mapping_41: {
        x: '-40',
        y: '615',
    },
    mapping_42: {
        x: '-590',
        y: '-45',
    },
    mapping_43: {
        x: '5',
        y: '725',
    },
    mapping_44: {
        x: '-40',
        y: '725',
    },
};
