import {IS_PRODUCTION} from '../config/constants';
import {db} from '../firebaseConfig';
import rtn from "reactn";

export const isDevelopmentEnv = () => {
    return process.env.REACT_APP_ENV === 'development';
};
export const isProductionEnv = () => {
    return process.env.REACT_APP_ENV === 'production';
};
export const isDevStagingEnv = () => {
    return process.env.NODE_ENV === 'development' && process.env.REACT_APP_ENV === 'stg';
};
export const isNodeEnvProduction = () => {
    return process.env.NODE_ENV === 'production';
};

export function isStaging() {
    return process.env.REACT_APP_ENV === 'stg';
}

export const mapRef = (mapId) => {
    mapId = mapId || window.location.search.split('id=').pop().split('&')[0]
    const user = rtn.getGlobal().user
    if (!user || !user?.user_id || !mapId) {
        console.log('user not found, mapRef=null')
        return null
    }
    return db.doc(`${DB_COLL}/${user.user_id}/insightmaps/${mapId}`)
}

export const DB_COLL = IS_PRODUCTION ? "insightmind" : "insightmind-stg"
