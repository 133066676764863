import React from 'react';
import Grid from '@material-ui/core/Grid';
import BlockConflictInput from './BlockConflictInput';

function BlockLightBub(props) {
    const {classes, conflict, setConflict, value, handleSave} = props;

    return (
        <>
            {value.lightBubData.map((data, index) => {
                return (
                    <div className={classes.lightBub} key={index}>
                        <Grid container className={classes.gridLightBub}>
                            {value.index === 1 ? (
                                <Grid item md={8} sm={7} className={classes.gridLightBubLeft}>
                                    <BlockConflictInput
                                        conflict={conflict}
                                        setConflict={setConflict}
                                        handleSave={handleSave}
                                    />
                                </Grid>
                            ) : (
                                <Grid item md={8} sm={7} className={classes.gridLightBubLeft}>
                                    {data.index ? (
                                        data.index === 'hide' ? (
                                            ''
                                        ) : (
                                            <p className={classes.lead}>
                                                <span className="fa-stack icon-number">
                                                    <i>{data.index}</i>
                                                </span>
                                            </p>
                                        )
                                    ) : (
                                        <p className={classes.lead}>
                                            <span className={`${classes.faStack} fa-stack`}>
                                                <i className="fas fa-circle fa-stack-2x"/>
                                                <i className="fas fa-lightbulb fa-stack-1x fa-inverse"/>
                                            </span>
                                            入力とコツ
                                        </p>
                                    )}
                                    <p className={classes.lead}>
                                        {data.title} <br/> {data?.title1} <br/> {data?.title2}
                                    </p>
                                </Grid>
                            )}
                            <Grid item md={4} sm={5} className={classes.gridLightBubRight}>
                                <img src={data.img} alt="" className={classes.gridLightBubImg}/>
                            </Grid>
                        </Grid>
                    </div>
                );
            })}
            {value.index === 4 && (
                <div className={`${classes.quantizingColor} content clear`}>
                    <div className="tit">
                        <h4>【4色のエネカラー】</h4>
                        <img src="/img/btn-encolor.png" alt=""/>
                    </div>
                    <div className="content clear">
                        <div className="box">
                            <p>〈プラス〉</p>
                            <ul>
                                <li>快楽・体験・楽しいエネルギー</li>
                                <li>結果に向かって情熱とか熱くいくエネルギー</li>
                                <li>知識・思考、頭で冷静に探求していくエネルギー</li>
                                <li>人間関係を愛情とか配慮で大事にしていくエネルギー</li>
                            </ul>
                        </div>
                        <div className="box">
                            <p>〈マイナス〉</p>
                            <ul>
                                <li> 怠惰なエネルギー</li>
                                <li>怒りのエネルギー</li>
                                <li>批判のエネルギー</li>
                                <li>不安のエネルギー</li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default BlockLightBub;
