import React, {useState} from 'react';

function MemoData(props) {
    const {classes, map, updateField, disabled = false} = props;
    const [memo, setMemo] = useState(map?.memo || '');

    const handleBlur = () => {
        if (disabled || (!memo && !map?.memo) || map?.memo === memo)
            return null
        updateField('memo', memo, null, true)
    };
    return (
        <div>
            <div className={classes.titleProcess}><span>メモ</span></div>
            <div className={`${classes.contentBox}`}>
                <textarea
                    // type="text"
                    value={memo}
                    rows={10}
                    onChange={(e) => {
                        if (disabled)
                            return
                        setMemo(e.target.value)
                    }}
                    onBlur={handleBlur}
                    className={classes.textArea}
                />
            </div>
        </div>
    );
}

export default MemoData;
