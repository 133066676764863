import React from 'react';
import {Grid} from "@material-ui/core";

function ListIdentities(props) {
    const {classes, listIdentities, color, setColor} = props;

    return (
        <div className={classes.listIdentities}>
            <Grid container spacing={4} justify={'center'}>
                {listIdentities.map((item) => {
                    return (
                        <Grid
                            item
                            xs={2}
                            className={classes.identityImg}
                            onClick={() => {
                                setColor(item);
                            }}
                            key={item}
                        >
                            <img
                                className={classes.iconIden}
                                alt={item}
                                src={`img/pawn/${item}.png`}
                            />
                            <div style={{backgroundColor: item, width: color === item ? "40px" : "0px"}}
                                 className={classes.identityImgBottom}/>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
}

export default ListIdentities;
