import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {DialogActions, IconButton, InputAdornment, TextField, Typography} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import rtn from "reactn";
import IdentityTagList from "./IdentityTagList";
import {db} from "../../../firebaseConfig";
import {DB_COLL} from "../../../common/envSettings";
import ButtonCustom from "../../ButtonCustom";
import moment from "moment";

const {useGlobal} = rtn


function EditNewIdentityTag({items}) {
    const classes = useStyles()
    const [user] = useGlobal('user')
    const listIdentities = ['gray', 'yellow', 'red', 'blue', 'green'];
    const [text, setText] = useState(items.content || '');
    const [color, setColor] = useState(items.color || 'gray');
    const [openEditIdentityTag, setOpenEditIdentityTag] = rtn.useGlobal('openEditIdentityTag');


    useEffect(() => {
        setColor(items.color)
        setText(items.content)

    }, [items])

    const handleClose = () => {
        setOpenEditIdentityTag(false)
    };


    const onSave = async () => {
        if (!text)
            return
        if (!openEditIdentityTag || !items) return null
        let tag_id = items.tag_id
        try {
            let tagDocRef = db.doc(`${DB_COLL}/${user.user_id}/tags/${tag_id}`)

            await tagDocRef.set({
                color: color,
                content: text,
                updatedAt: moment().format(),
            }, {merge: true})

            setOpenEditIdentityTag(false)

        } catch (e) {
            console.log(e);
        } finally {
            setOpenEditIdentityTag(false)
            setText('')
        }
    }
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onSave && onSave(e.target.value);
        }
    }

    return (
        <Dialog
            className={classes.root}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openEditIdentityTag}>
            <DialogTitle>
                <Typography className={classes.title_dialog}>
                    アイデンティティ・タグの管理
                </Typography>
                <IconButton className={classes.close}
                            onClick={handleClose}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div className={classes.marginTop30}>
                    <IdentityTagList
                        classes={classes}
                        color={color}
                        setColor={setColor}
                        listIdentities={listIdentities}
                    />
                </div>
                <div className={classes.formContent}>
                    <Typography className={classes.marginBottom20}>吹き出しの言葉</Typography>
                    <TextField
                        onKeyPress={handleKeyPress}
                        autoFocus={true}
                        fullWidth
                        variant={'outlined'}
                        className={classes.textField}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img alt={'penIco'} src={'img/icon/pawn.png'}/>
                                </InputAdornment>
                            )
                        }}
                        placeholder={"Tag name"}
                        value={text ? text : ""}
                        onChange={(e) => {
                            if (e.target.value?.length > 30) return null;
                            setText(e.target.value);
                        }}
                    />
                </div>

            </DialogContent>
            <DialogActions className={classes.actionsContainer}>
                <ButtonCustom
                    className={classes.button}
                    onClick={onSave}
                    disabled={!text}
                    backgroundColor={"rgba(43, 187, 173, 1)"}
                    backgroundBlur={"rgba(43, 187, 173, 0.5)"}
                >
                    OK
                </ButtonCustom>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiDialogTitle-root": {
            background: "-webkit-linear-gradient(45deg, rgba(43, 187, 173, 1) 0%, rgba(43, 187, 173, 0.5) 49%, rgba(43, 187, 173, 0.25) 50%, rgba(43, 187, 173, 0.75) 100%)",
            color: "white",
            fontWeight: "bold",
            padding: 15,
            position: 'relative'
        },
        width: '100%',
        '& .MuiDialog-paper': {
            maxWidth: '600px',
            position: 'absolute',
            top: "50px",
            right: 0,
            left: 0,
            margin: "auto",
            [theme.breakpoints.down('sm')]: {
                left: 10,
                right: 10,
                minWidth: "auto",
            }
        }
    },
    title_dialog: {
        fontSize: 18
    },
    close: {
        // float: "right",
        padding: 0,
        position: "absolute",
        top: 15,
        right: 10
    },
    dialog_body: {
        maxHeight: 'calc(100vh - 200px)',
        "& li": {
            display: "inline-block",
            margin: '0 2px',
            cursor: "pointer",
        },

    },
    formContent: {
        marginTop: 30,
        marginBottom: 10,
        padding: '0px 10px',
        minWidth: 400,
    },
    textField: {
        '& input': {
            // textAlign: 'center',
            padding: 10,
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#66afe9",
            outline: 0
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
            borderColor: "#66afe9",
        }
    },
    button: {
        backgroundColor: '#2bbbad',
        color: 'white',
        fontWeight: 'bold',
        margin: '10px auto',
        boxShadow: '0 4px rgba(43, 187, 173, 0.5)',
    },
    actionsContainer: {
        backgroundColor: '#f0f0f0',
    },
    IdentityTagSelected: {
        position: "absolute",
        top: "78px",
        left: "26px",
        height: "6px",
        transition: "all 200ms ease-in-out 0s"
    },
    list_Identities: {
        // display:"flex",
        // justifyContent: "center"
        marginBottom: 10,
    },
    identityTagImg: {
        position: "relative",
        textAlign: "center",
        '&:hover': {
            cursor: 'pointer',
        },
        paddingBottom: 10,
    },
    identityTagImgBottom: {
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
        height: "6px",
        transition: "all 200ms ease-in-out 0s"
    },
    marginTop30: {
        marginTop: 30
    },
    marginBottom20: {
        marginBottom: 20
    }

}))

export default EditNewIdentityTag;
