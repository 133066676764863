import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {dataMenu} from './common/fetchData';
import Tooltip from '@material-ui/core/Tooltip';
import DialogGuideComponent from './DialogGuideComponent';
import rtn from 'reactn';
import {useInsightMaps} from "../../context/InsightMindMapsContext";
import _ from "lodash";

// const {useGlobal} = globalValue.default;

const useStyles = makeStyles((theme) => ({
    divRoot: {
        width: '100%',
        height: 'auto',
        minHeight: '0px',
        marginTop: '64px',
        position: 'relative',
        cursor: 'pointer',
        textAlign: 'center',
    },
    // menuLeft: {
    //     height: "calc(100% - 60px)",
    //     width: "70px !important",
    //     backgroundColor: "transparent",
    //     position: "fixed",
    //     overflow: "initial"
    // },
    gridContainer: {
        backgroundColor: '#e0e0e0',
        overflow: 'hidden',
        zIndex: 4,
        [theme.breakpoints.down('sm')]: {
            // position: "static !important"
        }
    },
    gridItem: {
        borderRight: '1.5pt solid #f5f5f5',
        padding: '0 2px 2px',
    },
    blockItem: {
        // display: "flex"
        backgroundColor: '#e0e0e0',
    },
    blockItemLeft: {
        // display: "flex"
        backgroundColor: 'none',
    },
    blockItemHover: {
        backgroundColor: 'rgba(164, 164, 164, 0.8)',
    },
    blockNumber: {
        backgroundColor: '#f5f5f5',
        border: '1px solid #e0e0e0',
        fontFamily: 'Rubik',
        lineHeight: '30px',
        fontSize: '1.25em',
    },
    blockHeader: {
        lineHeight: '55px',
        fontWeight: 600,
    },
    blockImage: {
        width: '70px',
        height: '70px',
        borderRadius: '50%',
        // -webkit-border-radius: 50%;
        // -moz-border-radius: 50%;
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            backgroundColor: "#f5f5f5"
        },
    },

    blockTitle: {
        position: 'relative',
        display: 'inline-block',
        fontWeight: 600,
        width: '100%',
        lineHeight: '24px',
        backgroundColor: '#f5f5f5',
    },
    tooltipCustom: {
        display: 'block',
        left: 0,
        right: 0,
        bottom: 0,
        width: "70px",
    },
    tooltipCustomFixed: {
        width: "100%",
        height: "25%",
        position: "relative"
    },
    blockImageFixed: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
// -webkit-border-radius: 50%;
// -moz-border-radius: 50%;
        margin: "0 auto",
        transform: "translateY(-50%)",
        position: "inherit",
        left: 0,
        top: "50%",
        [theme.breakpoints.down('md')]: {
            backgroundColor: "#f5f5f5"
        },
    },
    fixedContainer: {
        position: "fixed !important",
        width: "70px",
        top: "64px",
        left: 0,
        display: "block",
        bottom: 0,
        [theme.breakpoints.down('sm')]: {
            position: "absolute !important",
            minHeight: "calc(100vh - 60px)",
            top: 0,
        },
    },
    listFixed: {
        height: "33%",
        width: "100%"
    },
    gridContainerScroll: {
        overflow: "auto",
        minHeight: 550
    }
}));

function MenuMapsBar(props) {
    const {conflict, setConflict, selectStep, setSelectStep} = props;
    const classes = useStyles();
    const [hoverImg, setHoverImg] = useState(null);
    const [hoverElement, setHoverElement] = useState(null);
    const [mapDialogInfo, setMapDialogInfo] = useState(null);
    // const [arrangement] = rtn.useGlobal("arrangement")
    const [insightMindUser = {}] = rtn.useGlobal('insightMindUser');
    const {map, updateField} = useInsightMaps();

    const isSelected = (item) => {
        return item.name === selectStep;
    };

    const isHaveData = (item) => {
        if (!map)
            return false;
        if (item.name === 'conflict' || item.name === 'mapping')
            return Boolean(map['map']) && !_.isEmpty(map['map']);
        if (item.name === "borderline")
            return Boolean(map['line']) && !_.isEmpty(map['line']);
        if (item.name === "space")
            return Boolean(map['vector']) && !_.isEmpty(map['vector']);
        if (item.name === "removing")
            return Boolean(map['clear']) && !_.isEmpty(map['clear']);
        if (item.name === "finding")
            return Boolean(map['identity']) && isIdentityFinding(map);
        return Boolean(map[item.name]) && !_.isEmpty(map[item.name])
    };

    const isIdentityFinding = (data) => {
        let result = false;
        if (data?.identity) {
            Object.values(data.identity).forEach(value => {
                if (value.includes("heart,") || value.includes("diamond,") || value.includes("flame,"))
                    result = true;
            })
        }
        return result
    };
    return (
        <div className={"print-hidden"}>
            <div className={classes.divRoot}>
                <Grid
                    id="menu-maps-bar" container
                    className={!Boolean(insightMindUser?.menu_position) ? classes.gridContainer : classes.fixedContainer}
                >
                    {dataMenu.map((menu, indexData) => {
                        if (!Boolean(insightMindUser?.menu_position))
                            return (
                                <Grid item md={4} xs={12} className={classes.gridItem} key={indexData}>
                                    <Grid container>
                                        {menu.data.map((m, idx) => {
                                            return (
                                                <Tooltip title={m.headerText} className={classes.tooltipCustom}
                                                         key={idx}>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        className={
                                                            isSelected(m) || hoverElement === m.index
                                                                ? classes.blockItemHover
                                                                : classes.blockItem
                                                        }
                                                        onMouseEnter={() => {
                                                            setHoverElement(m.index);
                                                            setHoverImg(m.index)
                                                        }}
                                                        onMouseLeave={() => {
                                                            setHoverElement(null);
                                                            setHoverImg(null)
                                                        }}
                                                        onClick={() => {
                                                            setSelectStep(m.name);
                                                            updateField('step', {block: m.name, sel: true}, null, true);
                                                            if (Boolean(insightMindUser?.show_guide))
                                                                setMapDialogInfo(m);
                                                        }}
                                                    >
                                                        <div
                                                            className={`scrollHidden ${classes.blockNumber}`}>{m.index}</div>
                                                        <div className={`scrollHidden ${classes.blockHeader}`}>
                                                            {m.headerText}
                                                        </div>
                                                        <img
                                                            src={isHaveData(m) || isSelected(m) || hoverImg === m.index ? m.imgUrlHover : m.imgUrl}
                                                            className={classes.blockImage}
                                                            alt=""
                                                        />
                                                    </Grid>
                                                </Tooltip>
                                            );
                                        })}
                                    </Grid>
                                    <div className={`scrollHidden ${classes.blockTitle}`}>{menu.title}</div>
                                </Grid>
                            );
                        return (
                            <div className={`${classes.listFixed}`} key={indexData}>
                                {
                                    menu.data.map((m, index) => {
                                        return (
                                            <Tooltip key={index} title={m.headerText}
                                                     className={classes.tooltipCustomFixed}
                                                     placement={'right'}>
                                                <div
                                                    key={index}
                                                    className={isSelected(m) || hoverElement === m.index ? classes.blockItemHover : classes.blockItemLeft}
                                                    onMouseEnter={() => {
                                                        setHoverElement(m.index);
                                                        setHoverImg(m.index)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHoverElement(null);
                                                        setHoverImg(null)
                                                    }}
                                                    onClick={() => {
                                                        setSelectStep(m.name);
                                                        updateField('step', {block: m.name, sel: true}, null, true);
                                                        if (Boolean(insightMindUser?.show_guide))
                                                            setMapDialogInfo(m);
                                                    }}
                                                >
                                                    <img
                                                        src={isHaveData(m) || isSelected(m) || hoverImg === m.index ? m.imgUrlHover : m.imgUrl}
                                                        className={classes.blockImageFixed}
                                                        alt=""
                                                    />
                                                </div>
                                            </Tooltip>
                                        )
                                    })
                                }
                            </div>
                        );
                    })}
                </Grid>
                {
                    Boolean(mapDialogInfo) &&
                    <DialogGuideComponent
                        conflict={conflict}
                        setConflict={setConflict}
                        mapDialogInfo={mapDialogInfo}
                        setMapDialogInfo={setMapDialogInfo}
                        setSelectStep={setSelectStep}
                    />
                }
            </div>
        </div>
    );
}

export default MenuMapsBar;
