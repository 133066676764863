import {dotMaps, dotMapsAdvance, dotMiniMaps} from "./dot";
import _ from 'lodash'

export const getStraight = function () {
    let lines = new Map();
    lines.set('01', ['02', '05', '06', '07', '08', '13', '14', '15', '16', '17', '18', '19', '20', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '85', '86', '87', '88', '89', '90', '91', '92', '97', '98', '99', '100', '103', '104']);
    lines.set('02', ['01', '05', '06', '07', '08', '13', '14', '15', '16', '17', '18', '19', '20', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '85', '86', '87', '88', '89', '90', '91', '92', '97', '98', '99', '100', '103', '104']);
    lines.set('05', ['01', '13', '14', '29', '30', '31', '32', '61', '62', '63', '64', '85', '86', '97', '103', '07', '99']);
    lines.set('06', ['01', '15', '16', '33', '34', '35', '36', '65', '66', '67', '68', '87', '88', '98', '103', '08', '100']);
    lines.set('07', ['02', '17', '18', '37', '38', '39', '40', '69', '70', '71', '72', '89', '90', '99', '104', '05', '97']);
    lines.set('08', ['02', '19', '20', '41', '42', '43', '44', '73', '74', '75', '76', '91', '92', '100', '104', '06', '98']);
    lines.set('13', ['01', '05', '14', '29', '30', '31', '32', '61', '62', '63', '64', '85', '86', '97', '103', '17', '89']);
    lines.set('14', ['01', '05', '13', '29', '30', '31', '32', '61', '62', '63', '64', '85', '86', '97', '103', '18', '90']);
    lines.set('15', ['01', '06', '16', '33', '34', '35', '36', '65', '66', '67', '68', '87', '88', '98', '103', '19', '91']);
    lines.set('16', ['01', '06', '15', '33', '34', '35', '36', '65', '66', '67', '68', '87', '88', '98', '103', '20', '92']);
    lines.set('17', ['02', '07', '18', '37', '38', '39', '40', '69', '70', '71', '72', '89', '90', '99', '104', '13', '85']);
    lines.set('18', ['02', '07', '17', '37', '38', '39', '40', '69', '70', '71', '72', '89', '90', '99', '104', '14', '86']);
    lines.set('19', ['02', '08', '20', '41', '42', '43', '44', '73', '74', '75', '76', '91', '92', '100', '104', '15', '87']);
    lines.set('20', ['02', '08', '19', '41', '42', '43', '44', '73', '74', '75', '76', '91', '92', '100', '104', '16', '88']);
    lines.set('29', ['01', '05', '13', '14', '30', '31', '32', '61', '62', '63', '64', '85', '86', '97', '103', '37', '69']);
    lines.set('30', ['01', '05', '13', '14', '29', '31', '32', '61', '62', '63', '64', '85', '86', '97', '103', '38', '70']);
    lines.set('31', ['01', '05', '13', '14', '29', '30', '32', '61', '62', '63', '64', '85', '86', '97', '103', '39', '71']);
    lines.set('32', ['01', '05', '13', '14', '29', '30', '31', '61', '62', '63', '64', '85', '86', '97', '103', '40', '72']);
    lines.set('33', ['01', '06', '15', '16', '34', '35', '36', '65', '66', '67', '68', '87', '88', '98', '103', '41', '73']);
    lines.set('34', ['01', '06', '15', '16', '33', '35', '36', '65', '66', '67', '68', '87', '88', '98', '103', '42', '74']);
    lines.set('35', ['01', '06', '15', '16', '33', '34', '36', '65', '66', '67', '68', '87', '88', '98', '103', '43', '75']);
    lines.set('36', ['01', '06', '15', '16', '33', '34', '35', '65', '66', '67', '68', '87', '88', '98', '103', '44', '76']);
    lines.set('37', ['02', '07', '17', '18', '38', '39', '40', '69', '70', '71', '72', '89', '90', '99', '104', '29', '61']);
    lines.set('38', ['02', '07', '17', '18', '37', '39', '40', '69', '70', '71', '72', '89', '90', '99', '104', '30', '62']);
    lines.set('39', ['02', '07', '17', '18', '37', '38', '40', '69', '70', '71', '72', '89', '90', '99', '104', '31', '63']);
    lines.set('40', ['02', '07', '17', '18', '37', '38', '39', '69', '70', '71', '72', '89', '90', '99', '104', '32', '64']);
    lines.set('41', ['02', '08', '19', '20', '42', '43', '44', '73', '74', '75', '76', '91', '92', '100', '104', '33', '65']);
    lines.set('42', ['02', '08', '19', '20', '41', '43', '44', '73', '74', '75', '76', '91', '92', '100', '104', '34', '66']);
    lines.set('43', ['02', '08', '19', '20', '41', '42', '44', '73', '74', '75', '76', '91', '92', '100', '104', '35', '67']);
    lines.set('44', ['02', '08', '19', '20', '41', '42', '43', '73', '74', '75', '76', '91', '92', '100', '104', '36', '68']);
    lines.set('61', ['01', '05', '13', '14', '29', '30', '31', '32', '62', '63', '64', '85', '86', '97', '103', '37', '69']);
    lines.set('62', ['01', '05', '13', '14', '29', '30', '31', '32', '61', '63', '64', '85', '86', '97', '103', '38', '70']);
    lines.set('63', ['01', '05', '13', '14', '29', '30', '31', '32', '61', '62', '64', '85', '86', '97', '103', '39', '71']);
    lines.set('64', ['01', '05', '13', '14', '29', '30', '31', '32', '61', '62', '63', '85', '86', '97', '103', '40', '72']);
    lines.set('65', ['01', '06', '15', '16', '33', '34', '35', '36', '66', '67', '68', '87', '88', '98', '103', '41', '73']);
    lines.set('66', ['01', '06', '15', '16', '33', '34', '35', '36', '65', '67', '68', '87', '88', '98', '103', '42', '74']);
    lines.set('67', ['01', '06', '15', '16', '33', '34', '35', '36', '65', '66', '68', '87', '88', '98', '103', '43', '75']);
    lines.set('68', ['01', '06', '15', '16', '33', '34', '35', '36', '65', '66', '67', '87', '88', '98', '103', '44', '76']);
    lines.set('69', ['02', '07', '17', '18', '37', '38', '39', '40', '70', '71', '72', '89', '90', '99', '104', '29', '61']);
    lines.set('70', ['02', '07', '17', '18', '37', '38', '39', '40', '69', '71', '72', '89', '90', '99', '104', '30', '62']);
    lines.set('71', ['02', '07', '17', '18', '37', '38', '39', '40', '69', '70', '72', '89', '90', '99', '104', '31', '63']);
    lines.set('72', ['02', '07', '17', '18', '37', '38', '39', '40', '69', '70', '71', '89', '90', '99', '104', '32', '64']);
    lines.set('73', ['02', '08', '19', '20', '41', '42', '43', '44', '74', '75', '76', '91', '92', '100', '104', '33', '65']);
    lines.set('74', ['02', '08', '19', '20', '41', '42', '43', '44', '73', '75', '76', '91', '92', '100', '104', '34', '66']);
    lines.set('75', ['02', '08', '19', '20', '41', '42', '43', '44', '73', '74', '76', '91', '92', '100', '104', '35', '67']);
    lines.set('76', ['02', '08', '19', '20', '41', '42', '43', '44', '73', '74', '75', '91', '92', '100', '104', '36', '68']);
    lines.set('85', ['01', '05', '13', '14', '29', '30', '31', '32', '61', '62', '63', '64', '86', '97', '103', '17', '89']);
    lines.set('86', ['01', '05', '13', '14', '29', '30', '31', '32', '61', '62', '63', '64', '85', '97', '103', '18', '90']);
    lines.set('87', ['01', '06', '15', '16', '33', '34', '35', '36', '65', '66', '67', '68', '88', '98', '103', '19', '91']);
    lines.set('88', ['01', '06', '15', '16', '33', '34', '35', '36', '65', '66', '67', '68', '87', '98', '103', '20', '92']);
    lines.set('89', ['02', '07', '17', '18', '37', '38', '39', '40', '69', '70', '71', '72', '90', '99', '104', '13', '85']);
    lines.set('90', ['02', '07', '17', '18', '37', '38', '39', '40', '69', '70', '71', '72', '89', '99', '104', '14', '86']);
    lines.set('91', ['02', '08', '19', '20', '41', '42', '43', '44', '73', '74', '75', '76', '92', '100', '104', '15', '87']);
    lines.set('92', ['02', '08', '19', '20', '41', '42', '43', '44', '73', '74', '75', '76', '91', '100', '104', '16', '88']);
    lines.set('97', ['01', '05', '13', '14', '29', '30', '31', '32', '61', '62', '63', '64', '85', '86', '103', '07', '99']);
    lines.set('98', ['01', '06', '15', '16', '33', '34', '35', '36', '65', '66', '67', '68', '87', '88', '103', '08', '100']);
    lines.set('99', ['02', '07', '17', '18', '37', '38', '39', '40', '69', '70', '71', '72', '89', '90', '104', '05', '97']);
    lines.set('100', ['02', '08', '19', '20', '41', '42', '43', '44', '73', '74', '75', '76', '91', '92', '104', '06', '98']);
    lines.set('103', ['01', '05', '06', '13', '14', '15', '16', '29', '30', '31', '32', '33', '34', '35', '36', '61', '62', '63', '64', '65', '66', '67', '68', '85', '86', '87', '88', '97', '98', '02', '104']);
    lines.set('104', ['02', '07', '08', '17', '18', '19', '20', '37', '38', '39', '40', '41', '42', '43', '44', '69', '70', '71', '72', '73', '74', '75', '76', '89', '90', '91', '92', '99', '100', '01', '103']);
    return lines;
}


const getOpposite = function () {
    let lines = new Map();
    lines.set('05', ['17', '18', '37', '38', '39', '40', '69', '70', '71', '72', '89', '90']);
    lines.set('06', ['19', '20', '41', '42', '43', '44', '73', '74', '75', '76', '91', '92']);
    lines.set('07', ['13', '14', '29', '30', '31', '32', '61', '62', '63', '64', '85', '86']);
    lines.set('08', ['15', '16', '33', '34', '35', '36', '65', '66', '67', '68', '87', '88']);
    lines.set('17', ['05']);
    lines.set('18', ['05']);
    lines.set('37', ['05']);
    lines.set('38', ['05']);
    lines.set('39', ['05']);
    lines.set('40', ['05']);
    lines.set('69', ['05']);
    lines.set('70', ['05']);
    lines.set('71', ['05']);
    lines.set('72', ['05']);
    lines.set('89', ['05']);
    lines.set('90', ['05']);
    lines.set('19', ['06']);
    lines.set('20', ['06']);
    lines.set('41', ['06']);
    lines.set('42', ['06']);
    lines.set('43', ['06']);
    lines.set('44', ['06']);
    lines.set('73', ['06']);
    lines.set('74', ['06']);
    lines.set('75', ['06']);
    lines.set('76', ['06']);
    lines.set('91', ['06']);
    lines.set('92', ['06']);
    lines.set('13', ['07']);
    lines.set('14', ['07']);
    lines.set('29', ['07']);
    lines.set('30', ['07']);
    lines.set('31', ['07']);
    lines.set('32', ['07']);
    lines.set('61', ['07']);
    lines.set('62', ['07']);
    lines.set('63', ['07']);
    lines.set('64', ['07']);
    lines.set('85', ['07']);
    lines.set('86', ['07']);
    lines.set('15', ['08']);
    lines.set('16', ['08']);
    lines.set('33', ['08']);
    lines.set('34', ['08']);
    lines.set('35', ['08']);
    lines.set('36', ['08']);
    lines.set('65', ['08']);
    lines.set('66', ['08']);
    lines.set('67', ['08']);
    lines.set('68', ['08']);
    lines.set('87', ['08']);
    lines.set('88', ['08']);
    return lines;
}

export const isStraight = (start, end) => {
    const straightLine = getStraight().get(start)
    return straightLine?.includes(end)

}
export const isOpposite = (start, end) => {
    const opposite = getOpposite().get(start)
    return opposite?.includes(end)

}

export const getVector = ({head, rail, vcolor, style, width}, mapOne = false, miniMap = false) => {
    const maps = mapOne ? dotMapsAdvance : miniMap ? dotMiniMaps : dotMaps
    const dotStart = head.split("_")[1]
    const dotEnd = rail.split("_")[1]
    const isStraightLine = isStraight(dotStart, dotEnd)
    const isOppositeLine = isOpposite(dotStart, dotEnd)
    let end = parseInt(dotEnd)
    let path = ''
    let color = "#a3b3b3"
    let stroke_width = 10
    let strokeDasharray = [0, 0]
    let maker = `url(#arrow_${vcolor})`
    const center = 0
    switch (vcolor) {
        case 'gray':
            color = '#a3b3b3';
            break;
        case 'yellow':
            color = '#f1c500';
            break;
        case 'red':
            color = '#e84c3d';
            break;
        case 'blue':
            color = '#267ebe';
            break;
        case 'green':
            color = '#27ae61';
            break;
    }
    if (width) {
        switch (true) {
            case (parseInt(width) <= 10):
                stroke_width = 10;
                break;
            case (parseInt(width) > 10 && parseInt(width) <= 100):
                stroke_width = 15;
                break;
            case (parseInt(width) > 100 && parseInt(width) <= 200):
                stroke_width = 20;
                break;
            case (parseInt(width) > 200 && parseInt(width) <= 300):
                stroke_width = 25;
                break;
            case (parseInt(width) > 300 && parseInt(width) <= 400):
                stroke_width = 30;
                break;
        }
    }
    if (style === "dot") {
        strokeDasharray = [0, stroke_width + 5]
    }
    if (parseInt(dotEnd) > 60 && !mapOne) {
        end += 20
    }
    const dotStartIdx = _.findIndex(maps, {id: dotStart})
    const dotEndIdx = _.findIndex(maps, {id: end < 10 ? `0${end}`.slice(-2) : `${end}`})
    if (dotEndIdx === -1 || dotStartIdx === -1)
        return null
    const startPos = !maps[dotStartIdx].transform ? maps[dotStartIdx] : transformMatrix(maps[dotStartIdx].transform, maps[dotStartIdx].x, maps[dotStartIdx].y)
    const endPos = !maps[dotEndIdx].transform ? maps[dotEndIdx] : transformMatrix(maps[dotEndIdx].transform, maps[dotEndIdx].x, maps[dotEndIdx].y)
    if (isStraightLine) {
        path = 'M' + startPos.x + ',' + startPos.y + ' L' + endPos.x + ',' + endPos.y
        return {
            path,
            color,
            stroke_width,
            strokeDasharray,
            maker,
            deletePoint: getDeletePoint(path)
        }
    }
    if (isOppositeLine) {
        path = 'M' + startPos.x + ',' + startPos.y + ' Q' + center + ',' + center + ' ' + endPos.x + ',' + endPos.y
        return {
            path,
            color,
            stroke_width,
            strokeDasharray,
            maker,
            deletePoint: getDeletePoint(path)
        }
    }

    path = 'M' + startPos.x + ',' + startPos.y + ' Q' + 0 + ',' + 0 + ' ' + endPos.x + ',' + endPos.y
    const pathSvg = document.createElementNS("http://www.w3.org/2000/svg", "path")
    pathSvg.setAttribute("d", path)
    const centerPoint = pathSvg.getPointAtLength(Math.ceil(pathSvg.getTotalLength() * 0.5))
    const tx = -(0.5 * Number(endPos.x)) - (0.5 * Number(startPos.x))
    const ty = -(0.5 * Number(endPos.y)) - (0.5 * Number(startPos.y))
    path = 'M' + centerPoint.x + ',' + centerPoint.y + ' L' + tx + ',' + ty
    const lineSvg = document.createElementNS("http://www.w3.org/2000/svg", "path")
    lineSvg.setAttribute("d", path)
    const interpolatedPoint = lineSvg.getPointAtLength(Math.ceil(lineSvg.getTotalLength() - 70))
    pathSvg.remove()
    lineSvg.remove()
    path = 'M' + startPos.x + ',' + startPos.y + ' Q' + interpolatedPoint.x + ',' + interpolatedPoint.y + ' ' + endPos.x + ',' + endPos.y
    return {
        path,
        color,
        stroke_width,
        strokeDasharray,
        maker,
        deletePoint: getDeletePoint(path)
    }
}

const transformMatrix = (matrix, x, y) => {
    const data = matrix.split("(")[1].split(",")
    return {
        x: Number(x) * Number(data[0]) + Number(y) * Number(data[2]),
        y: Number(x) * Number(data[1]) + Number(y) * Number(data[3]),
    }
}

const getDeletePoint = (path) => {
    const pathSvg = document.createElementNS("http://www.w3.org/2000/svg", "path")
    pathSvg.setAttribute("d", path)
    const point = pathSvg.getPointAtLength(Math.ceil(pathSvg.getTotalLength() / 2))
    pathSvg.remove()
    return point
}
