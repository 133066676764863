import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import AppbarScreen from "../AppBarScreen";
import Footer from "../Footer";

function Privacy(props) {
    const classes = useStyles()
    return (
        <div>
            <AppbarScreen/>
            <div className={classes.root}>
                <div className={classes.body}>
                    <h4 className={classes.textHeader}>Insight Mindプライバシーポリシー</h4>
                    <p>
                        株式会社 <span
                        className={classes.text}>Andom</span>（以下「当社」といいます。）は、当社の提供するアプリケーションサービス
                        <span className={classes.text}>「Insight Mind」</span>（以下「本サービス」といいます。）における、ユーザーについての個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
                    </p>
                    <p className={classes.title}>
                        第１条 （総則）
                    </p>
                    <p>
                        １&ensp;当社は、利用者情報の保護実現のため、個人情報保護法、各省庁ガイドラインその他関連する法令を遵守いたします。
                    </p>
                    <p>
                        ２&ensp;
                        本ポリシーは、本アプリ（本アプリに付随して当社が提携するサービスを含みます。以下同じ。）の利用に関し適用されます。ただし、未成年のユーザーの個人情報につきましては、必ず保護者の方のご同意のもとにご提供をいただきますようお願いいたします。
                    </p>
                    <p>
                        ３&ensp;
                        当社が当社の運営するウェブサイト上に掲載するプライバシーポリシーその他の個人情報保護方針又は本サービスに関する利用規約等において利用者情報の取扱について規定する場合、当該規定も適用されるものとし、当該規定等が本ポリシーと抵触する場合には本ポリシーが優先されるものとします。
                    </p>
                    <p>
                        ４&ensp;
                        提携パートナーにより提供される提携サービスその他当社以外の者が提供するサービスについては、本ポリシーは適用されません。これらのサービスにおける利用者情報の取扱については、当該外部サービスを提供する事業者が別途定めるプライバシーポリシー等をご参照ください。
                    </p>
                    <p className={classes.title}>第２条 （収集する利用者情報及び収集方法）</p>
                    <p>
                        １&ensp;
                        本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーのスマートフォン、ＰＣ等の端末においてユーザー又はユーザーの端末に関連して生成又は蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(1)</span> ユーザーからご提供いただく情報
                    </p>
                    <p>ユーザーが本サービスを利用するために、ご提供いただく可能性のある情報は以下のとおりです。</p>
                    <p className={classes.title}>ア&emsp;アカウント登録等に必要な情報</p>
                    <ul className={classes.ul_Style}>
                        <li>・&emsp;メールアドレス</li>
                        <li>・&emsp; パスワード</li>
                        <li>・&emsp; 生年月日</li>
                        <li>・&emsp; 性別</li>
                    </ul>
                    <p className={classes.title}>イ&emsp;本サービスを通じて自己理解を行うための情報</p>
                    <ul className={classes.ul_Style}>
                        <li>・&emsp; ニックネーム</li>
                        <li>・&emsp;年齢</li>
                        <li>・&emsp;住所</li>
                        <li>・&emsp;氏名（区市町村）</li>
                        <li>・&emsp; 生体情報（性別・血液型・身長・体重等）</li>
                        <li>・&emsp; 職業</li>
                        <li>・&emsp; 所属組織（会社・学校等</li>
                        <li>・&emsp; 血液型</li>
                        <li>・&emsp; 出身地</li>
                    </ul>
                    <p className={classes.title}>ウ&emsp;ユーザーへの商品発送のための情報</p>
                    <ul className={classes.ul_Style}>
                        <li>・&emsp; 住所・郵便番号</li>
                        <li>・&emsp; 電話番号</li>
                        <li>・&emsp; クレジットカード情報</li>
                    </ul>
                    <p className={classes.title}>エ&emsp;不具合解析のために取得する情報</p>
                    <ul className={classes.ul_Style}>
                        <li>・&emsp; 端末情報（型式・ＯＳバージョン・アプリバージョン）</li>
                        <li>・&emsp; 表示した画面の<span className={classes.text}>ID</span>、アプリ内の操作ログ</li>
                        <li>・&emsp; エラー情報</li>
                    </ul>
                    <p>
                        <span
                            className={classes.numberStyle}>(2) </span> ユーザーが本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報

                        ユーザーが、本サービスを利用するにあたり、ソーシャルネットワークサービス等の外部サービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。
                    </p>
                    <ul className={classes.ul_Style}>
                        <li>・&emsp; 当該外部サービスでユーザーが利用する<span className={classes.text}>ID</span></li>
                        <li>・&emsp; その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報</li>
                    </ul>
                    <p>
                        ２&ensp;
                        当社は、利用者情報の取得にあたっては、偽りその他不正の手段によらず、適正な方法により取得します。また、当社は、ユーザーが本アプリを利用することによる取得以外の方法で利用者情報を取得する場合には、その利用目的を事前に通知又は公表します。
                    </p>
                    <p className={classes.p_title}>第３条 （利用目的）</p>
                    <p>
                        １&ensp;
                        当社は、本アプリの利用を通じて取得した利用者情報を、本条第２項に定めるとおり本サービスの提供に利用されるほか、本条第３項に定めるとおりその他の目的にも利用される場合があります。もっとも、当社はユーザーご本人の同意なく利用目的の範囲を超えて利用することはありません。
                    </p>
                    <p>
                        ２&ensp; 本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。
                    </p>
                    <p><span className={classes.numberStyle}>(1) </span> 本サービスに関する登録の受付、本人確認等本サービスの提供、維持、保護及び改善のため
                    </p>
                    <p><span className={classes.numberStyle}>(2) </span> ユーザーに表示する分析結果の適切性を向上するため</p>
                    <p><span className={classes.numberStyle}>(3) </span> 本サービスを通じた分析結果の冊子をユーザーに対して配送するため</p>
                    <p><span className={classes.numberStyle}>(4) </span> 本サービスに関するご案内、お問い合せ等への対応のため</p>
                    <p><span
                        className={classes.numberStyle}>(5) </span> 本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
                    </p>
                    <p><span className={classes.numberStyle}>(6) </span> 本サービスに関する規約等の変更などを通知するため</p>
                    <p><span className={classes.numberStyle}>(7) </span> 上記の利用目的に付随する利用目的のため</p>
                    <p>３&ensp; 第２項以外の利用目的は以下のとおりです。</p>
                    <p><span
                        className={classes.numberStyle}>(1) </span> 当社の提供するウェブサービス、アプリサービス、チャットボットサービス等の関連サービスの提供のため
                    </p>
                    <p><span className={classes.numberStyle}>(2) </span> 当社の提供する関連サービスの広告・案内のため</p>
                    <p><span className={classes.numberStyle}>(3) </span> 当社のサービスに関連して、個人を識別できない形式に加工した統計データを作成するため</p>
                    <p className={classes.p_title}>第４条 （利用中止要請の方法）</p>
                    <p>
                        ユーザーは、本サービスの所定の設定を行うことにより、利用者情報の全部又は一部についてその利用の停止を求めることができ、この場合、当社は速やかに、当社の定めるところに従い、その利用を停止します。なお利用者情報の項目によっては、その収集又は利用が本サーヒスの前提となるため、当社所定の方法により本サービスを退会した場合に限り、当社はその収集を停止します。
                    </p>
                    <p className={classes.p_title}>
                        第５条 （第三者提供）
                    </p>
                    <p>
                        当社は、利用者情報のうち、個人情報については、個人情報保護法その他の法令に基づき開示が認められる場台を除くほか、あらかじめユーザーの同意を得ないで、第三者に提供しません。但し、次に掲げる場合はこの限りではありません。
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(1) </span> 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(2) </span>
                        <span
                            className={classes.text}>Facebook、Google</span> アカウントその他の外部サービスとの連携または外部サービスを利用した認証にあたり当該外部サービス運営会社に個人情報を提供する場合
                    </p>
                    <p>
                        <span
                            className={classes.numberStyle}>(3) </span> 統計的情報を提供する目的で、個々の個人情報を分析し識別不可能な形式に加工した上で、当該加工後の情報を統計データとして第三者に提供する場合
                    </p>
                    <p>
                        <span
                            className={classes.numberStyle}>(4) </span> 合併その他の事由による事業の承継に伴って個人情報が提供される場合
                    </p>
                    <p>
                         <span
                             className={classes.numberStyle}>(5) </span> 国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
                    </p>
                    <p>
                        <span className={classes.numberStyle}>(6) </span> その他、個人情報保護法その他の法令で認められる場合
                    </p>
                    <p className={classes.p_title}>
                        第６条 （個人情報の取り扱いの委託）
                    </p>
                    <p>
                        当社は、ユーザーから取得した個人情報の全部又は一部の取扱を第三者に委託（個人情報を含む情報の管理を事業者に委託する場合などをいいます。）することがあります。この場合、当社は、当該委託先との間で本ポリシーに準じる内容の秘密保持契約等を予め締結するとともに、当該委託先において情報の適切な安全管理が図られるよう、必要かつ適切な監督を行います。
                    </p>
                    <p className={classes.p_title}>第７条 （共同利用）</p>
                    <p>
                        当社は、提携パートナーとの間で、提携サービスの提供に必要な範囲において、ユーザーの個人情報を共同利用することがあります。この場合、当社はあらかじめ提携パートナーの名称、共同利用目的、共同利用する情報の種類を公表するものとします。
                    </p>
                    <p className={classes.p_title}>
                        第８条 （個人情報の開示）
                    </p>
                    <p>
                        当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（<span
                        className={classes.text}>1</span>件あたり<span className={classes.text}>1,000</span>円）を頂戴しておりますので、あらかじめ御了承ください。
                    </p>
                    <p className={classes.p_title}>第９条 （個人情報の訂正及び利用停止等）</p>
                    <p>
                        １ 当社は、ユーザーから、 <span
                        className={classes.numberStyle}>(1) </span>個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び <span
                        className={classes.numberStyle}>(2) </span> あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、合理的な理由に基づいて訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。
                    </p>
                    <p>
                        ２
                        当社は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。
                    </p>
                    <p>
                        ３ 個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、前2項の規定は適用されません。
                    </p>
                    <p className={classes.p_title}>第１０条 （お問い合わせ窓ロ）</p>
                    <p>
                        ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記の窓口までお願い致します。
                    </p>
                    <p>
                        住所：〒<span className={classes.text}
                                  style={{marginRight: 30}}>171-0014  </span> 東京都豊島区池袋 <span
                        className={classes.text}>2-14-2</span>池袋 <span
                        className={classes.text}>2</span>丁目ビル <span
                        className={classes.text}>2F</span>株式会社 <span
                        className={classes.text} style={{marginRight: 30}}>Andom  </span> 担当部署：<span
                        className={classes.text}>Insight Mind</span>事業部
                    </p>
                    <p>
                        E-mail：<a className={classes.a_Style}
                                  href="mailto:info-insightmind@wowway.co.jp">info-insightmind@wowway.co.jp</a>
                    </p>
                    <p className={classes.p_title}>
                        第１１条（プライバシーポリシーの変更手続）
                    </p>
                    <p>
                        当社は、利用者情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本ポリシーを変更することがあります。変更した場合には、当社所定の方法でユーザーに通知いたします。但し、法令上ユーザーの同意が必要となるような内容の変更の場合は、当社所定の方法でユーザーの同意を得るものとします。
                    </p>
                    <p>【平成２９年４月３０日制定】</p>
                    <p>
                        <a className={classes.a_Style} onClick={() => {
                            window.location = "/"
                        }}> 戻る</a>
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#f7f7f7',
        paddingTop: 60,
        fontSize: 15,
        outline: 'none',
        fontFamily: "メイリオ Meiryo ヒラギノ角ゴ Pro W3 Hiragino Kaku Gothic Pro Verdana MS P Gothic ＭＳ Ｐゴシック sans-serif",
        overflowY: 'auto',
        overflowX: 'hidden',
        "& p": {
            margin: '0 0 10px'
        }
    },
    body: {
        padding: '20px 0',
        maxWidth: '60%',
        margin: 'auto',
// textAlign: 'center'
    },
    textHeader: {
        fontFamily: 'inherit',
        fontWeight: 500,
        lineHeight: '1.1px',
        color: 'inherit',
        fontSize: 18
    },
    text: {
        fontFamily: 'inherit',
        fontWeight: 500,
        lineHeight: '1.1px',
        color: 'inherit',
    },
    title: {
        fontWeight: 700,
        color: 'inherit',
        fontFamily: 'inherit',
    },
    p_title: {
        fontWeight: 700,
        color: 'inherit',
        paddingTop: 20
    },
    numberStyle: {
        fontWeight: 600,
        marginRight: 15
    },
    ul_Style: {
        listStyle: 'none',
        padding: 0
    },
    boldName: {
        fontWeight: 600
    },
    a_Style: {
        color: ' #337ab7',
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'revert'
        }
    },
}))

export default Privacy;
