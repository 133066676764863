import {lazy} from 'react';

//AppBar
export const HomeMaps = lazy(() => import('./Home'));
export const AppBarScreen = lazy(() => import('./AppBarScreen'));

// export const NavApp = lazy(() => import('./Navigation/NavApp'))

// Files manager
export const FilesManager = lazy(() => import('./FilesManager'));

// Insight maps
export const InsightMaps = lazy(() => import('./InsightMind'));

// Starter pack
export const StarterPack = lazy(() => import('./StarterPack'));
