import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {ListItemIcon} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

export default function Item({
                                 text, onClick, icon, subMenu, minWidth = 220, onSubClick = () => {
    }
                             }) {
    const classes = useStyles()
    return (
        <ListItem
            button
            onClick={onClick}
            // style={styles}
            // component={props => <Link to={link} {...props}/>}
            className={classes.listItems}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text}/>
            {
                subMenu && <div className={`menu ${classes.subMenu}`}>
                    {
                        subMenu.map((sub, idx) => {
                                return (
                                    <ListItem key={idx} className={classes.text}
                                              onClick={() => onSubClick(sub)}
                                              style={{minWidth}}
                                    >
                                        <ListItemIcon><img src={`${sub?.iconSub}`} alt={""}
                                                           className={classes.iconSub}/></ListItemIcon>

                                        <ListItemText>{sub.textSub}</ListItemText>
                                    </ListItem>
                                )
                            }
                        )
                    }
                </div>
            }
        </ListItem>
    );
}
const useStyles = makeStyles((theme) => ({
    listItems: {
        position: "relative",
        "&:hover": {
            background: "#000",
            "& .menu": {
                display: "block"
            }
        }
    },
    iconSub: {
        width: 30,
        height: 30,
        marginRight: 7,
        objectFit: "scale-down"
    },
    subMenu: {
        position: "absolute",
        left: "100%",
        top: 0,
        zIndex: 10,
        border: "1px solid rgba(0,0,0,.15)",
        boxShadow: "0 6px 12px rgba(0,0,0,.175)",
        borderRadius: "4px",
        display: "none"
    },
    text: {
        whiteSpace: "no-wrap",
        fontSize: 14,
        height: 50,
        padding: "10px",
        backgroundPosition: "10px center",
        background: "#222",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#000"
        }
    }
}))
