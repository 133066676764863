import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, Divider, IconButton, TextField} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import {makeStyles} from "@material-ui/core/styles";
import rtn from 'reactn'
import {db} from '../../../firebaseConfig'
import {v4 as uuidv4} from 'uuid'
import {DB_COLL} from "../../../common/envSettings";
import FolderIcon from '@material-ui/icons/Folder';
import InputAdornment from "@material-ui/core/InputAdornment";
import ButtonCustom from "../../ButtonCustom";
import moment from "moment";

const {useGlobal} = rtn

function CreateNewFolder({openCreate, setOpenCreate}) {
    const classes = useStyles()
    const [name, setName] = useState("")
    const [user] = useGlobal("user")
    const [loading, setLoading] = useState(false)
    const handleClose = () => {
        setOpenCreate(false)
    };
    const onCreate = async () => {
        if (!user?.user_id || !name)
            return
        setLoading(true)
        try {
            const id = uuidv4()
            const createdAt = moment().utc().format()
            const folderRef = db.doc(`${DB_COLL}/${user.user_id}/folder/${id}`)
            await folderRef.set({
                is_delete: false,
                name,
                id,
                folder_id: id,
                createdAt: createdAt,
            })

        } catch (e) {
            console.log(e);
        } finally {
            setName('')
            setLoading(false)
            handleClose()
        }
    }
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onCreate && onCreate(e.target.value);
        }
    }

    return (
        <Dialog
            className={classes.root}
            onClose={handleClose}
            open={openCreate}>
            <DialogTitle>
                フォルダを作成する
                <IconButton className={classes.close}
                            onClick={handleClose}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div className={classes.inputContainer}>
                    <TextField
                        onKeyPress={handleKeyPress}
                        fullWidth
                        autoFocus={true}
                        placeholder={"新しいフォルダ"}
                        value={name}
                        onChange={e => setName(e.target.value)}
                        variant={"outlined"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FolderIcon style={{fontSize: 14, color: "#2b2b2b"}}/>
                                </InputAdornment>
                            ),
                            classes: {
                                root: classes.valueText,
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                </div>
            </DialogContent>
            <Divider light className={classes.divider}/>
            <DialogActions className={classes.actionsContainer}>
                <ButtonCustom
                    className={classes.button}
                    onClick={onCreate}
                    disabled={loading || !name}
                    backgroundColor={"rgba(43, 187, 173, 1)"}
                    backgroundBlur={"rgba(43, 187, 173, 0.5)"}
                >
                    作成する
                </ButtonCustom>

            </DialogActions>
        </Dialog>
    );
}

export default CreateNewFolder;

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiDialogTitle-root": {
            background: "-webkit-linear-gradient(45deg, rgba(43, 187, 173, 1) 0%, rgba(43, 187, 173, 0.5) 49%, rgba(43, 187, 173, 0.25) 50%, rgba(43, 187, 173, 0.75) 100%)",
            color: "white",
            fontWeight: "bold",
            padding: 15,
            position: 'relative'
        },
        width: '100%',
        '& .MuiDialog-paper': {
            maxWidth: '600px',
            position: 'absolute',
            top: "50px",
            right: 0,
            left: 0,
            margin: "auto"
        },
        paddingBottom: 50
    },
    close: {
        float: "right",
        padding: 0,
        textAlign: 'center'
    },
    inputContainer: {
        width: '90%',
        margin: "auto",
        "& .MuiInputBase-input": {
            padding: "8px 12px"
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#66afe9",
            outline: 0
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
            borderColor: "#66afe9",
        }
    },
    actionsContainer: {
        backgroundColor: '#f0f0f0',
        "& .Mui-disabled": {
            color: "#fff"
        }
    },
    divider: {
        backgroundColor: 'black',
    },
    button: {
        backgroundColor: '#2bbbad',
        color: 'white',
        fontWeight: 'bold',
        margin: '10px auto',
        boxShadow: '0 4px rgba(43, 187, 173, 0.5)',
        "&:hover": {
            backgroundColor: '#2bbbad',
        }
    },

}))
