import React from 'react';
import {mapAdvance} from "../common/map";
import {linesMap} from "../common/line";
import _ from "lodash";
import {barrierMap} from "../common/barrier";
import {images} from "../common/images";
import {getVector} from "../common/vector";
import {identitiesAdvance, identitySize} from "../common/identitites";
import {getQuantizingUrl} from "../common/quantizing";
import {clearsMap} from "../common/clear";
import {keyMap} from "../common/keys";
import {getTextPath} from "../common/conflict";
import {makeStyles} from "@material-ui/core/styles";

const stroke = "#a4a4a4"
const strokeWidth = 10

function CaptureInsightMindOne({map, captureId}) {

    const classes = useStyles()
    return (
        <div>
            <svg width={"100%"}
                 viewBox={"0 0 1600 1850"}
                 id={captureId}
            >
                <defs>
                    <marker viewBox="0 0 20 20" markerWidth="10" markerHeight="10" orient="auto" refX="0"
                            refY="3"
                            id="arrow_gray" markerUnits="strokeWidth" fill="#a3b3b3" stroke="none">
                        <path d="M0,0 L0,6 L9,3 z"/>
                    </marker>
                    <marker viewBox="0 0 20 20" markerWidth="10" markerHeight="10" orient="auto" refX="0"
                            refY="3"
                            id="arrow_yellow" markerUnits="strokeWidth" fill="#f1c500" stroke="none">
                        <path d="M0,0 L0,6 L9,3 z"/>
                    </marker>
                    <marker viewBox="0 0 20 20" markerWidth="10" markerHeight="10" orient="auto" refX="0"
                            refY="3"
                            id="arrow_red" markerUnits="strokeWidth" fill="#e84c3d" stroke="none">
                        <path d="M0,0 L0,6 L9,3 z"/>
                    </marker>
                    <marker viewBox="0 0 20 20" markerWidth="10" markerHeight="10" orient="auto" refX="0"
                            refY="3"
                            id="arrow_blue" markerUnits="strokeWidth" fill="#267ebe" stroke="none">
                        <path d="M0,0 L0,6 L9,3 z"/>
                    </marker>
                    <marker viewBox="0 0 20 20" markerWidth="10" markerHeight="10" orient="auto" refX="0"
                            refY="3"
                            id="arrow_green" markerUnits="strokeWidth" fill="#27ae61" stroke="none">
                        <path d="M0,0 L0,6 L9,3 z"/>
                    </marker>
                </defs>
                <circle cx="0" cy="-785" r="15" transform="matrix(1,0,0,1,800,925)" fill="#ffffff"
                        stroke="#a4a4a4"
                        strokeWidth={strokeWidth}/>
                <circle cx="0" cy="785" r="15" transform="matrix(1,0,0,1,800,925)" fill="#dcdedd"
                        stroke="#a4a4a4"
                        strokeWidth={strokeWidth}/>
                <g id="group_callouts">
                    <g id="group_callout_01"
                    >
                        <path
                            fill={"#ffffff"}
                            d="M800,120 L795,105 L400,105 A5,5 0 0,1 395,100 L395,40 A5,5 0 0,1 400,35 L1200,35 A5,5 0 0,1 1205,40 L1205,100 A5,5 0 0,1 1200,105 L805,105 L800,120Z"
                            stroke="#a4a4a4" id="callout_01" strokeWidth={3}
                        />
                        {
                            map?.map && map?.map["callout_01"] ?
                                <text id={"text_callout_01"}
                                      dominantBaseline="middle"
                                      textAnchor="middle"
                                      transform="matrix(1,0,0,1,800,925)"
                                      x={0}
                                      y={-850}
                                >
                                    {map.map["callout_01"]}
                                </text>
                                :
                                null
                        }
                    </g>
                    <g id="group_callout_02"
                    >
                        <path
                            fill={"#dcdedd"}
                            d="M800,1690 L795,1675 L400,1675 A5,5 0 0,1 395,1670 L395,1610 A5,5 0 0,1 400,1605 L1200,1605 A5,5 0 0,1 1205,1610 L1205,1670 A5,5 0 0,1 1200,1675 L805,1675 L800,1690Z"
                            stroke="#a4a4a4" id="callout_02" transform="matrix(-1,0,0,-1,1600,3420)"
                            strokeWidth={3}/>
                        {
                            map?.map && map?.map["callout_02"] ?
                                <text id={"text_callout_02"}
                                      dominantBaseline="middle"
                                      textAnchor="middle"
                                      transform="matrix(1,0,0,1,800,925)"
                                      x={0}
                                      y={850}
                                >
                                    {map.map["callout_02"]}
                                </text>
                                :
                                null
                        }
                    </g>
                </g>
                <g transform="matrix(1,0,0,1,800,925)" id="group_mappings" fill={"none"}>
                    {
                        mapAdvance.map(item => {
                            if (!item)
                                return null
                            return (
                                <g id={`group_${item.id}`} key={item.id}
                                >
                                    <path
                                        d={item.path}
                                        id={item.id}
                                        fill={item.fill}
                                        stroke={item.stroke || stroke}
                                        strokeWidth={strokeWidth}
                                        transform={item.transform}/>
                                </g>)
                        })
                    }
                </g>
                <g transform="matrix(1,0,0,1,800,925)" id="group_line">
                    {
                        map?.line && map.line.split(",").map(id => {
                            let idx = _.findIndex(linesMap, {id})
                            if (idx === -1) return null
                            const item = linesMap[idx]
                            return (
                                <g key={item.id} id={`line_${item.id}`}>
                                    {
                                        item.type === "circle" ?
                                            <path d={item.d}
                                                  transform={item.transform}
                                                  strokeWidth={10}
                                                  stroke={"#756bb0"}
                                                  fill={"none"}
                                                  className={classes.borderLineActive}
                                            />
                                            :
                                            <line
                                                x1={item.x1}
                                                x2={item.x2}
                                                y1={item.y1}
                                                y2={item.y2}
                                                transform={item.transform}
                                                strokeWidth={10}
                                                stroke={"#756bb0"}
                                                fill={"none"}
                                                className={classes.borderLineActive}

                                            />

                                    }
                                </g>
                            )
                        })
                    }
                </g>
                <g transform="matrix(1,0,0,1,800,925)" id="group_barriers">
                    {
                        map?.barrier && map.barrier.split(",").map(id => {
                            let idx = _.findIndex(barrierMap, {id})
                            if (idx === -1)
                                return null
                            const item = barrierMap[idx]
                            return (
                                <g key={item.id} transform={item.transform}>
                                    <image href={images['barrier']} width={"80"} height={"20"}
                                           x={item.x} y={item.y}
                                           data-uri={images['barrier']}
                                    />
                                </g>
                            )
                        })
                    }

                </g>
                <g transform="matrix(1,0,0,1,800,925)" id="group_vectors">
                    {
                        map?.vector && map.vector.map((item, idx) => {
                            const data = getVector(item, true)
                            if (!data)
                                return null
                            return <g key={idx} style={{opacity: 0.8}}>
                                <path
                                    d={data.path}
                                    strokeWidth={data.stroke_width}
                                    stroke={data.color}
                                    strokeLinecap={"round"}
                                    strokeDasharray={data.strokeDasharray}
                                    markerEnd={data.maker}
                                    fill={"none"}
                                />
                            </g>
                        })
                    }

                </g>
                <g transform="matrix(1,0,0,1,800,925)" id="group_identities">
                    {map?.identity && Object.keys(map.identity).map((key) => {
                        if (!map.identity[key])
                            return null
                        const value = map.identity[key].split(',');
                        const img = value[1];
                        const display = identitiesAdvance[key];
                        // const id = Number(display.id.split('_')[1]);
                        // const text1 = value[2]?.substr(0, 10) || '';
                        // const text2 = value[2]?.substr(10, 10) || '';
                        // const text3 = value[2]?.substr(20, 10) || '';
                        const dataUri = images['pawn'][img]
                        return (
                            <g
                                id={display.id}
                                key={key}
                            >
                                <image
                                    href={dataUri}
                                    {...identitySize}
                                    x={display.x}
                                    y={display.y}
                                    id={`image_${display.id}`}
                                    // preserveAspectRatio={"none"}
                                    data-uri={dataUri}
                                />
                                {value[0] && value[0] !== 'none' ? (
                                    <image
                                        href={images['pawnmark'][value[0]]}
                                        x={Number(display.x) + identitySize.width / 4 - 2}
                                        y={Number(display.y) + identitySize.height / 2}
                                        data-uri={images['pawnmark'][value[0]]}
                                    />
                                ) : null}
                            </g>
                        );
                    })}
                </g>
                <g transform="matrix(1,0,0,1,800,925)" id="group_quantizing">
                    {map?.quantizing && Object.keys(map.quantizing).map((key) => {
                        if (!map.quantizing[key]) return null;
                        const value = map.quantizing[key];
                        const img = getQuantizingUrl(key, value);
                        if (!img) return null;
                        return (
                            <g
                                id={`quantizing_${key}`}
                                key={key}>

                                <image
                                    href={img.dataUri}
                                    x={img.x}
                                    y={img.y}
                                    data-uri={img.dataUri}
                                />
                                <text
                                    style={{fontSize: img.fontSize, fontWeight: 100, lineHeight: 1.5}}
                                    x={img.x + img.size.width / 2}
                                    y={img.y + img.size.height / 2}
                                    stroke={'#ffffff'}
                                    fill={'#ffffff'}
                                    dominantBaseline="middle"
                                    textAnchor="middle"
                                >
                                    {value.split(',')[2]}
                                </text>
                            </g>
                        );
                    })}
                </g>
                <g transform="matrix(1,0,0,1,800,925)" id="group_clear">
                    {map?.clear && Object.keys(map.clear).map((key) => {
                        if (!map.clear[key]) return null;
                        const {x, y} = clearsMap[key];
                        const clearUri = images["clear"][map.clear[key]]
                        return (
                            <g
                                id={`clear_${key}`}
                                key={key}>

                                <image
                                    href={clearUri}
                                    width={'44'}
                                    height={'42'}
                                    x={x}
                                    y={y}
                                    data-uri={clearUri}
                                />
                            </g>
                        );
                    })}
                </g>
                <g transform="matrix(1,0,0,1,800,925)" id="group_key">
                    {map?.key && Object.keys(map.key).map((key) => {
                        if (!map.key[key]) return null;
                        const {x, y} = keyMap[key];
                        const dataUri = images['key'][map.key[key]]
                        return (
                            <g
                                id={`key_${key}`}
                                key={key}>

                                <image
                                    href={dataUri}
                                    width={'35'}
                                    height={'36'}
                                    x={x}
                                    y={y}
                                    data-uri={dataUri}
                                />
                            </g>
                        );
                    })}
                </g>
                <g transform="matrix(1,0,0,1,800,925)" id="group_conflict">
                    {map?.map && Object.keys(map.map).map((key) => {
                        if (!key?.includes("mapping_"))
                            return null
                        if (!map.map[key]) return null;
                        return (
                            <g id={`conflict_${key}`} key={key}
                            >
                                {getTextPath(key, map.map[key])}
                            </g>
                        );
                    })}
                </g>
            </svg>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    borderLineActive: {
        '&:hover': {
            cursor: 'pointer',
        },
    }
}));

export default CaptureInsightMindOne;
