import React from 'react';

export const conflictMap = {
    mapping_01: {
        x: '40.38674157667282',
        y: '-34.5',
    },
    mapping_02: {
        x: '-69.77736657667282',
        y: '-34.5',
    },
    mapping_03: {
        x: '96.2734375',
        y: '-139.5',
    },
    mapping_04: {
        x: '96.2734375',
        y: '90.5',
    },
    mapping_05: {
        x: '-133.7265625',
        y: '90.5',
    },
    mapping_06: {
        x: '-133.7265625',
        y: '-139.5',
    },
    mapping_07: {
        x: '97.9449375',
        y: '-272.281',
    },
    mapping_08: {
        x: '229.05127280759893',
        y: '-141.17149999999998',
    },
    mapping_09: {
        x: '229.0544375',
        y: '92.17149999999998',
    },
    mapping_10: {
        x: '97.9449375',
        y: '223.27783530759893',
    },
    mapping_11: {
        x: '-135.39806249999998',
        y: '223.281',
    },
    mapping_12: {
        x: '-266.50439780759893',
        y: '92.17149999999998',
    },
    mapping_13: {
        x: '-266.5075625',
        y: '-141.17149999999998',
    },
    mapping_14: {
        x: '-135.39806249999998',
        y: '-272.27783530759893',
    },
    mapping_15: {
        x: '65.4674375',
        y: '-396.94350044004057',
    },
    mapping_16: {
        x: '194.9926107',
        y: '-344.43476680000003',
    },
    mapping_17: {
        x: '296.2022467',
        y: '-243.2046158',
    },
    mapping_18: {
        x: '353.7280475',
        y: '-108.69399999999999',
    },
    mapping_19: {
        x: '353.71693794004057',
        y: '59.694',
    },
    mapping_20: {
        x: '296.20820430000003',
        y: '194.2191732',
    },
    mapping_21: {
        x: '194.9780533',
        y: '295.4288092',
    },
    mapping_22: {
        x: '65.4674375',
        y: '347.95461',
    },
    mapping_23: {
        x: '-102.9205625',
        y: '347.94350044004057',
    },
    mapping_24: {
        x: '-232.4457357',
        y: '295.43476680000003',
    },
    mapping_25: {
        x: '-333.6553717',
        y: '194.2046158',
    },
    mapping_26: {
        x: '-391.1811725',
        y: '59.694',
    },
    mapping_27: {
        x: '-391.17006294004057',
        y: '-108.694',
    },
    mapping_28: {
        x: '-333.66132930000003',
        y: '-243.2191732',
    },
    mapping_29: {
        x: '-232.4311783',
        y: '-344.4288092',
    },
    mapping_30: {
        x: '-102.92056249999999',
        y: '-396.95461',
    },
    mapping_31: {
        x: '173.1946875',
        y: '-480.062',
    },
    mapping_32: {
        x: '434.298913012665',
        y: '-218.9525',
    },
    mapping_33: {
        x: '434.3041875',
        y: '169.9525',
    },
    mapping_34: {
        x: '173.1946875',
        y: '431.056725512665',
    },
    mapping_35: {
        x: '-215.7103125',
        y: '431.062',
    },
    mapping_36: {
        x: '-476.814538012665',
        y: '169.9525',
    },
    mapping_37: {
        x: '-476.8198125',
        y: '-218.9525',
    },
    mapping_38: {
        x: '-215.7103125',
        y: '-480.056725512665',
    },
    mapping_39: {
        x: '422.17037500000004',
        y: '-451.686',
    },
    mapping_40: {
        x: '424.170375',
        y: '404.68600000000004',
    },
    mapping_41: {
        x: '-466.68600000000004',
        y: '404.686',
    },
    mapping_42: {
        x: '-464.686',
        y: '-451.68600000000004',
    },
    mapping_43: {
        x: '712.484375',
        y: '-24.5',
    },
    mapping_44: {
        x: '-755',
        y: '-24.5',
    },
};

export const getTextPath = (id, text) => {
    if (!conflictMap[id] || !text || !text.length) return null;
    const {x, y} = conflictMap[id];
    const rows = Math.ceil(text.length / 5);
    let result = null;
    switch (rows) {
        case 1:
            result = (
                <text x={'0'} y={'0'}>
                    <tspan x={Number(x) + 15} y={Number(y) + 20}
                           dominantBaseline="middle"
                           textAnchor="middle"
                    >
                        {text.substr(0, 5)}
                    </tspan>
                </text>
            );
            break;
        case 2:
            result = (
                <text x={'0'} y={'0'}>
                    <tspan x={Number(x) + 15} y={Number(y) + 20}
                           dominantBaseline="middle"
                           textAnchor="middle"
                    >
                        {text.substr(0, 5)}
                    </tspan>
                    <tspan x={Number(x) + 15} y={Number(y) + 40}
                           dominantBaseline="middle"
                           textAnchor="middle"
                    >
                        {text.substr(5, 5)}
                    </tspan>
                </text>
            );
            break;
        case 3:
            result = (
                <text x={'0'} y={'0'}>
                    <tspan x={Number(x) + 15} y={Number(y) + 20}
                           dominantBaseline="middle"
                           textAnchor="middle"
                    >
                        {text.substr(0, 5)}
                    </tspan>
                    <tspan x={Number(x) + 15} y={Number(y) + 40}
                           dominantBaseline="middle"
                           textAnchor="middle"
                    >
                        {text.substr(5, 5)}
                    </tspan>
                    <tspan x={Number(x) + 15} y={Number(y) + 60}
                           dominantBaseline="middle"
                           textAnchor="middle"
                    >
                        {text.substr(10, 5)}
                    </tspan>
                </text>
            );
            break;
        default:
            result = (
                <text x={'0'} y={'0'}
                      dominantBaseline="middle"
                      textAnchor="middle"
                >
                    <tspan x={Number(x) + 15} y={Number(y)}

                    >
                        {text.substr(0, 5)}
                    </tspan>
                    <tspan x={Number(x) + 15} y={Number(y) + 20}>
                        {text.substr(5, 5)}
                    </tspan>
                    <tspan x={Number(x) + 15} y={Number(y) + 40}>
                        {text.substr(10, 5)}
                    </tspan>
                    <tspan x={Number(x) + 15} y={Number(y) + 60}>
                        {text.substr(15, 5)}
                    </tspan>
                </text>
            );
    }
    return result;
};
