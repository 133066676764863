import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {ClickAwayListener, IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Background from "../../../common/image/file-management.png";
import {db} from '../../../firebaseConfig'
import rtn from 'reactn'
import {DB_COLL} from "../../../common/envSettings";
import {useHistory} from 'react-router-dom';
import CreateNewFolder from "./CreateNew";
import {v4 as uuidv4} from 'uuid'
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import {toast} from "react-toastify";
import DialogActions from "@material-ui/core/DialogActions";
import ButtonCustom from "../../ButtonCustom";

const {useGlobal} = rtn

function CreateInsightMapFlatDialog(props) {
    const classes = useStyles()
    const history = useHistory();
    const {folders} = props;
    const [loading, setLoading] = useState(false)
    const [user] = useGlobal('user')
    const [openNewFolder, setOpenNewFolder] = useState(false)
    const [openCreateInsightMapFlatDialog, setOpenCreateInsightMapFlatDialog] = useGlobal('openCreateInsightMapFlatDialog')
    const [list, setList] = useState(true);
    const [idFolder, setIdFolder] = useState(null)
    const [open, setOpen] = useState(false)
    const [name, setName] = useState("")
    const [editing, setEditing] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const handleClose = () => {
        setOpenCreateInsightMapFlatDialog(false)
    };

    const onCreate = async (folder) => {
        if (loading || editing)
            return null
        const id = Date.now()
        const token = `token_${uuidv4()}`
        setLoading(true)
        try {
            const data = {
                title: "無題のインサイトマップ",
                image: "img/map-default.png",
                folder_id: folder.id,
                is_delete: false,
                id,
                role: 0,
                token,
                createdAt: moment().utc().format(),
                updatedAt: moment().utc().format(),
                copy: 0
            }
            const batch = db.batch()
            const mapRef = db.doc(`${DB_COLL}/${user.user_id}/insightmaps/${id}`)
            const historyId = uuidv4()
            const historyRef = db.doc(`${DB_COLL}/${user.user_id}/histories/${historyId}`)
            batch.set(mapRef, data)
            batch.set(historyRef, {role: 0, mapId: `${id}`, updatedAt: Date.now(), isDefault: true})
            await batch.commit()
            history.push(`insightmap?id=${id}`)
        } catch (e) {
            console.log(e);
        } finally {
            setOpenCreateInsightMapFlatDialog(false)
            setLoading(false)
        }
    }

    const handleKeyDown = async (e) => {

        if (e.key === "Enter") {
            let idx = _.findIndex(folders, f => f.id === idFolder);
            if (folders[idx].name === name) {
                setEditing(false)
                return
            }
            if (name === "" || name?.replace(/\s+/g, '') === "") {
                toast.error("Name not null")
                setEditing(false)
                return
            } else {
                try {
                    const timeUpdated = moment().utc().format()
                    const folderRef = db.doc(`${DB_COLL}/${user.user_id}/folder/${idFolder}`)
                    await folderRef.set({name: name, updatedAt: timeUpdated}, {merge: true})
                    return
                } catch (e) {
                    console.log(e);
                } finally {
                    setEditing(false)
                    toast.success("Change name success")
                }
            }
        }
    }

    const handleBlur = async (e) => {
        let idx = _.findIndex(folders, f => f.id === idFolder);
        if (folders[idx].name === name) {
            setEditing(false)
            return
        }
        if (name === "" || name?.replace(/\s+/g, '') === "") {
            toast.error("Name not null")
            setEditing(false)
            return
        } else {
            try {
                const timeUpdated = moment().utc().format()
                const folderRef = db.doc(`${DB_COLL}/${user.user_id}/folder/${idFolder}`)
                await folderRef.set({name: name, updatedAt: timeUpdated}, {merge: true})
                return
            } catch (e) {
                console.log(e);
            } finally {
                setEditing(false)
                toast.success("Change name success")
            }
        }

        return setEditing(false)
    }

    const handleDelete = async () => {
        try {
            const timeUpdated = moment().utc().format()
            const folderRef = db.doc(`${DB_COLL}/${user.user_id}/folder/${idFolder}`)
            await folderRef.set({is_delete: true, updatedAt: timeUpdated}, {merge: true})
            toast.success("Delete file successfully !")
        } catch (e) {
            console.log(e);
        } finally {
            setOpenConfirm(false)
        }
    }
    return (
        <Dialog
            className={classes.root}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openCreateInsightMapFlatDialog}>
            <DialogTitle>
                新規作成
                <IconButton className={classes.close}
                            onClick={handleClose}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div>
                    <div className={classes.create_flat_body}>
                        <div className={classes.create_flat_header}>保存するフォルダを選択してください。</div>
                        <div>
                            <Tooltip
                                arrow
                                placement="bottom"
                                title="フォルダを作成">
                                <div className={classes.create_flat_body_left}>
                                    <a className={classes.create_flat_modal_folder}
                                       onClick={() => setOpenNewFolder(true)}
                                    >
                                        フォルダ
                                        <i className={`${classes.create_flat_bg_icon} ${classes.create_flat_add_icon}`}/>
                                    </a>
                                </div>
                            </Tooltip>
                            <Tooltip
                                arrow
                                placement="bottom"
                                title="リスト" style={{display: "inline-block"}}>
                                <ul className={classes.create_flat_menuRight}>
                                    <li onClick={() => setList(!list)}>
                                        <a className={`${classes.file_img_menuRight} ${list ? classes.icon1 : classes.icon4}`}/>
                                    </li>
                                </ul>
                            </Tooltip>
                        </div>
                    </div>
                    {
                        list ? <div className={classes.create_flat_padding}>
                                {
                                    folders.map(folder => {
                                        return (
                                            <div className={classes.create_flat_content} key={folder.id}
                                            >
                                                <div onClick={() => onCreate(folder)}>
                                                    <div className={`${classes.file_bg_icon} ${classes.file_icon}`}/>
                                                    {
                                                        idFolder === folder.id && editing ?

                                                            <TextField value={name}
                                                                       className={classes.textField}
                                                                       onChange={(e) => setName(e.target.value)}
                                                                       onKeyDown={(e) => handleKeyDown(e)}
                                                                       onBlur={handleBlur}
                                                                       autoFocus={true}
                                                            />
                                                            :
                                                            <p>{folder.name}</p>

                                                    }
                                                </div>
                                                {
                                                    open && idFolder === folder.id &&
                                                    <ClickAwayListener onClickAway={() => {
                                                        setOpen(false)
                                                        setIdFolder(null)
                                                    }}>
                                                        <ul className={classes.menuCard}>
                                                            <li onClick={() => {
                                                                setOpen(false)
                                                                setEditing(true)
                                                                setName(folder.name)
                                                            }}>
                                                                <a>
                                                                    <i className={`${classes.img_menuCard} ${classes.icon_menuCard4}`}/>
                                                                    名前を変更
                                                                </a>
                                                            </li>
                                                            <li onClick={() => setOpenConfirm(true)}>
                                                                <a>
                                                                    <i className={`${classes.img_menuCard} ${classes.icon_menuCard6}`}/>
                                                                    削除
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </ClickAwayListener>
                                                }
                                                {
                                                    folder.name !== "Myインサイトマインド" ?
                                                        <div className={classes.file_h_icon} onClick={() => {
                                                            setOpen(true)
                                                            setIdFolder(folder.id)
                                                        }
                                                        }>
                                                            <span className="glyphicon glyphicon-option-horizontal"/>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className={classes.file_padding_body}>
                                {folders.map((folder, idx) => {
                                    return (
                                        <div
                                            className={classes.file_content2}
                                            key={idx}
                                        >
                                            <div onClick={() => onCreate(folder)}
                                                 disabled={loading || editing}>
                                                <div className={`${classes.file_bg_icon} ${classes.file_icon}`}/>
                                                {
                                                    idFolder === folder.id && editing ?
                                                        <TextField value={name}
                                                                   className={classes.textField}
                                                                   onChange={(e) => setName(e.target.value)}
                                                                   onKeyDown={(e) => handleKeyDown(e)}
                                                                   onBlur={handleBlur}
                                                                   autoFocus={true}
                                                        />
                                                        :
                                                        <p>{folder.name}</p>

                                                }
                                                <span
                                                    className={classes.timeCreateAt}>{`作成日  ${moment(folder.createdAt).format("YYYY年MM月DD日")}`}</span>
                                            </div>
                                            {
                                                open && idFolder === folder.id &&
                                                <ClickAwayListener onClickAway={() => {
                                                    setOpen(false)
                                                    setIdFolder(null)
                                                }}>
                                                    <ul className={classes.menuCard}>
                                                        <li onClick={() => {
                                                            setOpen(false)
                                                            setEditing(true)
                                                            setName(folder.name)
                                                        }}>
                                                            <a>
                                                                <i className={`${classes.img_menuCard} ${classes.icon_menuCard4}`}/>
                                                                名前を変更
                                                            </a>
                                                        </li>
                                                        <li onClick={() => setOpenConfirm(true)}>
                                                            <a>
                                                                <i className={`${classes.img_menuCard} ${classes.icon_menuCard6}`}/>
                                                                削除
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </ClickAwayListener>
                                            }
                                            {
                                                folder.name !== "Myインサイトマインド" ?
                                                    <div className={classes.file_h_icon} onClick={() => {
                                                        setOpen(true)
                                                        setIdFolder(folder.id)
                                                    }
                                                    }>
                                                        <span className="glyphicon glyphicon-option-horizontal"/>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                    }

                </div>
            </DialogContent>
            <Dialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialogConfirm}
            >
                <DialogTitle>Are you sure ?</DialogTitle>
                <DialogActions>
                    <ButtonCustom
                        backgroundColor={"rgba(212, 63, 58, 1)"}
                        backgroundBlur={"rgba(212, 63, 58, 0.5)"}
                        onClick={handleDelete}
                    >
                        削除</ButtonCustom>
                    <ButtonCustom
                        backgroundColor={"rgba(119, 119, 119, 1)"}
                        backgroundBlur={"rgba(119, 119, 119, 0.5)"}
                        onClick={() => setOpenConfirm(false)}
                    >
                        キャンセル
                    </ButtonCustom>
                </DialogActions>
            </Dialog>
            <CreateNewFolder
                openCreate={openNewFolder}
                setOpenCreate={setOpenNewFolder}
            />
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiDialogTitle-root": {
            background: "-webkit-linear-gradient(45deg, rgba(43, 187, 173, 1) 0%, rgba(43, 187, 173, 0.5) 49%, rgba(43, 187, 173, 0.25) 50%, rgba(43, 187, 173, 0.75) 100%)",
            color: "white",
            fontWeight: "bold",
            padding: 15,
            position: 'relative'
        },
        width: '100%',
        '& .MuiDialog-paper': {
            maxWidth: '1200px',
            position: 'absolute',
            top: "50px",
            right: 20,
            left: 20,
            margin: "auto"
        },
        '& .MuiDialogContent-root': {
            overflowX: 'hidden'
        },
        paddingBottom: 50
    },
    close: {
        float: "right",
        padding: 0,
        textAlign: 'center'
    },
    create_flat_body: {
        maxHeight: 'calc(100vh - 200px)',
        "& li": {
            display: "inline-block",
            margin: '0 2px',
            cursor: "pointer",
        },
    },
    file_padding_body: {
        padding: ' 20px 0'
    },
    create_flat_header: {
        textAlign: 'center',
        marginBottom: 20
    },
    create_flat_body_left: {
        display: "inline-block",
        "&:hover": {
            cursor: 'pointer',
        },
        "& a": {
            textDecoration: 'none',
            color: 'black',
        }
    },
    create_flat_modal_folder: {
        position: 'relative',
        textDecoration: 'none',
        color: 'black',
        fontSize: 18
    },
    create_flat_bg_icon: {
        backgroundImage: `url(${Background})`,
        width: 20,
        height: 20,
        backgroundRepeat: "no-repeat",
        position: "absolute",
    },
    create_flat_add_icon: {
        backgroundPosition: '-99px -259px',
        marginLeft: 10,
        marginTop: 5
    },
    create_flat_menuRight: {
        float: "right",
        margin: 0,
    },
    create_flat_img_menuRight: {
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        width: 33,
        height: 30,
        position: "relative",
        display: "block",
    },
    create_flat_img_menuRight_icon: {
        backgroundPosition: '-6px -6px'
    },
    create_flat_padding: {
        padding: ' 20px 0'
    },
    create_flat_content: {
        border: '2px solid #ddddd6',
        backgroundColor: '#fff',
        position: 'relative',
        width: '25%',
        display: 'inline-block',
        marginRight: 30,
        marginBottom: "20px",
        verticalAlign: "top",

        "& p": {
            margin: '0 0 0 30px',
            padding: 10,
            color: 'black',
            textOverflow: "ellipsis",
            overflow: "hidden",
            paddingRight: 40
        },
        "& a": {
            textDecoration: 'none',
        },
        "& li": {
            listStyleType: 'none',
        },
        "&:hover": {
            cursor: 'pointer',
            background: "rgba(200, 200, 194, 0.75)",
        },
        [theme.breakpoints.down('md')]: {
            width: "30%"
        },
        [theme.breakpoints.down('sm')]: {
            width: "40%"
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
    },
    create_flat_icon: {
        margin: 11,
        backgroundPosition: '-10px -258px'
    },
    create_flat_h_folder_icon: {
        position: 'absolute !important',
        right: 5,
        top: 5,
        borderRadius: 16,
        backgroundColor: '#c8c8c2',
        width: 30,
        height: 30,
        textAlign: 'center',
        padding: '5px 0',
        zIndex: 10,
        "& span ": {
            color: '#fff',
            fontSize: 20,
            fontWeight: 900
        },
        "&:hover": {
            backgroundColor: '#808080',
        }
    },
    textField: {
        width: "100%",
        height: 43,
        verticalAlign: "middle",
        "& input": {
            padding: "10px 50px",
        }
    },
    file_img_menuRight: {
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        width: 33,
        height: 30,
        position: "relative",
        display: "block",
    },
    icon1: {
        backgroundPosition: "-6px -6px",
    },
    icon4: {
        backgroundPosition: "-6px -283px",
    },
    timeCreateAt: {
        fontSize: 13,
        position: "absolute",
        right: "50px",
        top: 13,
        [theme.breakpoints.down('xs')]: {
            position: "static",
            marginLeft: 40,
        },
    },
    file_content2: {
        borderBottom: '1px solid #ddddd6',
        backgroundColor: '#fff',
        position: 'relative',
        width: '100%',
        "& p": {
            margin: '0 0 0 30px',
            padding: 10,
            color: 'black',
            textOverflow: "ellipsis",
            overflow: "hidden",
            paddingRight: 40
        },
        "& a": {
            textDecoration: 'none',
        },
        "& li": {
            listStyleType: 'none',
        },
        "&:hover": {
            cursor: 'pointer',
            background: "rgba(200, 200, 194, 0.75)",
        }
    },
    file_h_icon: {
        position: 'absolute !important',
        right: 5,
        top: 5,
        borderRadius: 16,
        backgroundColor: '#c8c8c2',
        width: 30,
        height: 30,
        textAlign: 'center',
        padding: '5px 0',
        zIndex: 11,
        "& span ": {
            color: '#fff',
            fontSize: 20,
            fontWeight: 900
        },
        "&:hover": {
            backgroundColor: '#808080',
        }
    },
    menuCard: {
        zIndex: 12,
        listStyleType: "none",
        minWidth: 160,
        margin: '2px 0 0',
        fontSize: 14,
        backgroundColor: '#fff',
        position: "absolute",
        top: 43,
        left: "auto",
        right: 0,
        boxShadow: "0 6px 12px rgba(0,0,0,.175)",
        border: "1px solid rgba(0,0,0,.15)",
        borderRadius: "4px",
        padding: 0,
        "& > li": {
            cursor: "pointer",
            position: "relative",
            "&:hover": {
                color: '#262626',
                backgroundColor: '#e0e0e0',
                "& > ul": {
                    display: "block"
                }
            }
        },
        "& >li > a": {
            padding: '5px 20px 5px 40px',
            display: 'block',
            clear: 'both',
            fontWeight: 400,
            lineHeight: 1.42857143,
            color: '#333',
            whiteSpace: 'nowrap',
            textDecoration: 'none',
        },
    },
    img_menuCard: {
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        width: 20,
        height: 20,
        position: "absolute",
        display: "block",
    },
    icon_menuCard4: {
        backgroundPosition: "-10px -160px",
        marginLeft: '-20px'
    },
    icon_menuCard6: {
        backgroundPosition: "-10px -226px",
        marginLeft: '-20px'
    },
    file_bg_icon: {
        backgroundImage: `url(${Background})`,
        width: 20,
        height: 20,
        backgroundRepeat: "no-repeat",
        position: "absolute",
    },
    file_icon: {
        margin: 11,
        backgroundPosition: '-10px -258px'
    },
}))

export default CreateInsightMapFlatDialog;
