import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from '@material-ui/core'
import _ from "lodash";
import QuantizingDrawing from "../../Process/HandleActions/QuantizingDrawing";
import ProcessData from "../../Process/HandleActions/ProcessData";
import TagsData from "../../Process/HandleActions/TagsData";
import TasksData from "../../Process/HandleActions/TasksData";
import MemoData from "../../Process/HandleActions/MemoData";

function CaptureFooter({map}) {
    const classes = useStyles()
    return (
        <div className={`${classes.root} print-hidden`} id={"map-footer-capture"}>
            <Grid container className={classes.yearContainer}>
                <Grid item xs={6}>

                </Grid>
                <Grid item xs={6}>
                    <input
                        value={map.years}
                        className={classes.yearInput}
                        onChange={() => {

                        }}
                    />
                    {
                        map.years ?
                            <span
                                className={classes.yearInfo}>{Number(map.years) * 365}日間{Number(map.years) * 52}週間{Number(map.years) * 12}ヶ月間{Number(map.years)}年間</span>
                            :
                            null

                    }

                </Grid>

            </Grid>
            {
                Boolean(map?.quantizing) && !_.isEmpty(map.quantizing) &&
                <QuantizingDrawing map={map}/>
            }

            <div
                className={classes.container}>
                <div className={classes.tableBox}>
                    <div className={`${classes.tableCell66}`}>
                        <div className={classes.titleProcess}><span>プロセス</span></div>
                        <ProcessData
                            classes={classes}
                            map={map}
                            disabled={true}
                        />
                    </div>
                    <TagsData classes={classes} map={map}/>
                </div>
                <div className={classes.tableBox} style={{marginTop: 20}}>
                    <div className={`${classes.tableCell50} `}>
                        <TasksData
                            classes={classes}
                            map={map}
                            disabled={true}
                        />
                    </div>
                    <div className={`${classes.tableCell50}`}>
                        <MemoData
                            classes={classes}
                            map={map}
                            disabled={true}
                        />
                    </div>
                </div>
            </div>
            <footer className={classes.footer}>Copyright © 2016 WOW WAY.inc. All Rights Reserved.</footer>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        width: "100%",
        minWidth: 1600,
        background: "#e0e0e0",
        overflow: "auto",
        zIndex: -1,
    },
    yearContainer: {
        margin: 30,
    },
    yearInput: {
        width: 150,
        height: 40,
        marginLeft: -75,
        textAlign: "center",
        borderRadius: "5px"
    },
    yearInfo: {
        fontFamily: "Rubik",
        fontSize: "20px",
        lineHeight: "33px",
        color: "#333 !important",
        marginLeft: 20
    },
    footer: {
        textAlign: 'center',
        padding: '20px 0',
        backgroundColor: '#e0e0e0',
        color: '#9e9e9e',
    },
    container: {
        padding: "30px 50px",
    },
    tableBox: {
        display: "table",
        width: "100%"
    },
    tableCell66: {
        display: "table-cell",
        background: "#fff",
        border: "solid 10px #e0e0e0",
        width: "66%",
    },
    titleProcess: {
        background: '#e0e0e0',
        "& span": {
            background: '#fff',
            display: 'inline-block',
            padding: '10px 10px 3px',
            borderRadius: '5px 5px 0 0',
        }
    },
    contentBox: {
        background: '#fff',
        padding: 10,
        minHeight: 170,
    },
    processContent: {
        backgroundColor: '#fff',
        position: 'relative',
        border: '1px solid #000',
        margin: 10,
        height: 130,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& span': {
            fontSize: 40,
            position: 'absolute',
            top: 0,
            left: 10,
            opacity: 0.7,
            zIndex: 2,
            cursor: 'pointer',
        },
    },
    borderWhite: {
        borderColor: 'rgba(0, 0, 0, 0)',
        borderLeftColor: '#000',
        borderWidth: '65px 0 65px 15px',
        marginTop: '-65px',
        left: '100%',
        top: '50%',
        border: 'solid transparent',
        height: 0,
        width: 0,
        position: 'absolute',
    },
    borderBlack: {
        borderColor: 'rgba(255, 255, 255, 0)',
        borderLeftColor: '#fff',
        borderWidth: '66px 0 66px 15px',
        marginTop: '-66px',
        marginLeft: '-1px',
        left: '100%',
        top: '50%',
        border: 'solid transparent',
        height: 0,
        width: 0,
        position: 'absolute',
    },
    input: {
        maxHeight: '100%',
        display: 'inline-block',
        float: 'none',
        width: 'auto',
        minWidth: 60,
        overflowY: 'auto',
        marginLeft: 25,
        fontSize: 17,
        textAlign: 'center',
        padding: 10,
        resize: 'none',
        border: 'none',
        '&:focus': {
            outline: 0,
        },
    },
    tableCell34: {
        display: "table-cell",
        background: "#fff",
        border: "solid 10px #e0e0e0",
        width: "34%",
    },
    tableCell50: {
        display: "table-cell",
        background: "#fff",
        border: "solid 10px #e0e0e0",
        width: "50%",

    },
    formControl: {
        width: '100%',
        height: "30px",
        '& .MuiInput-root': {
            position: 'relative',
            margin: '0 20px 0 50px',
        },
        '& .MuiInputAdornment-positionStart': {
            position: 'absolute',
            right: '100%',
        },
    },
    textArea: {
        maxHeight: '100%',
        float: 'none',
        overflowY: 'auto',
        fontSize: 17,
        resize: 'none',
        width: '100%',
        border: 'none',

        '&:focus': {
            outline: 0,
        },
    },
}))
export default CaptureFooter;
