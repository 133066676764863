import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import {IconButton, LinearProgress} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import DialogContent from '@material-ui/core/DialogContent';
import {dialogData} from './common/fetchDialogData';
import _ from 'lodash';
import DialogActions from '@material-ui/core/DialogActions';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import BlockLightBub from './GridBlockComponent/BlockLightBub';
import rtn from "reactn";
import ButtonCustom from "../ButtonCustom";

const useStyles = makeStyles((theme) => ({
    divRoot: {
        width: '100%',
        '& .MuiDialog-paper': {
            minWidth: '900px',
            margin: 35,
        },
        [theme.breakpoints.down('md')]: {
            '& .MuiDialog-paper': {
                minWidth: '768px',
                margin: 25,
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiDialog-paper': {
                minWidth: '600px',
                margin: 10,
            },
        },
        [theme.breakpoints.down('xs')]: {
            '& .MuiDialog-paper': {
                minWidth: 'auto',
            },
        },
    },
    btnMoveDialog: {
        color: '#fff !important',
        marginRight: '30px',
        // float: "right",
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    divTitle: {
        // display: 'flex',
        position: "relative",
        '& .MuiTypography-h6': {
            float: 'right',
        },
    },
    close: {
        position: "absolute",
        right: 10,
        top: 10,
        zIndex: 10,
        // float: "right",
        // padding: 0
    },
    contentDialog: {
        maxHeight: 'unset',
        height: '700px',
        padding: '20px 29px !important',
        textAlign: 'center',
    },
    contentNumber: {
        fontSize: '45px',
    },
    contentTitle: {
        fontWeight: 600,
    },
    contentTitleEng: {
        fontSize: '12px',
    },
    contentMemo: {
        textAlign: 'justify',
        margin: '40px 0',
        position: 'relative',
    },
    highLight: {},
    dotDiv: {
        height: '25px',
        borderBottom: '1px dotted #7d7d7d',
        width: '100%',
    },
    spanDiv: {
        width: '100%',
        lineHeight: '25px',
        display: "block",
        textDecorationLine: "underline",
        textDecorationStyle: "dotted"
    },
    footerDiv: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#f0f0f0',
        borderTop: '2px solid #ccc',
        borderRadius: '0 0 5px 5px',
        textAlign: 'center',
    },
    btnConflict: {
        // boxShadow: "0 4px rgba(112, 195, 237, 0.5)",
        color: '#fff',
        marginBottom: 10,
        marginTop: 10,
        display: 'flex'
    },
    btnDefault: {
        color: '#333',
        backgroundColor: '#fff',
        borderColor: '#ccc',
        marginBottom: 15,
        marginTop: 15
    },
    clear: {
        clear: 'both',
    },
    lightBub: {
        border: '1px solid #e5e5e5',
        padding: '20px 5px!important',
        boxSizing: 'border-box',
        marginBottom: '22px',
        textAlign: 'justify',
    },
    gridLightBub: {},
    gridLightBubLeft: {
        padding: '10px',
    },
    gridLightBubRight: {},
    gridLightBubImg: {
        maxWidth: '100%',
        width: 'auto',
        display: 'inline-block',
    },
    lead: {
        marginBottom: '20px',
        fontSize: 'initial',
        fontWeight: '300',
        lineHeight: 1.4,
        margin: '0 0 10px',
    },
    faStack: {
        marginRight: '10px',
    },
    quantizingColor: {
        padding: '0 20px',
        textAlign: 'initial !important',
    },
    iconBtn: {
        marginLeft: '5px',
        fontSize: '21px'
    },
    btnFlex: {
        marginBottom: 15,
        marginTop: 15,
        display: 'flex !important'
    }
}));

function DialogGuideComponent(props) {
    const {conflict, setConflict, mapDialogInfo, setMapDialogInfo, setSelectStep} = props;
    const classes = useStyles();
    const [value, setValue] = useState(null);
    const [, setMenuMapSetting] = rtn.useGlobal("menuMapSetting");

    useEffect(() => {
        if (mapDialogInfo) {
            // console.log(mapDialogInfo, 'info');
            let index = _.findIndex(dialogData, (d) => d.index === mapDialogInfo.index);
            if (index !== -1) {
                // console.log(dialogData[index], 'index');
                setValue(dialogData[index]);
            }
        }
    }, [mapDialogInfo]);
    const onClose = () => {
        setMapDialogInfo(null);
    };

    const handleSave = () => {
        if (value.index === 1) {
            setMapDialogInfo({
                index: 2,
                name: 'mapping',
                headerText: 'ストーリー',
                imgUrl: '/img/menu/icon2.png',
                imgUrlHover: '/img/menu/icon2-hover.png',
            });
            setSelectStep('mapping');
            return;
        }
        setMapDialogInfo(null);
        //
    };

    const handleMoveDialog = () => {
        setMenuMapSetting(true)
        setMapDialogInfo(null)
    }


    if (!value) return <LinearProgress/>;
    return (
        <Dialog open={Boolean(mapDialogInfo)} onClose={onClose} className={classes.divRoot}>
            <DialogTitle className={classes.divTitle} style={{background: value.background}}>
                <span className={classes.btnMoveDialog} onClick={handleMoveDialog}> ガイドをOFF</span>
                <IconButton
                    className={classes.close}
                    onClick={() => {
                        setMapDialogInfo(null);
                    }}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.contentDialog}>
                <div className={classes.contentNumber} style={{color: value.color}}>
                    {value.index}
                </div>
                <div className={classes.contentTitle}>{value.title}</div>
                <div className={classes.contentTitleEng}>{value.titleEng}</div>
                <div className={classes.contentMemo}>
                    <div className={classes.highLight}>
                        {/*{[1, 2, 3, 4, 5].map((s) => {*/}
                        {/*    return <div key={s} className={classes.dotDiv}/>;*/}
                        {/*})}*/}
                        <span className={classes.spanDiv}>
                            {value?.memo1 || null}
                        </span>
                        <span className={classes.spanDiv}>
                            {value?.memo2 || null}
                        </span>
                        <span className={classes.spanDiv}>
                            {value?.memo3 || null}
                        </span>
                        <span className={classes.spanDiv}>
                            {value?.memo4 || null}
                        </span>
                        <span className={classes.spanDiv}>
                            {value?.memo5 || null}
                        </span>
                        <span className={classes.spanDiv}>
                            {value?.memo6 || null}
                        </span>
                        <span className={classes.spanDiv}>
                            {value?.memo7 || null}
                        </span>
                    </div>
                </div>
                <BlockLightBub
                    classes={classes}
                    value={value}
                    conflict={conflict}
                    setConflict={setConflict}
                    handleSave={handleSave}
                />
            </DialogContent>
            <DialogActions className={classes.footerDiv}>
                {value.index === 1 ? (
                    <>
                        <ButtonCustom
                            onClick={onClose}
                            className={`${classes.btnDefault}`}
                            backgroundColor={"rgba(103, 131, 194,1)"}
                            backgroundBlur={"rgba(103, 131, 194, 0.5)"}
                        >
                            キャンセル
                        </ButtonCustom>

                        <ButtonCustom
                            form="conflictForm"
                            type="submit"
                            className={`${classes.btnConflict}`}
                            style={{background: value.color}}
                            backgroundColor={"rgba(103, 131, 194,1)"}
                            backgroundBlur={"rgba(103, 131, 194, 0.5)"}
                        >
                            登録
                        </ButtonCustom>
                    </>
                ) : (
                    <ButtonCustom
                        onClick={handleSave}
                        className={`${classes.btnFlex}`}
                        type="submit"
                        backgroundColor={`${value.color}`}
                        backgroundBlur={`${value.colorBlur}`}
                    >
                        {value.buttonText}
                        <PlayArrowIcon className={classes.iconBtn}/>
                    </ButtonCustom>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default DialogGuideComponent;
