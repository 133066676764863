export const barrierMap = [
    {
        id: "05",
        x: "-40",
        y: "-115",
        transform: "matrix(0.7071,0.7071,-0.7071,0.7071,0,0)",
    },
    {
        id: "06",
        x: "-40",
        y: "-115",
        transform: "matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)",
    },
    {
        id: "07",
        x: "-40",
        y: "-115",
        transform: "matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)",
    },
    {
        id: "08",
        x: "-40",
        y: "-115",
        transform: "matrix(0.7071,-0.7071,0.7071,0.7071,0,0)",
    },
    {
        id: "13",
        x: "-40",
        y: "-230",
        transform: "matrix(0.9239,0.3827,-0.3827,0.9239,0,0)",
    },
    {
        id: "14",
        x: "-40",
        y: "-230",
        transform: "matrix(0.3827,0.9239,-0.9239,0.3827,0,0)",
    },
    {
        id: "15",
        x: "-40",
        y: "-230",
        transform: "matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)",
    },
    {
        id: "16",
        x: "-40",
        y: "-230",
        transform: "matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)",
    },
    {
        id: "17",
        x: "-40",
        y: "-230",
        transform: "matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)",
    },
    {
        id: "18",
        x: "-40",
        y: "-230",
        transform: "matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)",
    },
    {
        id: "19",
        x: "-40",
        y: "-230",
        transform: "matrix(0.3827,-0.9239,0.9239,0.3827,0,0)",
    },
    {
        id: "20",
        x: "-40",
        y: "-230",
        transform: "matrix(0.9239,-0.3827,0.3827,0.9239,0,0)",
    },
    {
        id: "29",
        x: "-40",
        y: "-340",
        transform: "matrix(0.9808,0.1951,-0.1951,0.9808,0,0)",
    },
    {
        id: "30",
        x: "-40",
        y: "-340",
        transform: "matrix(0.8315,0.5556,-0.5556,0.8315,0,0)",
    },
    {
        id: "31",
        x: "-40",
        y: "-340",
        transform: "matrix(0.5556,0.8315,-0.8315,0.5556,0,0)",
    },
    {
        id: "32",
        x: "-40",
        y: "-340",
        transform: "matrix(0.1951,0.9808,-0.9808,0.1951,0,0)",
    },
    {
        id: "33",
        x: "-40",
        y: "-340",
        transform: "matrix(-0.1951,0.9808,-0.9808,-0.1951,0,0)",
    },
    {
        id: "34",
        x: "-40",
        y: "-340",
        transform: "matrix(-0.5556,0.8315,-0.8315,-0.5556,0,0)",
    },
    {
        id: "35",
        x: "-40",
        y: "-340",
        transform: "matrix(-0.8315,0.5556,-0.5556,-0.8315,0,0)",
    },
    {
        id: "36",
        x: "-40",
        y: "-340",
        transform: "matrix(-0.9808,0.1951,-0.1951,-0.9808,0,0)",
    },
    {
        id: "37",
        x: "-40",
        y: "-340",
        transform: "matrix(-0.9808,-0.1951,0.1951,-0.9808,0,0)",
    },
    {
        id: "38",
        x: "-40",
        y: "-340",
        transform: "matrix(-0.8315,-0.5556,0.5556,-0.8315,0,0)",
    },
    {
        id: "39",
        x: "-40",
        y: "-340",
        transform: "matrix(-0.5556,-0.8315,0.8315,-0.5556,0,0)",
    },
    {
        id: "40",
        x: "-40",
        y: "-340",
        transform: "matrix(-0.1951,-0.9808,0.9808,-0.1951,0,0)",
    },
    {
        id: "41",
        x: "-40",
        y: "-340",
        transform: "matrix(0.1951,-0.9808,0.9808,0.1951,0,0)",
    },
    {
        id: "42",
        x: "-40",
        y: "-340",
        transform: "matrix(0.5556,-0.8315,0.8315,0.5556,0,0)",
    },
    {
        id: "43",
        x: "-40",
        y: "-340",
        transform: "matrix(0.8315,-0.5556,0.5556,0.8315,0,0)",
    },
    {
        id: "44",
        x: "-40",
        y: "-340",
        transform: "matrix(0.9808,-0.1951,0.1951,0.9808,0,0)",
    },
    {
        id: "61",
        x: "-40",
        y: "-450",
        transform: "matrix(0.9808,0.1951,-0.1951,0.9808,0,0)",
    },
    {
        id: "62",
        x: "-40",
        y: "-450",
        transform: "matrix(0.8315,0.5556,-0.5556,0.8315,0,0)",
    },
    {
        id: "63",
        x: "-40",
        y: "-450",
        transform: "matrix(0.5556,0.8315,-0.8315,0.5556,0,0)",
    },
    {
        id: "64",
        x: "-40",
        y: "-450",
        transform: "matrix(0.1951,0.9808,-0.9808,0.1951,0,0)",
    },
    {
        id: "65",
        x: "-40",
        y: "-450",
        transform: "matrix(-0.1951,0.9808,-0.9808,-0.1951,0,0)",
    },
    {
        id: "66",
        x: "-40",
        y: "-450",
        transform: "matrix(-0.5556,0.8315,-0.8315,-0.5556,0,0)",
    },
    {
        id: "67",
        x: "-40",
        y: "-450",
        transform: "matrix(-0.8315,0.5556,-0.5556,-0.8315,0,0)",
    },
    {
        id: "68",
        x: "-40",
        y: "-450",
        transform: "matrix(-0.9808,0.1951,-0.1951,-0.9808,0,0)",
    },
    {
        id: "69",
        x: "-40",
        y: "-450",
        transform: "matrix(-0.9808,-0.1951,0.1951,-0.9808,0,0)",
    },
    {
        id: "70",
        x: "-40",
        y: "-450",
        transform: "matrix(-0.8315,-0.5556,0.5556,-0.8315,0,0)",
    },
    {
        id: "71",
        x: "-40",
        y: "-450",
        transform: "matrix(-0.5556,-0.8315,0.8315,-0.5556,0,0)",
    },
    {
        id: "72",
        x: "-40",
        y: "-450",
        transform: "matrix(-0.1951,-0.9808,0.9808,-0.1951,0,0)",
    },
    {
        id: "73",
        x: "-40",
        y: "-450",
        transform: "matrix(0.1951,-0.9808,0.9808,0.1951,0,0)",
    },
    {
        id: "74",
        x: "-40",
        y: "-450",
        transform: "matrix(0.5556,-0.8315,0.8315,0.5556,0,0)",
    },
    {
        id: "75",
        x: "-40",
        y: "-450",
        transform: "matrix(0.8315,-0.5556,0.5556,0.8315,0,0)",
    },
    {
        id: "76",
        x: "-40",
        y: "-450",
        transform: "matrix(0.9808,-0.1951,0.1951,0.9808,0,0)",
    },
    {
        id: "85",
        x: "-40",
        y: "-560",
        transform: "matrix(0.9239,0.3827,-0.3827,0.9239,0,0)",
    },
    {
        id: "86",
        x: "-40",
        y: "-560",
        transform: "matrix(0.3827,0.9239,-0.9239,0.3827,0,0)",
    },
    {
        id: "87",
        x: "-40",
        y: "-560",
        transform: "matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)",
    },
    {
        id: "88",
        x: "-40",
        y: "-560",
        transform: "matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)",
    },
    {
        id: "89",
        x: "-40",
        y: "-560",
        transform: "matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)",
    },
    {
        id: "90",
        x: "-40",
        y: "-560",
        transform: "matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)",
    },
    {
        id: "91",
        x: "-40",
        y: "-560",
        transform: "matrix(0.3827,-0.9239,0.9239,0.3827,0,0)",
    },
    {
        id: "92",
        x: "-40",
        y: "-560",
        transform: "matrix(0.9239,-0.3827,0.3827,0.9239,0,0)",
    },
    {
        id: "97",
        x: "-40",
        y: "-665",
        transform: "matrix(0.7071,0.7071,-0.7071,0.7071,0,0)",
    },
    {
        id: "98",
        x: "-40",
        y: "-665",
        transform: "matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)",
    },
    {
        id: "99",
        x: "-40",
        y: "-665",
        transform: "matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)",
    },
    {
        id: "100",
        x: "-40",
        y: "-665",
        transform: "matrix(0.7071,-0.7071,0.7071,0.7071,0,0)",
    },
    {
        id: "103",
        x: "-40",
        y: "-775",
        transform: "matrix(0,1,-1,0,0,0)",
    },
    {
        id: "104",
        x: "-40",
        y: "-775",
        transform: "matrix(0,-1,1,0,0,0)",
    },
]

export const miniBarrierMap = [
    {
        id: "05",
        x: "-40",
        y: "-115",
        transform: "matrix(0.7071,0.7071,-0.7071,0.7071,0,0)",
    },
    {
        id: "06",
        x: "-40",
        y: "-115",
        transform: "matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)",
    },
    {
        id: "07",
        x: "-40",
        y: "-115",
        transform: "matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)",
    },
    {
        id: "08",
        x: "-40",
        y: "-115",
        transform: "matrix(0.7071,-0.7071,0.7071,0.7071,0,0)",
    },
    {
        id: "13",
        x: "-40",
        y: "-230",
        transform: "matrix(0.9239,0.3827,-0.3827,0.9239,0,0)",
    },
    {
        id: "14",
        x: "-40",
        y: "-230",
        transform: "matrix(0.3827,0.9239,-0.9239,0.3827,0,0)",
    },
    {
        id: "15",
        x: "-40",
        y: "-230",
        transform: "matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)",
    },
    {
        id: "16",
        x: "-40",
        y: "-230",
        transform: "matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)",
    },
    {
        id: "17",
        x: "-40",
        y: "-230",
        transform: "matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)",
    },
    {
        id: "18",
        x: "-40",
        y: "-230",
        transform: "matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)",
    },
    {
        id: "19",
        x: "-40",
        y: "-230",
        transform: "matrix(0.3827,-0.9239,0.9239,0.3827,0,0)",
    },
    {
        id: "20",
        x: "-40",
        y: "-230",
        transform: "matrix(0.9239,-0.3827,0.3827,0.9239,0,0)",
    },
    {
        id: "29",
        x: "-40",
        y: "-340",
        transform: "matrix(0.9239,0.3827,-0.3827,0.9239,0,0)",
    },
    {
        id: "30",
        x: "-40",
        y: "-340",
        transform: "matrix(0.3827,0.9239,-0.9239,0.3827,0,0)",
    },
    {
        id: "31",
        x: "-40",
        y: "-340",
        transform: "matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)",
    },
    {
        id: "32",
        x: "-40",
        y: "-340",
        transform: "matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)",
    },
    {
        id: "33",
        x: "-40",
        y: "-340",
        transform: "matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)",
    },
    {
        id: "34",
        x: "-40",
        y: "-340",
        transform: "matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)",
    },
    {
        id: "35",
        x: "-40",
        y: "-340",
        transform: "matrix(0.3827,-0.9239,0.9239,0.3827,0,0)",
    },
    {
        id: "36",
        x: "-40",
        y: "-340",
        transform: "matrix(0.9239,-0.3827,0.3827,0.9239,0,0)",
    },
]
