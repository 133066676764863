import rtn from 'reactn';

rtn.addCallback((global) => {
    // if (IS_PRODUCTION) {
    //     console.log("Global diff", difference(global, latestGlobal))
    //     latestGlobal = global
    // }
    if (typeof window !== 'undefined') window.global = global
    // console.log({global})
});

rtn.setGlobal({
    user: {},
    loading: false,
    insightMindUser: {},
    langDefault: 'ja-jp',
    mapDialogInfo: null,
    arrangement: "上",
    menuMapSetting: false,
    openCreateInsightMapFlatDialog: false,
    openDialogIdentityTag: false,
    openDialogSupport: false,
    openInvite: false,
    expDate: null,
    openNewIdentityTag: false,
    openEditIdentityTag: false,
    maps: [],
    folders: [],
    anonymous: false,
    downloadFile: null,
    mapShareData: []
});
