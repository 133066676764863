import React from 'react';
import {Grid} from "@material-ui/core";

function IdentityTagList(props) {
    const {classes, listIdentities, color, setColor} = props;

    return (
        <div className={classes.list_Identities}>
            <Grid container spacing={2} justify={'center'}>
                {listIdentities.map((item) => {
                    return (
                        <Grid
                            item
                            xs={2}
                            className={classes.identityTagImg}
                            onClick={() => {
                                setColor(item);
                            }}
                            key={item}
                        >
                            <img
                                className={classes.iconIdentity}
                                alt={item}
                                src={`img/pawn/${item}.png`}
                            />
                            <div style={{backgroundColor: item, width: color === item ? "40px" : "0px"}}
                                 className={classes.identityTagImgBottom}/>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
}

export default IdentityTagList;
