export const dataMenu = [
    {
        index: 1,
        title: '内面の理解',
        data: [
            {
                index: 1,
                name: 'conflict',
                headerText: '葛藤',
                imgUrl: '/img/menu/icon1.png',
                imgUrlHover: '/img/menu/icon1-hover.png',
            },
            {
                index: 2,
                name: 'mapping',
                headerText: 'ストーリー',
                imgUrl: '/img/menu/icon2.png',
                imgUrlHover: '/img/menu/icon2-hover.png',
            },
            {
                index: 3,
                name: 'borderline',
                headerText: '境界線',
                imgUrl: '/img/menu/icon3.png',
                imgUrlHover: '/img/menu/icon3-hover.png',
            },
            {
                index: 4,
                name: 'quantizing',
                headerText: 'エネルギー',
                imgUrl: '/img/menu/icon4.png',
                imgUrlHover: '/img/menu/icon4-hover.png',
            },
        ],
    },
    {
        index: 2,
        title: '自己の理解',
        data: [
            {
                index: 5,
                name: 'space',
                headerText: 'バイアス',
                imgUrl: '/img/menu/icon5.png',
                imgUrlHover: '/img/menu/icon5-hover.png',
            },
            {
                index: 6,
                name: 'identity',
                headerText: '主体',
                imgUrl: '/img/menu/icon6.png',
                imgUrlHover: '/img/menu/icon6-hover.png',
            },
            {
                index: 7,
                name: 'removing',
                headerText: 'クリア',
                imgUrl: '/img/menu/icon7.png',
                imgUrlHover: '/img/menu/icon7-hover.png',
            },
            {
                index: 8,
                name: 'barrier',
                headerText: 'ブロック',
                imgUrl: '/img/menu/icon8.png',
                imgUrlHover: '/img/menu/icon8-hover.png',
            },
        ],
    },
    {
        index: 3,
        title: '自己の実現',
        data: [
            {
                index: 9,
                name: 'finding',
                headerText: '主体の選択',
                imgUrl: '/img/menu/icon9.png',
                imgUrlHover: '/img/menu/icon9-hover.png',
            },
            {
                index: 10,
                name: 'key',
                headerText: '変革の鍵',
                imgUrl: '/img/menu/icon10.png',
                imgUrlHover: '/img/menu/icon10-hover.png',
            },
            {
                index: 11,
                name: 'process',
                headerText: 'プロセス',
                imgUrl: '/img/menu/icon11.png',
                imgUrlHover: '/img/menu/icon11-hover.png',
            },
            {
                index: 12,
                name: 'task',
                headerText: 'タスク',
                imgUrl: '/img/menu/icon12.png',
                imgUrlHover: '/img/menu/icon12-hover.png',
            },
        ],
    },
];
