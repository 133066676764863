import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import ProcessDialog from "../ProcessDialog";

const processDefault = [
    {
        content: "",
        color: "gray",
    },
    {
        content: "",
        color: "gray",
    },
    {
        content: "",
        color: "gray",
    },
    {
        content: "",
        color: "gray",
    }
]

function ProcessData(props) {
    const {classes, map, updateField, disabled = false} = props;
    const [processArray, setProcessArray] = useState(map?.process && map.process.length !== 0 ? map.process : processDefault);
    const [open, setOpen] = useState(null)
    const handleChange = (idx, data) => {
        if (disabled)
            return
        processArray[idx].content = data;
        setProcessArray([...processArray])
    };

    const handleBlur = (index) => {
        if (disabled)
            return
        if (!map.process || !map.process[index] || map.process[index].content !== processArray[index].content)
            updateField('process', processArray, null, true)
    };
    const updateColor = (idx, color) => {
        if (disabled)
            return
        processArray[idx].color = color
        updateField('process', processArray, null, true)
        setOpen(null)
    }
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.contentBox}
        >
            {
                processArray.map((value, index) => {
                    if (index <= 3)
                        return (
                            <Grid key={index} item xs={disabled ? 3 : 12} sm={disabled ? 3 : 12} md={3}>
                                <div className={classes.processContent}>
                                    <span style={{color: value.color}}
                                          onClick={() => {
                                              if (disabled)
                                                  return
                                              setOpen(index + 1)
                                          }}
                                    >{index + 1}</span>
                                    <textarea
                                        type="text"
                                        value={value.content}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        onBlur={(e) => handleBlur(index)}
                                        className={classes.input}
                                        rows="4"
                                    />
                                    <div className={classes.borderWhite}/>
                                    <div className={classes.borderBlack}/>
                                </div>
                            </Grid>
                        );
                    return null
                })
            }
            <ProcessDialog open={open} setOpen={setOpen} processArray={processArray} onOk={updateColor}/>
        </Grid>
    );
}

export default ProcessData;
