import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {mappingListLeft, mappingListRight, widthImg} from "../actions"
import _ from "lodash"

const useStyles = makeStyles(theme => ({
    divRoot: {
        width: "100%",
        marginTop: "5px",
        padding: "30px 40px 30px 70px",
        // [theme.breakpoints.down('xs')]: {
        //     display: "none"
        // },
    },
    drawBox: {
        padding: "15px",
        backgroundColor: "#fff",
        borderRadius: 5,
    },
    quantizingLeft: {},
    quantizingRight: {},
    gridDraw6: {
        display: "inline-flex",
        alignItems: "center"
    },
    gridDraw6Left: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    quantizingLeftDraw: {
        float: "right",
        position: "relative",
        textAlign: "center",
        display: "flex"
    },
    quantizingRightDraw: {
        float: "left",
        position: "relative",
        textAlign: "center",
        display: "flex"
    },
    pointer: {
        position: "absolute",
        left: 0,
        right: 0,
        top: "20%",
        fontWeight: "bold",
        color: "#ffffff"
    },
    ImgSpan: {
        position: "relative"
    },
    arrowLine: {
        width: "100%",
        display: "inline-flex",
        alignItems: "center"
    },
    Line: {
        width: "100%",
        height: "10px",
    },
    PointLeft: {
        width: 0,
        height: 0,
        borderTop: "15px solid transparent",
        borderBottom: "15px solid transparent",
    },
    PointRight: {
        width: 0,
        height: 0,
        borderTop: "15px solid transparent",
        borderBottom: "15px solid transparent",
    }
}));

function QuantizingDrawing({map}) {
    const classes = useStyles();
    // const {map} = useInsightMaps();
    const [showLeft, setShowLeft] = useState([]);
    const [showRight, setShowRight] = useState([]);
    const [showLeftNegative, setShowLeftNegative] = useState([]);
    const [showRightNegative, setShowRightNegative] = useState([]);
    const [maxPoint, setMaxPoint] = useState(0);
    useEffect(() => {
        generateQuantizingDraw()
    }, [map]);

    const generateQuantizingDraw = () => {
        let listMapping = Object.keys(map?.quantizing || {}).sort();
        let itemsLeft = [];
        let itemsRight = [];
        let itemsLeftNegative = [];
        let itemsRightNegative = [];
        let pointLeft = 0;
        let pointLeftNegative = 0;
        let pointRight = 0;
        let pointRightNegative = 0;
        if (listMapping.length === 0)
            return;
        listMapping.forEach(item => {
            let point = Number(_.split(map?.quantizing[item], ',')[2]);
            if (mappingListLeft.includes(item) && point > 0) {
                itemsLeft.push(item);
                pointLeft += Number(point)
            }
            if (mappingListLeft.includes(item) && point <= 0) {
                itemsLeftNegative.push(item);
                pointLeftNegative += Math.abs(point)
            }
            if (mappingListRight.includes(item) && point > 0) {
                itemsRight.push(item);
                pointRight += Number(point)
            }
            if (mappingListRight.includes(item) && point <= 0) {
                itemsRightNegative.push(item);
                pointRightNegative += Math.abs(point)
            }
        });
        setMaxPoint(Math.max(pointLeft, pointRight, pointLeftNegative, pointRight));
        setShowLeft(itemsLeft);
        setShowRight(itemsRight);
        setShowLeftNegative(itemsLeftNegative);
        setShowRightNegative(itemsRightNegative)
    };

    const getData = (value) => {
        if (value > 0 && value <= 19)
            return {font: '12.5px', img: '1-19'}
        if (value >= 20 && value <= 39)
            return {font: '14px', img: '20-39'}
        if (value >= 40 && value <= 59)
            return {font: '15.5px', img: '40-59'}
        if (value >= 60 && value <= 79)
            return {font: '17px', img: '60-79'}
        if (value >= 80 && value <= 99)
            return {font: '18.5px', img: '80-99'}
        if (value >= 100)
            return {font: '20px', img: '100'}
        if (value <= 0 && value >= -19)
            return {font: '12.5px', img: 'negative-1-19'}
        if (value <= -20 && value >= -39)
            return {font: '14px', img: 'negative-20-39'}
        if (value <= -40 && value >= -59)
            return {font: '15.5px', img: 'negative-40-59'}
        if (value <= -60 && value >= -79)
            return {font: '17px', img: 'negative-60-79'}
        if (value <= -80 && value >= -99)
            return {font: '18.5px', img: 'negative-80-99'}
        if (value <= -100)
            return {font: '20px', img: 'negative-100'}
    };

    return (
        <div className={classes.divRoot}>
            <div className={classes.drawBox}>
                <div className={classes.quantizingLeft}>
                    <Grid container>
                        <Grid item xs={6} className={classes.gridDraw6Left}>
                            {
                                showLeft.map((item, index) => {
                                    if (!map?.quantizing[item])
                                        return null
                                    let itemData = _.split(map?.quantizing[item], ',');
                                    const widthIdx = Math.floor(Math.abs(Number(itemData[2])) / 20)
                                    const minWidth = widthImg[itemData[0]][widthIdx] + (Number(itemData[2]) !== 0 ? 20 : 0)
                                    return (
                                        <div style={{
                                            width: `${(Number(itemData[2]) / maxPoint * 100)}%`,
                                            minWidth
                                        }} key={index}
                                             className={classes.quantizingLeftDraw}>
                                            <div className={classes.ImgSpan}>
                                                <img
                                                    src={getData(itemData[2])?.img ? `/img/energymark/${getData(itemData[2])?.img}/${itemData[0]}/${itemData[1]}.png` : ''}
                                                    alt=''/>
                                                <div style={{
                                                    fontSize: getData(itemData[2])?.font,
                                                    top: itemData[0] === 'flame' ? "40%" : "20%"
                                                }}
                                                     className={classes.pointer}>{itemData[2]}</div>
                                            </div>
                                            <div className={classes.arrowLine}>
                                                <div style={{borderRight: `20px solid ${itemData[1]}`}}
                                                     className={classes.PointLeft}/>
                                                <div style={{backgroundColor: itemData[1]}} className={classes.Line}/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                        <Grid item xs={6} className={classes.gridDraw6}>
                            {
                                showRightNegative.map((item, index) => {
                                    if (!map?.quantizing[item])
                                        return null
                                    let itemData = _.split(map?.quantizing[item], ',');
                                    const widthIdx = Math.floor(Math.abs(Number(itemData[2])) / 20)
                                    const minWidth = widthImg[itemData[0]][widthIdx] + (Number(itemData[2]) !== 0 ? 20 : 0)
                                    return (
                                        <div style={{
                                            width: `${(Math.abs(Number(itemData[2])) / maxPoint * 100)}%`,
                                            minWidth
                                        }}
                                             key={index}
                                             className={classes.quantizingRightDraw}>
                                            {
                                                Number(itemData[2]) !== 0 &&
                                                <div className={classes.arrowLine}>
                                                    <div style={{borderRight: `20px solid ${itemData[1]}`}}
                                                         className={classes.PointLeft}/>
                                                    <div style={{backgroundColor: itemData[1]}}
                                                         className={classes.Line}/>
                                                </div>
                                            }
                                            <div className={classes.ImgSpan}>
                                                <img
                                                    src={getData(itemData[2])?.img ? `/img/energymark/${getData(itemData[2])?.img}/${itemData[0]}/${itemData[1]}.png` : ''}
                                                    alt=''/>
                                                <div style={{
                                                    fontSize: getData(itemData[2])?.font,
                                                    top: itemData[0] === 'flame' ? "40%" : "20%"
                                                }}
                                                     className={classes.pointer}>{itemData[2]}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.quantizingRight}>
                    <Grid container>
                        <Grid item xs={6} className={classes.gridDraw6Left}>
                            {
                                showLeftNegative.map((item, index) => {
                                    if (!map?.quantizing[item])
                                        return null
                                    let itemData = _.split(map?.quantizing[item], ',');
                                    const widthIdx = Math.floor(Math.abs(Number(itemData[2])) / 20)
                                    const minWidth = widthImg[itemData[0]][widthIdx] + (Number(itemData[2]) !== 0 ? 20 : 0)
                                    return (
                                        <div style={{
                                            width: `${(Math.abs(Number(itemData[2])) / maxPoint * 100)}%`,
                                            minWidth
                                        }}
                                             key={index} className={classes.quantizingLeftDraw}>
                                            <div className={classes.ImgSpan}>
                                                <img
                                                    src={getData(itemData[2])?.img ? `/img/energymark/${getData(itemData[2])?.img}/${itemData[0]}/${itemData[1]}.png` : ''}
                                                    alt=''/>
                                                <div style={{
                                                    fontSize: getData(itemData[2])?.font,
                                                    top: itemData[0] === 'flame' ? "40%" : "20%"
                                                }}
                                                     className={classes.pointer}>{itemData[2]}</div>
                                            </div>
                                            {
                                                Number(itemData[2]) !== 0 &&
                                                <div className={classes.arrowLine}>
                                                    <div style={{backgroundColor: itemData[1]}}
                                                         className={classes.Line}/>
                                                    <div style={{borderLeft: `20px solid ${itemData[1]}`}}
                                                         className={classes.PointRight}/>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                        <Grid item xs={6} className={classes.gridDraw6}>
                            {
                                showRight.map((item, index) => {
                                    if (!map?.quantizing[item])
                                        return null
                                    let itemData = _.split(map?.quantizing[item], ',');
                                    const widthIdx = Math.floor(Math.abs(Number(itemData[2])) / 20)
                                    const minWidth = widthImg[itemData[0]][widthIdx] + (Number(itemData[2]) !== 0 ? 20 : 0)
                                    return (
                                        <div style={{
                                            width: `${(Number(itemData[2]) / maxPoint * 100)}%`,
                                            minWidth
                                        }} key={index}
                                             className={classes.quantizingRightDraw}>
                                            <div className={classes.arrowLine}>
                                                <div style={{backgroundColor: itemData[1]}} className={classes.Line}/>
                                                <div style={{borderLeft: `20px solid ${itemData[1]}`}}
                                                     className={classes.PointRight}/>
                                            </div>
                                            <div className={classes.ImgSpan}>
                                                <img
                                                    src={getData(itemData[2])?.img ? `/img/energymark/${getData(itemData[2])?.img}/${itemData[0]}/${itemData[1]}.png` : ''}
                                                    alt=''/>
                                                <div style={{
                                                    fontSize: getData(itemData[2])?.font,
                                                    top: itemData[0] === 'flame' ? "40%" : "20%"
                                                }}
                                                     className={classes.pointer}>{itemData[2]}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}

export default QuantizingDrawing;
