import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import rtn from "reactn";
import {makeStyles} from "@material-ui/core/styles";
import {IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useInsightMaps} from "../../../context/InsightMindMapsContext";
import {URL_INSIGHTMIND} from "../../../config/constants";
import copy from 'clipboard-copy'
import {toast} from "react-toastify";
import isEmail from 'validator/lib/isEmail';
import {getInviteEmailBody} from "./emailTemplate";
import {db} from '../../../firebaseConfig'
import {v4 as uuidv4} from 'uuid'

export default function InvitePopup() {
    const classes = useStyles()
    const [openInvite, setOpentInvite] = rtn.useGlobal('openInvite')
    const [user] = rtn.useGlobal('user')
    const [tab, setTab] = useState(1)
    const [emailInvite, setEmailInvite] = useState('')
    const [userNameInvite, setUserNameInvite] = useState('')
    const [loading, setLoading] = useState(false)
    const {map} = useInsightMaps()

    const handleClose = () => {
        setOpentInvite(false);
    };
    const handleCopy = () => {
        const text = `${URL_INSIGHTMIND}/insightmap?id=${map.id}&validToken=${map.token}&uid=${user.user_id}`
        copy(text)
        toast.success("copied")
    }
    const handleSendMail = async () => {
        if (!emailInvite || loading)
            return
        if (!isEmail(emailInvite))
            return toast.error("invalid email !")
        try {
            setLoading(true)
            const url = `${URL_INSIGHTMIND}/insightmap?id=${map.id}&validToken=${map.token}&uid=${user.user_id}`
            const body = getInviteEmailBody({
                nickname: user.nickname,
                email: user.email,
                url,
                homePage: URL_INSIGHTMIND
            })
            const emailId = "ism_" + uuidv4()
            const emailRef = db.doc(`mails/${emailId}`)
            await emailRef.set({
                to: emailInvite,
                from: "no-reply<geniamevents@geniam.com>",
                message: {
                    subject: "Invite to join Insight Mind !",
                    html: body
                }
            })
            toast.success("send email successfully !")
        } catch (e) {

        } finally {
            setLoading(false)
        }


    }
    if (!map || !openInvite)
        return null
    return (
        <Dialog
            className={classes.root}
            open={openInvite}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                ファイルを共有する
                <IconButton className={classes.close}
                            onClick={handleClose}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div className={classes.bodyInvite}>
                    <div className={classes.tab}>
                        <button className={tab === 1 ? classes.tabActive : ''} onClick={() => setTab(1)}>メールアドレスで招待
                        </button>
                        <button className={tab === 2 ? classes.tabActive : ''} onClick={() => setTab(2)}>ユーザー名から招待
                        </button>
                    </div>
                    {tab === 1 &&
                    <div className={classes.positionRelative}>
                        <input
                            className={classes.inputInvite}
                            placeholder={"Ex: email@wowway.com"}
                            value={emailInvite}
                            onChange={(event => setEmailInvite(event.target.value))}
                        />
                        <img className={classes.imgInvite} src="/img/icon/email.png" alt=""/>
                    </div>}
                    {tab === 2 &&
                    <div className={classes.positionRelative}>
                        <input
                            className={classes.inputInvite}
                            placeholder={"ユーザー名"}
                            value={userNameInvite}
                            onChange={(event => setUserNameInvite(event.target.value))}
                        />
                        <img className={classes.imgInvite} src="/img/icon/user.png" alt=""/>
                    </div>

                    }
                </div>


                <div className={classes.divButton}>
                    <button className={classes.btnInvite}
                            onClick={handleSendMail}
                            disabled={!emailInvite}
                    > このファイルに招待
                    </button>
                </div>

                <div className={classes.footerInvite}>
                    <p>共有可能なリンクを取得</p>
                    <div className={classes.positionRelative}>
                        <input className={classes.inputFooterInvite} type="text"/>
                        <a className={classes.linkUrlInvite}
                           href={`${URL_INSIGHTMIND}/insightmap?id=${map.id}&validToken=${map.token}&uid=${user.user_id}`}>
                            {URL_INSIGHTMIND}/insightmap?id={map.id}&validToken={map.token}&uid={user.user_id}
                        </a>
                        <img className={classes.imgFooterInvite} src="/img/icon/url-copy-map.png" alt=""
                             onClick={handleCopy}
                        />
                    </div>
                    <p className={classes.padding50}>このURLを知っているユーザーは、誰でもファイルにアクセスできます。</p>
                </div>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiDialogTitle-root": {
            background: "-webkit-linear-gradient(45deg, rgba(17, 160, 194, 1) 0%, rgba(17, 160, 194, 0.5) 49%, rgba(17, 160, 194, 0.25) 50%, rgba(17, 160, 194, 0.75) 100%)",
            color: "white",
            fontWeight: "bold",
            padding: 15,
            position: 'relative'
        },
        width: '100%',
        '& .MuiDialog-paper': {
            minWidth: '600px',
            position: 'absolute',
            top: "50px",
            right: 0,
            left: 0,
            margin: "auto",
            [theme.breakpoints.down('sm')]: {
                left: 10,
                right: 10,
                minWidth: "auto",
            },
        },
        paddingBottom: 50
    },
    close: {
        float: "right",
        padding: 0
    },
    inputInvite: {
        paddingLeft: 35,
        display: 'block',
        width: '100%',
        height: '34px',
        padding: '6px 12px',
        fontSize: '14px',
        lineHeight: 1.42857143,
        color: '#555',
        backgroundColor: '#fff',
        backgroundImage: 'none',
        border: '1px solid #ccc',
        borderRadius: "4px 4px 0 0",
        position: "relative",
        "&::before": {
            content: '""',
            left: 0,
            right: 0,
            bottom: 1,
            height: 1,
            background: "#fff",
        }
    },

    positionRelative: {
        position: "relative"
    },
    imgInvite: {
        top: '50%',
        transform: 'translate(12px, -9px)',
        left: 0,
        position: 'absolute',

    },
    btnInvite: {
        display: 'inline-block',
        padding: '6px 12px',
        marginBottom: 0,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.42857143,
        // textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        cursor: 'pointer',
        backgroundImage: 'none',
        border: '1px solid transparent',
        borderRadius: 4,
        backgroundColor: '#2bbbad',
        boxShadow: '0 4px rgba(43, 187, 173, 0.5)',
        color: '#fff !important',
        outline: 'none'
    },
    divButton: {
        textAlign: "center",
        marginBottom: 15
    },
    footerInvite: {
        width: '100%',
        overflow: 'hidden',
        margin: '10px 0',
        marginBottom: 30
    },
    inputFooterInvite: {
        height: 40,
        width: '100%',
        position: 'absolute'
    },
    imgFooterInvite: {
        position: 'absolute',
        top: 0,
        left: 0,
        transform: 'translate(2px, 2px)',
        border: '2px solid #000',
        "&:hover": {
            cursor: "pointer"
        }
    },
    linkUrlInvite: {
        position: 'absolute',
        top: 0,
        left: 40,
        wordBreak: 'break-word',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '90%',
        display: 'inline-block',
        margin: 'auto',
        lineHeight: '30px',
        backgroundColor: 'transparent',
        textDecoration: 'none',
        color: '#337ab7',
        transform: ' translate(5px, 3px)'
    },

    bodyInvite: {
        marginBottom: 15,
        marginTop: 15
    },

    tab: {
        borderBottom: "solid 1px #ddd",
        marginBottom: 15,
        "& button": {
            padding: "10px 15px",
            display: "inline-block",
            border: "none",
            color: "#337ab7",
            background: "#fff",
            outline: "none",
            cursor: "pointer",
            [theme.breakpoints.down("sm")]: {
                padding: "5px 7px",
                fontSize: 13,
            }
        },

    },
    tabActive: {
        color: "#555 !important",
        border: "1px solid #ddd !important",
        borderBottomColor: "transparent !important",
        borderRadius: "4px 4px 0  0",
        marginBottom: -1,
    },
    padding50: {
        paddingTop: 50
    }

}))
