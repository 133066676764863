export const maps = [
    {
        id: 'mapping_01',
        path: 'M0,15 L0,110 A110,110 0 0,0 0,-110 L0,-15 A15,15 0 0,1 0,15Z',
        fill: '#dcdedd',
        transform: '',
    },
    {
        id: 'mapping_02',
        path: 'M0,15 L0,110 A110,110 0 0,0 0,-110 L0,-15 A15,15 0 0,1 0,15Z',
        fill: '#ffffff',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: 'mapping_03',
        path: 'M110,0 L220,0 A220,220 0 0,0 0,-220 L0,-110 A110,110 0 0,1 110,0Z',
        fill: '#ffffff',
        transform: '',
    },
    {
        id: 'mapping_04',
        path: 'M110,0 L220,0 A220,220 0 0,0 0,-220 L0,-110 A110,110 0 0,1 110,0Z',
        fill: '#dcdedd',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: 'mapping_05',
        path: 'M110,0 L220,0 A220,220 0 0,0 0,-220 L0,-110 A110,110 0 0,1 110,0Z',
        fill: '#dcdedd',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: 'mapping_06',
        path: 'M110,0 L220,0 A220,220 0 0,0 0,-220 L0,-110 A110,110 0 0,1 110,0Z',
        fill: '#ffffff',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: 'mapping_07',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#ffffff',
        transform: '',
    },
    {
        id: 'mapping_08',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#dcdedd',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: 'mapping_09',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#ffffff',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: 'mapping_10',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#dcdedd',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: 'mapping_11',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#dcdedd',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: 'mapping_12',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#ffffff',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: 'mapping_13',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#dcdedd',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: 'mapping_14',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#ffffff',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: 'mapping_15',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: '',
    },
    {
        id: 'mapping_16',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(0.9239,0.3827,-0.3827,0.9239,0,0)',
    },
    {
        id: 'mapping_17',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: 'mapping_18',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(0.3827,0.9239,-0.9239,0.3827,0,0)',
    },
    {
        id: 'mapping_19',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: 'mapping_20',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)',
    },
    {
        id: 'mapping_21',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: 'mapping_22',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)',
    },
    {
        id: 'mapping_23',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: 'mapping_24',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: 'matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)',
    },
    {
        id: 'mapping_25',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: 'mapping_26',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: 'matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)',
    },
    {
        id: 'mapping_27',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: 'mapping_28',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: 'matrix(0.3827,-0.9239,0.9239,0.3827,0,0)',
    },
    {
        id: 'mapping_29',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: 'mapping_30',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: 'matrix(0.9239,-0.3827,0.3827,0.9239,0,0)',
    },
    {
        id: 'mapping_31',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: '',
        stroke: 'none',
    },
    {
        id: 'mapping_32',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(0.9239,0.3827,-0.3827,0.9239,0,0)',
        stroke: 'none',
    },
    {
        id: 'mapping_33',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
        stroke: 'none',
    },
    {
        id: 'mapping_34',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(0.3827,0.9239,-0.9239,0.3827,0,0)',
        stroke: 'none',
    },
    {
        id: 'mapping_35',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(0,1,-1,0,0,0)',
        stroke: 'none',
    },
    {
        id: 'mapping_36',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)',
        stroke: 'none',
    },
    {
        id: 'mapping_37',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
        stroke: 'none',
    },
    {
        id: 'mapping_38',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)',
        stroke: 'none',
    },
    {
        id: 'mapping_39',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(-1,0,0,-1,0,0)',
        stroke: 'none',
    },
    {
        id: 'mapping_40',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)',
        stroke: 'none',
    },
    {
        id: 'mapping_41',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
        stroke: 'none',
    },
    {
        id: 'mapping_42',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)',
        stroke: 'none',
    },
    {
        id: 'mapping_43',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(0,-1,1,0,0,0)',
        stroke: 'none',
    },
    {
        id: 'mapping_44',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(0.3827,-0.9239,0.9239,0.3827,0,0)',
        stroke: 'none',
    },
    {
        id: 'mapping_45',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
        stroke: 'none',
    },
    {
        id: 'mapping_46',
        path:
            'M168.388,-406.516 L210.48499999999999,-508.14500000000004 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 168.388,-406.516Z',
        fill: '#f7f7f7',
        transform: 'matrix(0.9239,-0.3827,0.3827,0.9239,0,0)',
        stroke: 'none',
    },
];

export const miniMaps = [
    {
        id: 'mapping_01',
        path: 'M0,15 L0,110 A110,110 0 0,0 0,-110 L0,-15 A15,15 0 0,1 0,15Z',
        fill: '#dcdedd',
        transform: '',
    },
    {
        id: 'mapping_02',
        path: 'M0,15 L0,110 A110,110 0 0,0 0,-110 L0,-15 A15,15 0 0,1 0,15Z',
        fill: '#ffffff',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: 'mapping_03',
        path: 'M110,0 L220,0 A220,220 0 0,0 0,-220 L0,-110 A110,110 0 0,1 110,0Z',
        fill: '#ffffff',
        transform: '',
    },
    {
        id: 'mapping_04',
        path: 'M110,0 L220,0 A220,220 0 0,0 0,-220 L0,-110 A110,110 0 0,1 110,0Z',
        fill: '#dcdedd',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: 'mapping_05',
        path: 'M110,0 L220,0 A220,220 0 0,0 0,-220 L0,-110 A110,110 0 0,1 110,0Z',
        fill: '#dcdedd',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: 'mapping_06',
        path: 'M110,0 L220,0 A220,220 0 0,0 0,-220 L0,-110 A110,110 0 0,1 110,0Z',
        fill: '#ffffff',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: 'mapping_07',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#ffffff',
        transform: '',
    },
    {
        id: 'mapping_08',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#dcdedd',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: 'mapping_09',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#ffffff',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: 'mapping_10',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#dcdedd',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: 'mapping_11',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#dcdedd',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: 'mapping_12',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#ffffff',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: 'mapping_13',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#dcdedd',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: 'mapping_14',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#ffffff',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: 'mapping_15',
        path: 'M311.12399999999997,-311.12399999999997 L311.12,-311.12 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 233.12399999999997,-233.12399999999997Z',
        fill: 'transparent',
        transform: '',
    },
    {
        id: 'mapping_16',
        path: 'M311.12399999999997,-311.12399999999997 L311.12,-311.12 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 233.12399999999997,-233.12399999999997Z',
        fill: 'transparent',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: 'mapping_17',
        path: 'M311.12399999999997,-311.12399999999997 L311.12,-311.12 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 233.12399999999997,-233.12399999999997Z',
        fill: 'transparent',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: 'mapping_18',
        path: 'M311.12399999999997,-311.12399999999997 L311.12,-311.12 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 233.12399999999997,-233.12399999999997Z',
        fill: 'transparent',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: 'mapping_19',
        path: 'M311.12399999999997,-311.12399999999997 L311.12,-311.12 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 233.12399999999997,-233.12399999999997Z',
        fill: 'transparent',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: 'mapping_20',
        path: 'M311.12399999999997,-311.12399999999997 L311.12,-311.12 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 233.12399999999997,-233.12399999999997Z',
        fill: 'transparent',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: 'mapping_21',
        path: 'M311.12399999999997,-311.12399999999997 L311.12,-311.12 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 233.12399999999997,-233.12399999999997Z',
        fill: 'transparent',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: 'mapping_22',
        path: 'M311.12399999999997,-311.12399999999997 L311.12,-311.12 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 233.12399999999997,-233.12399999999997Z',
        fill: 'transparent',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
];

export const mapAdvance = [
    {
        id: 'mapping_01',
        path: 'M0,15 L0,110 A110,110 0 0,0 0,-110 L0,-15 A15,15 0 0,1 0,15Z',
        fill: '#dcdedd',
        transform: '',
    },
    {
        id: 'mapping_02',
        path: 'M0,15 L0,110 A110,110 0 0,0 0,-110 L0,-15 A15,15 0 0,1 0,15Z',
        fill: '#ffffff',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: 'mapping_03',
        path: 'M110,0 L220,0 A220,220 0 0,0 0,-220 L0,-110 A110,110 0 0,1 110,0Z',
        fill: '#ffffff',
        transform: '',
    },
    {
        id: 'mapping_04',
        path: 'M110,0 L220,0 A220,220 0 0,0 0,-220 L0,-110 A110,110 0 0,1 110,0Z',
        fill: '#dcdedd',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: 'mapping_05',
        path: 'M110,0 L220,0 A220,220 0 0,0 0,-220 L0,-110 A110,110 0 0,1 110,0Z',
        fill: '#dcdedd',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: 'mapping_06',
        path: 'M110,0 L220,0 A220,220 0 0,0 0,-220 L0,-110 A110,110 0 0,1 110,0Z',
        fill: '#ffffff',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: 'mapping_07',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#ffffff',
        transform: '',
    },
    {
        id: 'mapping_08',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#dcdedd',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: 'mapping_09',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#ffffff',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: 'mapping_10',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#dcdedd',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: 'mapping_11',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#dcdedd',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: 'mapping_12',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#ffffff',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: 'mapping_13',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#dcdedd',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: 'mapping_14',
        path:
            'M155.56199999999998,-155.56199999999998 L233.343,-233.343 A330,330 0 0,0 0,-330 L0,-220 A220,220 0 0,1 155.56199999999998,-155.56199999999998Z',
        fill: '#ffffff',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: 'mapping_15',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: '',
    },
    {
        id: 'mapping_16',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(0.9239,0.3827,-0.3827,0.9239,0,0)',
    },
    {
        id: 'mapping_17',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: 'mapping_18',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(0.3827,0.9239,-0.9239,0.3827,0,0)',
    },
    {
        id: 'mapping_19',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: 'mapping_20',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(-0.3827,0.9239,-0.9239,-0.3827,0,0)',
    },
    {
        id: 'mapping_21',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: 'mapping_22',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(-0.9239,0.3827,-0.3827,-0.9239,0,0)',
    },
    {
        id: 'mapping_23',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: 'mapping_24',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: 'matrix(-0.9239,-0.3827,0.3827,-0.9239,0,0)',
    },
    {
        id: 'mapping_25',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: 'mapping_26',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: 'matrix(-0.3827,-0.9239,0.9239,-0.3827,0,0)',
    },
    {
        id: 'mapping_27',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: 'mapping_28',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: 'matrix(0.3827,-0.9239,0.9239,0.3827,0,0)',
    },
    {
        id: 'mapping_29',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#dcdedd',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: 'mapping_30',
        path: 'M126.291,-304.887 L168.388,-406.516 A440,440 0 0,0 0,-440 L0,-330 A330,330 0 0,1 126.291,-304.887Z',
        fill: '#ffffff',
        transform: 'matrix(0.9239,-0.3827,0.3827,0.9239,0,0)',
    },
    {
        id: 'mapping_31',
        path: 'M311.12399999999997,-311.12399999999997 L388.905,-388.905 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 311.12399999999997,-311.12399999999997Z',
        fill: '#dcdedd',
        transform: '',
    },
    {
        id: 'mapping_32',
        path: 'M311.12399999999997,-311.12399999999997 L388.905,-388.905 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 311.12399999999997,-311.12399999999997Z',
        fill: '#ffffff',
        transform: 'matrix(0.7071,0.7071,-0.7071,0.7071,0,0)',
    },
    {
        id: 'mapping_33',
        path: 'M311.12399999999997,-311.12399999999997 L388.905,-388.905 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 311.12399999999997,-311.12399999999997Z',
        fill: '#dcdedd',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: 'mapping_34',
        path: 'M311.12399999999997,-311.12399999999997 L388.905,-388.905 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 311.12399999999997,-311.12399999999997Z',
        fill: '#ffffff',
        transform: 'matrix(-0.7071,0.7071,-0.7071,-0.7071,0,0)',
    },
    {
        id: 'mapping_35',
        path: 'M311.12399999999997,-311.12399999999997 L388.905,-388.905 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 311.12399999999997,-311.12399999999997Z',
        fill: '#ffffff',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: 'mapping_36',
        path: 'M311.12399999999997,-311.12399999999997 L388.905,-388.905 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 311.12399999999997,-311.12399999999997Z',
        fill: '#dcdedd',
        transform: 'matrix(-0.7071,-0.7071,0.7071,-0.7071,0,0)',
    },
    {
        id: 'mapping_37',
        path: 'M311.12399999999997,-311.12399999999997 L388.905,-388.905 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 311.12399999999997,-311.12399999999997Z',
        fill: '#ffffff',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: 'mapping_38',
        path: 'M311.12399999999997,-311.12399999999997 L388.905,-388.905 A550,550 0 0,0 0,-550 L0,-440 A440,440 0 0,1 311.12399999999997,-311.12399999999997Z',
        fill: '#dcdedd',
        transform: 'matrix(0.7071,-0.7071,0.7071,0.7071,0,0)',
    },
    {
        id: 'mapping_39',
        path: 'M550,0 L660,0 A660,660 0 0,0 0,-660 L0,-550 A550,550 0 0,1 550,0Z',
        fill: '#dcdedd',
        transform: '',
    },
    {
        id: 'mapping_40',
        path: 'M550,0 L660,0 A660,660 0 0,0 0,-660 L0,-550 A550,550 0 0,1 550,0Z',
        fill: '#ffffff',
        transform: 'matrix(0,1,-1,0,0,0)',
    },
    {
        id: 'mapping_41',
        path: 'M550,0 L660,0 A660,660 0 0,0 0,-660 L0,-550 A550,550 0 0,1 550,0Z',
        fill: '#ffffff',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },
    {
        id: 'mapping_42',
        path: 'M550,0 L660,0 A660,660 0 0,0 0,-660 L0,-550 A550,550 0 0,1 550,0Z',
        fill: '#dcdedd',
        transform: 'matrix(0,-1,1,0,0,0)',
    },
    {
        id: 'mapping_43',
        path: 'M0,660 L0,770 A770,770 0 0,0 0,-770 L0,-660 A660,660 0 0,1 0,660Z',
        fill: '#ffffff',
        transform: '',
    },
    {
        id: 'mapping_44',
        path: 'M0,660 L0,770 A770,770 0 0,0 0,-770 L0,-660 A660,660 0 0,1 0,660Z',
        fill: '#dcdedd',
        transform: 'matrix(-1,0,0,-1,0,0)',
    },

]
